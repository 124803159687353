import BaseError from '.';

class NotFoundError extends BaseError {
	constructor(error = NotFoundError.ERROR_NOT_FOUND, variables) {
		super('Not Found', error, variables);

		this.code = 400;
	}
}

NotFoundError.ERROR_NOT_FOUND = [40400000, 'Not Found'];
NotFoundError.ERROR_NOT_FOUND_PRODUCT = [40406001, 'Product not found'];

export default NotFoundError;
