import mitt from 'mitt';
import { useCallback, useEffect } from 'react';
export const BarcodeEvent = mitt();

if (window.electron) {
	window.electron.receive('barcode-scanner-scan', (barcode: string) => {
		BarcodeEvent.emit('barcode', barcode.trim());
	});
}

export function useBarcodeScanner(listener: (barcode: string) => void) {
	useEffect(() => {
		BarcodeEvent.on('barcode', listener);
		return () => {
			BarcodeEvent.off('barcode', listener);
		};
	}, [listener]);
}
