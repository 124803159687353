import { t, Trans } from '@lingui/macro';
import {
	Card,
	Col,
	Divider,
	Drawer,
	Row,
	Spin,
	Statistic,
	Table,
	Tabs,
	Tag,
	Typography,
} from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useSize, useUpdateEffect } from 'react-use';

import styles from './ReceiptsDay.module.less';
import globalStyles from '../../../../../../assets/styles/global.module.less';
import { useDrawer } from '../../../../../../components/Page';
import { StaticComponents } from '../../../../../../components/StaticComponents';
import { Title } from '../../../../../../components/Title';
import {
	getPaymentTypeTextShort,
	getTransactionTypeText,
	PAYMENT_TYPE_FROM_STRING,
	TRANSACTION_TYPE_FROM_STRING,
} from '../../../../../../constants/journal';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
const ReceiptsDay = () => {
	const PAYMENT_TYPE_TEXT_SHORT = getPaymentTypeTextShort();
	const TRANSACTION_TYPE_TEXT = getTransactionTypeText();

	const [dayParams, open, close, visible] = useDrawer('receipts-day');
	const [, openReceipt, , , , ReceiptViewDrawer] =
		useDrawer('view/receipts/list');
	const [, openProduct, , , , ProductEditDrawer] = useDrawer(
		'view/catalog/products'
	);
	const {
		dailyReceipts: { byDay, isFetching },
		products: { getById },
	} = stores;

	const [date, tab = 'receipts', currentStore] = useMemo(() => {
		return dayParams ? dayParams.split(',') : [];
	}, [dayParams]);

	const singleDay = useMemo(() => {
		if (!date) {
			return;
		}
		const formattedDate = dayjs(date).format('YYYY-MM-DD');

		return !isFetching ? byDay?.[formattedDate] : null;
	}, [byDay, date, isFetching]);

	useEffect(() => {
		const e = new Event('resize');
		window.dispatchEvent(e);
	}, [singleDay?.details?.receipts, singleDay?.receiptItems]);

	useUpdateEffect(() => {
		if (date && !isFetching) {
			singleDay?.fetchDetails?.(currentStore);
		}
	}, [date, isFetching, singleDay, currentStore]);

	const sales = useMemo(
		() =>
			Object.entries(singleDay?.details?.stats?.sales || []).filter(
				([, value]) => value > 0
			),
		[singleDay?.details]
	);

	const refunds = useMemo(
		() =>
			Object.entries(singleDay?.details?.stats?.refunds || []).filter(
				([, value]) => value > 0
			),
		[singleDay?.details]
	);

	const totals = useMemo(
		() =>
			Object.keys(PAYMENT_TYPE_FROM_STRING).filter(
				(key) =>
					singleDay?.details?.stats?.sales?.[key] ||
					0 > 0 ||
					singleDay?.details?.stats?.refunds?.[key] ||
					0 > 0
			),
		[singleDay?.details]
	);

	const salesSum = useMemo(
		() => sales.reduce((sum, [, value]) => sum + value, 0),
		[sales]
	);

	const refundsSum = useMemo(
		() => refunds.reduce((sum, [, value]) => sum + value, 0),
		[refunds]
	);

	const totalSum = useMemo(() => salesSum - refundsSum, [salesSum, refundsSum]);

	const [details, { height: detailsHeight }] = useSize(() => (
		<Row gutter={[8, 8]} style={{ backgroundColor: 'white' }}>
			<Col xs={24} md={8}>
				<Card className={styles.card}>
					<>
						<Statistic
							title={
								<Title>
									<Trans>Продаје</Trans>
								</Title>
							}
							value={numberFormat(salesSum, false, 2, true)}
							valueStyle={{ color: '#52c41a' }}
							style={{ marginBottom: sales.length ? 8 : 0 }}
							prefix={
								<Typography.Text type="success" style={{ fontSize: 24 }}>
									<i className="fi fi-rr-arrow-up" />
								</Typography.Text>
							}
						/>
						{sales.map(([key, amount]) => (
							<Row key={key}>
								<Col flex="none">
									<Typography.Text>
										{PAYMENT_TYPE_TEXT_SHORT[PAYMENT_TYPE_FROM_STRING[key]]}
									</Typography.Text>
								</Col>
								<Col flex="auto" style={{ textAlign: 'right' }}>
									<Divider
										orientation="right"
										plain
										dashed
										orientationMargin={16}
									>
										{numberFormat(amount, false, 2, true)}
									</Divider>
								</Col>
							</Row>
						))}
					</>
				</Card>
			</Col>
			<Col xs={24} md={8}>
				<Card className={styles.card}>
					<>
						<Statistic
							title={
								<Title>
									<Trans>Рефундације</Trans>
								</Title>
							}
							value={numberFormat(refundsSum, false, 2, true)}
							valueStyle={{ color: '#ff4d4f' }}
							style={{ marginBottom: refunds.length ? 8 : 0 }}
							prefix={
								<Typography.Text type="danger" style={{ fontSize: 24 }}>
									<i className="fi fi-rr-arrow-down" />
								</Typography.Text>
							}
						/>
						{refunds.map(([key, amount]) => (
							<Row key={key}>
								<Col flex="none">
									<Typography.Text>
										{PAYMENT_TYPE_TEXT_SHORT[PAYMENT_TYPE_FROM_STRING[key]]}
									</Typography.Text>
								</Col>
								<Col flex="auto" style={{ textAlign: 'right' }}>
									<Divider
										orientation="right"
										plain
										dashed
										orientationMargin={16}
									>
										{numberFormat(amount, false, 2, true)}
									</Divider>
								</Col>
							</Row>
						))}
					</>
				</Card>
			</Col>
			<Col xs={24} md={8}>
				<Card className={styles.card}>
					<>
						<Statistic
							title={
								<Title>
									<Trans>Укупно</Trans>
								</Title>
							}
							value={numberFormat(totalSum, false, 2, true)}
							style={{ marginBottom: totals.length ? 8 : 0 }}
							prefix={
								<Typography.Text style={{ fontSize: 24 }}>＝</Typography.Text>
							}
						/>
						{totals.map((key) => (
							<Row key={key}>
								<Col flex="none">
									<Typography.Text>
										{PAYMENT_TYPE_TEXT_SHORT[PAYMENT_TYPE_FROM_STRING[key]]}
									</Typography.Text>
								</Col>
								<Col flex="auto" style={{ textAlign: 'right' }}>
									<Divider
										orientation="right"
										plain
										dashed
										orientationMargin={16}
									>
										{numberFormat(
											(singleDay?.details?.stats?.sales?.[key] || 0) -
												(singleDay?.details?.stats?.refunds?.[key] || 0),
											false,
											2,
											true
										)}
									</Divider>
								</Col>
							</Row>
						))}
					</>
				</Card>
			</Col>
		</Row>
	));

	return (
		<Drawer
			open={visible}
			width={900}
			title={t`Преглед дана ${dayjs(date).format('DD.MM.YYYY')}`}
			onClose={close}
			// zIndex={zIndex}
			className={styles.drawer}
		>
			<Spin spinning={singleDay?.isFetchingDetails}>
				<>
					{details}
					<Tabs
						className={styles.tabs}
						activeKey={tab}
						onChange={(activeKey) =>
							open(`${date},${activeKey},${currentStore}`)
						}
					>
						<Tabs.TabPane tab={t`По рачунима`} key="receipts" class>
							<div className={globalStyles.container}>
								<Table
									size="small"
									dataSource={singleDay?.details?.receipts || []}
									columns={[
										{
											title: t`Број рачуна`,
											key: 'invoiceNumber',
											render: (record) => (
												<Typography.Link onClick={() => openReceipt(record.id)}>
													{record.invoiceNumber}
												</Typography.Link>
											),
										},
										{
											title: t`Време`,
											key: 'sdcTime',
											render(record) {
												return dayjs(record.sdcTime).format('HH:mm:ss');
											},
											width: 90,
										},
										{
											title: t`Врста трансакције`,
											ellipsis: true,
											key: 'transactionType',
											dataIndex: 'transactionType',
											render(text) {
												return TRANSACTION_TYPE_TEXT[
													TRANSACTION_TYPE_FROM_STRING[text]
												];
											},
											width: 150,
										},
										{
											title: t`Начини плаћања`,
											key: 'paymentType',
											width: 200,
											render(record) {
												return record.payment.map((payment) => (
													<Tag
														bordered={false}
														title={numberFormat(payment.amount, false, 2, true)}
													>
														{
															PAYMENT_TYPE_TEXT_SHORT[
																PAYMENT_TYPE_FROM_STRING[payment.paymentType]
															]
														}
													</Tag>
												));
											},
										},
										{
											title: t`Износ`,
											dataIndex: 'totalAmount',
											key: 'totalAmount',
											render(text) {
												return numberFormat(text, true, 2, true);
											},
											width: 150,
										},
									]}
									pagination={false}
									scroll={{ x: 830 }}
								/>
							</div>
						</Tabs.TabPane>
						<Tabs.TabPane tab={t`По артиклима`} key="items">
							<div className={globalStyles.container}>
								<Table
									size="small"
									dataSource={(singleDay?.receiptItems || []) as any[]}
									columns={[
										{
											title: t`Шифра`,
											key: 'sku',
											render: (record) => (
												<Typography.Link
													onClick={() => {
														const product = getById(record.id);
														const parent = product.parentId
															? getById(product.parentId)
															: null;
														if (
															!product ||
															product.deletedAt ||
															(parent && parent.deletedAt)
														) {
															return StaticComponents.notification.error({
																message: t`Грешка`,
																description: t`Артикал ${record.name} је обрисан`,
															});
														}
														openProduct(
															product.parentId ? product.parentId : record.id
														);
													}}
												>
													{record.sku}
												</Typography.Link>
											),
											width: 100,
										},
										{
											title: t`Назив`,
											key: 'name',
											render: (record) => (
												<Typography.Link
													onClick={() => {
														const product = getById(record.id);
														const parent = product.parentId
															? getById(product.parentId)
															: null;
														if (
															!product ||
															product.deletedAt ||
															(parent && parent.deletedAt)
														) {
															return StaticComponents.notification.error({
																message: t`Грешка`,
																description: t`Артикал ${record.name} је обрисан`,
															});
														}
														openProduct(
															product.parentId ? product.parentId : record.id
														);
													}}
												>
													{record.name}
												</Typography.Link>
											),
										},
										{
											title: t`Количина`,
											key: 'quantity',
											render(record) {
												return `${numberFormat(
													record.quantity,
													false,
													3,
													false
												)}${` ${record.unit || ''}`}`;
											},
											width: 100,
										},
										{
											title: t`Цена`,
											key: 'unitPrice',
											dataIndex: 'unitPrice',
											render(text) {
												return numberFormat(text, true, 2, true);
											},
											width: 150,
										},
										{
											title: t`Вредност`,
											key: 'totalAmount',
											render(record) {
												return numberFormat(
													record.unitPrice * record.quantity,
													true,
													2,
													true
												);
											},
											width: 150,
										},
									]}
									pagination={false}
									scroll={{ x: 800 }}
								/>
							</div>
						</Tabs.TabPane>
					</Tabs>
				</>
			</Spin>
			<ReceiptViewDrawer />
			<ProductEditDrawer />
		</Drawer>
	);
};

export default observer(ReceiptsDay);
