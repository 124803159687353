import { LoadingOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { Button, Upload } from 'antd';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useState } from 'react';
import DisabledContext from 'antd/es/config-provider/DisabledContext';

import { v2Client } from '../../store/client';

type Props = {
	record: any;
	recordProperty: string;
	resource: string;
	onChange?: any;
	value?: any;
};

const SingleImageUpload = ({
	record,
	recordProperty,
	resource,
	onChange,
}: Props) => {
	const disabled = useContext(DisabledContext);

	const [isLoading, setIsLoading] = useState(false);
	const [previewUrl, setPreviewUrl] = useState(
		record?.[recordProperty]?.urls?.['256x256']
	);

	useEffect(() => {
		setPreviewUrl(record?.[recordProperty]?.urls?.['256x256']);
	}, [record, recordProperty]);

	const handleChange = ({ file }) => {
		if (file.status === 'uploading') {
			setPreviewUrl(null);
			onChange({ status: 'uploading' });
			setIsLoading(true);
		}
		if (file.status === 'done') {
			onChange({
				id: file.response.id,
			});
			setIsLoading(false);
			setPreviewUrl(file.response.urls['256x256']);
		}
		if (file.status === 'error') {
			setPreviewUrl(null);
			setIsLoading(false);
		}
	};

	const uploadButton = useMemo(
		() => (
			<div>
				{isLoading ? (
					<LoadingOutlined />
				) : (
					<>
						<img
							src="/images/icons/new/cloud-computing.svg"
							alt=""
							width={32}
						/>

						<div style={{ marginTop: 8 }}>
							<Trans>Отпреми</Trans>
						</div>
					</>
				)}
			</div>
		),
		[isLoading]
	);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			}}
		>
			<Upload
				disabled={isLoading || disabled}
				name={'file'}
				listType="picture-card"
				maxCount={1}
				showUploadList={false}
				accept={'.jpg, .jpeg, .png'}
				onChange={handleChange}
				headers={{
					'x-access-token': v2Client.defaults.headers.common['x-access-token'],
				}}
				action={`${v2Client.defaults.baseURL}/files/${resource}`}
			>
				{previewUrl ? (
					<img src={previewUrl} alt={t`Преглед`} style={{ width: '100%' }} />
				) : (
					uploadButton
				)}
			</Upload>
			{previewUrl && (
				<Button
					disabled={isLoading || disabled}
					onClick={() => {
						setPreviewUrl(null);
						onChange(undefined);
					}}
					icon={
						isLoading ? <LoadingOutlined /> : <i className="fi fi-rr-trash"></i>
					}
				>
					{isLoading ? t`Замењујем...` : t`Уклони тренутну слику`}
				</Button>
			)}
		</div>
	);
};

export default observer(SingleImageUpload);
