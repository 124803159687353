import { t, Trans } from '@lingui/macro';
import { Input, Button, Form, Divider } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Link, Navigate } from 'react-router-dom';

import styles from './Login.module.less';
import { LanguagePicker } from '../../components/LanguagePicker';
import { getApplicationName } from '../../constants/application';
import stores from '../../stores/index.mobx';

const { Password } = Input;

function Login() {
	const APPLICATION_NAME = getApplicationName();
	const {
		users: { login, isFetching, token },
	} = stores;

	const [form] = Form.useForm();
	const [loginError, setLoginError] = useState(null);

	async function handleSubmit({ email, password }) {
		try {
			await login(email, password);
		} catch (e) {
			setLoginError(t`Адреса електронске поште или лозинка нису исправни`);
		}
	}

	function onValuesChange() {
		setLoginError(null);
	}

	return (
		<DocumentTitle title={t`${APPLICATION_NAME} - Пријављивање`}>
			<>
				<div className={styles.wrapper}>
					<div className={styles.logoFace}>
						<img src="/images/logo-face.svg" />
					</div>
					<div className={styles.container}>
						{token && <Navigate to="/app" />}
						<div className={styles.content}>
							<Form
								onFinish={handleSubmit}
								className={styles.form}
								form={form}
								onValuesChange={onValuesChange}
								validateTrigger="onSubmit"
								layout="vertical"
							>
								<Form.Item
									rules={[
										{
											required: true,
											message: t`Унесите адресу електронске поште!`,
										},
										{
											type: 'email',
											message: t`Адреса електронске поште је неисправна!`,
										},
									]}
									name="email"
									validateStatus={loginError ? 'error' : undefined}
								>
									<Input
										size="large"
										prefix={<i className="fi fi-rr-envelope"></i>}
										placeholder={t`Адреса електронске поште`}
									/>
								</Form.Item>
								<Form.Item
									name="password"
									rules={[{ required: true, message: t`Унесите лозинку!` }]}
									validateStatus={loginError ? 'error' : undefined}
									help={loginError}
								>
									<Password
										size="large"
										prefix={<i className="fi fi-rr-lock"></i>}
										type="password"
										placeholder={t`Лозинка`}
									/>
								</Form.Item>
								<Form.Item>
									<Button
										size="large"
										loading={isFetching}
										type="primary"
										htmlType="submit"
										block
									>
										<Trans>Пријави се</Trans>
									</Button>
								</Form.Item>
								<Divider>
									<Trans>или</Trans>
								</Divider>
								<Form.Item>
									<Link to="/register">
										<Button size="large" type="link" block>
											<Trans>Отвори нови налог</Trans>
										</Button>
									</Link>
								</Form.Item>
							</Form>
							<div className={styles.copyright}>
								<LanguagePicker />
								<Divider type="vertical" />
								<Trans>Copyright © 2023 DVSoftware. Сва права задржана.</Trans>
							</div>
						</div>
					</div>
				</div>
			</>
		</DocumentTitle>
	);
}

export default observer(Login);
