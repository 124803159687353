import { t, Trans } from '@lingui/macro';
import { Button, Empty, Table } from 'antd';
import { useCallback, useState } from 'react';

import ContactEditDrawer from './Components/ContactEditDrawer';
import styles from './ContactsInput.module.less';

type Contact = {
	name?: string;
	role?: string;
	phone?: string;
	email?: string;
	id: string;
};

type Props = {
	value?: Contact[];
	onChange?: (value: Contact[]) => void;
};

function ContactsInput(props: Props) {
	const { value = [], onChange = () => null } = props;
	const [
		[editContactVisible, editContactEntity, editContactIndex],
		setEditContact,
	] = useState([false, null, null]);

	const save = useCallback(
		(contact) => {
			const newContacts = [...(value || [])];
			if (editContactIndex !== null) {
				newContacts[editContactIndex] = {
					...newContacts[editContactIndex],
					...contact,
				};
			} else {
				newContacts.push(contact);
			}
			onChange(newContacts);
			setEditContact([false, null, null]);
		},
		[editContactIndex, value, setEditContact]
	);

	function handleDeleteContact(index) {
		const newContacts = (value || []).slice();
		newContacts.splice(index, 1);

		onChange(newContacts);
	}

	if ((value || []).length === 0) {
		return (
			<div className={styles.container}>
				<Empty
					image={<img src="/images/icons/new/phone-book.svg" alt="Kontakti" />}
					imageStyle={{
						height: 64,
					}}
					description={t`Нема контаката`}
				>
					<Button
						type="link"
						onClick={() => {
							setEditContact([true, null, null]);
						}}
					>
						<Trans>Додај контакт</Trans>
					</Button>
				</Empty>
				<ContactEditDrawer
					visible={editContactVisible}
					entity={editContactEntity}
					save={save}
					close={() => setEditContact([false, null, null])}
				/>
			</div>
		);
	}

	return (
		<>
			<div className={styles.container}>
				<Table
					pagination={false}
					size="small"
					rowKey="id"
					dataSource={value || []}
					footer={() => (
						<Button onClick={() => setEditContact([true, null, null])}>
							<Trans>Додај контакт</Trans>
						</Button>
					)}
					scroll={{ x: 800 }}
				>
					<Table.Column title={t`Име`} dataIndex="name" key="name" ellipsis />
					<Table.Column
						title={t`Улога`}
						dataIndex="role"
						key="role"
						width={120}
						ellipsis
					/>
					<Table.Column
						title={t`Телефон`}
						dataIndex="phone"
						key="phone"
						width={120}
						ellipsis
					/>
					<Table.Column
						title={t`Моб. телефон`}
						dataIndex="mobilePhone"
						key="mobilePhone"
						width={120}
						ellipsis
					/>
					<Table.Column
						title={t`Електронска пошта`}
						dataIndex="email"
						key="email"
						width={200}
						ellipsis
					/>
					<Table.Column
						title=""
						width={80}
						fixed="right"
						render={(text, record, index) => (
							<Button.Group>
								<Button
									icon={<i className="fi fi-rr-pencil" />}
									onClick={() => {
										setEditContact([true, record, index]);
									}}
								/>
								<Button
									icon={<i className="fi fi-rr-trash" />}
									onClick={() => handleDeleteContact(index)}
								/>
							</Button.Group>
						)}
					/>
				</Table>
			</div>
			<ContactEditDrawer
				visible={editContactVisible}
				entity={editContactEntity}
				save={save}
				close={() => setEditContact([false, null, null])}
			/>
		</>
	);
}

export default ContactsInput;
