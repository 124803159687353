import { ThermalPrinter } from './thermalPrinter';
import enUS from '../assets/locales/journal/en-US.json';
import srCyrlRS from '../assets/locales/journal/sr-Cyrl-RS.json';
import srLatnRS from '../assets/locales/journal/sr-Latn-RS.json';
import stores from '../stores/index.mobx';
import { Store } from '../stores/Store.mobx';
import { Receipt } from '../stores/Receipt.mobx';
import VariableFontPosTerminal from '../components/VariableFontPosTerminal';

const locales = {
	'en-US': enUS,
	'sr-Cyrl-RS': srCyrlRS,
	'sr-Latn-RS': srLatnRS,
};

export class VariableFontPosTerminalJournal {
	locale: Record<string, string>;
	language: string;
	store: Store;
	posTerminalResponse: Receipt['posTerminalResponse'];
	type: 'acquirer' | 'buyer' = 'acquirer';
	printer = null;

	constructor(
		store: Store,
		posTerminalResponse: Receipt['posTerminalResponse'],
		type: 'acquirer' | 'buyer' = 'acquirer'
	) {
		const language = stores.stores.currentStore?.language || 'sr-Cyrl-RS';
		this.language = language;

		this.store = store;
		this.locale = locales[this.language];
		this.posTerminalResponse = posTerminalResponse;
		this.type = type;

		this.printer = new ThermalPrinter();
	}

	getRenderDeferred() {
		return Promise.resolve();
	}

	getComponent() {
		return (
			<VariableFontPosTerminal
				locale={this.locale}
				store={this.store}
				posTerminalResponse={this.posTerminalResponse}
				type={this.type}
				language={this.language}
			/>
		);
	}
}
