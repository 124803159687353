import { t } from '@lingui/macro';
import { Button } from 'antd';

import EditForm from './Components/EditForm';
import styles from './Devices.module.less';
import { useDrawer } from '../../../../components/Page';
import { CreatePage } from '../../../../components/Page';
import {
	DEVICE_CONNECTION_TYPE,
	getDeviceTypeName,
} from '../../../../constants/device';
import './Drawers/Configure';

function DeviceActions({ record }) {
	const [, openConfigureDrawer, , , , ConfigureDrawer] =
		useDrawer('configure-device');

	return (
		// <HasPermission module="documents" submodule="calculations" action="edit">
		(record.type === 'thermal_printer' || record.type === 'pos_terminal') && (
			<>
				<Button
					key="configureButton"
					icon={<i className="fi fi-rr-settings-sliders"></i>}
					onClick={() => {
						openConfigureDrawer(record.id);
					}}
				/>
				<ConfigureDrawer />
			</>
		)
		// </HasPermission>
	);
}

export default () => {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const DEVICE_TYPE_NAME = getDeviceTypeName();
	return CreatePage({
		module: 'settings',
		submodule: 'devices',
		model: 'device',
		edit: {
			width: 500,
			title: {
				new: t`Додавање уређаја`,
				existing: (entity) =>
					t`Измена уређаја ${DEVICE_TYPE_NAME[entity.type] || ''}`,
			},
			shouldFetch: false,
			beforeSave: (entity) => {
				if (entity.connectionType === DEVICE_CONNECTION_TYPE.NETWORK) {
					return {
						...entity,
						name: `${entity.host}:${entity.port}`,
						device: JSON.stringify({
							port: parseInt(entity.port, 10),
							host: entity.host,
						}),
					};
				}
				const device = JSON.parse(entity.device);
				return {
					...entity,
					name: device.name,
					device: JSON.stringify({
						...device,
						protocol: entity.protocol,
					}),
				};
			},
			fields: <EditForm />,
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/uredjaji/',
			shouldFetch: false,
			empty: {
				text: t`Нема уређаја`,
				image: 'plug',
			},
			table: {
				actions: [(record) => <DeviceActions record={record} />],
				columns: [
					{
						title: '',
						width: 46,
						render(record) {
							return (
								<img
									className={styles.icon}
									src={`/images/icons/new/devices/${record.type}.svg`}
									alt={record.type}
								/>
							);
						},
					},
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
					},
					{
						title: t`Тип`,
						dataIndex: 'type',
						key: 'type',
						render(text: string | number) {
							return DEVICE_TYPE_NAME[text];
						},
					},
				],
			},
			createButtonText: t`Додај уређај`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете овај уређај?`,
		},
	});
};
