import { t, Trans } from '@lingui/macro';
import {
	TreeSelect,
	Button,
	ConfigProvider,
	Empty,
	Form,
	notification,
	Spin,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { StaticComponents } from '../../../../../../../../../components/StaticComponents';
import { v2Client } from '../../../../../../../../../store/client';
import stores from '../../../../../../../../../stores/index.mobx';
import { MarketplaceApplication } from '../../../../../../../../../stores/MarketplaceApplication.mobx';
import globalStyles from '../../../../../../../../../assets/styles/global.module.less';
interface Props extends Omit<React.ComponentProps<typeof TreeSelect>, 'value'> {
	treeCheckable?: boolean;
	value?: string | void;
	showCreateButton?: boolean;
}

function convertData(data: any[]) {
	return data.map((item: any) => {
		return {
			...item,
			disabled: item.type === 'collection',
		};
	});
}

function CategorySelect({ onChange, value, ...rest }: Props) {
	const { saleChannels } = stores;

	const parentForm = Form.useFormInstance();
	const storeId = Form.useWatch('storeId', parentForm);

	const [isFetching, setIsFetching] = useState(false);
	const [installedApplication, setInstalledApplication] =
		useState<MarketplaceApplication | null>(null);

	useEffect(() => {
		const id =
			saleChannels.byId[parentForm.getFieldValue('saleChannelId')]
				?.applicationId;

		if (storeId && id) {
			setIsFetching(true);
			v2Client
				.get(`/marketplace-applications/installed/${id}`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setInstalledApplication(response.data);
				})
				.finally(() => {
					setIsFetching(false);
				});
		}
	}, [storeId, parentForm, saleChannels.byId]);

	const reloadCategories = useCallback(() => {
		setIsFetching(true);
		v2Client
			.get(
				`/marketplace-applications/${installedApplication?.id}/functions/get-items`
			)
			.then((response) =>
				v2Client.get(
					`/marketplace-applications/installed/${installedApplication?.id}`,
					{
						headers: {
							'store-id': storeId,
						},
					}
				)
			)
			.then((response) => {
				setInstalledApplication(response.data);
			})
			.catch((error) => {
				console.log(error);
				StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Дошло је до грешке приликом учитавања категорија`,
				});
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, [installedApplication]);

	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/new/folder.svg" />}
						imageStyle={{ height: 64 }}
						description={t`Нема категорија`}
					></Empty>
				)}
			>
				<TreeSelect
					loading={isFetching}
					treeLine
					fieldNames={{
						label: 'name',
						value: 'id',
						children: 'children',
					}}
					treeDefaultExpandAll
					popupMatchSelectWidth={false}
					treeData={convertData(
						installedApplication?.settings?.categories || []
					)}
					dropdownRender={(menu) => (
						<Spin spinning={isFetching}>
							{menu}
							<Button
								type="link"
								className={globalStyles.dropdownCreateButton}
								onClick={() => reloadCategories()}
							>
								<Trans>Освежи категорије</Trans>
							</Button>
						</Spin>
					)}
					onChange={onChange}
					value={value}
					{...rest}
				/>
			</ConfigProvider>
		</>
	);
}

export default observer(CategorySelect);
