import { Input, InputProps } from 'antd';
import { useRef } from 'react';

import { useBarcodeScanner } from '../../../../../../hooks/useBarcodeScanner';

export function BarcodeInput(props: InputProps) {
	const focused = useRef(false);

	useBarcodeScanner((barcode) => {
		if (!focused.current) {
			return;
		}

		props.onChange?.({
			target: {
				value: barcode,
			},
		} as any);
	});

	return (
		<Input
			onFocus={(e) => {
				focused.current = true;
				props.onFocus?.(e);
			}}
			onBlur={(e) => {
				focused.current = false;
				props.onBlur?.(e);
			}}
			{...props}
		/>
	);
}
