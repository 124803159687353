import { t, Trans } from '@lingui/macro';
import { Button, Modal, Result, Space } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import styles from './PosTerminalModal.module.less';
import { POS_TERMINAL_ERROR_CODE } from '../../constants/posTerminal';
import { PosTerminalDeviceConfiguration } from '../../stores/Device.mobx';
import stores from '../../stores/index.mobx';
import { ThermalPosTerminal } from '../../lib/thermalPosTerminal';
interface Props {
	visible: boolean;
	onCancel: () => void;
	onOk: (any) => void;
	amount: number;
}

function PosTerminalModal({
	visible,
	onCancel = () => null,
	onOk = () => null,
	amount,
}: Props) {
	const [[errorVisible, errorMessage], setError] = useState([false, '']);

	const createTransaction = useCallback(async () => {
		try {
			const response = await window.electron.posTerminal.transaction(amount);
			if (response?.error) {
				if (
					response.error?.message?.code === POS_TERMINAL_ERROR_CODE.CANCELED
				) {
					setError([true, t`Трансакција је поништена на ПОС терминалу.`]);
				} else if (
					response.error?.message?.code !==
					POS_TERMINAL_ERROR_CODE.CANCELED_BY_ECR
				) {
					setError([true, t`Проверите разлог на извештају са ПОС терминала.`]);
					const posTerminalConfiguration = stores.devices.posTerminals?.[0]
						?.configuration as PosTerminalDeviceConfiguration;

					if (posTerminalConfiguration?.printOnThermalPrinter) {
						const thermalPosTerminalBuyer = new ThermalPosTerminal(
							stores.stores.currentStore,
							response.error?.message,
							'buyer'
						);
						await thermalPosTerminalBuyer.print();
					}
				} else {
					setError([true, t`Проверите разлог на извештају са ПОС терминала.`]);
				}
			} else {
				onOk(response.message);
			}
		} catch (e) {
			// onCancel();
		}
	}, [amount, onOk]);

	useEffect(() => {
		if (visible) {
			createTransaction();
		}
	}, [visible, amount, createTransaction]);

	return (
		<Modal
			centered
			open={visible}
			width={600}
			footer={null}
			closable={false}
			// zIndex={1600}
		>
			{!errorVisible && (
				<Result
					title={t`Плаћање картицом`}
					subTitle={t`Чекање уплате путем ПОС терминала`}
					icon={
						<img
							className={styles.icon}
							src="/images/icons/new/credit-card.svg"
							alt=""
						/>
					}
					extra={
						<Space>
							<Button
								danger
								onClick={() => {
									window.electron.posTerminal.cancel();
									onCancel();
								}}
							>
								<Trans>Одустани</Trans>
							</Button>
							<Button
								onClick={() => {
									window.electron.posTerminal.cancel();
									onOk();
								}}
							>
								<Trans>Ручно потврди уплату</Trans>
							</Button>
						</Space>
					}
				/>
			)}
			{errorVisible && (
				<Result
					title={t`Трансакција није успела`}
					subTitle={errorMessage}
					icon={
						<img
							className={styles.icon}
							src="/images/icons/new/cvv.svg"
							alt=""
						/>
					}
					extra={
						<Space>
							<Button
								onClick={() => {
									setError([false, '']);
									onCancel();
								}}
								danger
							>
								<Trans>Одустани</Trans>
							</Button>

							<Button
								onClick={() => {
									window.electron.posTerminal.cancel();
									onOk();
								}}
							>
								<Trans>Ручно потврди уплату</Trans>
							</Button>
							<Button
								type="primary"
								onClick={() => {
									setError([false, '']);
									createTransaction();
								}}
							>
								<Trans>Покушај поново</Trans>
							</Button>
						</Space>
					}
				/>
			)}
		</Modal>
	);
}

export default PosTerminalModal;
