import { i18n } from '@lingui/core';
import antd_enUS from 'antd/lib/locale/en_US';
import antd_ruRU from 'antd/lib/locale/ru_RU';
import antd_srRS from 'antd/lib/locale/sr_RS';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import numeral from 'numeral';
import './numeral/locales/sr';
import './numeral/locales/ru';
import './numeral/locales/en';

import antd_srRSCyrl from '../assets/locales/antd/sr_RS-cyrl';
import { messages as enMessages } from '../locales/en/messages';
import { messages as ruMessages } from '../locales/ru/messages';
import { messages as srCyrlMessages } from '../locales/sr-cyrl/messages';
import { messages as srLatnMessages } from '../locales/sr-latn/messages';

import 'dayjs/locale/sr-cyrl';
import 'dayjs/locale/sr';
import 'dayjs/locale/en';

export const locales = {
	en: 'English',
	ru: 'Русский',
	'sr-latn': 'Srpski',
	'sr-cyrl': 'Српски',
};

export const messages = {
	en: enMessages,
	ru: ruMessages,
	'sr-latn': srLatnMessages,
	'sr-cyrl': srCyrlMessages,
};

const dayjsLocales = {
	en: 'en',
	ru: 'ru',
	'sr-latn': 'sr',
	'sr-cyrl': 'sr-cyrl',
};

const numeralLocales = {
	en: 'en-badi',
	ru: 'ru',
	'sr-latn': 'sr',
	'sr-cyrl': 'sr',
};

const antdLocales = {
	en: antd_enUS,
	ru: antd_ruRU,
	'sr-latn': antd_srRS,
	'sr-cyrl': antd_srRSCyrl,
};

export const defaultLocale = 'sr-cyrl';

dayjs.extend(localizedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export function useLocale(locale: string) {
	i18n.load(locale, messages[locale]);
	i18n.activate(locale);
	dayjs.locale(dayjsLocales[locale]);
	numeral.locale(numeralLocales[locale]);
	return [antdLocales[locale], true];
}
