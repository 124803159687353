import { t, Trans } from '@lingui/macro';
import { Checkbox, Input, Tooltip } from 'antd';

import { CreatePage } from '../../../../components/Page';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'units',
		model: 'unit',
		view: {
			useEdit: true,
		},
		edit: {
			width: 500,
			title: {
				new: t`Додавање јединице мере`,
				existing: (entity) => t`Измена јединице мере ${entity?.label || ''}`,
			},
			shouldFetch: false,
			fields: [
				{
					key: 'row1',
					fields: [
						{
							key: 'label',
							label: t`Ознака`,
							rules: [{ required: true, message: t`Ознака је обавезна` }],
							component: <Input />,
							span: 24,
						},
					],
				},
				{
					key: 'row2',
					fields: [
						{
							key: 'isPieceUnitOfMeasure',
							label: '',
							component: (
								<Checkbox>
									<Trans>Комадна јединица мере</Trans>&nbsp;
									<Tooltip
										title={
											<Trans>
												За комадне јединице мере се неће рачунати цена по
												јединици мере, и јединица мере се неће приказивати на
												фискалном рачуну.
											</Trans>
										}
									>
										<i className="fi fi-rr-info"></i>
									</Tooltip>
								</Checkbox>
							),
							valuePropName: 'checked',
							span: 12,
							initialValue: false,
						},
					],
				},
			],
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/jedinice-mere/',
			empty: {
				image: 'measuring-tape',
				text: t`Нема јединица мере`,
			},
			shouldFetch: false,
			table: {
				columns: [
					{
						title: t`Ознака`,
						dataIndex: 'label',
						key: 'label',
						shouldLink: true,
					},
					{
						title: t`Комадна јединица мере`,
						dataIndex: 'isPieceUnitOfMeasure',
					},
				],
			},
			createButtonText: t`Додај јединицу мере`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете ову јединицу мере?`,
		},
	});
