import { t, Trans } from '@lingui/macro';
import {
	Button,
	Checkbox,
	Col,
	Drawer,
	Form,
	Input,
	Radio,
	Row,
	Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import globalStyles from '../../../../../../assets/styles/global.module.less';

import {
	addToDrawersRegistry,
	useDrawer,
} from '../../../../../../components/Page';
import { Title } from '../../../../../../components/Title';
import stores from '../../../../../../stores/index.mobx';
const Configure = () => {
	const [deviceId, , close, visible] = useDrawer('configure-device');

	const {
		devices: { getById },
	} = stores;

	const [form] = Form.useForm();

	const device = useMemo(() => getById(deviceId), [deviceId]);
	const [formValues, setFormValues] = useState<any>({});

	useEffect(() => {
		if (!device) {
			return;
		}
		form.setFieldsValue(device.configuration);
		setFormValues(device.configuration || {});
	}, [device, form]);

	async function save() {
		const fields = await form.validateFields();
		device.saveConfiguration({
			...fields,
			resolution: Number(fields.resolution),
			delayConsecutive: Number(fields.delayConsecutive || 0),
			fontSizeMultiplier: Number(fields.fontSizeMultiplier || 1),
		});
		close();
	}

	const isEscPos = useMemo(
		() => !device?.device?.protocol || device?.device?.protocol === 'escpos',
		[device]
	);

	if (!device) {
		return null;
	}

	return (
		<Drawer
			destroyOnClose
			open={visible}
			title={t`Конфигурација уређаја ${device?.name}`}
			width={500}
			onClose={close}
			footer={
				<Row>
					<Col flex="auto">
						<Button key="cancel" onClick={close}>
							<Trans>Одустани</Trans>
						</Button>
					</Col>
					<Col flex="none">
						<Button key="save" type="primary" onClick={save}>
							<Trans>Сачувај</Trans>
						</Button>
					</Col>
				</Row>
			}
		>
			{device.type === 'thermal_printer' && (
				<Form
					layout="vertical"
					form={form}
					onValuesChange={(values) =>
						setFormValues({ ...formValues, ...values })
					}
				>
					<Title>
						<Trans>Основна подешавања</Trans>
					</Title>
					<Form.Item name="printLogo" valuePropName="checked">
						<Checkbox>
							<Trans>Штампај лого предузећа пре фискалног рачуна</Trans>
						</Checkbox>
					</Form.Item>
					{isEscPos && (
						<Form.Item
							name="cut"
							valuePropName="checked"
							extra={
								<Trans>Штампач мора подржавати функцију сечења папира</Trans>
							}
						>
							<Checkbox>
								<Trans>Пресеци папир након фискалног рачуна</Trans>
							</Checkbox>
						</Form.Item>
					)}
					{isEscPos && (
						<>
							<Form.Item
								name="kick"
								valuePropName="checked"
								extra={
									<Trans>
										Штампач мора подржавати функцију отварања фиоке за новац
									</Trans>
								}
							>
								<Checkbox>
									<Trans>Отвори фиоку за новац након штампања рачуна</Trans>
								</Checkbox>
							</Form.Item>
							<Form.Item
								name="kickCommand"
								label={t`Команда за отварање фиоке за новац`}
								extra={<Trans>За подразумевану вредност оставите празно</Trans>}
								rules={[
									{
										pattern: /^([0-9A-Fa-f]{2}\s?)*$/,
										message: t`Команда мора бити низ хексадецималних карактера раздвојених размаком`,
									},
								]}
							>
								<Input />
							</Form.Item>
						</>
					)}
					<Form.Item label={t`Ширина папира`} name="printWidth">
						<Radio.Group>
							<Radio value={'58'}>
								<Trans>57мм</Trans>
							</Radio>
							<Radio value={'80'}>
								<Trans>80мм</Trans>
							</Radio>
						</Radio.Group>
					</Form.Item>

					<Form.Item
						label={t`Додатни текст након фискалног рачуна`}
						name="additionalText"
					>
						<TextArea />
					</Form.Item>
					<Form.Item label={t`Тип фонта`} name="fontType">
						<Radio.Group>
							<Radio value={'fixed'}>
								<Trans>Фиксна ширина</Trans>
							</Radio>
							<Radio value={'variable'}>
								<Trans>Варијабилна ширина</Trans>
							</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label={t`Величина фонта`}
						name="fontSizeMultiplier"
						initialValue={'1'}
					>
						<Select>
							<Select.Option value={'1'}>
								<Trans>100%</Trans>
							</Select.Option>
							<Select.Option value={'1.1'}>
								<Trans>110%</Trans>
							</Select.Option>
							<Select.Option value={'1.2'}>
								<Trans>120%</Trans>
							</Select.Option>
						</Select>
					</Form.Item>
					{formValues.fontType === 'fixed' && (
						<Form.Item
							label={t`Фонт`}
							name="font"
							rules={[{ required: true, message: t`Морате изабрати фонт` }]}
						>
							<Select>
								<Select.Option value={'Roboto Mono'}>Roboto Mono</Select.Option>
								<Select.Option value={'Source Code Pro'}>
									Source Code Pro
								</Select.Option>
								{/* <Select.Option value={'Ubuntu Mono'}>Ubuntu Mono</Select.Option> */}
								<Select.Option value={'IBM Plex Mono'}>
									IBM Plex Mono
								</Select.Option>
								<Select.Option value={'PT Mono'}>PT Mono</Select.Option>
								<Select.Option value={'Cousine'}>Cousine</Select.Option>
								<Select.Option value={'Fira Mono'}>Fira Mono</Select.Option>
								<Select.Option value={'Anonymous Pro'}>
									Anonymous Pro
								</Select.Option>
								<Select.Option value={'Overpass Mono'}>
									Overpass Mono
								</Select.Option>
								<Select.Option value={'JetBrains Mono'}>
									JetBrains Mono
								</Select.Option>
								<Select.Option value={'Noto Sans Mono'}>
									Noto Sans Mono
								</Select.Option>
							</Select>
						</Form.Item>
					)}
					{formValues.fontType === 'variable' && (
						<Form.Item
							label={t`Фонт`}
							name="variableFont"
							rules={[{ required: true, message: t`Морате изабрати фонт` }]}
						>
							<Select>
								<Select.Option value={'Roboto'}>Roboto</Select.Option>
								<Select.Option value={'Roboto Condensed'}>
									Roboto Condensed
								</Select.Option>
								<Select.Option value={'Roboto Slab'}>Roboto Slab</Select.Option>
								<Select.Option value={'Open Sans'}>Open Sans</Select.Option>
								<Select.Option value={'Montserrat'}>Montserrat</Select.Option>
								<Select.Option value={'Source Sans 3'}>
									Source Sans 3
								</Select.Option>
								<Select.Option value={'Noto Sans'}>Noto Sans</Select.Option>
								<Select.Option value={'Ubuntu'}>Ubuntu</Select.Option>
								<Select.Option value={'IBM Plex Sans'}>
									IBM Plex Sans
								</Select.Option>
								<Select.Option value={'PT Sans'}>PT Sans</Select.Option>
								<Select.Option value={'PT Serif'}>PT Serif</Select.Option>
							</Select>
						</Form.Item>
					)}
					<Form.Item label={t`Преглед фонта`}>
						<span
							style={{
								fontFamily:
									formValues.fontType === 'fixed'
										? formValues.font
										: formValues.variableFont,
							}}
						>
							============ ФИСКАЛНИ РАЧУН ============
							<br />
							------------ПРОМЕТ - ПРОДАЈА------------
							<br />
							Готовина:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;123.456.789,00
							<br />
							<span style={{ fontSize: 23 }}>ОВО НИЈЕ ФИСКАЛНИ РАЧУН</span>
						</span>
					</Form.Item>
					<Title>
						<Trans>Напредна подешавања</Trans>
					</Title>

					<Form.Item
						label={t`Резолуција штампача`}
						name="resolution"
						rules={[{ required: true, message: t`Резолуција је обавезна` }]}
					>
						<Input type="number" suffix={t`ДПИ`} />
					</Form.Item>
					<Form.Item
						label={t`Одлагање штампања следећег рачуна`}
						name="delayConsecutive"
						extra={t`Број секунди који ће апликација сачекати пре штампања следећег рачуна.`}
					>
						<Input type="number" suffix={t`секунди`} />
					</Form.Item>
					{isEscPos && (
						<Form.Item
							label={t`Команда за штампање`}
							name="imageMode"
							extra={t`Команда која се користи за штампање рачуна. Неки штампачи не подржавају оба режима.`}
						>
							<Select>
								<Select.Option value={'image'}>Image</Select.Option>
								<Select.Option value={'raster'}>Raster</Select.Option>
							</Select>
						</Form.Item>
					)}
				</Form>
			)}
			{device.type === 'pos_terminal' && (
				<Form
					layout="vertical"
					form={form}
					onValuesChange={(values) =>
						setFormValues({ ...formValues, ...values })
					}
				>
					<Title>
						<Trans>Штампа слипова</Trans>
					</Title>
					<Form.Item
						name="printOnThermalPrinter"
						valuePropName="checked"
						className={globalStyles.condensedFormField}
					>
						<Checkbox>
							<Trans>
								Штампај слипове картичних трансакција на термалном штампачу
							</Trans>
						</Checkbox>
					</Form.Item>
					<Form.Item
						name="printSeparately"
						valuePropName="checked"
						className={globalStyles.condensedFormField}
					>
						<Checkbox disabled={!formValues.printOnThermalPrinter}>
							<Trans>Штампај на посебном исечку</Trans>
						</Checkbox>
					</Form.Item>
					<Form.Item
						name="printOnlyWhenSignatureIsRequired"
						valuePropName="checked"
						className={globalStyles.condensedFormField}
					>
						<Checkbox disabled={!formValues.printOnThermalPrinter}>
							<Trans>
								Штампај исечак за акцептанта само када је потребан потпис купца
							</Trans>
						</Checkbox>
					</Form.Item>
				</Form>
			)}
		</Drawer>
	);
};

const ObservedConfigure = observer(Configure);
addToDrawersRegistry('configure-device', ObservedConfigure);
