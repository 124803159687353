import { t, Trans } from '@lingui/macro';
import {
	DatePicker,
	Input,
	InputNumber,
	Select,
	Tooltip,
	Typography,
} from 'antd';

import { CreatePage } from '../../../../../components/Page';
import ProductInput from '../../../../../components/Inputs/ProductInput';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'offers',
		model: 'offer',
		view: {
			useEdit: true,
		},
		edit: {
			width: 900,
			title: {
				new: t`Додавање понуде`,
				existing: (entity) => t`Измена понуде ${entity?.title || ''}`,
			},
			beforeSave: (entity) => {
				entity.beginAt = entity.interval?.[0];
				entity.endAt = entity.interval?.[1];
				delete entity.interval;
				return entity;
			},
			beforeSetFormFields: (entity) => {
				entity.interval = [entity.beginAt, entity.endAt];
				return entity;
			},
			shouldFetch: true,
			fields: (record, form) => [
				{
					key: 'basic',
					label: t`Основне информације`,
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							rules: [{ required: true, message: t`Назив је обавезан` }],
							component: <Input />,
							span: 24,
						},
						{
							key: 'description',
							label: t`Опис`,
							rules: [{ required: true, message: t`Опис је обавезан` }],
							component: <Input.TextArea />,
							span: 24,
						},
						{
							label: t`Тип`,
							key: 'type',
							rules: [{ required: true, message: t`Тип је обавезан` }],
							rerenderOnChange: true,
							component: (
								<Select>
									<Select.Option value="percentage">
										<Trans>Проценат</Trans>
									</Select.Option>
									<Select.Option value="fixed-price">
										<Trans>Фиксна цена</Trans>
									</Select.Option>
									<Select.Option value="buy-n-get-m">
										<Trans>Купи Н добиј М</Trans>
									</Select.Option>
								</Select>
							),
							span: 6,
						},
						...(form.getFieldValue('type') === 'percentage'
							? [
									{
										key: 'percentage',
										label: t`Проценат`,
										rules: [
											{ required: true, message: t`Проценат је обавезан` },
										],
										component: (
											<InputNumber
												addonAfter="%"
												step={0.01}
												min={0}
												max={100}
												style={{ width: '100%' }}
											/>
										),
										span: 18,
									},
							  ]
							: []),
						...(form.getFieldValue('type') === 'fixed-price'
							? [
									{
										key: 'fixedPrice',
										label: t`Цена`,
										rules: [{ required: true, message: t`Цена је обавезна` }],
										component: (
											<InputNumber
												addonAfter="RSD"
												step={0.01}
												min={0}
												style={{ width: '100%' }}
											/>
										),
										span: 18,
									},
							  ]
							: []),
						...(form.getFieldValue('type') === 'buy-n-get-m'
							? [
									{
										key: 'requiredForPurchase',
										label: t`Купи`,
										rules: [
											{
												required: true,
												message: t`Број артикала за куповину је обавезан`,
											},
										],
										component: (
											<InputNumber
												step={0.01}
												min={0}
												style={{ width: '100%' }}
											/>
										),
										span: 6,
									},
									{
										key: 'promotedProductQuantity',
										label: t`Добиј`,
										rules: [
											{
												required: true,
												message: t`Број артикала за добијање је обавезан`,
											},
										],
										component: (
											<InputNumber
												step={0.01}
												min={0}
												style={{ width: '100%' }}
											/>
										),
										span: 6,
									},
									{
										key: 'promotedProductPrice',
										label: t`За`,
										rules: [
											{
												required: true,
												message: t`Цена артикала за добијање је обавезна`,
											},
										],
										component: (
											<InputNumber
												addonAfter="RSD"
												step={0.01}
												min={0}
												style={{ width: '100%' }}
											/>
										),
										span: 6,
									},
							  ]
							: []),
					],
				},
				{
					key: 'products',
					label: t`Артикли`,
					fields: [
						{
							key: 'productId',
							label: t`Артикал на који се односи понуда`,
							component: <ProductInput />,
							rules: [
								{
									required: true,
									message: t`Артикал је обавезан`,
								},
							],
							span: 24,
						},
						...(form.getFieldValue('type') === 'buy-n-get-m'
							? [
									{
										key: 'promotedProductId',
										label: t`Артикал који се добија`,
										component: <ProductInput />,
										rules: [
											{
												required: true,
												message: t`Артикал је обавезан`,
											},
										],
										span: 24,
									},
							  ]
							: []),
					],
				},
				{
					key: 'interval',
					label: t`Период`,
					fields: [
						{
							key: 'interval',
							component: (
								<DatePicker.RangePicker
									showTime
									format="LL HH:mm:ss"
									style={{ width: '100%' }}
									allowEmpty={[true, true]}
								/>
							),
							span: 24,
						},
					],
				},
			],
		},
		page: {
			// docsLink: 'koriscenje/moduli-aplikacije/podesavanja/popusti/',
			empty: {
				image: 'ticket',
				text: t`Нема понуда`,
			},
			shouldFetch: true,
			table: {
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
						shouldLink: true,
						ellipsis: true,
					},
					{
						title: t`Статус`,
						dataIndex: 'status',
						key: 'status',
						shouldLink: true,
						ellipsis: true,
						render: (status, record) => {
							if (status === 'active') {
								return (
									<Typography.Text type="success">
										<i className="fi fi-rr-check-circle"></i>{' '}
										<Trans>Активан</Trans>
									</Typography.Text>
								);
							}

							if (status === 'pending') {
								return (
									<Typography.Text type="secondary">
										<i className="fi fi-rr-pause-circle"></i>{' '}
										<Trans>На чекању</Trans>
									</Typography.Text>
								);
							}

							if (status === 'rejected') {
								return (
									<Tooltip title={record.rejectedReason}>
										<Typography.Text type="danger">
											<i className="fi fi-rr-cross-circle"></i>{' '}
											<Trans>Одбијен</Trans>
										</Typography.Text>
									</Tooltip>
								);
							}
						},
					},
					{
						title: t`Тип`,
						dataIndex: 'type',
						key: 'type',
						ellipsis: true,
						render: (value) => {
							switch (value) {
								case 'percentage':
									return t`Проценат`;
								case 'fixed-price':
									return t`Фиксна цена`;
								case 'buy-n-get-m':
									return t`Купи Н добиј М`;
								default:
									return value;
							}
						},
					},
					{
						title: t`Период`,
						key: 'interval',
						render: (value, entity) => {
							if (!entity.beginAt && !entity.endAt) {
								return t`Неограничено`;
							}
							return `${
								entity?.beginAt
									? t`Од ${entity.beginAt.format('LL HH:mm:ss')} `
									: ''
							}${
								entity?.endAt
									? `${entity?.beginAt ? t`до` : t`До`} ${entity.endAt.format(
											'LL HH:mm:ss'
									  )}`
									: ''
							}`;
						},
						ellipsis: true,
						responsive: ['xl'],
					},
				],
			},
			createButtonText: t`Додај понуду`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете ову понуду?`,
		},
	});
