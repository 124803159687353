import { Trans, t } from '@lingui/macro';
import { Col, Row } from 'antd';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import round from 'lodash/round';
import { useMemo } from 'react';

import styles from './CalculationPrint.module.less';
import numberFormat from '../../../../../../lib/numberFormat';
import { Calculation } from '../../../../../../stores/Calculation.mobx';
import stores from '../../../../../../stores/index.mobx';
import { Invoice } from '../../../../../../stores/Invoice.mobx';

interface Props {
	calculation: Calculation;
	invoice?: Invoice;
}

export default function CalculationPrint({ calculation, invoice }: Props) {
	const {
		company,
		partners: { getById: getPartnerById },
		products: { getById: getProductById },
		warehouses: { byId: warehouseById },
	} = stores;

	const partner = useMemo(() => {
		if (!calculation?.partnerId) {
			return null;
		}

		return getPartnerById(calculation.partnerId);
	}, [calculation?.partnerId, getPartnerById]);

	function formatDate(date: string | Dayjs, dateOnly = false): string {
		if (dateOnly) {
			return dayjs(date).format('DD.MM.YYYY');
		}

		return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
	}

	const [totalsDataSource, taxesDetailsDataSource] = useMemo(() => {
		const totalPurchaseAmountWithRebate = round(
			calculation.items?.reduce(
				(acc, item) => acc + Number(item.purchaseAmount || 0),
				0
			),
			4
		);

		const totalPurchaseAmount = round(
			calculation.items?.reduce(
				(acc, item) =>
					acc +
					Number(item.purchasePrice || 0) * Number(item.invoiceQuantity || 0),
				0
			),
			4
		);

		const purchaseAmountWithVat = round(
			calculation.items?.reduce(
				(acc, item) =>
					acc +
					Number(item.purchaseAmount || 0) *
						(1 + Number(item.purchaseTaxRate || 0) / 100),
				0
			),
			4
		);

		const totalSaleAmount = round(
			calculation.items?.reduce(
				(acc, item) =>
					acc + Number(item.salePrice || 0) * Number(item.quantity || 0),
				0
			),
			4
		);

		const totalSaleAmountWithVat = round(
			calculation.items?.reduce(
				(acc, item) => acc + Number(item.saleAmountWithVat || 0),
				0
			),
			4
		);

		const taxes = calculation.items?.reduce((acc, item) => {
			acc[item.purchaseTaxRate] = acc[item.purchaseTaxRate] || 0;
			acc[item.purchaseTaxRate] = round(
				acc[item.purchaseTaxRate] +
					item.purchaseAmount * (item.purchaseTaxRate / 100),
				4
			);

			return acc;
		}, {});

		const taxesDetails = calculation.items?.reduce((acc, item) => {
			acc[item.taxRate] = acc[item.taxRate] || {
				purchaseAmount: 0,
				priceDifference: 0,
				saleAmount: 0,
				taxAmount: 0,
				saleAmountWithVat: 0,
			};

			acc[item.taxRate].purchaseAmount = round(
				acc[item.taxRate].purchaseAmount + item.purchaseAmount,
				4
			);

			acc[item.taxRate].priceDifference = round(
				acc[item.taxRate].priceDifference +
					(item.salePrice * item.quantity - item.purchaseAmount),
				4
			);

			acc[item.taxRate].saleAmount = round(
				acc[item.taxRate].saleAmount + item.salePrice * item.quantity,
				4
			);

			acc[item.taxRate].taxAmount = round(
				acc[item.taxRate].taxAmount +
					(item.salePriceWithVat - item.salePrice) * item.quantity,
				4
			);

			acc[item.taxRate].saleAmountWithVat = round(
				acc[item.taxRate].saleAmountWithVat +
					item.salePriceWithVat * item.quantity,
				4
			);

			return acc;
		}, {}) as Record<
			number,
			{
				purchaseAmount: number;
				priceDifference: number;
				saleAmount: number;
				taxAmount: number;
				saleAmountWithVat: number;
			}
		>;

		const taxesRows = [];

		Object.keys(taxes || {}).forEach((tax) => {
			taxesRows.push({
				title: t`Порез ${numberFormat(tax, false, 2, true)}%:`,
				taxAmount: `+${numberFormat(taxes[tax], false, 2, true)}`,
				key: `tax${tax}`,
			});
		});

		const taxesDetailsRows = [];

		Object.keys(taxesDetails || {}).forEach((tax) => {
			taxesDetailsRows.push({
				title: t`Порез на додату вредност ${numberFormat(
					tax,
					false,
					2,
					true
				)}%:`,
				amount: numberFormat(taxesDetails[tax].purchaseAmount, false, 2, true),
				priceDifference: numberFormat(
					taxesDetails[tax].priceDifference,
					false,
					2,
					true
				),
				saleAmount: numberFormat(taxesDetails[tax].saleAmount, false, 2, true),
				taxAmount: numberFormat(taxesDetails[tax].taxAmount, false, 2, true),
				saleAmountWithVat: numberFormat(
					taxesDetails[tax].saleAmountWithVat,
					false,
					2,
					true
				),
				key: `tax${tax}`,
			});
		});

		const totals = Object.values(taxesDetails || {}).reduce(
			(acc, item) => {
				acc.purchaseAmount = round(acc.purchaseAmount + item.purchaseAmount, 4);
				acc.priceDifference = round(
					acc.priceDifference + item.priceDifference,
					4
				);
				acc.saleAmount = round(acc.saleAmount + item.saleAmount, 4);
				acc.taxAmount = round(acc.taxAmount + item.taxAmount, 4);
				acc.saleAmountWithVat = round(
					acc.saleAmountWithVat + item.saleAmountWithVat,
					4
				);

				return acc;
			},
			{
				purchaseAmount: 0,
				priceDifference: 0,
				saleAmount: 0,
				taxAmount: 0,
				saleAmountWithVat: 0,
			}
		);

		taxesDetailsRows.push({
			title: t`Укупно:`,
			amount: numberFormat(totals.purchaseAmount, false, 2, true),
			priceDifference: numberFormat(totals.priceDifference, false, 2, true),
			saleAmount: numberFormat(totals.saleAmount, false, 2, true),
			taxAmount: numberFormat(totals.taxAmount, false, 2, true),
			saleAmountWithVat: numberFormat(totals.saleAmountWithVat, false, 2, true),
			key: `taxTotals`,
			className: styles.heading,
		});

		return [
			[
				{
					title: t`Набавна вредност:`,
					amount: numberFormat(totalPurchaseAmount, false, 2, true),
				},
				{
					title: t`Рабат:`,
					amount: numberFormat(
						totalPurchaseAmountWithRebate - totalPurchaseAmount,
						false,
						2,
						true
					),
				},
				...taxesRows,
				{
					title: t`Вредност без ПДВ / ПДВ:`,
					amount: numberFormat(totalPurchaseAmountWithRebate, false, 2, true),
					taxAmount: numberFormat(
						purchaseAmountWithVat - totalPurchaseAmountWithRebate,
						false,
						2,
						true
					),
				},
				{
					title: t`Укупна набавна вредност:`,
					amount: numberFormat(purchaseAmountWithVat, false, 2, true),
					className: styles.heading,
				},
				{
					title: t`Разлика у цени:`,
					amount: numberFormat(
						totalSaleAmount - totalPurchaseAmountWithRebate,
						false,
						2,
						true
					),
				},
				{
					title: t`Продајна вредност без ПДВ / ПДВ:`,
					amount: numberFormat(totalSaleAmount, false, 2, true),
					taxAmount: numberFormat(
						totalSaleAmountWithVat - totalSaleAmount,
						false,
						2,
						true
					),
				},
				{
					title: t`Укупна продајна вредност:`,
					amount: numberFormat(totalSaleAmountWithVat, false, 2, true),
					className: styles.heading,
				},
			],
			taxesDetailsRows,
		];
	}, [calculation?.items]);

	return (
		<div className={styles.page}>
			<header>
				<div>
					<h3>{company.name}</h3>
					{company.address && (
						<>
							{company.address}
							<br />
						</>
					)}
					{company.city && (
						<>
							{company.zip && <>{company.zip}</>}
							{company.city}
							<br />
						</>
					)}
					{company.email && (
						<>
							{company.email}
							<br />
						</>
					)}
					{company.phone && (
						<>
							{company.phone}
							<br />
						</>
					)}
					<Trans>ПИБ</Trans>: {company.tin}
					<br />
					<Trans>Матични број</Trans>: {company.companyRegistrationNumber}
				</div>
			</header>
			<div className="calculation">
				<div className="calculationDetails">
					<h3>
						<Trans>Калкулација {calculation.number}</Trans>
					</h3>
					{calculation.date && (
						<>
							<strong>
								<Trans>Датум калкулације: </Trans>
							</strong>
							{formatDate(calculation.date, true)}
							<br />
						</>
					)}
					<strong>
						<Trans>Добављач: </Trans>
					</strong>
					{partner?.name}
					<br />
					{calculation.invoice && (
						<>
							<strong>
								<Trans>Број фактуре: </Trans>
							</strong>
							{invoice.number}
							<br />
							<strong>
								<Trans>Датум фактуре: </Trans>
							</strong>
							{formatDate(invoice.date, true)}
							<br />
						</>
					)}
					{calculation.deliveryNoteNumber && (
						<>
							<strong>
								<Trans>Број отпремнице: </Trans>
							</strong>
							<br />
							{calculation.deliveryNoteNumber}
							<br />
						</>
					)}
				</div>
				<h4>
					<Trans>Ставке калкулације</Trans>
				</h4>
				<div className="calculationBlock calculationItems">
					<table>
						<thead>
							<tr>
								<th style={{ width: 40 }}>
									<Trans>РБ</Trans>
								</th>
								<th>
									<Trans>Складиште</Trans>
								</th>
								<th>
									<Trans>Шифра</Trans>
								</th>
								<th>
									<Trans>Назив артикла</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Количина</Trans>
								</th>
								<th>
									<Trans>ЈМ</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Наб. цена</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Прод. цена</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Раз. у цени</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>ПДВ</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Износ ПДВ</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Прод. цена са ПДВ</Trans>
								</th>
							</tr>
						</thead>
						<tbody>
							{calculation.items.map((item, index) => {
								const warehouse = warehouseById[item.warehouseId];
								const product = getProductById(item.productId);
								const sku = product?.sku;
								const name = product.parent
									? `${product.parent.name} ${product.variantName}`
									: product?.name;
								return (
									<tr>
										<td>{index + 1}</td>
										<td>{warehouse?.name}</td>
										<td>{sku}</td>
										<td>{name}</td>
										<td className={'alignRight'}>
											{numberFormat(item.quantity, false, 3, false)}
										</td>
										<td>{item.productUnit}</td>
										<td className={'alignRight'}>
											{numberFormat(item.unitPurchasePrice, false, 4, true)}
										</td>
										<td className={'alignRight'}>
											{' '}
											{numberFormat(item.salePrice, false, 2, true)}
										</td>
										<td className={'alignRight'}>
											{' '}
											{numberFormat(item.priceDifference, false, 4, true)}
										</td>
										<td className={'alignRight'}>
											{' '}
											{numberFormat(item.taxRate, false, 0)}%
										</td>
										<td className={'alignRight'}>
											{' '}
											{numberFormat(item.taxAmount, false, 4, true)}
										</td>
										<td className={'alignRight'}>
											{' '}
											{numberFormat(item.salePriceWithVat, false, 2, true)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<h4>
					<Trans>Рекапитулација калкулације</Trans>
				</h4>
				<Row gutter={8}>
					<Col span={7}>
						<table>
							<tbody>
								{totalsDataSource.map((item, index) => {
									return (
										<tr>
											<td className={`${styles.heading} ${item.className}`}>
												{item.title}
											</td>
											<td className={item.className}>{item.amount}</td>
											<td className={item.className}>{item.taxAmount}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Col>
					<Col span={17}>
						<table>
							<thead>
								<tr>
									<th></th>
									<th>
										<Trans>Набавна вредност</Trans>
									</th>
									<th>
										<Trans>Продајна вредност без ПДВ</Trans>
									</th>
									<th>
										<Trans>Разлика у цени</Trans>
									</th>
									<th>
										<Trans>ПДВ</Trans>
									</th>
									<th>
										<Trans>Продајна вредност</Trans>
									</th>
								</tr>
							</thead>
							<tbody>
								{taxesDetailsDataSource.map((item, index) => {
									return (
										<tr>
											<td
												align="right"
												className={`${styles.heading} ${item.className}`}
											>
												{item.title}
											</td>
											<td className={item.className}>{item.amount}</td>
											<td className={item.className}>{item.saleAmount}</td>
											<td className={item.className}>{item.priceDifference}</td>
											<td className={item.className}>{item.taxAmount}</td>
											<td className={item.className}>
												{item.saleAmountWithVat}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Col>
				</Row>
			</div>
		</div>
	);
}
