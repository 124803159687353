import { t } from '@lingui/macro';
import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import styles from './StoresEdit.module.less';
import globalStyles from '../../../assets/styles/global.module.less';
import stores from '../../../stores/index.mobx';
import { Role } from '../../../stores/Role.mobx';

interface Props {
	readonly?: boolean;
	value?: string[];
	onChange?: (any) => void;
	record?: Role;
}

function StoresEdit({
	value = null,
	onChange,
	readonly,
	record = null,
}: Props) {
	const {
		stores: { all },
	} = stores;

	return (
		<div className={globalStyles.container}>
			<Table
				tableLayout="auto"
				rowSelection={{
					type: 'checkbox',
					selectedRowKeys:
						record?.isAdmin || !value ? all.map((s) => s.id) : value,
					onSelect(r, selected, selectedRows) {
						onChange?.(selectedRows.map((s) => s.id));
					},
					onSelectAll(selected, selectedRows) {
						onChange?.(selectedRows.map((s) => s.id));
					},
					getCheckboxProps: () => ({
						disabled: readonly,
					}),
				}}
				pagination={false}
				size="small"
				className={styles.table}
				dataSource={all}
				rowKey={(r) => r.id}
				columns={[
					{
						key: 'displayName',
						title: t`Назив`,
						dataIndex: 'displayName',
					},
					{
						key: 'fullAddress',
						title: t`Адреса`,
						dataIndex: 'fullAddress',
						ellipsis: true,
					},
				]}
			/>
		</div>
	);
}

export default observer(StoresEdit);
