import classNames from 'classnames';

import styles from './NoImage.module.less';
import sw from '../../lib/stopwords/sr.json';
type Props = {
	name: string;
	size?: 'extra-small' | 'small' | 'large';
};

export function NoImage({ name, size = 'large' }: Props) {
	return (
		<div
			className={classNames(
				styles.noImage,
				size === 'small' && styles.small,
				size === 'extra-small' && styles.extraSmall,
				'noImage'
			)}
		>
			{name
				.split(' ')
				.filter((w) => !sw.includes(w.toLowerCase()))
				.slice(0, 3)
				.map((w) => (w?.[0] || '').toUpperCase())
				.join('')}
		</div>
	);
}
