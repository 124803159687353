import { t } from '@lingui/macro';
import { Select, Input, Space, Typography, Divider } from 'antd';
import { useMemo, useCallback, useRef } from 'react';

import styles from './Input.module.less';

interface Props {
	value?: string;
	onChange?: (string) => void;
	readOnly?: boolean;
	disabled?: boolean;
}

function BuyerCostCenterInput({
	value,
	onChange,
	readOnly = false,
	disabled = false,
}: Props) {
	const costCenterTypes = [
		// {
		// 	value: 10,
		// 	label: t`Број ЗПППДВ`,
		// },
		// {
		// 	value: 11,
		// 	label: t`Број обрасца оператера за рефакцију`,
		// },
		{
			value: 20,
			label: t`Број СНПДВ`,
			description: t`Број обрасца`,
		},
		{
			value: 21,
			label: t`Број ЛНПДВ`,
			description: t`Број обрасца`,
		},
		{
			value: 30,
			label: t`Број ППО-ПДВ`,
			description: t`Број овере у Пореској управи`,
		},
		{
			value: 31,
			label: t`Број ЗППО-ПДВ`,
			description: t`Број овере у Пореској управи`,
		},
		{
			value: 32,
			label: t`Број МППО-ПДВ`,
			description: t`Број овере у Пореској управи`,
		},
		{
			value: 33,
			label: t`Број ИППО-ПДВ`,
			description: t`Број овере у Пореској управи`,
		},
		// {
		// 	value: 40,
		// 	label: t`Број једнонаменског ваучера`,
		// },
	];
	const ref = useRef(null);
	const [currentType, currentId] = useMemo(() => {
		return value ? value.split(':') : ['', ''];
	}, [value]);

	const handleChange = useCallback(
		(type?: number, id?: string) => {
			const current = [currentType, currentId];
			if (typeof type !== 'undefined') {
				current[0] = `${type}`;
			}
			if (typeof id !== 'undefined') {
				current[1] = id;
			}

			const val = current.join(':');
			onChange(val === ':' ? null : val);
		},
		[currentType, currentId, onChange]
	);

	return (
		<Space.Compact style={{ width: '100%' }}>
			<Select
				style={{ width: '50%' }}
				popupMatchSelectWidth={false}
				value={currentType ? Number(currentType) : undefined}
				onChange={(value) => {
					handleChange(value);
					ref.current.focus();
				}}
				disabled={readOnly || disabled}
				optionLabelProp="label"
			>
				<Select.Option value="">&nbsp;</Select.Option>
				{costCenterTypes.map((option) => (
					<Select.Option
						value={option.value}
						key={option.value}
						label={
							<>
								<Typography.Text type="secondary">
									{option.value}
								</Typography.Text>
								<Divider type="vertical" />
								{option.label}
							</>
						}
					>
						<Typography.Text type="secondary">{option.value}</Typography.Text>
						<Divider type="vertical" />
						{option.label}
						<p className={styles.description}>{option.description}</p>
					</Select.Option>
				))}
			</Select>
			<Input
				ref={ref}
				style={{ width: '50%' }}
				value={currentId}
				onChange={(event) => {
					handleChange(undefined, event.target.value);
				}}
				disabled={readOnly || disabled}
			/>
		</Space.Compact>
	);
}
export default BuyerCostCenterInput;
