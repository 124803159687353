import { Select } from 'antd';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';

import numberFormat from '../../lib/numberFormat';
import stores from '../../stores/index.mobx';

interface Props {
	vat?: boolean;
	value?: string;
	useDefault?: boolean;
	mode?: 'multiple' | 'tags';
}

function TaxRateSelect({ vat, mode = undefined, ...props }: Props) {
	const disabled = useContext(DisabledContext);

	const {
		company,
		taxRates: { isFetching, vat: vatList, noVat: noVatList },
	} = stores;

	const { vat: companyVat } = company;

	const isVat = useMemo(
		() => (typeof vat !== 'undefined' ? vat : companyVat),
		[vat, companyVat]
	);

	const data = useMemo(
		() => (isVat ? vatList : noVatList),
		[vatList, vatList.length, noVatList, noVatList.length, isFetching, isVat]
	);

	return (
		<Select
			showSearch
			filterOption={(input, option) => {
				return (
					option.props.children
						.join('')
						.toLowerCase()
						.indexOf(input.toLowerCase()) !== -1
				);
			}}
			loading={isFetching}
			popupMatchSelectWidth={false}
			disabled={!isVat || disabled}
			mode={mode}
			{...props}
		>
			{data.map((taxRate) => (
				<Select.Option key={taxRate.label} value={taxRate.label}>
					{taxRate.name} - {taxRate.label} ({numberFormat(taxRate.rate, false)}
					%)
				</Select.Option>
			))}
		</Select>
	);
}

export default observer(TaxRateSelect);
