import { t } from '@lingui/macro';
import { Form, Input, Modal, Spin } from 'antd';
import React from 'react';

import { Invoice } from '../../../../../../stores/Invoice.mobx';

interface Props {
	visible?: boolean;
	close: () => void;
	onOk: (any) => void;
	okText?: string;
	cancelText?: string;
	title?: string;
	isCommentRequired?: boolean;
	record: Invoice;
}

export default function AcceptRejectModal({
	visible,
	close,
	onOk,
	okText,
	cancelText,
	title,
	isCommentRequired,
	record,
}: Props) {
	const [form] = Form.useForm();

	return (
		<Modal
			open={visible}
			title={title}
			okText={okText}
			cancelText={cancelText}
			onOk={() => {
				form.submit();
			}}
			onCancel={close}
		>
			<Spin spinning={record?.isUpdating}>
				<Form
					form={form}
					layout="vertical"
					onFinish={(values) => {
						onOk(values);
						close();
					}}
				>
					<Form.Item
						name="comment"
						label={t`Коментар`}
						rules={
							isCommentRequired
								? [
										{
											required: true,
											message: t`Коментар је обавезан`,
										},
								  ]
								: []
						}
					>
						<Input.TextArea
							rows={4}
							ref={(r) => {
								setTimeout(() => {
									if (r) {
										r.focus();
									}
								}, 100);
							}}
						/>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	);
}
