import { observer } from 'mobx-react-lite';

import styles from './SaleChannelsCell.module.less';
import stores from '../../../../../../stores/index.mobx';
import { ProductSaleChannel } from '../../../../../../stores/Product.mobx';
import { NoImage } from '../../../../../../components/NoImage';
import { Tooltip } from 'antd';

type Props = {
	productSaleChannels: ProductSaleChannel[];
};

function BaseSaleChannelsCell({ productSaleChannels }: Props) {
	return (
		<div>
			{(productSaleChannels || []).map((saleChannel) => {
				if (
					stores.saleChannels.byId[saleChannel.saleChannelId]?.applicationId
				) {
					const sc =
						stores.marketplaceApplications.installedByApplicationId?.[
							stores.saleChannels.byId[saleChannel.saleChannelId]?.applicationId
						];

					return (
						<Tooltip title={sc?.name}>
							<img
								className={styles.image}
								src={`${sc?.logoFile?.urls?.['64x64'] || ''}`}
								alt={sc?.name}
								title={sc?.name}
							/>
						</Tooltip>
					);
				} else if (
					stores.saleChannels.byId[saleChannel.saleChannelId]?.logoFile
				) {
					return (
						<Tooltip
							title={stores.saleChannels.byId[saleChannel.saleChannelId]?.name}
						>
							<img
								className={styles.image}
								src={`${
									stores.saleChannels.byId[saleChannel.saleChannelId]?.logoFile
										?.urls?.['64x64'] || ''
								}`}
								alt={stores.saleChannels.byId[saleChannel.saleChannelId]?.name}
								title={
									stores.saleChannels.byId[saleChannel.saleChannelId]?.name
								}
							/>
						</Tooltip>
					);
				} else {
					return (
						<Tooltip
							title={stores.saleChannels.byId[saleChannel.saleChannelId]?.name}
						>
							<div className={styles.noImage}>
								<NoImage
									name={
										stores.saleChannels.byId[saleChannel.saleChannelId]?.name ||
										''
									}
									size="extra-small"
								/>
							</div>
						</Tooltip>
					);
				}
			})}
		</div>
	);
}

export const SaleChannelsCell = observer(BaseSaleChannelsCell);
