import { Button } from 'antd';

import { useDrawer } from '../../../../../../../components/Page';
import { MarketplaceApplication } from '../../../../../../../stores/MarketplaceApplication.mobx';

interface Props {
	record: MarketplaceApplication;
}

export default function ActionSettings({ record }: Props) {
	const [, openSettingsDrawer] = useDrawer('marketplace-application-settings');

	return (
		<>
			<Button
				key="configureButton"
				icon={<i className="fi fi-rr-settings-sliders"></i>}
				onClick={() => {
					openSettingsDrawer(record.id);
				}}
			/>
		</>
	);
}
