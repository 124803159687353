import { t } from '@lingui/macro';
import { DatePicker, Input } from 'antd';
import dayjs from 'dayjs';

import { EditButtons } from './Components/EditButtons';
import InitialStocksItemsInput from './Components/InitialItems';
import { CreatePage } from '../../../../components/Page';
import { InitialStock } from '../../../../stores/InitialStock.mobx';
import { StockTurnoverStatus } from '../../../../stores/StockTurnover.mobx';

export default () =>
	CreatePage({
		module: 'stocks',
		submodule: 'initial',
		model: 'initialStock',
		view: {},
		edit: {
			width: 1800,
			beforeSave: (entity) => {
				return {
					...entity,
					items: entity.items.filter(
						(item) => item.warehouseId && item.productId && item.quantity
					),
					number: undefined,
				};
			},
			disabled: (record: InitialStock) => {
				return (
					record?.stockTurnoverItem?.status === StockTurnoverStatus.CONFIRMED
				);
			},
			disabledReason: (entity) => {
				return t`Почетно стање је потврђено и није могуће вршити измене.`;
			},
			title: {
				new: t`Додавање почетног стања`,
				existing: (entity) => t`Измена почетног стања ${entity?.number || ''}`,
			},
			shouldFetch: false,
			buttons: (props) => <EditButtons {...props} />,
			fields: (record, form, setFields) => {
				return [
					{
						key: 'basic',
						label: t`Основне информације`,
						fields: [
							{
								key: 'number',
								label: t`Број почетног стања`,
								xs: 24,
								sm: 4,
								component: <Input disabled />,
							},
							{
								key: 'date',
								label: t`Датум`,
								xs: 24,
								sm: 4,
								component: <DatePicker style={{ width: '100%' }} format="L" />,
								initialValue: dayjs(),
								rules: [
									{
										required: true,
										message: t`Датум је обавезан`,
									},
								],
							},
						],
					},
					{
						key: 'items',
						label: t`Ставке почетног стања`,
						fields: [
							{
								key: 'items',
								component: <InitialStocksItemsInput />,
								span: 24,
							},
						],
					},
				];
			},
		},
		page: {
			shouldFetch: false,
			searchable: false,
			table: {
				columns: () => {
					return [];
				},
			},
		},
	});
