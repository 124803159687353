import { t, Trans } from '@lingui/macro';
import { Form, Grid, Input, InputNumber, Select, Table } from 'antd';
import round from 'lodash/round';
import { useEffect, useRef } from 'react';

import SkuInput from './Components/SkuInput';
import styles from './InvoiceItemsInput.module.less';
import { useDrawer } from '../../../../../../components/Page';
import { TableInput } from '../../../../../../components/TableInput';
import UnitSelect from '../../../../../../components/UnitSelect';
import {
	DocumentType,
	DOCUMENT_TYPE,
	getTaxFeeCategory,
} from '../../../../../../constants/documentInvoice';
import stores from '../../../../../../stores/index.mobx';
import { bignumber, evaluate } from 'mathjs';

interface Props {
	currencyId: string;
	id?: string;
	documentType?: DocumentType;
	value?: any[];
}

export default function InvoiceItemsInput(props: Props) {
	const TAX_FEE_CATEGORY = getTaxFeeCategory();
	const { currencyId, id } = props;
	const tableInputRef = useRef();
	const screens = Grid.useBreakpoint();

	const {
		company: { vat },
	} = stores;

	const [, , , , , ProductSearchDrawer] = useDrawer('product-search');

	useEffect(() => {
		if (props.documentType === DOCUMENT_TYPE.ADVANCE_INVOICE) {
			(props.value || []).forEach((item, index) => {
				tableInputRef?.current?.handleEditObject?.(index, {
					quantity: 1,
				});
			});
		}
	}, [props.documentType, props.value, tableInputRef]);

	return (
		<>
			<TableInput
				{...props}
				ref={tableInputRef}
				isResponsive
				disallowLastRowDelete
				addButtonText={t`Додај ставку`}
				emptyText={t`Нема ставки`}
				iconPath="/images/icons/new/list.svg"
				columns={[
					{
						title: t`Шифра`,
						editable: true,
						dataIndex: 'sellerItemIdentification',
						width: 120,
						component: (record, index, handleEditObject) => (
							<SkuInput
								currencyId={currencyId}
								handleEditObject={handleEditObject}
								index={index}
							/>
						),
						colProps: {
							xs: 24,
							sm: 8,
						},
					},
					{
						title: t`Тип`,
						editable: true,
						dataIndex: 'type',
						width: 120,
						component: (
							<Select popupMatchSelectWidth={false} style={{ width: '100%' }}>
								<Select.Option value="product">
									<Trans>Физички производ</Trans>
								</Select.Option>
								<Select.Option value="digital">
									<Trans>Дигитални производ</Trans>
								</Select.Option>
								<Select.Option value="service">
									<Trans>Услуга</Trans>
								</Select.Option>
							</Select>
						),
						rules: [
							{
								required: true,
								message: t`Тип је обавезан`,
							},
						],
					},
					{
						title: t`Назив`,
						editable: true,
						dataIndex: 'name',
						component: (
							<Input
								onFocus={(event) => {
									event.currentTarget.select();
								}}
							/>
						),
						rules: [
							{
								required: true,
								message: t`Назив је обавезан`,
							},
						],
						colProps: {
							xs: 24,
							sm: 16,
						},
					},
					{
						title: t`Количина`,
						editable: true,
						width: 190,
						dataIndex: 'quantity',

						component: (record, index, handleEditObject) => (
							<InputNumber
								type="number"
								step={1}
								disabled={props.documentType === DOCUMENT_TYPE.ADVANCE_INVOICE}
								addonAfter={
									<Form.Item
										noStyle
										name={[id, index, 'unitId']}
										rules={[
											{
												required: true,
												message: t`Јединица мере је обавезна`,
											},
										]}
									>
										<UnitSelect value={record.unitId} placeholder="JM" />
									</Form.Item>
								}
								style={{ width: '100%' }}
								onFocus={(event) => {
									event.currentTarget.select();
								}}
							/>
						),
						rules: [
							{
								required: true,
								message: t`Количина је обавезна`,
							},
							{
								type: 'number',
								min: 0.001,
								message: t`Количина мора бити већа од 0`,
							},
						],
						colProps: {
							xs: 24,
							sm: 12,
						},
					},
					{
						title: t`Цена без ПДВ`,
						editable: true,
						width: 180,
						dataIndex: 'unitPrice',
						component: () => (
							<InputNumber
								type="number"
								step={1}
								addonAfter={currencyId || 'RSD'}
								style={{ width: '100%' }}
								onFocus={(event) => {
									event.currentTarget.select();
								}}
							/>
						),
						rules: [
							{
								required: true,
								message: t`Цена је обавезна`,
							},
							{
								type: 'number',
								min: 0.0001,
								message: t`Цена мора бити већа од 0`,
							},
						],
						colProps: {
							xs: 24,
							sm: 12,
						},
					},
					{
						title: screens.md ? t`ПДВ кат.` : t`ПДВ категорија`,
						editable: true,
						width: 80,
						dataIndex: 'taxCode',
						component: (record, index, handleEditObject) => (
							<Select
								popupMatchSelectWidth={false}
								optionLabelProp="label"
								onChange={(value) => {
									if (value !== 'S') {
										handleEditObject(index, {
											taxPercentage: null,
											taxCode: value,
										});
									}
								}}
							>
								<Select.Option value={null} label="">
									{' '}
								</Select.Option>
								{Object.entries(TAX_FEE_CATEGORY).map(([key, value]) => (
									<Select.Option key={key} value={key} label={key}>
										<strong className={styles.selectTaxCategoryLabel}>
											{key}:&nbsp;
										</strong>
										{value}
									</Select.Option>
								))}
							</Select>
						),
						rules: [
							{
								required: true,
								message: t`Обавезно`,
							},
						],
						colProps: {
							span: 12,
						},
					},
					{
						title: t`ПДВ`,
						editable: true,
						width: 90,
						dataIndex: 'taxPercentage',
						component: (record, index, handleEditObject) => (
							<Select
								popupMatchSelectWidth={false}
								disabled={record.taxCode !== 'S'}
								placeholder="0%"
								optionLabelProp="label"
								onChange={(value) => {
									if (value !== 'S') {
										handleEditObject(index, {
											taxPercentage: value,
										});
									}
								}}
							>
								<Select.Option value={20} label={'20%'}>
									<strong className={styles.selectTaxCategoryLabel}>
										20%:
									</strong>{' '}
									<Trans>Општа стопа</Trans>
								</Select.Option>
								<Select.Option value={10} label={'10%'}>
									<strong className={styles.selectTaxCategoryLabel}>
										10%:
									</strong>{' '}
									<Trans>Посебна стопа</Trans>
								</Select.Option>
							</Select>
						),
						colProps: {
							span: 12,
						},
					},
					{
						title: t`Попуст`,
						editable: true,
						width: 130,
						dataIndex: 'discountPercentage',
						component: (record, index, handleEditObject) => (
							<InputNumber
								type="number"
								addonAfter="%"
								step={1}
								style={{ width: '100%' }}
								min={0}
								max={100}
								onFocus={(event) => {
									event.currentTarget.select();
								}}
								onChange={(value) => {
									handleEditObject(index, {
										discountPercentage: value,
										discountAmount: value
											? round(
													(value / 100) * record.unitPrice * record.quantity,
													2
											  )
											: 0,
									});
								}}
							/>
						),
						colProps: {
							span: 12,
						},
						rules: [
							{
								type: 'number',
								max: 100,
								message: t`Макс је 100%`,
							},
							{
								type: 'number',
								min: 0,
								message: t`Мин је 0%`,
							},
						],
					},
					{
						title: t`Попуст ${currencyId}`,
						editable: true,
						width: 160,
						dataIndex: 'discountAmount',
						component: (record, index, handleEditObject) => (
							<InputNumber
								type="number"
								addonAfter={currencyId || 'RSD'}
								step={1}
								min={0}
								max={record.unitPrice * record.quantity}
								onFocus={(event) => {
									event.currentTarget.select();
								}}
								style={{ width: '100%' }}
								rules={[
									{
										type: 'number',
										max: round(record.unitPrice * record.quantity, 2),
										message: t`Макс је ${round(
											record.unitPrice * record.quantity,
											2
										)}`,
									},
									{
										type: 'number',
										min: 0,
										message: t`Мин је 0`,
									},
								]}
								onChange={(value) => {
									handleEditObject(index, {
										discountAmount: value,
										discountPercentage: value
											? round(
													evaluate('value / (unitPrice * quantity) * 100', {
														value: bignumber(value),
														unitPrice: bignumber(record.unitPrice),
														quantity: bignumber(record.quantity),
													}).toNumber(),
													2
											  )
											: 0,
									});
								}}
							/>
						),
						colProps: {
							span: 12,
						},
					},
				]}
				shouldShowDuplicateButton
				tableProps={{
					scroll: { x: 1420 },
				}}
				newRowValue={{
					taxCode: vat ? 'S' : 'SS',
					unitPrice: 0,
					quantity:
						props.documentType === DOCUMENT_TYPE.ADVANCE_INVOICE ? 1 : 0,
					unitId: null,
					discountPercentage: 0,
					discountAmount: 0,
				}}
				insertNewRow
			/>
			<ProductSearchDrawer />
		</>
	);
}
