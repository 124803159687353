import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import HasPermission from '../../../../../../components/HasPermission';
import { Invoice } from '../../../../../../stores/Invoice.mobx';
import PayModal from '../PayModal';

interface Props {
	record: Invoice;
}

function PayButton({ record }: Props) {
	const [isModalVisible, setIsModalVisible] = useState(false);
	return (
		<HasPermission module="documents" submodule="invoices" action="pay">
			<Button
				key="payButton"
				icon={<i className="fi fi-rr-coins"></i>}
				onClick={() => {
					setIsModalVisible(true);
				}}
			/>
			<PayModal
				close={() => setIsModalVisible(false)}
				visible={isModalVisible}
				id={record.id}
			/>
		</HasPermission>
	);
}

export default observer(PayButton);
