import { t } from '@lingui/macro';
import { Input, Select, Row, Col, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, useMemo, useEffect } from 'react';

import styles from './EditForm.module.less';
import { FormRow } from '../../../../../../components/Page';
import {
	DEVICE_TYPE,
	DEVICE_TYPE_CONNECTION_TYPE,
	getDeviceConnectionTypeName,
	getDeviceTypeName,
} from '../../../../../../constants/device';
import stores from '../../../../../../stores/index.mobx';

function EditForm() {
	const DEVICE_TYPE_NAME = getDeviceTypeName();
	const DEVICE_CONNECTION_TYPE_NAME = getDeviceConnectionTypeName();

	const [selectedDeviceType, setSelectedDeviceType] = useState(null);
	const [selectedConnectionType, setSelectedConnectionType] = useState(null);

	useEffect(() => {
		setSelectedConnectionType(null);
	}, [selectedDeviceType, setSelectedConnectionType]);

	const { devices } = stores;
	const { thermalPrinters, posTerminals, barcodeScanners } = devices;

	const disabledDeviceTypes = useMemo(
		() => ({
			[DEVICE_TYPE.THERMAL_PRINTER]: thermalPrinters.length > 0,
			[DEVICE_TYPE.POS_TERMINAL]: posTerminals.length > 0,
			[DEVICE_TYPE.BARCODE_SCANNER]: barcodeScanners.length > 0,
		}),
		[thermalPrinters, posTerminals, barcodeScanners]
	);

	const fields: FormRow[] = useMemo(
		() => [
			{
				key: 'type',
				fields: [
					{
						label: t`Врста`,
						key: 'type',
						rules: [{ required: true, message: t`Одаберите тип уређаја` }],
						span: 24,
						component: (
							<Select onChange={(value) => setSelectedDeviceType(value)}>
								<Select.Option value={null}>{''}</Select.Option>
								{Object.values(DEVICE_TYPE).map((key) => (
									<Select.Option
										key={key}
										value={key}
										disabled={disabledDeviceTypes[key]}
									>
										<img
											className={styles.selectIcon}
											src={`/images/icons/new/devices/${key}.svg`}
										/>
										{DEVICE_TYPE_NAME[key]}
									</Select.Option>
								))}
							</Select>
						),
					},
				],
			},
			...(selectedDeviceType
				? [
						{
							key: 'connectionType',
							fields: [
								{
									label: t`Повезивање`,
									key: 'connectionType',
									rules: [
										{ required: true, message: t`Одаберите тип повезивања` },
									],
									span: 24,
									component: (
										<Select
											onChange={(value) => {
												// console.log(value);
												// if (value === 'hid') {
												// 	navigator.hid.requestDevice({ filters: [] });
												// }
												setSelectedConnectionType(value);
											}}
										>
											<Select.Option value={null}>{''}</Select.Option>
											{DEVICE_TYPE_CONNECTION_TYPE[selectedDeviceType].map(
												(key) => (
													<Select.Option key={key} value={key}>
														<img
															className={styles.selectIcon}
															src={`/images/icons/new/connection-types/${key}.svg`}
														/>
														{DEVICE_CONNECTION_TYPE_NAME[key]}
													</Select.Option>
												)
											)}
										</Select>
									),
								},
							],
						},
				  ]
				: []),
			...(selectedConnectionType &&
			['serial', 'usb', 'printer'].includes(selectedConnectionType)
				? [
						{
							key: 'device',
							fields: [
								{
									label: t`Уређај`,
									key: 'device',
									rules: [{ required: true, message: t`Одаберите уређај` }],
									span: 24,
									component: () => {
										if (selectedConnectionType === 'printer') {
											const ObservableSelect = observer(
												({ printerDevices, ...rest }) => {
													return (
														<Select {...rest}>
															<Select.Option value={null}>{''}</Select.Option>
															{printerDevices.map((device) => (
																<Select.Option
																	key={device.name}
																	value={JSON.stringify(device)}
																>
																	{device.name}
																</Select.Option>
															))}
														</Select>
													);
												}
											);

											return (
												<ObservableSelect
													printerDevices={devices.printerDevices}
												/>
											);
										}
										if (selectedConnectionType === 'serial') {
											const ObservableSelect = observer(
												({ serialDevices, ...rest }) => {
													return (
														<Select {...rest}>
															<Select.Option value={null}>{''}</Select.Option>
															{serialDevices.map((device) => (
																<Select.Option
																	key={device.name}
																	value={JSON.stringify(device)}
																>
																	{device.name || t`Непознат уређај`}
																</Select.Option>
															))}
														</Select>
													);
												}
											);

											return (
												<ObservableSelect
													serialDevices={devices.serialDevices}
												/>
											);
										}
									},
								},
								.../*process.platform === 'win32' &&*/
								(selectedConnectionType === 'printer'
									? [
											{
												label: t`Протокол`,
												key: 'protocol',
												rules: [
													{ required: true, message: t`Одаберите протокол` },
												],
												span: 24,
												initialValue: 'escpos',
												component: (
													<Select>
														<Select.Option value={null}>{''}</Select.Option>
														<Select.Option value={'escpos'}>
															ESC/POS
														</Select.Option>
														<Select.Option value={'peripage'}>
															PeriPage
														</Select.Option>
													</Select>
												),
											},
									  ]
									: []),
							],
						},
				  ]
				: []),
			...(selectedConnectionType && ['network'].includes(selectedConnectionType)
				? [
						{
							key: 'device',
							fields: [
								{
									label: t`IP/Hostname`,
									key: 'host',
									rules: [
										{
											required: true,
											message: t`Унесите IP или hostname`,
										},
									],
									span: 24,
									component: <Input />,
								},
								{
									label: t`Порт`,
									key: 'port',
									rules: [
										{
											required: true,
											message: t`Унесите порт`,
										},
									],
									span: 24,
									component: <Input />,
								},
							],
						},
				  ]
				: []),
		],
		[selectedDeviceType, selectedConnectionType]
	);

	return (
		<>
			{fields.map((row) => (
				<Row key={row.key} gutter={8}>
					{row.fields.map((field) => (
						<Col key={field.key} span={field.span || 24}>
							<Form.Item {...field} name={field.key}>
								{typeof field.component === 'function'
									? field.component()
									: field.component}
							</Form.Item>
						</Col>
					))}
				</Row>
			))}
		</>
	);
}

export default observer(EditForm);
