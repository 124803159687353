import { Select } from 'antd';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';

import numberFormat from '../../../../../../../lib/numberFormat';
import stores from '../../../../../../../stores/index.mobx';

interface Props {
	value?: string;
}

function TaxRateSelect(props: Props) {
	const disabled = useContext(DisabledContext);

	const {
		taxRates: { isFetching, vat },
	} = stores;

	return (
		<Select
			showSearch
			filterOption={(input, option) => {
				return (
					option.props.children
						.join('')
						.toLowerCase()
						.indexOf(input.toLowerCase()) !== -1
				);
			}}
			loading={isFetching}
			popupMatchSelectWidth={false}
			disabled={disabled}
			{...props}
		>
			{vat.map((taxRate) => (
				<Select.Option key={taxRate.label} value={taxRate.rate}>
					{numberFormat(taxRate.rate, false)}%
				</Select.Option>
			))}
		</Select>
	);
}

export default observer(TaxRateSelect);
