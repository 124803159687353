import { Mod97_10 } from '@konfirm/iso7064';
import { t, Trans } from '@lingui/macro';
import { Button, Checkbox, Col, Drawer, Form, Row, Select, Space } from 'antd';
import MaskedInput from 'antd-5-mask-input';
import IMask from 'imask';
import { observer } from 'mobx-react-lite';
import { useMemo, useRef } from 'react';
import { useCallback, useEffect } from 'react';

import globalStyles from '../../../../assets/styles/global.module.less';
import stores from '../../../../stores/index.mobx';

interface Props {
	visible?: boolean;
	entity?: any;
	close: () => void;
	save: (entity: any) => void;
	showInvoiceSwitch?: boolean;
}

export default observer(function BankAccountEditDrawer({
	visible,
	entity,
	close,
	save,
	showInvoiceSwitch = false,
}: Props) {
	const [form] = Form.useForm();
	const maskedRef = useRef<any>();
	const {
		application: { banks },
	} = stores;

	const accountNumberMask = useMemo(
		() => [
			{
				mask: 'BANKID - ACCOUNTNUMBER - CONTROLNUMBER',
				lazy: false,

				blocks: {
					BANKID: {
						mask: IMask.MaskedRange,
						from: 100,
						to: 999,
					},
					ACCOUNTNUMBER: {
						mask: IMask.MaskedRange,
						from: 1,
						to: 9999999999999,
					},
					CONTROLNUMBER: {
						mask: '00',
					},
				},
			},
		],
		[]
	);

	const onAccountNumberChange = useCallback((event) => {
		const { unmaskedValue } = event;
		const bankId = unmaskedValue.substring(0, 3);
		if (banks[Number(bankId)]) {
			form.setFieldsValue({ bankId });
		} else {
			form.setFieldsValue({ bankId: '0' });
		}
	}, []);

	const onBankIdChange = useCallback(
		(value) => {
			const unmaskedValue = form.getFieldValue('number') || '';
			const accountNumber = unmaskedValue.substring(3);

			form.setFieldsValue({ number: `${value}${accountNumber}` });
		},
		[form]
	);

	useEffect(() => {
		if (!entity) {
			form.resetFields();
			return;
		}
		form.setFieldsValue(entity);
	}, [entity, form]);

	const reformatAccountNumber = useCallback(
		(event) => {
			const { unmaskedValue } = event;
			const bankId = unmaskedValue.substring(0, 3);
			const accountNumber = unmaskedValue
				.substring(3, unmaskedValue.length - 2)
				.padStart(13, '0');
			const controlNumber =
				unmaskedValue.length >= 5
					? unmaskedValue.substring(unmaskedValue.length - 2)
					: '00';

			const number = `${bankId}${accountNumber}${controlNumber}`;
			form.setFieldValue('number', number);
		},
		[form]
	);

	return (
		<Drawer
			width={500}
			open={visible}
			title={entity ? t`Измена текућег рачуна` : t`Додавање текућег рачуна`}
			onClose={close}
			destroyOnClose
			footerStyle={{ textAlign: 'right' }}
			footer={
				<Space>
					<Button key="cancel" onClick={close}>
						<Trans context="Cancel button">Одустани</Trans>
					</Button>
					<Button key="save" type="primary" onClick={form.submit}>
						{entity
							? t({
									context: 'Save button',
									message: 'Сачувај',
							  })
							: t({
									context: 'Create button',
									message: 'Додај',
							  })}
					</Button>
				</Space>
			}
		>
			<Form
				form={form}
				onFinish={(newValue) => {
					form.resetFields();
					newValue.number = newValue.number.replace(/\D/g, '');
					save(newValue);
				}}
				layout="vertical"
				validateTrigger="submit"
				preserve={false}
			>
				<Row gutter={8}>
					<Col span={24}>
						<Form.Item label={t`Банка`} name="bankId">
							<Select
								popupMatchSelectWidth={false}
								options={[
									{ value: '0', label: ' ' },
									...Object.entries(banks).map((bank) => ({
										value: `${bank[0]}`,
										label: bank[1],
									})),
								]}
								onChange={onBankIdChange}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label={t`Број рачуна`}
							name="number"
							initialValue={entity ? entity.formattedNumber : undefined}
							rules={[
								{
									validator: (_, value) => {
										const cleanValue = value.replace(/\D/g, '');
										if (
											cleanValue.length !== 18 ||
											!Mod97_10.validate(cleanValue)
										) {
											return Promise.reject();
										}
										return Promise.resolve();
									},
									message: t`Број текућег рачуна није исправан`,
								},
							]}
						>
							<MaskedInput
								mask={accountNumberMask}
								onBlur={reformatAccountNumber}
								onChange={onAccountNumberChange}
								ref={maskedRef}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="primary"
							valuePropName="checked"
							className={globalStyles.condensedFormField}
						>
							<Checkbox>
								<Trans>Примаран текући рачун</Trans>
							</Checkbox>
						</Form.Item>
					</Col>
					{showInvoiceSwitch && (
						<Col span={24}>
							<Form.Item
								name="invoice"
								valuePropName="checked"
								className={globalStyles.condensedFormField}
							>
								<Checkbox>
									<Trans>Подразумеван за фактурисање</Trans>
								</Checkbox>
							</Form.Item>
						</Col>
					)}
				</Row>
				<input type="submit" style={{ display: 'none' }} />
			</Form>
		</Drawer>
	);
});
