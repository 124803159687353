import { t } from '@lingui/macro';
import { Input } from 'antd';

import PermissionsEdit from './Components/PermissionsEdit';
import StoresEdit from '../../../../components/Inputs/StoresEdit';
import { CreatePage } from '../../../../components/Page';
import { StaticComponents } from '../../../../components/StaticComponents';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'roles',
		model: 'role',
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/uloge-korisnika/',
			shouldFetch: false,
			table: {
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'displayName',
						key: 'displayName',
						shouldLink: true,
					},
				],
				showDisabledActions: true,
			},
			createButtonText: t`Додај улогу`,
			customDeleteModal: (record) => {
				if (record.hasUsers) {
					StaticComponents.modal.warning({
						title: t`Улога се не може обрисати`,
						content: t`Улога се не може обрисати јер постоје корисници којима је додељена. Доделите корисницима другу улогу пре брисања ове улоге.`,
						okText: t`У реду`,
					});
				} else {
					StaticComponents.modal.confirm({
						title: t`Да ли сте сигурни да желите да обришете ову улогу?`,
						content: t`Ова акција не може бити поништена.`,
						onOk() {
							record.destroy();
						},
						okText: t`Обриши`,
						cancelText: t`Одустани`,
					});
				}
				return null;
			},
		},
		edit: {
			width: 800,
			title: {
				new: t`Додавање улоге`,
				existing: (entity) => t`Измена улоге ${entity?.name || ''}`,
			},
			fields: [
				{
					key: 'row1',
					label: t`Информације`,
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							rules: [{ required: true, message: t`Назив је обавезан` }],
							component: <Input />,
							span: 24,
						},
					],
				},
				{
					key: 'stores',
					label: t`Продајна места`,
					fields: [
						{
							key: 'stores',
							component: (record) => <StoresEdit record={record} />,
							span: 24,
						},
					],
				},
				{
					key: 'row2',
					label: t`Дозволе`,
					column: 24,
					fields: [
						{
							key: 'permissions',
							component: (record) => <PermissionsEdit record={record} />,
							span: 24,
						},
					],
				},
			],
		},
		view: {
			width: 800,
			title: (record) => t`Преглед улоге ${record?.displayName || ''}`,
			fields: [
				{
					key: 'info',
					label: t`Информације`,
					column: 24,
					fields: [
						{
							key: 'displayName',
							label: t`Назив`,
							span: 24,
						},
					],
				},
				{
					key: 'stores',
					label: t`Продајна места`,
					column: 24,
					fields: [
						{
							key: 'stores',
							component: <StoresEdit readonly />,
							span: 24,
						},
					],
				},
				{
					key: 'permissions',
					label: t`Дозволе`,
					column: 24,
					fields: [
						{
							key: 'permissions',
							span: 24,
							component: <PermissionsEdit readonly />,
						},
					],
				},
			],
		},
	});
