import { observable } from 'mobx';

import { CreateStore } from './Crud.mobx';

const { Store, Entity } = CreateStore({
	name: 'currency',
	paginated: false,
	persistFields: ['all'],
});

class Currency extends Entity {
	@observable name?: string;
	@observable code?: number;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isDeletable(): boolean {
		return false;
	}

	get isEditable(): boolean {
		return false;
	}
}

class Currencies extends Store<Currency> {
	constructor() {
		super(Currency);
	}

	get isCreatable() {
		return false;
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await this.fetchAll();
		}
	}
}

export { Currencies, Currency };
