import { Badge } from 'antd';
import { observer } from 'mobx-react-lite';

import stores from '../../stores/index.mobx';
import './SocketStatus.module.less';
function SocketStatus({ children }) {
	const { socket } = stores;

	switch (socket.status) {
		case 'connected':
			return (
				<Badge offset={[-4, 30]} dot status="success">
					{children}
				</Badge>
			);
		case 'connecting':
			return (
				<Badge offset={[-4, 30]} dot status="processing">
					{children}
				</Badge>
			);
		case 'disconnected':
			return (
				<Badge offset={[-4, 30]} dot status="warning">
					{children}
				</Badge>
			);
		case 'error':
			return (
				<Badge offset={[-4, 30]} dot status="error">
					{children}
				</Badge>
			);
	}
}

export default observer(SocketStatus);
