import { Trans } from '@lingui/macro';
import { Select } from 'antd';
import { observer } from 'mobx-react-lite';

import stores from '../../../../../stores/index.mobx';

function StoreSelect(props) {
	const {
		stores: { isFetching, available },
	} = stores;

	return (
		<Select
			showSearch
			filterOption={(input, option) => {
				return (
					(option.children as unknown as string)
						.toLowerCase()
						.indexOf(input.toLowerCase()) !== -1
				);
			}}
			loading={isFetching}
			popupMatchSelectWidth={false}
			style={{ width: '200px' }}
			{...props}
		>
			<Select.Option key={'all'} value={'all'}>
				<Trans>Сва продајна места</Trans>
			</Select.Option>
			{available.map((store) => (
				<Select.Option key={store.id} value={store.id}>
					{store.displayName}
				</Select.Option>
			))}
		</Select>
	);
}

export default observer(StoreSelect);
