import { t } from '@lingui/macro';
import { Input, Select } from 'antd';

import { CreatePage } from '../../../../components/Page';
import RoleSelect from '../../../../components/RoleSelect';
import stores from '../../../../stores/index.mobx';
export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'users',
		model: 'user',
		view: {
			useEdit: true,
		},
		edit: {
			width: 700,
			title: {
				new: t`Додавање корисника`,
				existing: (entity) => t`Измена корисника ${entity?.fullName || ''}`,
			},
			shouldFetch: false,
			disablePasswordAutocomplete: true,
			fields: () => [
				{
					key: 'row1',
					fields: [
						{
							key: 'firstName',
							label: t`Име`,
							rules: [{ required: true, message: t`Име је обавезно` }],
							component: <Input />,
							md: 12,
							xs: 24,
						},
						{
							key: 'lastName',
							label: t`Презиме`,
							rules: [{ required: true, message: t`Презиме је обавезно` }],
							component: <Input />,
							md: 12,
							xs: 24,
						},
						{
							key: 'email',
							label: t`Адреса електронске поште`,
							rules: [
								{
									required: true,
									message: t`Адреса електронске поште је обавезна`,
								},
								{
									email: true,
									message: `Адреса електронске поште је неисправна`,
								},
							],
							component: <Input />,
							md: 12,
							xs: 24,
						},
						{
							key: 'password',
							label: t`Лозинка`,
							rules: [
								{ min: 8, message: t`Лозинка мора имати најмање 8 карактера` },
							],
							component: (
								<Input
									type="password"
									autoComplete="false"
									role="presentation"
								/>
							),
							md: 12,
							xs: 24,
						},
					],
				},
				...(stores.users.authenticatedUser?.hasPermission(
					'settings',
					'users',
					'edit'
				)
					? [
							{
								key: 'role',
								fields: [
									{
										key: 'roleId',
										label: t`Улога`,
										rules: [{ required: true, message: t`Улога је обавезна` }],
										component: <RoleSelect />,
										md: 12,
										xs: 24,
									},
								],
							},
					  ]
					: []),

				{
					key: 'locale',
					fields: [
						{
							key: 'locale',
							label: t`Језик`,
							component: (
								<Select>
									<Select.Option value="sr-cyrl">
										Српски (Ћирилица)
									</Select.Option>
									<Select.Option value="sr-latn">
										Srpski (Latinica)
									</Select.Option>
									<Select.Option value="en">English</Select.Option>
									<Select.Option value="ru">Русский</Select.Option>
								</Select>
							),
							span: 24,
						},
					],
				},
			],
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/korisnici/',
			shouldFetch: false,
			table: {
				columns: [
					{
						title: t`Име`,
						dataIndex: 'fullName',
						key: 'fullName',
						shouldLink: true,
						ellipsis: true,
					},
					{
						title: t`Адреса електронске поште`,
						dataIndex: 'email',
						key: 'email',
						responsive: ['sm'],
						ellipsis: true,
					},
				],
				showDisabledActions: true,
			},
			createButtonText: t`Додај корисника`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете овог корисника?`,
		},
	});
