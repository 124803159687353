import { t } from '@lingui/macro';
import { Select, Input, Space, Typography, Divider } from 'antd';
import { useCallback, useMemo, useRef } from 'react';

import styles from './Input.module.less';
interface Props {
	value?: string;
	onChange?: (string) => void;
	readOnly?: boolean;
	disabled?: boolean;
}

function BuyerInput({
	value,
	onChange,
	readOnly = false,
	disabled = false,
}: Props) {
	const buyerTypes = [
		{
			label: t`Лица која обављају делатност`,
			options: [
				{
					value: 10,
					label: t`ПИБ`,
					description: t`Домаће правно лице идентификовано ПИБ-ом`,
				},
				{
					value: 11,
					label: t`ЈМБГ`,
					description: t`Домаће физичко лице које обавља самосталну делатност идентификовано ЈМБГ-ом`,
				},
				{
					value: 12,
					label: t`ПИБ и ЈБКЈС`,
					description: t`Домаће правно лице које је идентификовано Пореским идентификационим бројем (ПИБ) и Јединственим бројем корисника јавних средстава из Евиденције корисника јавних средстава Управе за трезор (ЈБКЈС)`,
				},
			],
		},
		{
			label: t`Пољопривредна газдинства`,
			options: [
				{
					value: 14,
					label: t`ПИБ`,
					description: t`Правно лице, пољопривредно газдинство, идентификовано Пореским идентификационим бројем (ПИБ)`,
				},
				{
					value: 15,
					label: t`ЈМБГ`,
					description: t`Домаће физичко лице, пољопривредно газдинство, које обавља самосталну делатност идентификовано ЈМБГ-ом`,
				},
				{
					value: 16,
					label: t`БПГ`,
					description: t`Физичко лице идентификовано јединственим бројем пољопривредног газдинства (БПГ)`,
				},
			],
		},
		{
			label: t`Физичка лица`,
			options: [
				{
					value: 20,
					label: t`Број личне карте`,
					description: t`Домаће физичко лице`,
				},
				{
					value: 21,
					label: t`Број избегличке легитимације`,
					description: t`Домаће физичко лице`,
				},
				{
					value: 22,
					label: t`ЕБС`,
					description: t`Страно физичко лице које има пријављени боравак у Србији`,
				},
				{
					value: 23,
					label: t`Број пасоша`,
					description: t`Домаће физичко лице`,
				},
				{
					value: 13,
					label: t`Код (број) пензионерске картице`,
					description: t`Физичко лице – ималац пензионерске картице`,
				},
			],
		},
		{
			label: t`Страна физичка лица`,
			options: [
				{
					value: 30,
					label: t`Број пасоша`,
					description: t`Страно физичко лице које се идентификује пасошем`,
				},
				{
					value: 31,
					label: t`Број дипломатске легитимације/ЛК`,
					description: t`Страно физичко лице - дипломата`,
				},
				{
					value: 32,
					label: t`Број личне карте MKD`,
					description: t`Страно физичко лице - које се идентификује личном картом из Македоније`,
				},
				{
					value: 33,
					label: t`Број личне карте MNE`,
					description: t`Страно физичко лице - које се идентификује личном картом из Црне Горе`,
				},
				{
					value: 34,
					label: t`Број личне карте ALB`,
					description: t`Страно физичко лице - које се идентификује личном картом из Албаније`,
				},
				{
					value: 35,
					label: t`Број личне карте BIH`,
					description: t`Страно физичко лице - које се идентификује личном картом из Босне и Херцеговине`,
				},
				{
					value: 36,
					label: t`Број личне карте по Одлуци`,
					description: t`Страно физичко лице – које се идентификује личном картом из земаља чланица Европске уније, Швајцарске конфедерације, Краљевине Норвешке и Републике Исланд`,
				},
			],
		},
		{
			label: t`Страна правна лица`,
			options: [
				{
					value: 40,
					label: t`Порески ИД из стране државе (ТИН)`,
					description: `Порески идентификациони број издат у иностранству`,
				},
			],
		},
	];

	const ref = useRef(null);
	const [currentType, currentId] = useMemo(() => {
		const [t, ...v] = value ? value.split(':') : ['', ''];
		return [t, v.join(':')];
	}, [value]);

	const handleChange = useCallback(
		(type?: number, id?: string) => {
			const current = [currentType, currentId];
			if (typeof type !== 'undefined') {
				current[0] = `${type}`;
			}
			if (typeof id !== 'undefined') {
				current[1] = id;
			}
			const val = current.join(':');
			onChange(val === ':' ? null : val);
		},
		[currentType, currentId, onChange]
	);

	return (
		<Space.Compact style={{ width: '100%' }}>
			<Select
				style={{ width: '50%' }}
				popupMatchSelectWidth={false}
				value={currentType ? Number(currentType) : undefined}
				onChange={(value) => {
					handleChange(value);
					ref.current.focus();
				}}
				disabled={readOnly || disabled}
				optionLabelProp="label"
			>
				<Select.Option value="">&nbsp;</Select.Option>
				{buyerTypes.map((type) => (
					<Select.OptGroup label={type.label} key={type.label}>
						{type.options.map((option) => (
							<Select.Option
								value={option.value}
								key={option.value}
								label={
									<>
										<Typography.Text type="secondary">
											{option.value}
										</Typography.Text>
										<Divider type="vertical" />
										{option.label}
									</>
								}
							>
								<Typography.Text type="secondary">
									{option.value}
								</Typography.Text>
								<Divider type="vertical" />
								{option.label}
								<p className={styles.description}>{option.description}</p>
							</Select.Option>
						))}
					</Select.OptGroup>
				))}
			</Select>
			<Input
				ref={ref}
				style={{ width: '50%' }}
				value={currentId}
				onChange={(event) => {
					handleChange(undefined, event.target.value);
				}}
				disabled={readOnly || disabled}
			/>
		</Space.Compact>
	);
}
export default BuyerInput;
