import BaseError from '.';

class BadRequestError extends BaseError {
	constructor(error = BadRequestError.ERROR_BAD_REQUEST, variables) {
		super('Bad Request', error, variables);

		this.code = 400;
	}
}

BadRequestError.ERROR_BAD_REQUEST = [40000000, 'Bad Request'];

BadRequestError.ERROR_BAD_REQUEST_CHANGE_AMOUNT_GREATER_THAN_CASH_AMOUNT = [
	40080003,
	'Change amount must be less or equal to cash amount',
];
BadRequestError.ERROR_BAD_REQUEST_SUM_PAYMENT_METHODS_LESS_THAN_TOTAL = [
	40080004,
	'Sum of all payment methods can not be less than total.',
];

BadRequestError.ERROR_BAD_REQUEST_UNSUPPORTED_INVOICE_TYPE = [
	40080007,
	'Unsupported invoice type',
];

BadRequestError.ERROR_BAD_REQUEST_NO_PAYMENT_METHOD_SPECIFIED = [
	40080009,
	'No payment method specified',
];

BadRequestError.ERROR_BAD_REQUEST_UNSUPPORTED_OPERATION_OLD_ADVANCE_REFUND = [
	40080010,
	'Refunding old advances is not supported via an API',
];

BadRequestError.ERROR_BAD_REQUEST_ADVANCE_SPECIFICATION_MISMATCH = [
	40080011,
	'Mismatch between advance specification and items tax labels or payment amout',
];

BadRequestError.ERROR_BAD_REQUEST_ADVANCE_SPECIFICATION_MISSING = [
	40080012,
	'Advance specification is missing. Could not determine amount per tax label automatically',
];

BadRequestError.ERROR_BAD_REQUEST_ADVANCE_ITEMS_MISSING = [
	40080013,
	'Items are missing. Could not determine amounts per item automatically',
];

BadRequestError.ERROR_BAD_REQUEST_ADVANCE_ALREADY_CLOSED = [
	40080014,
	'Advance is already closed',
];

BadRequestError.ERROR_BAD_REQUEST_ADVANCE_TOTAL_AMOUNT_PAID = [
	40080015,
	'Total amount has already been paid',
];

BadRequestError.ERROR_BAD_REQUEST_UNSUPPORTED_TRANSACTION_TYPE = [
	400800016,
	'Unsupported transaction type',
];

BadRequestError.ERROR_BAD_REQUEST_SUM_PAYMENT_METHODS_GREATER_THAN_TOTAL = [
	400800017,
	'Sum of all payment methods can not be greater than total.',
];

BadRequestError.ERROR_REFUND_QUANTITY_LARGER_THAN_REMAINING_QUANTITY = [
	400800018,
	'Refund quantity is larger than remaining quantity',
];

BadRequestError.ERROR_REFUND_ITEM_NOT_ON_RECEIPT = [
	400800019,
	'Item is not on the receipt',
];

// General errors
BadRequestError.ERROR_BAD_REQUEST_VALIDATION_ERROR = [
	40090001,
	'Validation error',
];

BadRequestError.ERROR_BAD_REQUEST_INVALID_CREDENTIALS = [
	40090002,
	'Invalid credentials',
];

export default BadRequestError;
