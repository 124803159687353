import { useDrawer } from '../../../../../../components/Page';

type Props = {
	hideIfReceiptIsOpen?: boolean;
};
export function RefundDrawerWrapper({ hideIfReceiptIsOpen = false }: Props) {
	const [, , , isReceiptOpen] = useDrawer('view/receipts/list');
	const [, , , , , ReceiptRefundDrawer] = useDrawer('receipt-refund');

	if (hideIfReceiptIsOpen && isReceiptOpen) {
		return null;
	}

	return <ReceiptRefundDrawer />;
}
