import localforage from 'localforage';
import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class Session {
	@observable currentCompanyId?: string;
	@observable locale?: string;

	constructor() {
		makeObservable(this);
	}

	init() {
		const storage = localforage.createInstance({
			name: 'storeBuddy',
			version: 1.0,
			storeName: `settings`,
		});

		async function setItem(key: string, value: any) {
			const currentValue = (await storage.getItem(key)) as any;
			await storage.setItem(key, value);
			if (currentValue?.currentCompanyId && !value?.currentCompanyId) {
				window.location.reload();
			}
		}

		return makePersistable(this, {
			name: 'session',
			properties: ['currentCompanyId', 'locale'],
			storage: {
				setItem: setItem,
				getItem: storage.getItem,
				removeItem: storage.removeItem,
			},
			stringify: false,
			// debugMode: process.env.NODE_ENV === 'development',
			debugMode: false,
		});
	}

	@action.bound
	setCurrentCompanyId(currentCompanyId: string) {
		this.currentCompanyId = currentCompanyId;
	}

	@action.bound
	setLocale(locale: string) {
		this.locale = locale;
	}
}
