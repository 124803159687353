import { t } from '@lingui/macro';

export const DEVICE_TYPE = {
	BARCODE_SCANNER: 'barcode_scanner',
	THERMAL_PRINTER: 'thermal_printer',
	// PRINTER: 'printer',
	// SCANNER: 'scanner',
	// RFID_READER: 'rfid_reader',
	// NFC_READER: 'nfc_reader',
	SCALE: 'scale',
	POS_TERMINAL: 'pos_terminal',
};
export const getDeviceTypeName = () => ({
	barcode_scanner: t`Бар код читач`,
	thermal_printer: t`Термални штампач`,
	printer: t`Штампач`,
	scanner: t`Скенер`,
	scale: t`Вага`,
	rfid_reader: t`RFID читач`,
	nfc_reader: t`NFC читач`,
	pos_terminal: t`ПОС терминал`,
});

export const DEVICE_CONNECTION_TYPE = {
	USB: 'usb',
	BLUETOOTH: 'bluetooth',
	NETWORK: 'network',
	SERIAL: 'serial',
	HID: 'hid',
	KEYBOARD_EMULATION: 'keyboard_emulation',
	PRINTER: 'printer',
};
export const getDeviceConnectionTypeName = () => ({
	usb: t`УСБ`,
	serial: t`Серијски`,
	bluetooth: t`Блутут`,
	network: t`Мрежа`,
	// serial: t`УСБ/Серијски`,
	hid: t`HID`,
	keyboard_emulation: t`Емулација тастатуре`,
	printer: t`Штампач`,
});

export const HID_DEVICE_USAGE = {
	barcode_scanner: {
		usagePage: 0x8c,
	},
	rfid_reader: {
		usagePage: 0x20,
		usage: 0x92,
	},
	nfc_reader: {
		usagePage: 0x20,
		usage: 0x93,
	},
};

export const DEVICE_TYPE_CONNECTION_TYPE = {
	thermal_printer: [
		// DEVICE_CONNECTION_TYPE.BLUETOOTH,
		DEVICE_CONNECTION_TYPE.SERIAL,
		DEVICE_CONNECTION_TYPE.NETWORK,
		DEVICE_CONNECTION_TYPE.PRINTER,
	],
	pos_terminal: [
		DEVICE_CONNECTION_TYPE.NETWORK,
		//  DEVICE_CONNECTION_TYPE.SERIAL
	],
	barcode_scanner: [
		// DEVICE_CONNECTION_TYPE.BLUETOOTH,
		DEVICE_CONNECTION_TYPE.SERIAL,
		// DEVICE_CONNECTION_TYPE.USB,
		// DEVICE_CONNECTION_TYPE.HID,
		// DEVICE_CONNECTION_TYPE.KEYBOARD_EMULATION,
	],
	scale: [DEVICE_CONNECTION_TYPE.SERIAL],
};
