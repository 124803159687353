import { t } from '@lingui/macro';
import { Button, Input } from 'antd';

import { AddButton } from './Components/AddButton';
import CategorySelect from '../../../../components/CategorySelect';
import { CreatePage } from '../../../../components/Page';
import stores from '../../../../stores/index.mobx';

export default () =>
	CreatePage({
		module: 'catalog',
		submodule: 'categories',
		model: 'category',
		view: {
			useEdit: true,
		},
		edit: {
			width: 500,
			title: {
				new: t`Додавање категорије`,
				existing: (entity) => t`Измена категорије ${entity?.name || ''}`,
			},
			shouldFetch: false,
			beforeSave: (values) => {
				return {
					...values,
					categoryId:
						values.categoryId === 'root' ? undefined : values.categoryId,
				};
			},
			fields: [
				{
					key: 'row1',
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							rules: [{ required: true, message: t`Назив је обавезан` }],
							component: <Input />,
							span: 24,
						},
					],
				},
				{
					key: 'row2',
					fields: [
						{
							key: 'description',
							label: t`Опис`,
							rules: [],
							component: <Input.TextArea />,
							span: 24,
						},
					],
				},
				{
					key: 'row3',
					fields: [
						{
							key: 'categoryId',
							label: t`Отац`,
							rules: [],
							component: <CategorySelect showRoot defaultValue={'root'} />,
							span: 24,
						},
					],
				},
			],
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/katalog/kategorije/',
			shouldFetch: false,
			empty: {
				image: 'folder',
				text: t`Нема категорија`,
			},
			table: {
				actions: [(record) => <AddButton parentId={record.id} />],
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
						shouldLink: true,
						width: 400,
						ellipsis: true,
					},
					{
						title: t`Опис`,
						dataIndex: 'description',
						key: 'description',
						responsive: ['sm'],
						ellipsis: true,
					},
				],
				listProp: 'tree',
			},
			createButtonText: t`Додај категорију`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете ову категорију?`,
		},
	});
