import { t, Trans } from '@lingui/macro';
import { Badge, Button, ConfigProvider, Empty, List, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
// import VirtualList from 'rc-virtual-list';
import { useCallback, useMemo } from 'react';
import VirtualList from 'react-virtualized/dist/es/List';

import { NoImage } from '../../../../../components/NoImage';
import { Category } from '../../../../../stores/Category.mobx';
import stores from '../../../../../stores/index.mobx';
import { Product } from '../../../../../stores/Product.mobx';
import styles from '../../POS.module.less';
import { Folder } from '../../../../../components/Folder';
interface Props {
	height?: number;
	width?: number;
	category?: Category | null;
	zIndex?: number;
	push?: (category: Category) => void;
	pop?: () => void;
	selectProduct?: (product: Product) => void;
	openSearchDrawer?: (any, any, any) => void;
	currentSaleChannelId?: string | null;
}

function CategoryView({
	height,
	width,
	category,
	zIndex,
	push,
	pop,
	selectProduct,
	openSearchDrawer,
	currentSaleChannelId,
}: Props) {
	const {
		categories: { tree, isFetching: isFetchingCategories },
		stores: { currentStore },
		products: { isFetching: isFetchingProducts, byCategory },
	} = stores;

	const filteredData = useCallback(
		(data) => {
			if (!currentSaleChannelId) {
				return data;
			}
			return (data || []).filter((item) => {
				if (item.type === 'category') {
					return true;
				}

				if (item.hasVariants) {
					return item.variants.some((variant) =>
						variant.saleChannelIds.includes(currentSaleChannelId)
					);
				}

				return item.saleChannelIds.includes(currentSaleChannelId);
			});
		},
		[currentSaleChannelId]
	);

	const pickerType = useMemo(() => {
		if (!currentStore || currentStore?.isResolving) {
			return 'list';
		}
		return currentStore?.productPickerType;
	}, [currentStore]);
	const data = useMemo(() => {
		return [
			...((category ? category.children : tree) || []).map((c) => ({
				type: 'category',
				key: c.id,
				id: c.id,
				title: c.name,
				image: pickerType === 'list' ? <Folder size="small" /> : <Folder />,
				category: c,
				count:
					(filteredData(byCategory(c?.id))?.length || 0) +
					(c.children?.length || 0),
			})),
			...filteredData(byCategory(category?.id)).map((p) => ({
				type: 'product',
				key: p.id,
				id: p.id,
				title: p.name,
				image: p.coverImage ? (
					<img
						src={p.coverImage.urls['64x64']}
						alt="Slika"
						className={styles.image}
					/>
				) : pickerType === 'list' ? (
					<NoImage name={p.name || ''} size="small" />
				) : (
					<NoImage name={p.name || ''} />
				),
				product: p,
			})),
		];
	}, [category, tree, filteredData, byCategory, pickerType]);

	const chunkSize = useMemo(() => {
		return (width / 75) | 0;
	}, [width]);

	return (
		<div className={styles.categoryView} style={{ height, zIndex }}>
			<div
				className={styles.categoryHeader}
				onClick={() => {
					if (category) {
						pop();
					}
				}}
			>
				{!category && (
					<>
						<i className="fi fi-rr-home"></i>
						<Trans>Све категорије</Trans>
					</>
				)}
				{category && (
					<>
						<i className="fi fi-rr-angle-left"></i>
						{category.name}
					</>
				)}
				<Button
					size="small"
					type="primary"
					shape="circle"
					icon={<i className="fi fi-rr-search"></i>}
					onClick={() =>
						openSearchDrawer(undefined, undefined, {
							currentSaleChannelId,
						})
					}
					className={styles.searchButton}
				/>
			</div>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						className={styles.emptyCategory}
						image={
							tree.length ? (
								<img
									src="/images/icons/new/folder-flat.svg"
									alt={t`Нема артикала`}
									className="anticon"
								/>
							) : (
								<img
									src="/images/icons/new/folder-flat.svg"
									alt={t`Нема категорија`}
									className="anticon"
								/>
							)
						}
						description={
							tree.length
								? t`У одабраној категорији нема артикала`
								: t`Нема категорија`
						}
					/>
				)}
			>
				<Spin spinning={isFetchingCategories || isFetchingProducts}>
					<List
						className={styles.categoryList}
						size="small"
						style={{
							height: height - 40,
						}}
						itemLayout="horizontal"
					>
						{data.length === 0 &&
							!(isFetchingCategories || isFetchingProducts) && (
								<Empty
									className={styles.emptyCategory}
									style={{ marginTop: 24 }}
									image={
										tree.length ? (
											<img
												src="/images/icons/new/folder-flat.svg"
												alt={t`Нема артикала`}
												className="anticon"
											/>
										) : (
											<img
												src="/images/icons/new/folder-flat.svg"
												alt={t`Нема категорија`}
												className="anticon"
											/>
										)
									}
									description={
										tree.length
											? t`У одабраној категорији нема артикала`
											: t`Нема категорија`
									}
								/>
							)}
						{data.length > 0 &&
							!(isFetchingCategories || isFetchingProducts) &&
							(pickerType === 'list' ? (
								<VirtualList
									rowCount={data.length}
									height={height - 40}
									rowHeight={41}
									width={width}
									rowRenderer={({ index, key, style }) => {
										const item = data[index];
										return (
											<List.Item
												style={style}
												key={key}
												className={
													item.type === 'category'
														? styles.categoryItem
														: styles.productItem
												}
												onClick={async () => {
													if (item.type === 'category') {
														push(item.category);
													}
													if (item.type === 'product') {
														selectProduct(item.product);
													}
												}}
											>
												<List.Item.Meta
													avatar={item.image}
													title={`${item.title}${
														item.type === 'category' ? ` (${item.count})` : ``
													}`}
												/>
											</List.Item>
										);
									}}
								/>
							) : (
								<VirtualList
									rowCount={Math.ceil(data.length / chunkSize)}
									height={height - 40}
									rowHeight={88}
									width={width}
									rowRenderer={({ index, key, style }) => {
										const chunk = data.slice(
											index * chunkSize,
											(index + 1) * chunkSize
										);
										return (
											<List.Item
												className={styles.gridRow}
												style={style}
												key={key}
											>
												{chunk.map((item) => (
													<div
														className={`item ${
															item.type === 'category' ? 'category' : ''
														}`}
														onClick={async () => {
															if (item.type === 'category') {
																push(item.category);
															}
															if (item.type === 'product') {
																selectProduct(item.product);
															}
														}}
													>
														{item.type === 'category' ? (
															<Badge.Ribbon
																color="#7E89C8"
																text={
																	item.count === 0
																		? '\u00A0' + item.count + '\u00A0'
																		: item.count
																}
																placement="end"
															>
																<div>{item.image}</div>
															</Badge.Ribbon>
														) : (
															<div>{item.image}</div>
														)}
														<span className="title" lang="sr">
															{item.title}
														</span>
													</div>
												))}
												{chunk.length < ((width / 75) | 0) &&
													[...Array(((width / 75) | 0) - chunk.length)].map(
														(_, i) => (
															<div
																className="item"
																key={i}
																style={{ width: 64, height: 80 }}
															/>
														)
													)}
											</List.Item>
										);
									}}
								/>
							))}
					</List>
				</Spin>
			</ConfigProvider>
		</div>
	);
}

export default observer(CategoryView);
