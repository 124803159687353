import { ProConfigProvider } from '@ant-design/pro-provider/es';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ConfigProvider, App as AntdApp } from 'antd';
import axios from 'axios';
import { auto as followSystemColorScheme } from 'darkreader';
import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { Provider as BusProvider } from 'react-bus';
import { Routes } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
// import 'antd/dist/antd.less';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import StaticComponents from './components/StaticComponents';
import VSDC from './components/VSDC';
import 'array-flat-polyfill';
import 'polyfill-object.fromentries';
import config from './config.json';
import { APPLICATION_VERSION } from './constants/application';
import { DrawerProvider } from './context/DrawerContext';
import withAuth from './hocs/withAuth';
import { useLocale } from './lib/i18n';
import Application from './modules/Application';
import Login from './modules/Login';
import Register from './modules/Register';
import stores from './stores/index.mobx';
import './App.less';

if (process.env.NODE_ENV === 'production') {
	Bugsnag.start({
		apiKey: '4f08ee5076e0cdfadd75a2ede14cfb10',
		plugins: [new BugsnagPluginReact()],
		appVersion: `${process.env.REACT_APP_APP_NAME}-${APPLICATION_VERSION}`,
	});
}

followSystemColorScheme(
	{
		brightness: 100,
		contrast: 100,
		sepia: 0,
	},
	{
		css: `
		.ant-checkbox-checked .ant-checkbox-inner::after {
			border-right-color: rgba(232, 230, 227, 0.85) !important;
			border-bottom-color: rgba(232, 230, 227, 0.85) !important;
		}

		.ant-radio-inner::after {
			background-color: rgba(232, 230, 227, 0.85) !important;
		}

		.ant-switch-handle::before {
			background-color: rgba(232, 230, 227, 0.85) !important;
		}

		.ant-empty .ant-empty-image img {
			filter: invert(1) hue-rotate(180deg) grayscale(100%) !important;
			opacity: 0.3 !important;
		}
		img.anticon, .noImage, .ant-upload-drag-icon img {
			filter: invert(1) hue-rotate(180deg) brightness(1.5) !important;
		}

		.noImage {
			color: #000 !important;
		}

		#thermal-temporary *, #a4-temporary *, #email-temporary * {
			color: #000 !important;
			background-color: transparent !important;
		}

		#a4-temporary th {
			background: #eee !important;
		}

		#a4-temporary .title span, #a4-temporary h3 span {
			background: #fff !important;
		}

		#thermal-temporary, #a4-temporary, #email-temporary {
			color: #000 !important;
			background-color: #fff !important;
		}
	`,
		invert: [
			// '#printarea',
			// '#a4-temporary',
			// '#email-temporary',
			// '#thermal-temporary',
		],
		ignoreInlineStyle: [
			'.home-chart svg *',
			'.home-chart .g2-tooltip .g2-tooltip-marker',
		],
	}
);

// Enable once ant supports font in variables css
axios.defaults.baseURL = config.api;

// const presentation = navigator.presentation;
// const session = presentation.requestSession(
// 	'data:text/html;charset=utf-8,%3Chtml%3E%3Cbody%3Efoo%3C/body%3E%3C/html%3E'
// );

const ErrorBoundary =
	process.env.NODE_ENV === 'production'
		? Bugsnag.getPlugin('react').createErrorBoundary(React)
		: Fragment;

const customTheme = {
	token: {
		colorLink: '#7E89C8',
		colorBgLayout: '#fcfcfc',
		colorPrimary: '#7E89C8',
		fontFamily:
			"Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji'",
	},
};

function App() {
	const {
		application: { isInitialized, loadingProgress, isRegistrationFinished },
		users: { currentLocale },
	} = stores;

	const [antdLocale, activated] = useLocale(currentLocale);

	return (
		<ErrorBoundary>
			<I18nProvider i18n={i18n}>
				<ConfigProvider locale={antdLocale} theme={customTheme}>
					<ProConfigProvider theme={customTheme} locale={antdLocale}>
						<DrawerProvider>
							<AntdApp>
								<StaticComponents />
								{(!isInitialized || !activated) && !isRegistrationFinished ? (
									<div id="bootloader-container">
										<progress
											id="progressbar"
											value={loadingProgress / 100 + 1}
											max="2"
										></progress>
									</div>
								) : (
									<BusProvider>
										<BrowserRouter>
											<QueryParamProvider adapter={ReactRouter6Adapter}>
												<Routes>
													<Route exact path="/" Component={withAuth(Login)} />
													<Route
														path="/register/:step?/:package?/:paymentTypes?"
														Component={withAuth(Register, false, true)}
													/>
													<Route
														path="/app/*"
														Component={withAuth(Application, true)}
													/>
												</Routes>
											</QueryParamProvider>
										</BrowserRouter>
									</BusProvider>
								)}
							</AntdApp>
						</DrawerProvider>
						<VSDC />
					</ProConfigProvider>
				</ConfigProvider>
			</I18nProvider>
		</ErrorBoundary>
	);
}

export default observer(App);
