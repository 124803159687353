import { observer } from 'mobx-react-lite';
import stores from '../../../../../../stores/index.mobx';
import { Trans } from '@lingui/macro';
import { Tooltip, Typography } from 'antd';

function BaseHeaderStatus() {
	const {
		mojBadi: {
			settings: { status, rejectedReason },
		},
	} = stores;

	if (status === 'active') {
		return (
			<Typography.Text type="success">
				<i className="fi fi-rr-check-circle"></i> <Trans>Активан</Trans>
			</Typography.Text>
		);
	}

	if (status === 'pending') {
		return (
			<Typography.Text type="secondary">
				<i className="fi fi-rr-pause-circle"></i> <Trans>На чекању</Trans>
			</Typography.Text>
		);
	}

	if (status === 'rejected') {
		return (
			<Tooltip title={rejectedReason}>
				<Typography.Text type="danger">
					<i className="fi fi-rr-cross-circle"></i> <Trans>Одбијен</Trans>
				</Typography.Text>
			</Tooltip>
		);
	}

	return null;
}

export const HeaderStatus = observer(BaseHeaderStatus);
