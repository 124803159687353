export const PRODUCT_SALE_CHANNEL_STATUS = {
	PENDING: 'pending',
	PROCESSING: 'processing',
	FAILED: 'failed',
	COMPLETED: 'completed',
};
export const JOB_TYPE = {
	CREATE_PRODUCT: 'create_product',
	UPDATE_PRODUCT: 'update_product',
	UPDATE_AVAILABILITY: 'update_availability',
};
