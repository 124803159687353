import { Spin } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Product } from '../../../../../../stores/Product.mobx';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useMedia } from 'react-use';
import ColumnChart from '@ant-design/plots/es/components/column';
import numberFormat from '../../../../../../lib/numberFormat';
import { t } from '@lingui/macro';
import stores from '../../../../../../stores/index.mobx';
import { sortBy } from 'lodash';
import styles from '../../Products.module.less';
import { bignumber, evaluate } from 'mathjs';
interface Props {
	product?: Product;
}

const TurnoverStatsViewBase = (props: Props) => {
	const { product } = props;
	const isDarkMode = useMedia('(prefers-color-scheme: dark)');
	const {
		stores: { byId },
	} = stores;
	useEffect(() => {
		flowResult(product?.fetchTurnoverStats());
	}, [product]);

	return (
		<Spin spinning={product?.turnoverStats?.isFetching}>
			<ColumnChart
				className={styles.chart}
				animation
				theme={{
					styleSheet: {
						fontFamily: 'Montserrat',
					},
					// defaultColor: '#7e89c8',
					components: isDarkMode
						? {
								tooltip: {
									marker: {
										stroke: '#131516',
									},
									crosshairs: {
										line: {
											style: {
												stroke: '#303030',
											},
										},
									},
								},
								axis: {
									common: {
										tickLine: {
											style: {
												stroke: '#303030',
											},
										},
										line: {
											style: {
												stroke: '#303030',
											},
										},
									},
								},
								slider: {
									common: {
										textStyle: {
											fill: '#fff',
											opacity: 0.45,
										},
										backgroundStyle: {
											fill: '#7e89c8',
											opacity: 0.05,
										},
									},
								},
						  }
						: {
								slider: {
									common: {
										backgroundStyle: {
											fill: '#7e89c8',
											opacity: 0.05,
										},
									},
								},
						  },
				}}
				color={[
					'#7E89C8',
					'#C87EAE',
					'#C8BD7E',
					'#7EC898',
					'#7EAEC8',
					'#BD7EC8',
					'#C8987E',
					'#7EC8BD',
				]}
				xField="date"
				height={200}
				yField="amount"
				seriesField="storeId"
				isStack
				xAxis={{
					alias: t`Датум`,
				}}
				data={sortBy(product?.turnoverStats?.stats || [], 'date')}
				label={false}
				legend={false}
				yAxis={{
					grid: {
						line: {
							style: {
								stroke: isDarkMode ? '#303030' : '#f0f0f0',
							},
						},
					},
				}}
				meta={{
					date: {
						formatter: (date) => dayjs(date).format('DD. MMM'),
					},
					amount: {
						formatter: (amount) => numberFormat(amount, true, 2, true),
					},
				}}
				tooltip={{
					showContent: (items) => {
						return !!items?.[0]?.data?.storeId;
					},
					customItems: (items) => {
						return [
							...items,
							{
								color: '#ccc',
								data: {},
								value: numberFormat(
									items.reduce((acc, item) => {
										return evaluate('acc + value', {
											acc: bignumber(acc),
											value: bignumber(item.data.amount),
										}).toNumber();
									}, 0),
									true,
									2,
									true
								),
								name: t`Укупно`,
								marker: '',
								mappingData: {},
							},
						];
					},
					formatter: ({ amount, storeId }) =>
						storeId
							? {
									name: byId[storeId]?.name,
									value: numberFormat(amount, true, 2, true),
							  }
							: undefined,
				}}
			/>
		</Spin>
	);
};

export const TurnoverStatsView = observer(TurnoverStatsViewBase);
