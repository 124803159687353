import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { MenuProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';

import store from '../../stores/index.mobx';

function LanguagePickerComponent() {
	const {
		session: { locale },
	} = store;

	const items = [
		{ label: 'Српски (Ћирилица)', key: 'sr-cyrl' },
		{ label: 'Srpski (Latinica)', key: 'sr-latn' },
		{
			label: 'English',
			key: 'en',
		},
		{
			label: 'Русский',
			key: 'ru',
		},
	];

	const onClick: MenuProps['onClick'] = ({ key }) => {
		store.session.setLocale(key as any);
	};

	return (
		<Dropdown menu={{ items, onClick }} trigger={['click']}>
			<span onClick={(e) => e.preventDefault()} style={{ cursor: 'pointer' }}>
				<Space>
					{items.find((item) => item.key === (locale || 'sr-cyrl'))?.label}
					<i className="fi fi-rr-angle-small-down"></i>
				</Space>
			</span>
		</Dropdown>
	);
}

export const LanguagePicker = observer(LanguagePickerComponent);
