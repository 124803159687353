import { Trans, t } from '@lingui/macro';
import { Button, Col, Row, Space } from 'antd';
import { FormInstance } from 'antd/lib';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import printjs from 'print-js';
import { useCallback, useRef, useState } from 'react';
import { flushSync } from 'react-dom';

import styles from './EditButtons.module.less';
import { StaticComponents } from '../../../../../../components/StaticComponents';
import { ERROR_BAD_REQUEST_CANNOT_UNCONFIRM_STOCK_TURNOVER_USED } from '../../../../../../constants/errors';
import { Calculation } from '../../../../../../stores/Calculation.mobx';
import stores from '../../../../../../stores/index.mobx';
import { Invoice } from '../../../../../../stores/Invoice.mobx';
import { StockTurnoverStatus } from '../../../../../../stores/StockTurnover.mobx';
import CalculationPrint from '../CalculationPrint/CalculationPrint';
import ConnectInvoiceModal from './Components/ConnectInvoiceModal';
type Props = {
	entity: Calculation;
	save: (closeDrawer: boolean) => Promise<Calculation>;
	close: () => void;
	isCreating: boolean;
	isLoading: boolean;
	disabled: boolean;
	canEditPermission: boolean;
	form?: FormInstance;
};

function BaseEditButtons({
	entity,
	isLoading,
	isCreating,
	save,
	close,
	disabled,
	canEditPermission,
	form,
}: Props) {
	const printAreaRef = useRef<HTMLDivElement>(null);
	const {
		invoices: { fetchSingle },
	} = stores;

	const [printData, setPrintData] = useState<any>(null);
	const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
	const [invoice, setInvoice] = useState<Invoice>(null);

	const [isConnectInvoiceModalOpen, setIsConnectInvoiceModalOpen] =
		useState(false);

	const openConnectInvoiceModal = useCallback(() => {
		setIsConnectInvoiceModalOpen(true);
	}, []);

	const closeConnectInvoiceModal = useCallback(() => {
		setIsConnectInvoiceModalOpen(false);
	}, []);

	const handlePrint = useCallback(() => {
		if (entity?.invoiceId) {
			setIsLoadingInvoice(true);
			flowResult(fetchSingle(entity?.invoiceId))
				.then((res) => {
					flushSync(() => {
						setInvoice(res);
						setPrintData({
							calculation: entity,
						});
					});
					printjs({
						printable: 'printarea',
						type: 'html',
						targetStyles: ['*'],
						font_size: '',
					});
				})
				.finally(() => {
					setIsLoadingInvoice(false);
				});
		} else {
			flushSync(() => {
				setInvoice(null);
				setPrintData({
					calculation: entity,
				});
			});
			printjs({
				printable: 'printarea',
				type: 'html',
				targetStyles: ['*'],
				font_size: '',
			});
		}
	}, [entity, fetchSingle]);

	const handleConfirm = useCallback(async () => {
		form?.setFields?.([
			{
				name: 'partnerId',
				errors: [],
			},
			{
				name: 'invoiceId',
				errors: [],
			},
		]);

		const fields = form.getFieldsValue();
		if (!fields.partnerId) {
			form?.setFields?.([
				{
					name: 'partnerId',
					errors: [t`Унесите добављача`],
				},
			]);
			return;
		}

		if (!fields.invoiceId && !fields.deliveryNoteNumber) {
			form?.setFields?.([
				{
					name: 'invoiceId',
					errors: [t`Унесите фактуру или отпремницу`],
				},
				{
					name: 'deliveryNoteNumber',
					errors: [t`Унесите фактуру или отпремницу`],
				},
			]);
			return;
		}

		await save(false);
		await flowResult(entity?.confirm());
		close();
	}, [form, save, entity, close]);
	const handleUnconfirm = useCallback(async () => {
		try {
			await flowResult(entity?.unconfirm());
		} catch (error) {
			console.log(error);
			if (
				error?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_CANNOT_UNCONFIRM_STOCK_TURNOVER_USED
			) {
				StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Није могуће отказати потврђивање калкулације јер су неки артикли са залиха већ део другог промета.`,
				});
			}
		}
	}, [entity]);

	const handleSave = useCallback(async () => {
		if (
			!entity ||
			entity?.stockTurnoverItem?.status === StockTurnoverStatus.DRAFT
		) {
			try {
				await save(false);
			} catch (error) {
				//
			}
		}
	}, [entity, save]);

	// useInterval(handleSave, 60000);

	return (
		<>
			{entity?.stockTurnoverItem?.status === StockTurnoverStatus.CONFIRMED &&
				!entity.invoiceId && (
					<ConnectInvoiceModal
						open={isConnectInvoiceModalOpen}
						onCancel={closeConnectInvoiceModal}
						onOk={closeConnectInvoiceModal}
						calculation={entity}
						form={form}
					/>
				)}
			<Row>
				<Col flex="auto">
					<Space>
						<Button
							key="cancel"
							onClick={close}
							disabled={
								isLoading ||
								isCreating ||
								entity?.isUpdating ||
								entity?.isConfirming
							}
						>
							<Trans>Одустани</Trans>
						</Button>
					</Space>
				</Col>
				<Col flex="none">
					<Space>
						<Button
							disabled={
								entity?.stockTurnoverItem?.status ===
									StockTurnoverStatus.DRAFT ||
								isLoadingInvoice ||
								isLoading
							}
							loading={isLoadingInvoice}
							onClick={handlePrint}
						>
							Штампај
						</Button>
						{entity?.stockTurnoverItem?.status ===
							StockTurnoverStatus.CONFIRMED &&
							!entity.invoiceId && (
								<Button
									disabled={isLoadingInvoice || isLoading}
									loading={isLoadingInvoice}
									onClick={openConnectInvoiceModal}
								>
									<Trans>Повежи фактуру</Trans>
								</Button>
							)}
						{entity?.stockTurnoverItem?.status ===
							StockTurnoverStatus.DRAFT && (
							<Button
								disabled={
									isLoading ||
									isCreating ||
									entity?.isUpdating ||
									entity?.isConfirming
								}
								loading={entity?.isConfirming}
								onClick={handleConfirm}
							>
								<Trans>Потврди</Trans>
							</Button>
						)}
						{entity?.stockTurnoverItem?.status ===
							StockTurnoverStatus.CONFIRMED && (
							<Button
								disabled={
									isLoading ||
									isCreating ||
									entity?.isUpdating ||
									entity?.isConfirming
								}
								loading={entity?.isConfirming}
								onClick={handleUnconfirm}
							>
								<Trans>Откажи потврђивање</Trans>
							</Button>
						)}
						<Button
							key="save"
							type="primary"
							loading={isCreating || entity?.isUpdating}
							onClick={handleSave}
							disabled={
								isLoading ||
								disabled ||
								isCreating ||
								entity?.isUpdating ||
								!canEditPermission
							}
						>
							<Trans>Сачувај нацрт</Trans>
						</Button>
					</Space>
				</Col>
			</Row>

			<div className={styles.printArea}>
				<div ref={printAreaRef} id="printarea">
					{printData && <CalculationPrint {...printData} invoice={invoice} />}
				</div>
			</div>
		</>
	);
}

export const EditButtons = observer(BaseEditButtons);
