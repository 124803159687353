import round from 'lodash/round';

import { locales } from './i18n';
import { InvoiceType, TAX_FREE_LABEL } from '../constants/invoice';
import { ADVANCE_TYPE } from '../constants/journal';
import stores from '../stores/index.mobx';
import { bignumber, evaluate } from 'mathjs';

export enum PaymentModalMode {
	normal = 'normal',
	addAdvancePayment = 'addAdvancePayment',
	closeAdvance = 'closeAdvance',
	finalizeProforma = 'finalizeProforma',
	refund = 'refund',
	void = 'void',
	copy = 'copy',
}

export function getTotalsByLabelNormal(sale, discount = 0) {
	return sale.itemsAsArray.reduce((prev, curr) => {
		const label = sale.vatExempt ? TAX_FREE_LABEL : curr.product.taxRateLabel;
		prev[label] = round(
			evaluate(
				'prev + (finalPrice - (finalPrice * discount) / 100) * quantity',
				{
					prev: bignumber(prev[label] || 0),
					finalPrice: bignumber(curr.finalPrice),
					discount: bignumber(discount || 0),
					quantity: bignumber(curr.quantity),
				}
			).toNumber(),
			2
		);
		return prev;
	}, {});
}

export function getAdvancePaymentsByLabelKnown(
	mode,
	discountedAmount,
	values,
	receipt,
	paymentsSum,
	changeAmount
) {
	const {
		stores: { currentStore: store },
	} = stores;

	let advanceItemsSum;

	if (mode === PaymentModalMode.normal) {
		advanceItemsSum = discountedAmount;
	} else {
		advanceItemsSum =
			values.invoiceType === InvoiceType.ADVANCE
				? receipt?.advanceItems?.reduce((prev, curr) => {
						return round(
							evaluate('prev + unitPrice * quantity', {
								prev: bignumber(prev),
								unitPrice: bignumber(curr.unitPrice),
								quantity: bignumber(curr.quantity),
							}).toNumber(),
							2
						);
				  }, 0)
				: 0;
	}

	const totalsByLabel = getTotalsByLabelNormal(receipt, values.discount);

	return Object.entries(totalsByLabel).map(([label, total]) => {
		return {
			finalPrice: round(
				evaluate('(total * (paymentsSum - changeAmount)) / advanceItemsSum', {
					total: bignumber(Number(total)),
					paymentsSum: bignumber(paymentsSum),
					changeAmount: bignumber(changeAmount),
					advanceItemsSum: bignumber(
						values.invoiceType === InvoiceType.ADVANCE
							? advanceItemsSum
							: discountedAmount
					),
				}).toNumber(),
				2
			),
			taxRateLabel: label,
			quantity: 1,
			product: {
				id: null,
				ean: null,
				name: `${ADVANCE_TYPE[label]}: ${
					locales[store?.language || 'sr-Cyrl-RS'].advance
				}`,
				sku: null,
			},
			isAdvance: true,
		};
	});
}

export function hex2a(hexx) {
	var hex = hexx.toString(); //force conversion
	var str = '';
	for (var i = 0; i < hex.length; i += 2)
		str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
	return str;
}
