import { t, Trans } from '@lingui/macro';
import { Modal, Result, Button } from 'antd';

import styles from './POS.module.less';

interface Props {
	visible: boolean;
	onCancel: () => void;
	onOk: () => void;
	text: string;
}

export default function ProductNotFound({
	visible,
	onCancel,
	onOk,
	text,
}: Props) {
	return (
		<Modal
			centered
			width={600}
			open={visible}
			onOk={onOk}
			onCancel={onCancel}
			title={null}
			footer={null}
		>
			<Result
				title={t`Артикал није пронађен`}
				subTitle={text}
				icon={
					<img
						className={styles.icon}
						src="/images/icons/new/decline.svg"
						alt={t`Није пронађено`}
					/>
				}
				extra={
					<Button type="primary" onClick={onOk}>
						<Trans>У реду</Trans>
					</Button>
				}
			/>
		</Modal>
	);
}
