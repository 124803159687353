import { t } from '@lingui/macro';
import sortBy from 'lodash/sortBy';
import { action, computed, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import stores from './index.mobx';
import { ReferenceTransformer } from './transformers/Reference';
import { Warehouse } from './Warehouse.mobx';
import { StaticComponents } from '../components/StaticComponents';
import { v2Client, defaultClient } from '../store/client';
interface PrintMethod {
	thermal: boolean;
	a4: boolean;
	email: boolean;
}

let errorModal;

const { Store: BaseStore, Entity } = CreateStore({
	name: 'store',
	paginated: false,
	persistFields: ['all', 'currentStoreId'],
});

class Store extends Entity {
	@observable name?: string;
	@observable address?: string;
	@observable city?: string;
	@observable zip?: string;
	@observable phone?: string;
	@observable language: 'sr-Cyrl-RS' | 'sr-Latn-RS' | 'en-US' = 'sr-Cyrl-RS';
	@observable printMethod?: PrintMethod = {
		thermal: true,
		a4: true,
		email: true,
	};
	@observable paymentMethodsOrder?: number[] = [1, 3, 2, -1, 4, 5, 6, 0];
	@observable productPickerType?: 'list' | 'grid' = 'list';
	@observable printBankAccounts?: boolean = false;
	@observable printDiscounts?: boolean = true;
	@observable isSoundEnabled?: boolean = true;
	@observable gln?: string;
	@observable defaultUnknownAmountAdvance?: boolean = false;

	@observable warehouseId?: string;

	@ReferenceTransformer('warehouse', 'warehouseId') warehouse?: Warehouse;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isDeletable() {
		return this.getParent().available.length > 1;
	}

	@computed
	get fullAddress() {
		const address = `${this.address || ''}, ${this.zip || ''} ${
			this.city || ''
		}`
			.replace(/  /g, ' ')
			.replace(/\n/g, ', ');

		return address === ', ' ? '' : address;
	}

	@computed
	get displayName() {
		if (this.name === 'Sedište') {
			return t`Седиште`;
		}
		return this.name;
	}
}

class Stores extends BaseStore<Store> {
	@observable currentStoreId?: string;

	@ReferenceTransformer('store', 'currentStoreId')
	currentStore?: Store;

	constructor() {
		super(Store);
	}

	get isCreatable() {
		return stores.company.hasFeature('store_management');
	}

	replaceAll(data: any[]): void {
		super.replaceAll(data);
		if (typeof this.currentStoreId !== 'undefined') {
			this.setCurrentStore(this.currentStoreId);
		} else {
			const sorted = sortBy(data, 'createdAt');
			if (sorted.length) {
				this.setCurrentStore(sorted[0].id);
			}
		}
	}

	@action.bound
	setCurrentStore(storeId: string) {
		this.currentStoreId = storeId;
		defaultClient.defaults.headers.common['store-id'] = storeId;
		v2Client.defaults.headers.common['store-id'] = storeId;
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await this.fetchAll();
			if (
				this.currentStoreId &&
				!this.available.map((s) => s.id).includes(this.currentStoreId)
			) {
				this.setCurrentStore(null);

				StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Одабрано продајно место више не постоји, или више немате дозволу да му приступите.`,
				});
			}
			if (this.available.length === 0) {
				errorModal = StaticComponents.modal.error({
					title: t`Грешка`,
					content: t`Немате дозволу да приступите ни једном продајном месту. Обратите се администратору како би вам доделио дозволе за приступ продајним местима.`,
					closable: false,
					footer: null,
				});
			} else {
				if (errorModal) {
					errorModal.destroy();
					errorModal = null;
				}
			}
		}
	}
}

export { Stores, Store };
