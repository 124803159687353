import { Button, Input } from 'antd';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';

interface Props {
	onChange?: (value: string) => void;
}

export default function SecretInput({ onChange, ...props }: Props) {
	const [readOnly, setReadOnly] = useState(true);
	return (
		<Input
			{...props}
			onChange={(e) => onChange(e.target.value)}
			placeholder="*********************"
			readOnly={readOnly}
			addonAfter={
				<Button
					size="small"
					type="link"
					icon={<i className="fi fi-rr-rotate-right"></i>}
					onClick={() => {
						setReadOnly(false);
						onChange(uuid());
						setReadOnly(true);
					}}
				/>
			}
		/>
	);
}
