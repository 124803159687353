import { t, Trans } from '@lingui/macro';
import { Button, Form, Input, Modal, Result } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';

import styles from './SDCStatusModal.module.less';
import { getStatusCodes, getStatusPerMRC } from '../../constants/esdc';
import stores from '../../stores/index.mobx';

function SDCStatusModal() {
	const STATUS_CODES = getStatusCodes();
	const STATUS_PER_MRC = getStatusPerMRC();

	const {
		sdc: {
			esdcStatus,
			currentESDCError,
			resolveDeferred,
			rejectDeferred,
			pinVerify,
			setESDCError,
		},
	} = stores;

	const [pin, setPin] = useState('');

	const [statusText, statusIcon] = useMemo(() => {
		if (!currentESDCError) {
			return [];
		}

		const status = STATUS_CODES[`${currentESDCError}`];
		if (!status) {
			const MRC = esdcStatus?.deviceSerialNumber?.substring?.(0, 7);
			if (!MRC) {
				return [t`Непозната грешка (${currentESDCError})`, 'question-mark'];
			}
			const mscStatus = STATUS_PER_MRC[MRC][`${currentESDCError}`];
			if (!mscStatus) {
				return [t`Непозната грешка (${currentESDCError})`, 'question-mark'];
			}
			return mscStatus;
		}
		return status;
	}, [currentESDCError]);

	useEffect(() => {
		setPin('');
	}, [currentESDCError]);

	return (
		<Modal
			centered
			open={!!currentESDCError}
			width={600}
			footer={null}
			// zIndex={1501}
			closable={false}
			destroyOnClose={true}
			title={
				['1500', '2100'].includes(currentESDCError)
					? null
					: t`Грешка локалног процесора фискалних рачуна`
			}
		>
			{!!currentESDCError && (
				<Result
					className={styles.result}
					icon={<img src={`/images/icons/new/esdc/${statusIcon}.svg`} />}
					title={statusText}
					subTitle={
						['1500', '2100'].includes(currentESDCError) ? (
							<>
								<Form.Item>
									<Input
										className={styles.pin}
										type="password"
										maxLength={4}
										inputMode="numeric"
										autoComplete="false"
										ref={(input) => {
											input && setTimeout(() => input.focus());
										}}
										value={pin}
										onChange={(e) => setPin(e.target.value)}
										onPressEnter={() => pinVerify(pin)}
									/>
								</Form.Item>
								<Form.Item>
									<Trans>
										<strong>Пажња:</strong> Уносом погрешног ПИН кода 5 пута
										узастопно, блокираћете безбедносни елемент и он више неће
										моћи да се користи!
									</Trans>
								</Form.Item>
							</>
						) : null
					}
					extra={[
						<Button
							key="cancel"
							onClick={() => {
								rejectDeferred('user canceled');
								setESDCError(null);
							}}
						>
							<Trans>Одустани</Trans>
						</Button>,
						!['1500', '2100'].includes(currentESDCError) ? (
							<Button
								key="retry"
								type="primary"
								onClick={() => resolveDeferred()}
							>
								<Trans>Покушај поново</Trans>
							</Button>
						) : (
							<Button key="ok" type="primary" onClick={() => pinVerify(pin)}>
								<Trans>Пошаљи</Trans>
							</Button>
						),
					]}
				/>
			)}
		</Modal>
	);
}

export default observer(SDCStatusModal);
