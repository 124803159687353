import { Trans } from '@lingui/macro';
import { Button, Input } from 'antd';

type Props = {
	setSelectedKeys: (keys: string[]) => void;
	selectedKeys: string[];
	confirm: (options?: { closeDropdown?: boolean }) => void;
	clearFilters: () => void;
	placeholder?: string;
};

export function TextFilter({
	setSelectedKeys,
	selectedKeys,
	confirm,
	clearFilters,
	placeholder = '',
}: Props) {
	return (
		<div>
			<div style={{ padding: '8px' }}>
				<Input
					ref={(node) => {
						setTimeout(() => node?.focus());
					}}
					placeholder={placeholder}
					value={selectedKeys[0]}
					onPressEnter={() => confirm({ closeDropdown: true })}
					onChange={(event) =>
						setSelectedKeys([event.target.value || undefined])
					}
				/>
			</div>
			<div className="ant-table-filter-dropdown-btns">
				<Button
					onClick={() => clearFilters()}
					size="small"
					type="link"
					disabled={!selectedKeys[0]}
				>
					<Trans>Поништи</Trans>
				</Button>
				<Button
					type="primary"
					onClick={() => confirm({ closeDropdown: true })}
					size="small"
				>
					<Trans>У реду</Trans>
				</Button>
			</div>
		</div>
	);
}
