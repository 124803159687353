import { Dayjs } from 'dayjs';
import { computed, flow, observable } from 'mobx';

import { Calculation } from './Calculation.mobx';
import { CreateStore } from './Crud.mobx';
import { InitialStock } from './InitialStock.mobx';
import { Partner } from './Partner.mobx';
import { Pricechange } from './Pricechange.mobx';
import { Receipt } from './Receipt.mobx';
import { DayjsTransformer } from './transformers/Dayjs';
import { ReferenceTransformer } from './transformers/Reference';

export enum StockTurnoverStatus {
	DRAFT = 'draft',
	CONFIRMED = 'confirmed',
}

export enum StockTurnoverType {
	INITIAL = 'initial',
	CALCULATION = 'calculation',
	PRICE_CHANGE = 'price_change',
	RECEIPT = 'receipt',
	INVOICE = 'invoice',
	INVENTORY = 'inventory',
}

const { Store, Entity } = CreateStore({
	name: 'stockTurnover',
	paginated: true,
	order: 'desc',
});

class StockTurnover extends Entity {
	@observable name?: string;
	@observable status?: StockTurnoverStatus;
	@observable type?: StockTurnoverType;
	@observable purchaseAmount?: number;
	@observable saleAmount?: number;
	@observable initialStock?: InitialStock;
	@observable calculation?: Calculation;
	@observable receipt?: Receipt;
	@observable pricechange?: Pricechange;

	@observable receiptId?: string;

	@ReferenceTransformer('partner', 'partnerId')
	partner?: Partner;

	@DayjsTransformer
	date?: Dayjs;

	replace(data: any): void {
		super.replace(data);
		if (data.initialStock) {
			this.initialStock = new InitialStock(data.initialStock, this);
		}

		if (data.calculation) {
			this.calculation = new Calculation(data.calculation, this);
		}

		if (data.receipt) {
			this.receipt = new Receipt(data.receipt, this);
		}

		if (data.pricechange) {
			this.pricechange = new Pricechange(data.pricechange, this);
		}
	}

	@computed
	get number() {
		if (this.type === StockTurnoverType.INITIAL) {
			return this.initialStock?.number;
		}

		if (this.type === StockTurnoverType.CALCULATION) {
			return this.calculation?.number;
		}

		if (this.type === StockTurnoverType.RECEIPT) {
			return this.receipt?.invoiceNumber;
		}

		if (this.type === StockTurnoverType.PRICE_CHANGE) {
			return this.pricechange?.number;
		}
	}

	get isEditable() {
		return false;
	}

	get isDeletable() {
		return false;
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class StockTurnovers extends Store<StockTurnover> {
	constructor() {
		super(StockTurnover);
	}

	get isCreatable() {
		return true;
	}
}

export { StockTurnovers, StockTurnover };
