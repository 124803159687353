import { t, Trans } from '@lingui/macro';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { flowResult } from 'mobx';
import { useCallback } from 'react';

import { useDrawer } from '../../../../../../components/Page';
import { usePermissions } from '../../../../../../hooks/permissions';
import stores from '../../../../../../stores/index.mobx';

export default function CalculationCreateButton() {
	const {
		calculations: { create, isCreating },
	} = stores;

	const [, openEditCalculation] = useDrawer('edit/stocks/calculation');

	const createButtonHandler = useCallback(async () => {
		const response = await flowResult(
			create({
				date: dayjs(),
				items: [],
			})
		);

		openEditCalculation(response.id);
	}, [create, openEditCalculation]);

	const canCreateCalculationPermission = usePermissions(
		'stocks',
		'calculation',
		'create'
	);

	if (!canCreateCalculationPermission) {
		return null;
	}

	return (
		<Button
			type="primary"
			onClick={createButtonHandler}
			disabled={isCreating}
			loading={isCreating}
		>
			<Trans>Додај калкулацију</Trans>
		</Button>
	);
}
