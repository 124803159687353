import { Trans } from '@lingui/macro';
import { Button, Form, InputNumber, Space } from 'antd';
import clamp from 'lodash/clamp';
import round from 'lodash/round';
import { MutableRefObject } from 'react';

import { PaymentType } from '../../../constants/invoice';
import {
	PAYMENT_TYPE_MAP,
	getPaymentTypeTextShort,
} from '../../../constants/journal';
import { PaymentModalMode } from '../../../lib/receipt';
import {
	PaymentModalValues,
	PaymentModalInitialPayments,
} from '../PaymentModal';
import styles from '../PaymentModal.module.less';

type Props = {
	method: PaymentType;
	showHidden: boolean;
	mode: PaymentModalMode;
	isInputFocused: MutableRefObject<boolean>;
	values: PaymentModalValues;
	unknownAmountAdvance: boolean;
	index: number;
	paymentsSum: number;
	discountedAmount: number;
	advanceAmount: number;
	remainingAmount: number;
	totalAmount: number;
	isOldAdvance: boolean;
	setValues: (values: PaymentModalValues) => void;
};

export function PaymentInput({
	method,
	showHidden,
	mode,
	isInputFocused,
	values,
	unknownAmountAdvance,
	index,
	paymentsSum,
	discountedAmount,
	advanceAmount,
	isOldAdvance,
	setValues,
	remainingAmount,
	totalAmount,
}: Props) {
	const form = Form.useFormInstance();
	const PAYMENT_TYPE_TEXT_SHORT = getPaymentTypeTextShort();
	return (
		<Form.Item
			label={PAYMENT_TYPE_TEXT_SHORT[method]}
			key={PAYMENT_TYPE_MAP[method]}
			className={!showHidden ? styles.hiddenPaymentMethod : ''}
		>
			<Space.Compact style={{ width: '100%' }}>
				<Form.Item name={['payments', PAYMENT_TYPE_MAP[method]]} noStyle>
					<InputNumber
						onClick={(e) => e.currentTarget.select()}
						onWheel={(e) => {
							const element = e.currentTarget;
							const hasFocus = element.matches(':focus');
							element.blur();
							setTimeout(() => {
								if (hasFocus) {
									element.focus();
								}
							}, 0);
						}}
						className={styles.paymentMethodInput}
						type="number"
						min={0}
						controls={false}
						disabled={[PaymentModalMode.copy, PaymentModalMode.void].includes(
							mode
						)}
						ref={(input) => {
							if (!isInputFocused.current) {
								setTimeout(() => {
									if (!input) {
										return;
									}
									if (
										values?.payments?.[PAYMENT_TYPE_MAP[method]] > 0 ||
										(index === 0 && paymentsSum === 0)
									) {
										input.select();
										isInputFocused.current = true;
									}
								});
							}
						}}
						onPressEnter={() => {
							form.submit();
						}}
					/>
				</Form.Item>
				<Button
					disabled={
						[PaymentModalMode.copy, PaymentModalMode.void].includes(mode) ||
						unknownAmountAdvance
					}
					tabIndex={-1}
					onClick={() => {
						const currentValue = values.payments[PAYMENT_TYPE_MAP[method]];
						if (paymentsSum === remainingAmount) {
							return;
						}
						const newValue = clamp(
							round(remainingAmount - paymentsSum + currentValue, 2),
							0,
							Infinity
						);

						form.setFieldValue(
							['payments', PAYMENT_TYPE_MAP[method]],
							newValue
						);
						setValues({
							...values,
							payments: {
								...values.payments,
								[PAYMENT_TYPE_MAP[method]]: newValue,
							},
						});
					}}
				>
					<Trans>Остатак</Trans>
				</Button>
				<Button
					disabled={
						[PaymentModalMode.copy, PaymentModalMode.void].includes(mode) ||
						unknownAmountAdvance
					}
					tabIndex={-1}
					onClick={() => {
						const newValue = round(remainingAmount, 2);

						form.setFieldValue('payments', {
							...PaymentModalInitialPayments,
							[PAYMENT_TYPE_MAP[method]]: newValue,
						});
						setValues({
							...values,
							payments: {
								...PaymentModalInitialPayments,
								[PAYMENT_TYPE_MAP[method]]: newValue,
							},
						});
					}}
				>
					<Trans>Све</Trans>
				</Button>
			</Space.Compact>
		</Form.Item>
	);
}
