import { t, Trans } from '@lingui/macro';
import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { getMarketplaceApplicationScope } from '../../../../../../constants/marketplace';
import { MarketplaceApplication } from '../../../../../../stores/MarketplaceApplication.mobx';

interface Props {
	application: MarketplaceApplication;
	visible?: boolean;
	close: () => void;
	openSuccessModal: () => void;
}

const InstallConfirmation = ({
	application,
	visible,
	close,
	openSuccessModal,
}: Props) => {
	const MARKETPLACE_APPLICATION_SCOPE = getMarketplaceApplicationScope();
	return (
		<Modal
			open={visible}
			title={t`Инсталирање апликације ${application?.name}`}
			okText={t`Инсталирај`}
			cancelText={t`Одустани`}
			onOk={() => {
				application?.install();
				close();
				openSuccessModal();
			}}
			onCancel={close}
		>
			<p>
				<Trans>
					Да ли сте сигурни да желите да инсталирате апликацију{' '}
					{application?.name}?<br />
					Апликација ће имати приступ следећим подацима:
				</Trans>
				<br />
				<br />
				<ul>
					{application?.scopes?.map((scope) => (
						<li>{MARKETPLACE_APPLICATION_SCOPE[scope]}</li>
					))}
				</ul>
			</p>
		</Modal>
	);
};

export default observer(InstallConfirmation);
