import { t, Trans } from '@lingui/macro';
import {
	TreeSelect,
	Button,
	ConfigProvider,
	Empty,
	Form,
	notification,
	Spin,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { StaticComponents } from '../../../../../../../../../components/StaticComponents';
import { v2Client } from '../../../../../../../../../store/client';
import stores from '../../../../../../../../../stores/index.mobx';
import { MarketplaceApplication } from '../../../../../../../../../stores/MarketplaceApplication.mobx';

interface Props extends Omit<React.ComponentProps<typeof TreeSelect>, 'value'> {
	treeCheckable?: boolean;
	value?: string | void;
	showCreateButton?: boolean;
}

function convertData(data: any[]) {
	return data.map((item: any) => {
		return {
			...item,
			children: convertData(item.children || []),
			disabled: item.children?.length > 0,
		};
	});
}

function CategorySelect({ onChange, value, ...rest }: Props) {
	const { saleChannels } = stores;

	const parentForm = Form.useFormInstance();
	const storeId = Form.useWatch('storeId', parentForm);

	const [isFetching, setIsFetching] = useState(false);
	const [installedApplication, setInstalledApplication] =
		useState<MarketplaceApplication | null>(null);

	const [categories, setCategories] = useState<any[]>([]);
	// const [productTypes, setProductTypes] = useState<any[]>([]);

	useEffect(() => {
		const id =
			saleChannels.byId[parentForm.getFieldValue('saleChannelId')]
				?.applicationId;

		if (storeId && id) {
			setIsFetching(true);
			Promise.all([
				v2Client.get(`/marketplace-applications/installed/${id}`, {
					headers: {
						'store-id': storeId,
					},
				}),

				v2Client.get(
					`/marketplace-applications/${id}/functions/get-categories`,
					{
						headers: {
							'store-id': storeId,
						},
					}
				),
				// v2Client.get(
				// 	`/marketplace-applications/${id}/functions/get-product-types`,
				// 	{
				// 		headers: {
				// 			'store-id': storeId,
				// 		},
				// 	}
				// ),
			])
				.then(([applicationResponse, categoriesResponse]) => {
					setInstalledApplication(applicationResponse.data);
					setCategories(convertData(categoriesResponse.data));
					// setProductTypes(productTypesResponse.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setIsFetching(false);
				});
		}
	}, [storeId, parentForm, saleChannels.byId]);

	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/new/folder.svg" />}
						imageStyle={{ height: 64 }}
						description={t`Нема категорија`}
					></Empty>
				)}
			>
				<TreeSelect
					showSearch
					loading={isFetching}
					treeLine
					fieldNames={{
						label: 'name',
						value: 'id',
						children: 'children',
					}}
					popupMatchSelectWidth={false}
					treeData={categories}
					dropdownRender={(menu) => <Spin spinning={isFetching}>{menu}</Spin>}
					onChange={onChange}
					virtual={false}
					value={value}
					{...rest}
				/>
			</ConfigProvider>
		</>
	);
}

export default observer(CategorySelect);
