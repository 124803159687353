import { t, Trans } from '@lingui/macro';
import { Button, Card, Col, Modal, Result, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { useDrawer } from '../../../../components/Page';
import stores from '../../../../stores/index.mobx';
import styles from '../POS.module.less';
function SelectStore() {
	const {
		stores: { available, setCurrentStore },
	} = stores;

	const [, openCreate] = useDrawer('create');

	return (
		<Modal
			centered
			open={true && available.length > 0}
			title={available.length > 0 ? t`Одаберите продајно место` : null}
			footer={null}
			width={700}
			closable={false}
		>
			{available.length > 0 && (
				<Row gutter={[8, 8]}>
					{available.map((store) => (
						<Col span={8}>
							<Card
								style={{ height: '100%' }}
								type="inner"
								title={store.displayName}
								hoverable
								onClick={() => {
									setCurrentStore(store.id);
								}}
							>
								{store.address}
							</Card>
						</Col>
					))}
				</Row>
			)}
		</Modal>
	);
}

export default observer(SelectStore);
