import { Trans, t } from '@lingui/macro';
import { Progress } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import round from 'lodash/round';
import { action, computed, flow, observable } from 'mobx';
import qs from 'qs';

import { Calculation } from './Calculation.mobx';
import { CreateStore } from './Crud.mobx';
import { Currency } from './Currency.mobx';
import stores from './index.mobx';
import { Partner } from './Partner.mobx';
import type { File } from './shared/File.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { DayjsTransformer } from './transformers/Dayjs';
import { ReferenceTransformer } from './transformers/Reference';
import { Unit } from './Unit.mobx';
import { StaticComponents } from '../components/StaticComponents';
import { INVOICE_STATUS } from '../constants/documentInvoice';
import type { DocumentType } from '../constants/documentInvoice';
import { GlobalHistory } from '../lib/GlobalNavigator';
import numberFormat from '../lib/numberFormat';

const { Store, Entity } = CreateStore({
	name: 'invoice',
	paginated: true,
	clientVersion: 'v2',
	persistFields: [],
});

export type InvoiceReference = {
	model?: string;
	number?: string;
};

export class CreditDebitNoteReference extends Entity {
	inSystem: boolean;
	invoiceNumber: string;
	invoiceId?: string;

	@DayjsTransformer
	issueDate?: string;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

export class InvoiceCassaSconto extends Entity {
	@DayjsTransformer
	dueDate?: Dayjs;
	amount?: number;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

export type TaxBreakdown = {
	currencyId: string;
	taxableAmount: number;
	taxAmount: number;
	taxRate: number;
	taxCategory: string;
	taxExemptionReason?: string;
	taxExemptionCode?: string;
};

export type AdvancePayment = {
	taxableAmount: number;
	taxAmount: number;
	taxRate: number;
	taxCategory: string;
	invoiceNumber: string;
};

export class AdvanceInvoice extends Entity {
	@observable inSystem: boolean;
	@observable advanceInvoiceNumber?: string;
	@observable advanceInvoiceId?: string;
	@observable amountS10: number;
	@observable amountS20: number;
	@observable vatS10: number;
	@observable vatS20: number;
	@observable amountAE: number;
	@observable amountO: number;
	@observable amountE: number;
	@observable amountR: number;
	@observable amountZ: number;
	@observable amountSS: number;
	@observable amountOE: number;
	@observable amountN: number;
	@observable amountTotal: number;

	@DayjsTransformer
	date?: Dayjs;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

export type ReducedTotal = {
	taxableAmount: number;
	taxAmount: number;
	taxRate: number;
	taxCategory: string;
};

export type Allowance = {
	amount?: number;
	percentage?: number;
	taxCategoryCode: string;
	currencyId?: string;
	reason?: string;
	indicator?: 'charge' | 'discount';
};

export type TaxExemption = {
	code: string;
	reason?: string;
	category?: string;
};

export class InvoiceItem extends Entity {
	@observable externalId?: string;
	@observable name?: string;
	@observable description?: string;
	@observable quantity?: number;
	@observable unitPrice?: number;
	@observable totalAmount?: number;
	@observable taxPercentage?: number;
	@observable taxAmount?: number;
	@observable discountPercentage?: number;
	@observable discountAmount?: number;
	@observable taxCode?: string;
	@observable currencyId?: string;
	@observable unitId?: string;
	@observable type?: 'product' | 'service' | 'digital';

	@observable allowances?: Allowance[];
	@observable sellerItemIdentification?: string;

	@ReferenceTransformer('currency', 'currencyId')
	currency?: Currency;

	@ReferenceTransformer('unit', 'unitId')
	unit?: Unit;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Invoice extends Entity {
	@observable direction?: 'incoming' | 'outgoing';
	@observable externalId?: string;
	@observable documentType?: DocumentType;
	@observable number?: string;
	@observable status?: string;
	@observable amount?: number;
	@observable payableAmount?: number;
	@observable taxAmount?: number;
	@observable amountWithCassaSconto?: number;
	@observable paidAmount?: number;
	@observable paid?: boolean;
	@observable partiallyPaid?: boolean;

	@observable reference?: InvoiceReference;

	@ArrayTypeTransformer(InvoiceCassaSconto)
	@observable
	cassaSconto?: InvoiceCassaSconto[];

	@ArrayTypeTransformer(AdvanceInvoice)
	@observable
	advanceInvoices?: AdvanceInvoice[];

	@ArrayTypeTransformer(InvoiceItem)
	@observable
	invoiceItems?: InvoiceItem[];

	@observable type?: string[];

	@observable partnerId?: string;

	@observable currencyId?: string;
	@observable taxCurrencyId?: string;

	@observable allowances?: Allowance[];
	@observable taxBreakdown?: TaxBreakdown[];
	@observable advancePayments?: AdvancePayment[];

	@observable reducedTotals?: ReducedTotal[];

	@observable contractNumber?: string;
	@observable lotNumber?: string;
	@observable orderNumber?: string;

	@observable paymentMeans?: string[];

	@observable note?: string;

	@observable taxExemption?: TaxExemption[];

	@observable creditDebitNoteType?: string;
	@observable creditDebitNotePeriod?: [string, string];

	@ArrayTypeTransformer(CreditDebitNoteReference)
	@observable
	creditDebitNoteReferences?: CreditDebitNoteReference[];

	@DayjsTransformer date?: Dayjs;
	@DayjsTransformer dueDate?: Dayjs;
	@DayjsTransformer issueDate?: Dayjs;
	@DayjsTransformer dueDateWithCassaSconto?: Dayjs;
	@DayjsTransformer canceledAt?: Dayjs;

	@observable cancelNumber?: string;
	@observable cancelReason?: string;

	@ReferenceTransformer('partner', 'partnerId')
	partner?: Partner;

	@observable isSending?: boolean;

	file?: File;
	xmlFile?: File;

	attachments?: File[];

	calculations?: Calculation[];

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isDeletable(): boolean {
		return (
			(!this.externalId && this.direction === 'incoming') ||
			(!this.externalId &&
				this.direction === 'outgoing' &&
				this.status === 'draft')
		);
	}

	get isEditable(): boolean {
		return (
			(!this.externalId && this.direction === 'incoming') ||
			(!this.externalId &&
				this.direction === 'outgoing' &&
				(this.status === 'draft' || this.status === 'mistake'))
		);
	}

	@computed get remainingAmount() {
		return (this.amountWithCassaSconto || 0) - (this.paidAmount || 0);
	}

	@flow.bound
	*accept(data: any) {
		this.isUpdating = true;
		try {
			yield this.getClient().patch(`invoices/${this.id}/accept`, data);
			this.status = INVOICE_STATUS.APPROVED;
		} catch {
		} finally {
			this.isUpdating = false;
		}
	}

	@flow.bound
	*reject(data: any) {
		this.isUpdating = true;
		try {
			yield this.getClient().patch(`invoices/${this.id}/reject`, data);
			this.status = INVOICE_STATUS.REJECTED;
		} catch {
		} finally {
			this.isUpdating = false;
		}
	}
	@flow.bound
	*cancel(data: any) {
		this.isUpdating = true;
		try {
			yield this.getClient().patch(`invoices/${this.id}/cancel`, data);
			this.status = INVOICE_STATUS.STORNO;
		} catch {
		} finally {
			this.isUpdating = false;
		}
	}

	@flow.bound
	*pay(amount: number, date?: Dayjs) {
		this.isUpdating = true;
		try {
			const { data } = yield this.getClient().patch(`invoices/${this.id}/pay`, {
				amount,
				date: date?.toISOString?.(),
			});
			const parent = this.getParent();

			parent?.setLastUpdated?.(data.updatedAt);
			parent?.triggerUpdate?.();
			this.replace(data);
			this.isUpdating = false;
		} catch {
			this.isUpdating = false;
		}
	}

	@flow.bound
	*send(email?, storeEmail?) {
		this.isSending = true;
		try {
			yield this.getClient().post(`invoices/${this.id}/send`, {
				email,
				storeEmail,
			});
		} catch (e) {
			throw e;
		} finally {
			this.isSending = false;
		}
	}
}

class Invoices extends Store<Invoice> {
	@observable isImporting = false;
	@observable isSearching = false;

	constructor() {
		super(Invoice);
	}

	@action.bound
	setImporting(isImporting: boolean) {
		this.isImporting = isImporting;
	}

	receiveFromSocket(data: any[]): void {
		data.forEach((item) => {
			function openInvoice() {
				StaticComponents.notification.destroy(`invoice-${item.id}`);
				GlobalHistory.navigate(`/app/documents/invoices`);
				setTimeout(() => {
					GlobalHistory.navigate(
						`/app/documents/invoices/incoming?view%2Fdocuments%2Fincoming-invoices=${item.id}`
					);
				});
			}
			if (item.import) {
				if (item.import.imported === item.import.total) {
					StaticComponents.notification.success({
						key: 'eFakturaSync',
						message: t`Увоз е-фактура`,
						description: t`Увоз е-фактура је завршен`,
						duration: 5,
					});
					this.setImporting(false);
				} else {
					StaticComponents.notification.info({
						placement: 'bottomRight',
						key: 'eFakturaSync',
						message: t`Увоз е-фактура`,
						description: (
							<span>
								<Trans>Увоз постојећих е-фактура је у току</Trans>
								<Progress
									percent={round(
										(item.import.imported / item.import.total) * 100,
										0
									)}
									status="active"
								/>
							</span>
						),
						duration: 0,
					});
				}
			} else if (item.id && item.status === 'new') {
				if (item.documentType === 'invoice') {
					StaticComponents.notification.info({
						placement: 'bottomRight',
						key: `invoice-${item.id}`,
						onClick: openInvoice,
						message: t`Пристигла је нова фактура`,
						description: (
							<Trans>
								Пристигла је фактура <strong>{item.number}</strong> од{' '}
								<strong>{stores.partners.byId[item.partnerId].name}</strong>,
								износ{' '}
								<strong>
									{numberFormat(
										item.payableAmount,
										true,
										2,
										true,
										item.currencyId
									)}
								</strong>
								, са валутом плаћања{' '}
								<strong>{dayjs(item.dueDate).format('LL')}</strong>
							</Trans>
						),
						duration: 0,
					});
				}
				if (item.documentType === 'advanceInvoice') {
					StaticComponents.notification.info({
						placement: 'bottomRight',
						key: `invoice-${item.id}`,
						onClick: openInvoice,
						message: t`Пристигао је нови авансни рачун`,
						description: (
							<Trans>
								Пристигао је авансни рачун <strong>{item.number}</strong> од{' '}
								<strong>{stores.partners.byId[item.partnerId].name}</strong>,
								износ{' '}
								<strong>
									{numberFormat(
										item.payableAmount,
										true,
										2,
										true,
										item.currencyId
									)}
								</strong>
							</Trans>
						),
						duration: 0,
					});
				}
				if (item.documentType === 'creditNote') {
					StaticComponents.notification.info({
						placement: 'bottomRight',
						key: `invoice-${item.id}`,
						onClick: openInvoice,
						message: t`Пристигло је ново књижно одобрење`,
						description: (
							<Trans>
								Пристигло је књижно одобрење <strong>{item.number}</strong> од{' '}
								<strong>{stores.partners.byId[item.partnerId].name}</strong>,
								износ{' '}
								<strong>
									{numberFormat(
										item.payableAmount,
										true,
										2,
										true,
										item.currencyId
									)}
								</strong>
							</Trans>
						),
						duration: 0,
					});
				}
				if (item.documentType === 'debitNote') {
					StaticComponents.notification.info({
						placement: 'bottomRight',
						key: `invoice-${item.id}`,
						onClick: openInvoice,
						message: t`Пристигло је ново књижно задужење`,
						description: (
							<Trans>
								Пристигло је књижно одобрење <strong>{item.number}</strong> од{' '}
								<strong>{stores.partners.byId[item.partnerId].name}</strong>,
								износ{' '}
								<strong>
									{numberFormat(
										item.payableAmount,
										true,
										2,
										true,
										item.currencyId
									)}
								</strong>
							</Trans>
						),
						duration: 0,
					});
				}
			}
		});

		super.receiveFromSocket(data.filter((item) => !!item.id));
	}

	@flow.bound
	*searchInvoices(
		query: string,
		direction: 'incoming' | 'outgoing',
		partnerId?: string,
		types?: string[]
	) {
		this.isSearching = true;
		const queryString = qs.stringify({
			number: query,
			documentType: types,
			direction,
			partnerId: partnerId ? [partnerId] : undefined,
			paid: ['yes', 'no', 'partial'],
		});

		try {
			const { data } = yield this.getClient().get(`invoices?${queryString}`);
			return data;
		} catch {
			return [];
		} finally {
			this.isSearching = false;
		}
	}
}

export { Invoices, Invoice };
