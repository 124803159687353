import Bugsnag from '@bugsnag/browser';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Form, Input, Spin, Typography } from 'antd';
import sortBy from 'lodash/sortBy';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { AnanasItem } from './types';
import DisablePasswordAutocomplete from '../../../../../../../../components/DisablePasswordAutocomplete';
import { useDrawer } from '../../../../../../../../components/Page';
import { StaticComponents } from '../../../../../../../../components/StaticComponents';
import { Title } from '../../../../../../../../components/Title';
import config from '../../../../../../../../config.json';
import { ERROR_BAD_REQUEST_INVALID_CREDENTIALS } from '../../../../../../../../constants/errors';
import { v2Client } from '../../../../../../../../store/client';
import ConnectProductsDrawer from '../../../../Drawers/ConnectProductsDrawer';

type Venue = {
	id: number;
	name: string;
	address: string;
};

interface Props {
	storeId: string;
}

function ApplicationSettingsShoppster({ storeId }, ref) {
	const [id, , close, ,] = useDrawer('marketplace-application-settings');
	const [form] = Form.useForm();
	// const [loading, setLoading] = useState(false);
	// const [isLoggedIn, setIsLoggedIn] = useState(false);

	// const [clientId, setClientId] = useState('');

	// const [areItemsLoading, setAreItemsLoading] = useState(false);
	// const [items, setItems] = useState<AnanasItem[]>([]);

	// const [isConnectProductsVisible, setIsConnectProductsVisible] =
	// 	useState(false);

	// const [form] = Form.useForm();

	// useEffect(() => {
	// 	if (id) {
	// 		setLoading(true);
	// 		v2Client
	// 			.get(`/marketplace-applications/installed/${id}`, {
	// 				headers: {
	// 					'store-id': storeId,
	// 				},
	// 			})
	// 			.then((response) => {
	// 				setClientId(response.data.credentials?.clientId);
	// 				setIsLoggedIn(!!response.data.settings?.loggedIn);
	// 				form.setFieldValue('venueId', response.data.settings?.venueId);
	// 			})
	// 			.catch((error) => {
	// 				console.log(error);
	// 			})
	// 			.finally(() => {
	// 				setLoading(false);
	// 			});
	// 	}
	// }, [id, storeId, form]);

	// useImperativeHandle(ref, () => ({
	// 	save: () => {
	// 		form.submit();
	// 	},
	// }));

	// const showConnectProductsDrawer = useCallback(
	// 	async (alwaysOpen = true) => {
	// 		setAreItemsLoading(true);
	// 		try {
	// 			const itemsResponse = await v2Client.get(
	// 				`/marketplace-applications/${id}/functions/get-items`
	// 			);

	// 			// setItems(sortBy(itemsResponse.data, 'name.0.value'));
	// 			setItems(sortBy(itemsResponse.data, 'name'));
	// 			if (alwaysOpen || itemsResponse.data.find((item) => !item.product)) {
	// 				setIsConnectProductsVisible(true);
	// 			}
	// 		} catch (error) {
	// 			console.log(error);
	// 		} finally {
	// 			setAreItemsLoading(false);
	// 		}
	// 	},
	// 	[id]
	// );

	// const handleConnectProductsButtonClick = useCallback(() => {
	// 	showConnectProductsDrawer(true);
	// }, [showConnectProductsDrawer]);

	// const handleSubmit = useCallback(async () => {
	// 	const values = await form.validateFields();
	// 	setLoading(true);
	// 	try {
	// 		const response = await v2Client.patch(
	// 			`/marketplace-applications/${id}/settings`,
	// 			{
	// 				settings: {
	// 					venueId: values.venueId,
	// 				},
	// 				credentials: {
	// 					clientId: values.clientId,
	// 					clientSecret: values.clientSecret,
	// 				},
	// 			}
	// 		);

	// 		setIsLoggedIn(!!response.data.settings?.loggedIn);

	// 		StaticComponents.notification.success({
	// 			message: t`Подешавања су успешно сачувана`,
	// 		});
	// 	} catch (error) {
	// 		if (
	// 			error?.response?.data?.errorCode ===
	// 			ERROR_BAD_REQUEST_INVALID_CREDENTIALS
	// 		) {
	// 			return StaticComponents.notification.error({
	// 				message: t`Грешка`,
	// 				description: t`ИД клијента или тајни кључ нису исправни`,
	// 			});
	// 		}
	// 		StaticComponents.notification.error({
	// 			message: t`Грешка`,
	// 			description: t`Дошло је до грешке приликом чувања подешавања`,
	// 		});
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// }, [form, id]);

	// const closeConnectProductsDrawer = useCallback(() => {
	// 	setIsConnectProductsVisible(false);
	// }, [setIsConnectProductsVisible]);

	// const formatItem = useCallback(
	// 	(item) => ({
	// 		externalId: item.id,
	// 		name: item.product?.name || item.name,
	// 		description: item.product?.description || item.description,
	// 		productId: item.product?.productId,
	// 		productName: item.product?.product?.parent
	// 			? `${item.product?.product?.parent?.name} ${item.product?.product?.name}`
	// 			: item.product?.product?.name,
	// 		salePrice: item.product?.salePrice,
	// 		saleQuantity: item.product?.saleQuantity,
	// 		active: item.active,
	// 		metadata: {
	// 			...(item.metadata || {}),
	// 			groupId: item.metadata?.groupId || item.groupId,
	// 		},
	// 		imageUrl: item.imageUrl,
	// 		price: item.displayPrice,
	// 		product: item.product,
	// 	}),
	// 	[]
	// );

	useEffect(() => {
		form.setFieldValue(
			'xmlUrl',
			`${config.apiV2}/marketplace-applications/${id}/public-functions/get-xml/${storeId}`
		);
	}, [form, id, storeId]);

	return (
		<Form form={form} layout="vertical">
			<Title>
				<Trans>Повезивање</Trans>
			</Title>

			<Form.Item>
				<Alert
					type="info"
					message={t`Повезивање са платформом Shoppster се врши на тај начин што ћете им, у поступку интеграције, послати адресу до XML фајла са вашим артиклима, која се налази испод.`}
				/>
			</Form.Item>
			<Form.Item name="xmlUrl" label={t`Адреса до XML фајла`}>
				<Input readOnly />
			</Form.Item>

			<input type="submit" style={{ display: 'none' }} />
		</Form>
	);
}

export default observer(ApplicationSettingsShoppster, { forwardRef: true });
