import { t, Trans } from '@lingui/macro';
import { Alert, Input, Tooltip } from 'antd';
import { v4 as uuid } from 'uuid';

import SecretInput from './Components/SecretInput';
import { CreatePage } from '../../../../../components/Page';
import { getApplicationName } from '../../../../../constants/application';
import stores from '../../../../../stores/index.mobx';

export default () =>
	CreatePage({
		docsLink:
			'koriscenje/moduli-aplikacije/podesavanja/aplikacije/za-programere/api-kljucevi/',
		module: 'settings',
		submodule: 'apikeys',
		model: 'apiKey',
		page: {
			empty: {
				image: 'key',
				text: t`Нема АПИ кључева`,
			},
			shouldFetch: true,
			table: {
				columns: [
					{
						title: t`АПИ кључ`,
						dataIndex: 'key',
						key: 'key',
						shouldLink: true,
					},
					{
						title: t`Последња употреба`,
						dataIndex: 'latUsedAt',
						key: 'latUsedAt',
						render: (date) => (date ? date.fromNow() : '-'),
					},
				],
			},
			createButtonText: t`Додај АПИ кључ`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете овај АПИ кључ?`,
		},
		view: {
			useEdit: true,
		},
		edit: {
			width: 500,
			title: {
				new: t`Додавање АПИ кључа`,
				existing: (entity) => t`Измена АПИ кључа`,
			},
			shouldFetch: true,
			beforeSave: (entity) => {
				if (entity.secretPlain === null) {
					delete entity.secretPlain;
				}
				delete entity.clientId;
				return entity;
			},
			fields: (entity) => [
				{
					key: 'all',
					fields: [
						{
							key: 'warning',
							component: (
								<Alert
									type="warning"
									message={t`Пажња: АПИ кључеви омогућавају неограничен приступ целом Вашем
								предузећу на ${getApplicationName()} апликацији. Будите обазриви на који начин чувате и коме дајете АПИ кључеве.`}
								></Alert>
							),
						},
						{
							key: 'key',
							label: t`АПИ кључ`,
							initialValue: !entity
								? `${
										process.env.REACT_APP_IS_SANDBOX ? 'sandbox' : 'production'
								  }.${uuid()}`
								: undefined,
							component: <Input readOnly />,
							span: 24,
						},
						entity
							? {
									// TODO: extract to component to be able to control readonly state
									key: 'secretPlain',
									label: t`АПИ тајна`,
									component: <SecretInput />,
									span: 24,

									extra: t`Пажња: АПИ тајну није могуће приказати. Уколико сте изгубили АПИ тајну, можете је поново генерисати.`,
							  }
							: {
									key: 'secretPlain',
									label: t`АПИ тајна`,
									initialValue: uuid(),
									component: <Input readOnly />,
									span: 24,

									extra: t`Пажња: АПИ тајну нећете бити у могућности да видите након што затворите овај прозор. Уколико је изгубите, мораћете да је поново изгенеришете`,
							  },
						{
							key: 'clientId',
							label: (
								<>
									<Trans>ИД клијента</Trans>
									&nbsp;
									<Tooltip
										title={t`ИД клијента се користи за приступ ресурсима који захтевају рефренцирање одређеног клијента. Овај ИД се разликује за сваку инсталацију.`}
									>
										<i className="fi fi-rr-info"></i>
									</Tooltip>
								</>
							),
							span: 24,
							initialValue: stores.application.clientId,
							component: <Input readOnly />,
						},
					],
				},
			],
		},
	});
