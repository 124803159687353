import { Trans } from '@lingui/macro';
import { Button, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import styles from './HeaderComponent.module.less';
import WarehouseSelect from '../../../../../components/Selects/WarehouseSelect';
import stores from '../../../../../stores/index.mobx';

function HeaderComponentComponent() {
	const {
		stocks,
		warehouses: { available },
	} = stores;
	const [date, setDate] = useState(dayjs());
	const [warehouseId, setWarehouseId] = useState<string>('');

	useEffect(() => {
		stocks.fetchAll(undefined, undefined, {
			date: date.format('YYYY-MM-DD'),
			warehouseId,
		});
	}, [date, stocks, warehouseId]);

	return (
		<Space>
			{available.length > 1 && (
				<>
					<div>
						<Trans>Складиште</Trans>
					</div>
					<WarehouseSelect
						value={warehouseId}
						style={{ width: 150 }}
						showCreateButton={false}
						showAllOption
						onChange={(value) => {
							setWarehouseId(value);
						}}
					/>
				</>
			)}

			<Button.Group>
				<Button
					icon={<i className="fi fi-rr-angle-left"></i>}
					onClick={() => setDate(dayjs(date).subtract(1, 'day'))}
				/>
				<DatePicker
					className={styles.datePicker}
					onChange={setDate}
					format="L"
					style={{ width: 120 }}
					allowClear={false}
					value={dayjs(date)}
				/>
				<Button
					icon={<i className="fi fi-rr-angle-right"></i>}
					onClick={() => setDate(dayjs(date).add(1, 'day'))}
				/>
			</Button.Group>
		</Space>
	);
}

export const HeaderComponent = observer(HeaderComponentComponent);
