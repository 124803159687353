import { t } from '@lingui/macro';
import { Table } from 'antd';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';

import {
	TransactionType,
	TRANSACTION_TYPE_MAP,
} from '../../../../../../constants/journal';
import numberFormat from '../../../../../../lib/numberFormat';
import styles from '../../List.module.less';

function AdvanceList({ record }) {
	if (!record) {
		return null;
	}
	return (
		<Table
			dataSource={[...record.advanceItems]}
			pagination={false}
			size="small"
			bordered
			className={styles.table}
			scroll={{ x: 600 }}
		>
			<Table.Column key="name" dataIndex="name" title={t`Назив`} />
			<Table.Column
				key="taxLabels"
				title={t`Ознака`}
				render={(record) => record.taxLabels?.join(', ')}
				width={70}
				align="center"
			/>
			{!record.unknownAmountAdvance && (
				<Table.Column
					key="unitPrice"
					dataIndex="unitPrice"
					title={t`Цена`}
					width={150}
					align="right"
					render={(text) => numberFormat(text, true, 2, true)}
				/>
			)}
			<Table.Column
				key="quantity"
				title={t`Количина`}
				width={100}
				align="right"
				render={(record) =>
					`${numberFormat(record.quantity, false, 3, false)} ${
						record.unit || ''
					}`
				}
			/>
			{!record.unknownAmountAdvance && (
				<Table.Column
					key="totalAmount"
					dataIndex="totalAmount"
					title={t`Укупно`}
					width={150}
					align="right"
					render={(text) => numberFormat(text, true, 2, true)}
				/>
			)}
		</Table>
	);
}

export default observer(AdvanceList);
