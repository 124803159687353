import { Select } from 'antd';
import { observer } from 'mobx-react-lite';

import stores from '../../../../stores/index.mobx';
import styles from '../POS.module.less';

function SelectStoreDropdown() {
	const {
		stores: { available, setCurrentStore, currentStoreId },
	} = stores;
	return (
		<Select
			onChange={setCurrentStore}
			className={styles.selectStoreDropdown}
			value={currentStoreId}
			popupMatchSelectWidth={false}
		>
			{available.map((store) => (
				<Select.Option value={store.id}>{store.displayName}</Select.Option>
			))}
		</Select>
	);
}

export default observer(SelectStoreDropdown);
