import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';

import { withRouter } from './withRouter';
import stores from '../stores/index.mobx';

type Props = {
	location: string;
};

function withAuth(
	WrappedComponent,
	protectedRoute,
	allowAuthenticated = false
) {
	function Wrapper(props: Props) {
		const {
			users: { token, loggedOut },
			company: { isCreating },
		} = stores;
		if (
			token &&
			!protectedRoute &&
			!allowAuthenticated &&
			!loggedOut &&
			!isCreating
		) {
			return (
				<Navigate
					to={{
						pathname: '/app/home',
						state: { from: props.location },
					}}
				/>
			);
		}

		if (!token && protectedRoute && !loggedOut && !isCreating) {
			return (
				<Navigate
					to={{
						pathname: '/',
						state: { from: props.location },
					}}
				/>
			);
		}

		return <WrappedComponent {...props} />;
	}

	return withRouter(observer(Wrapper));
}

export default withAuth;
