import { Trans, t } from '@lingui/macro';
import dayjs from 'dayjs';
import { flowResult } from 'mobx';
import { useCallback, useEffect, useState } from 'react';

import { useDrawer } from '../../../../../components/Page';
import { StaticComponents } from '../../../../../components/StaticComponents';
import stores from '../../../../../stores/index.mobx';

export function CheckInitial() {
	const {
		initialStocks: { loadingInitial, fetchInitial, hasInitial },
		warehouses: { isFetching, available },
	} = stores;

	const [isWarehouseModalShown, setIsWarehouseModalShown] = useState(false);
	const [isInitialModalShown, setIsInitialModalShown] = useState(false);

	const [, openEditInitial, , , , EditInitialDrawer] = useDrawer(
		'edit/stocks/initial'
	);
	const [, , , , , EditCalculationDrawer] = useDrawer(
		'edit/stocks/calculation'
	);
	const [, , , , , EditPricechangeDrawer] = useDrawer(
		'edit/stocks/pricechange'
	);
	const [, openCreateWarehouse, , , , CreateWarehouseDrawer] = useDrawer(
		'create/settings/warehouses'
	);

	const [, , , , , ViewReciptDrawer] = useDrawer('view/receipts/list');

	const checkInitial = useCallback(async () => {
		const initial = await flowResult(fetchInitial());
		if (!initial) {
			setIsInitialModalShown(true);
		}
	}, [fetchInitial]);

	useEffect(() => {
		if (isFetching || loadingInitial) {
			return;
		}
		if (available.length === 0) {
			setIsWarehouseModalShown(true);
			return;
		}
		checkInitial();
	}, [
		hasInitial,
		loadingInitial,
		isFetching,
		available,
		openCreateWarehouse,
		checkInitial,
	]);

	useEffect(() => {
		if (isWarehouseModalShown) {
			StaticComponents.modal.confirm({
				title: t`Складиште`,
				content: (
					<Trans>
						Како бисте били у могућности да пратите залихе, морате креирати
						складиште.
						<br />
						<br />
						Да ли желите да креирате складиште?
					</Trans>
				),
				okText: t`Да`,
				cancelText: t`Не`,
				onOk: () => {
					openCreateWarehouse();
				},
			});
		}
	}, [isWarehouseModalShown, openCreateWarehouse]);

	useEffect(() => {
		if (isInitialModalShown) {
			StaticComponents.modal.confirm({
				title: t`Почетно стање`,
				content: (
					<Trans>
						Како бисте били у могућности да пратите залихе, морате креирати
						почетно стање залиха.
						<br />
						<br />
						Чак и ако немате залихе, потребно је да потврдите празан запис са
						почетним стањем.
						<br />
						<br />
						Да ли желите да креирате почетно стање?
					</Trans>
				),
				okText: t`Да`,
				cancelText: t`Не`,
				onOk: async () => {
					const { initialStocks } = stores;
					const response = await flowResult(
						initialStocks.create({
							date: dayjs(),
							items: [],
						})
					);
					openEditInitial(response.id);
				},
			});
		}
	}, [isInitialModalShown, openEditInitial]);

	return (
		<>
			<CreateWarehouseDrawer />
			<EditInitialDrawer />
			<EditCalculationDrawer />
			<EditPricechangeDrawer />
			<ViewReciptDrawer />
		</>
	);
}
