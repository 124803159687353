import { t } from '@lingui/macro';
import { Badge } from 'antd';
import dayjs from 'dayjs';

import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'taxRates',
		model: 'taxRate',
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/poreske-stope/',
			shouldFetch: false,
			table: {
				showActions: false,
				props: {
					scroll: { x: 520 },
				},
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
					},
					{
						title: t`Ознака`,
						dataIndex: 'label',
						key: 'label',
						width: 70,
					},
					{
						title: t`Стопа`,
						dataIndex: 'rate',
						key: 'rate',
						width: 70,
						render: (text) => `${numberFormat(text, false)}%`,
					},
					{
						title: t`Важи од`,
						dataIndex: 'validFrom',
						key: 'falidFrom',
						width: 150,
						render: (text) => dayjs(text).format('LL'),
						sorter: (a, b) => a.validFrom?.valueOf() - b.validFrom?.valueOf(),
						defaultSortOrder: 'descend',
					},
					{
						title: t`Активна`,
						dataIndex: 'isActive',
						key: 'isActive',
						width: 120,
						filters: [
							{
								text: <Badge color="green" text={t`Активна`} />,
								value: '1',
							},
							{
								text: <Badge color="red" text={t`Неактивна`} />,
								value: '0',
							},
						],
						defaultFilteredValue: ['1'],
						filterMultiple: true,
						onFilter: (value, record) =>
							(record.isActive && value === '1') ||
							(!record.isActive && value === '0'),
						render: (text) =>
							text ? (
								<Badge color="green" text={t`Активна`} />
							) : (
								<Badge color="red" text={t`Неактивна`} />
							),
					},
				],
			},
		},
	});
