import round from 'lodash/round';
import { bignumber, evaluate } from 'mathjs';

export function deductTax(amount: number, taxRate: number): number {
	return round(
		evaluate('(amount * 100) / (100 + taxRate)', {
			amount: bignumber(amount || 0),
			taxRate: bignumber(taxRate || 0),
		}).toNumber(),
		4
	);
}
