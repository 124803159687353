import { t } from '@lingui/macro';

export const getMarketplaceApplicationScope = () => ({
	company: t`Предузеће`,
	invoices: t`Фактуре`,
	users: t`Корисници`,
	products: t`Артикли`,
	receipts: t`Фискални рачуни`,
	categories: t`Категорије`,
	units: t`Јединице мере`,
	partners: t`Партнери`,
});
