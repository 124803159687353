import { t, Trans } from '@lingui/macro';
import {
	Button,
	Checkbox,
	Col,
	Drawer,
	Form,
	Input,
	InputNumber,
	Row,
	Tooltip,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';

import CurrencySelect from '../../../../../../../../components/CurrencySelect';
import { Title } from '../../../../../../../../components/Title';
import UnitSelect from '../../../../../../../../components/UnitSelect';
import stores from '../../../../../../../../stores/index.mobx';
import { Product } from '../../../../../../../../stores/Product.mobx';
import { BarcodeInput } from '../../../BarcodeInput';
import DimensionsInput from '../../../DimensionsInput';
import SaleChannelsInput from '../../../SaleChannelsInput';
import TaxRateSelect from '../../../../../../../../components/TaxRateSelect';

interface Props {
	visible?: boolean;
	entity?: Product;
	close: () => void;
	productType: string;
	save: (entity: Product) => void;
}

export default function VariantEditDrawer({
	visible,
	entity,
	close,
	productType,
	save,
}: Props) {
	const [formData, setFormData] = useState({ ...(entity || {}) });
	const [form] = Form.useForm();

	const parentForm = Form.useFormInstance();

	useEffect(() => {
		if (entity) {
			setFormData({ ...entity });
		} else {
			form.resetFields();
			setFormData({});
		}
	}, [entity]);

	useEffect(() => {
		form.setFieldsValue(formData);
	}, [formData, form]);

	const onFieldsChange = useCallback((changedFields, allFields) => {
		setFormData((prev) => ({
			...prev,
			...allFields.reduce((acc, field) => {
				acc[field.name[0]] = field.value;
				return acc;
			}, {}),
		}));
	}, []);

	return (
		<Drawer
			width={842}
			open={visible}
			title={
				entity
					? t`Измена варијанте ${entity?.variantName}`
					: t`Додавање варијанте`
			}
			onClose={close}
			footer={
				<Row>
					<Col flex="auto">
						<Button key="cancel" onClick={close}>
							<Trans>Одустани</Trans>
						</Button>
					</Col>
					<Col flex="none">
						<Button key="save" type="primary" onClick={form.submit}>
							{entity ? t`Сачувај` : t`Додај`}
						</Button>
					</Col>
				</Row>
			}
		>
			<Form
				form={form}
				onFinish={save}
				layout="vertical"
				onFieldsChange={onFieldsChange}
			>
				<Title>
					<Trans>Основне информације</Trans>
				</Title>
				<Row gutter={8}>
					<Col span={24}>
						<Form.Item
							label={t`Назив варијанте`}
							name="variantName"
							rules={[
								{ required: true, message: t`Назив варијанте је обавезан` },
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={8}>
					<Col xs={8} sm={4} md={3}>
						<Form.Item
							name="sku"
							rules={[
								{
									type: 'number',
									validator: async (rule, value) => {
										if (value > 9999999) {
											throw new Error(t`Максимална шифра је 9999999`);
										}
									},
									message: t`Максимална шифра је 9999999`,
								},
							]}
							label={
								<>
									<Trans>Шифра</Trans>&nbsp;
									<Tooltip
										title={
											<Trans>
												Шифру можете користити да откуцате артикал уколико он
												нема бар код
												<br />
												<br />
												Уколико ово поље оставите празно, артиклу ће се доделити
												прва слободна шифра.
											</Trans>
										}
									>
										<i className="fi fi-rr-info"></i>
									</Tooltip>
								</>
							}
						>
							<Input type="number" />
						</Form.Item>
					</Col>
					{productType === 'product' && (
						<>
							<Col xs={16} sm={8} md={6}>
								<Form.Item name="manufacturerSku" label={t`Шифра произвођача`}>
									<Input />
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={5}>
								<Form.Item
									name="ean"
									label={t`Бар код (ГТИН)`}
									rules={[
										{
											type: 'string',
											validator: async (rule, value) => {
												if (
													typeof value === 'undefined' ||
													value === null ||
													value === ''
												) {
													return;
												}
												if (value.length < 8 || value.length > 14) {
													throw new Error(
														t`ГТИН не може бити краћи од 8 и дужи од 14 карактера`
													);
												}
											},
										},
									]}
								>
									<BarcodeInput type="number" />
								</Form.Item>
							</Col>
						</>
					)}
					<Col xs={12} sm={12} md={5}>
						<Form.Item name="currencyId" style={{ display: 'none' }}>
							<CurrencySelect onlyPreferred />
						</Form.Item>
						<Form.Item
							name="price"
							label={
								<Trans>
									Продајна цена &nbsp;
									{productType === 'product' &&
										stores.stores.currentStore?.warehouseId && (
											<Tooltip
												title={t`Уколико је за тренутно продајно место
														укључено праћење залиха, цену је могуће
														променити само израдом калкулација и
														нивелација.`}
											>
												<i className="fi fi-rr-info"></i>
											</Tooltip>
										)}
								</Trans>
							}
						>
							{productType === 'product' &&
							stores.stores.currentStore?.warehouseId ? (
								<div>
									<Input
										disabled
										addonAfter="RSD"
										value={entity?.currentStorePrice || entity?.price || 0}
									/>
								</div>
							) : (
								<InputNumber
									type="number"
									addonAfter={
										stores.company.allowCurrencyConversion ? (
											<CurrencySelect
												style={{ width: 75 }}
												onlyPreferred
												value={formData.currencyId || 'RSD'}
												optionLabelProp="value"
												onChange={(value) => {
													setFormData({ ...formData, currencyId: value });
												}}
											/>
										) : (
											'RSD'
										)
									}
									step={0.01}
								/>
							)}
						</Form.Item>
					</Col>
					<Col xs={24} sm={12} md={5}>
						<Form.Item
							name="taxRateLabel"
							label={t`Пореска ознака`}
							initialValue={
								stores.company?.vat
									? stores.company?.defaultTaxRateLabel
										? stores.company?.defaultTaxRateLabel
										: null
									: stores.taxRates.noVat?.[0]?.label
							}
							rules={[
								{
									required: true,
									message: t`Пореска ознака је обавезна`,
								},
							]}
						>
							<TaxRateSelect />
						</Form.Item>
					</Col>
				</Row>
				{stores.saleChannels.all.length > 0 && (
					<>
						<Title>
							<Trans>Додатни канали продаје</Trans>
						</Title>
						<Row gutter={8}>
							<Col span={24}>
								<Form.Item name="saleChannels">
									<SaleChannelsInput
										isVariant
										productForm={parentForm}
										price={
											productType === 'product' &&
											typeof entity?.prices?.[
												stores.stores.currentStore?.warehouseId
											] !== 'undefined'
												? entity?.currentStorePrice
												: form.getFieldValue('price')
										}
									/>
								</Form.Item>
							</Col>
						</Row>
					</>
				)}

				<Title>
					<Trans>Мере</Trans>
				</Title>

				<Row gutter={8}>
					{productType === 'product' && (
						<Col xs={12} md={6}>
							<Form.Item name="baseUnitId" style={{ display: 'none' }}>
								<UnitSelect />
							</Form.Item>
							<Form.Item
								name="quantityPerBaseUnit"
								label={
									<>
										<Trans>Количина у ЈМ</Trans>&nbsp;
										<Tooltip
											title={
												<Trans>
													Јединица мере у којој је исказана количина на
													амбалажи, на пример: уколико је у питању чоколада која
													има 100г, онда је јединица мере килограм, а количина
													је 0.1
												</Trans>
											}
										>
											<i className="fi fi-rr-info"></i>
										</Tooltip>
									</>
								}
							>
								<Input
									type="number"
									addonAfter={
										<>
											<UnitSelect
												value={formData.baseUnitId}
												onChange={(value) => {
													setFormData({ ...formData, baseUnitId: value });
												}}
												placeholder="JM"
											/>
										</>
									}
								/>
							</Form.Item>
						</Col>
					)}
					<Col xs={12} md={6}>
						<Form.Item name="saleUnitId" label={t`Продајна ЈМ`}>
							<UnitSelect />
						</Form.Item>
					</Col>
					{productType === 'product' && (
						<>
							<Col xs={6} md={4}>
								<Form.Item name="weight" label={t`Бруто маса`}>
									<Input type="number" addonAfter={t`кг`} />
								</Form.Item>
							</Col>
							<Col xs={18} md={8}>
								<Form.Item name="dimensions" label={t`Димензије`}>
									<DimensionsInput />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="quantityFromScale"
									label=""
									valuePropName="checked"
								>
									<Checkbox>
										<Trans>Користи тежину са ваге</Trans>&nbsp;
										<Tooltip
											title={
												<Trans>
													Уколико је вага конфигурисана, приликом додавања
													артикла на рачун, као количина ће се користити тежина
													са ваге
												</Trans>
											}
										>
											<i className="fi fi-rr-info"></i>
										</Tooltip>
									</Checkbox>
								</Form.Item>
							</Col>
						</>
					)}
				</Row>
				<Title>
					<Trans>Фискални рачун</Trans>
				</Title>
				<input type="submit" style={{ display: 'none' }} />
				<Row gutter={8}>
					<Col span={24}>
						<Form.Item name="multiplePerReceipt" valuePropName="checked">
							<Checkbox>
								<Trans>Дозволи више пута на једном рачуну</Trans>
							</Checkbox>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
}
