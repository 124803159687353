import { t } from '@lingui/macro';
import { Table, Checkbox } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';

import styles from './PermissionsEdit.module.less';
import globalStyles from '../../../../../../assets/styles/global.module.less';
import {
	getRoleActions,
	getRoleModuleName,
} from '../../../../../../constants/roles';
import stores from '../../../../../../stores/index.mobx';
import { Role, Permissions } from '../../../../../../stores/Role.mobx';
const icons = {
	pos: '/images/icons/new/cash-register.svg',
	catalog: '/images/icons/new/flyer.svg',
	documents: '/images/icons/new/copy.svg',
	reports: '/images/icons/new/business-report.svg',
	receipts: '/images/icons/new/receipt.svg',
	print: '/images/icons/new/printer.svg',
	settings: '/images/icons/new/settings.svg',
};

interface Props {
	readonly?: boolean;
	value?: Permissions;
	onChange?: (any) => void;
	record?: Role;
}

function PermissionsEdit({
	value = null,
	onChange,
	readonly,
	record = null,
}: Props) {
	const ROLE_ACTIONS = getRoleActions();
	const ROLE_MODULE_NAME = getRoleModuleName();

	const {
		application: { permissions: data, isFetchingPermissions: isFetching },
	} = stores;

	const [values, setValues] = useState({});
	useEffect(() => {
		setValues(value || {});
	}, [value]);
	function onCheckboxChange(paths: string[], event) {
		const valuesClone = cloneDeep(values);
		paths.forEach((path: string) => {
			set(valuesClone, path, event.target.checked);
		});
		setValues(valuesClone);
		onChange(valuesClone);
	}

	function renderPermissions(submoduleRecord, moduleRecord) {
		return (
			<Table
				loading={isFetching}
				pagination={false}
				size="small"
				columns={[
					{
						key: 'all',
						title: t`Све`,
						width: 60,
						render: () => {
							const checkedValues = Object.values(
								get(values, `${moduleRecord.key}.${submoduleRecord.key}`) || {}
							).filter(Boolean).length;
							return (
								<Checkbox
									disabled={readonly}
									checked={record?.isAdmin || checkedValues > 0}
									indeterminate={
										checkedValues !== 0 &&
										checkedValues !==
											Object.keys(submoduleRecord.permissions).length
									}
									onChange={(event) => {
										onCheckboxChange(
											Array.from(Object.keys(submoduleRecord.permissions)).map(
												(key) =>
													`${moduleRecord.key}.${submoduleRecord.key}.${key}`
											),
											event
										);
									}}
								/>
							);
						},
					},
					...Array.from(Object.entries(submoduleRecord.permissions)).map(
						([key, value]) => ({
							key: key,
							title: ROLE_ACTIONS[value.title] || value.title,
							render: () => (
								<Checkbox
									disabled={readonly}
									checked={
										record?.isAdmin ||
										get(
											values,
											`${moduleRecord.key}.${submoduleRecord.key}.${key}`
										)
									}
									onChange={(event) =>
										onCheckboxChange(
											[`${moduleRecord.key}.${submoduleRecord.key}.${key}`],
											event
										)
									}
								/>
							),
						})
					),
				]}
				dataSource={[{}]}
			/>
		);
	}

	function renderSubmodule(moduleRecord) {
		return (
			<Table
				pagination={false}
				size="small"
				showHeader={false}
				className={styles.innerTable}
				bordered
				columns={[
					{
						key: 'submodule',
						width: 150,
						render: (text, record) => {
							return <>{ROLE_MODULE_NAME[record.title] || record.title}</>;
						},
					},
					{
						key: 'permissions',
						render: (text, record) => {
							return renderPermissions(record, moduleRecord);
						},
						className: styles.innerTableWrapper,
					},
				]}
				dataSource={Array.from(Object.entries(moduleRecord.submodules)).map(
					([key, value]) => ({ ...value, key })
				)}
			></Table>
		);
	}

	function renderModule(key, value) {
		return (
			<Table
				showHeader={false}
				pagination={false}
				size="small"
				className={styles.table}
				bordered
				columns={[
					{
						key: 'module',
						width: 150,
						render: (text, record) => {
							return (
								<>
									<img src={icons[record.key]} rel="icon" />
									{ROLE_MODULE_NAME[record.title] || record.title}
								</>
							);
						},
					},

					{
						key: 'submodule',
						className: styles.innerTableWrapper,
						render: (text, record) => {
							return record.submodules
								? renderSubmodule({ ...record, key })
								: renderSubmodule({
										key: key,
										submodules: {
											[record.key]: {
												key: record.key,
												title: t`Цео модул`,
												permissions: record.permissions,
											},
										},
								  });
						},
					},
				]}
				dataSource={[{ ...value, key }]}
			></Table>
		);
	}

	return (
		<div className={globalStyles.container}>
			{Array.from(Object.entries(data)).map(([key, value]) => {
				return renderModule(key, value);
			})}
		</div>
	);
}

export default observer(PermissionsEdit);
