import { t, Trans } from '@lingui/macro';
import { Button, Col, Drawer, Row, Typography } from 'antd';

import { Title } from '../../../../../../../../components/Title';
import stores from '../../../../../../../../stores/index.mobx';
import { Product } from '../../../../../../../../stores/Product.mobx';
import SaleChannelsInput from '../../../SaleChannelsInput';
import pageStyles from '../../../../../../../../components/Page/Page.module.less';
import numberFormat from '../../../../../../../../lib/numberFormat';
import { SalesStatsView } from '../../../SalesStatsView';
import { TurnoverStatsView } from '../../../TurnoverStatsView';
import { PurchaseStatsView } from '../../../PurcaseStatsView';
import { StockStatsView } from '../../../StockStatsView';
import { ShowAll } from '../../../ShowAll';
import { SalesView } from '../../../SalesView';
import { PurchasesView } from '../../../PurchasesView';

interface Props {
	visible?: boolean;
	entity?: Product;
	close: () => void;
	productType: string;
	save: (entity: Product) => void;
}

export default function VariantViewDrawer({
	visible,
	entity,
	close,
	productType,
}: Props) {
	return (
		<Drawer
			width={842}
			open={visible}
			title={t`Преглед варијанте ${entity?.variantName}`}
			onClose={close}
			footer={
				<Row>
					<Col flex="auto"></Col>
					<Col flex="none">
						<Button type="primary" onClick={close}>
							<Trans>Затвори</Trans>
						</Button>
					</Col>
				</Row>
			}
		>
			<Title>
				<Trans>Основне информације</Trans>
			</Title>
			<Row gutter={[8, 8]} className={pageStyles.descriptions}>
				<Col span={24}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Назив варијанте</Trans>:
									</Typography.Text>
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							{entity?.variantName || (
								<Typography.Text disabled>
									<Trans>Није доступно</Trans>
								</Typography.Text>
							)}
						</Col>
					</Row>
				</Col>
				<Col xs={8} sm={4} md={3}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Шифра</Trans>:
									</Typography.Text>
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							{entity?.sku || (
								<Typography.Text disabled>
									<Trans>Није доступно</Trans>
								</Typography.Text>
							)}
						</Col>
					</Row>
				</Col>
				{productType === 'product' && (
					<>
						<Col xs={16} sm={8} md={6}>
							<Row gutter={[4, 4]}>
								<Col span={24}>
									<Row align={'middle'}>
										<Col flex={1}>
											<Typography.Text strong className={`${pageStyles.label}`}>
												<Trans>Шифра произвођача</Trans>:
											</Typography.Text>
										</Col>
									</Row>
								</Col>
								<Col flex="auto" span={24}>
									{entity?.manufacturerSku || (
										<Typography.Text disabled>
											<Trans>Није доступно</Trans>
										</Typography.Text>
									)}
								</Col>
							</Row>
						</Col>
						<Col xs={12} sm={12} md={5}>
							<Row gutter={[4, 4]}>
								<Col span={24}>
									<Row align={'middle'}>
										<Col flex={1}>
											<Typography.Text strong className={`${pageStyles.label}`}>
												<Trans>Бар код (ГТИН)</Trans>:
											</Typography.Text>
										</Col>
									</Row>
								</Col>
								<Col flex="auto" span={24}>
									{entity?.ean || (
										<Typography.Text disabled>
											<Trans>Није доступно</Trans>
										</Typography.Text>
									)}
								</Col>
							</Row>
						</Col>
					</>
				)}
				<Col xs={12} sm={12} md={5}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Продајна цена</Trans>:
									</Typography.Text>
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							{entity?.price ? (
								numberFormat(
									entity.price,
									true,
									2,
									true,
									entity.currencyId || 'RSD'
								)
							) : (
								<Typography.Text disabled>
									<Trans>Није доступно</Trans>
								</Typography.Text>
							)}
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={5}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Пореска ознака</Trans>:
									</Typography.Text>
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							{entity?.taxRateLabel ? (
								`${stores.taxRates.byLabel(entity.taxRateLabel).name} - ${
									entity.taxRateLabel
								} (${numberFormat(
									stores.taxRates.byLabel(entity.taxRateLabel).rate,
									false
								)}%)`
							) : (
								<Typography.Text disabled>
									<Trans>Није доступно</Trans>
								</Typography.Text>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
			{stores.saleChannels.all.length > 0 && (
				<>
					<Title>
						<Trans>Додатни канали продаје</Trans>
					</Title>
					<Row gutter={[8, 8]} className={pageStyles.descriptions}>
						<Col span={24}>
							<Row gutter={[4, 4]}>
								<Col flex="auto" span={24}>
									{entity?.saleChannels ? (
										<SaleChannelsInput
											value={entity.saleChannels}
											mode="view"
											price={entity.price}
											currencyId={entity.currencyId}
										/>
									) : (
										<Typography.Text disabled>
											<Trans>Није доступно</Trans>
										</Typography.Text>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
				</>
			)}

			<Title>
				<Trans>Мере</Trans>
			</Title>

			<Row gutter={[8, 8]} className={pageStyles.descriptions}>
				{productType === 'product' && (
					<Col xs={12} md={6}>
						<Row gutter={[4, 4]}>
							<Col span={24}>
								<Row align={'middle'}>
									<Col flex={1}>
										<Typography.Text strong className={`${pageStyles.label}`}>
											<Trans>Количина у ЈМ</Trans>:
										</Typography.Text>
									</Col>
								</Row>
							</Col>
							<Col flex="auto" span={24}>
								{entity?.quantityPerBaseUnit ? (
									`${numberFormat(
										entity?.quantityPerBaseUnit,
										false,
										3,
										false
									)} ${entity.baseUnit?.name || ''}`
								) : (
									<Typography.Text disabled>
										<Trans>Није доступно</Trans>
									</Typography.Text>
								)}
							</Col>
						</Row>
					</Col>
				)}
				<Col xs={12} md={6}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Продајна ЈМ</Trans>:
									</Typography.Text>
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							{entity?.saleUnit?.name || (
								<Typography.Text disabled>
									<Trans>Није доступно</Trans>
								</Typography.Text>
							)}
						</Col>
					</Row>
				</Col>
				{productType === 'product' && (
					<>
						<Col xs={6} md={4}>
							<Row gutter={[4, 4]}>
								<Col span={24}>
									<Row align={'middle'}>
										<Col flex={1}>
											<Typography.Text strong className={`${pageStyles.label}`}>
												<Trans>Бруто маса</Trans>:
											</Typography.Text>
										</Col>
									</Row>
								</Col>
								<Col flex="auto" span={24}>
									{entity?.weight ? (
										t`${entity.weight} кг`
									) : (
										<Typography.Text disabled>
											<Trans>Није доступно</Trans>
										</Typography.Text>
									)}
								</Col>
							</Row>
						</Col>
						<Col xs={18} md={8}>
							<Row gutter={[4, 4]}>
								<Col span={24}>
									<Row align={'middle'}>
										<Col flex={1}>
											<Typography.Text strong className={`${pageStyles.label}`}>
												<Trans>Димензије</Trans>:
											</Typography.Text>
										</Col>
									</Row>
								</Col>
								<Col flex="auto" span={24}>
									{entity?.dimensions ? (
										t`${entity.dimensions} цм`
									) : (
										<Typography.Text disabled>
											<Trans>Није доступно</Trans>
										</Typography.Text>
									)}
								</Col>
							</Row>
						</Col>
						{/* <Col span={24}>
							<Form.Item
								name="quantityFromScale"
								label=""
								valuePropName="checked"
							>
								<Checkbox>
									<Trans>Користи тежину са ваге</Trans>&nbsp;
									<Tooltip
										title={
											<Trans>
												Уколико је вага конфигурисана, приликом додавања артикла
												на рачун, као количина ће се користити тежина са ваге
											</Trans>
										}
									>
										<i className="fi fi-rr-info"></i>
									</Tooltip>
								</Checkbox>
							</Form.Item>
						</Col> */}
					</>
				)}
			</Row>
			<Title>
				<Trans>Фискални рачун</Trans>
			</Title>
			<Row gutter={[8, 8]} className={pageStyles.descriptions}>
				<Col span={24}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Дозволи више пута на једном рачуну</Trans>:
									</Typography.Text>
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							{entity?.multiplePerReceipt ? t`Да` : t`Не`}
						</Col>
					</Row>
				</Col>
			</Row>
			<Title>
				<Trans>Статистика</Trans>
			</Title>
			<Row gutter={[8, 8]} className={pageStyles.descriptions}>
				<Col xs={24} sm={12}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Статистика продаје</Trans>:
									</Typography.Text>
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							<SalesStatsView product={entity} />
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={12}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Статистика промета</Trans>:
									</Typography.Text>
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							<TurnoverStatsView product={entity} />
						</Col>
					</Row>
				</Col>
				{stores.company.hasFeature('stock') && (
					<>
						<Col xs={24} sm={12}>
							<Row gutter={[4, 4]}>
								<Col span={24}>
									<Row align={'middle'}>
										<Col flex={1}>
											<Typography.Text strong className={`${pageStyles.label}`}>
												<Trans>Статистика набавке</Trans>:
											</Typography.Text>
										</Col>
									</Row>
								</Col>
								<Col flex="auto" span={24}>
									<PurchaseStatsView product={entity} />
								</Col>
							</Row>
						</Col>
						<Col xs={24} sm={12}>
							<Row gutter={[4, 4]}>
								<Col span={24}>
									<Row align={'middle'}>
										<Col flex={1}>
											<Typography.Text strong className={`${pageStyles.label}`}>
												<Trans>Статистика залиха</Trans>:
											</Typography.Text>
										</Col>
									</Row>
								</Col>
								<Col flex="auto" span={24}>
									<StockStatsView product={entity} />
								</Col>
							</Row>
						</Col>
					</>
				)}
				<Col span={24}>
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Последње продаје</Trans>:
									</Typography.Text>
								</Col>
								<Col>
									<ShowAll product={entity} mode="sales" />
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							<SalesView product={entity} />
						</Col>
					</Row>
				</Col>
				{stores.company.hasFeature('stock') && (
					<Row gutter={[4, 4]}>
						<Col span={24}>
							<Row align={'middle'}>
								<Col flex={1}>
									<Typography.Text strong className={`${pageStyles.label}`}>
										<Trans>Последње набавке</Trans>:
									</Typography.Text>
								</Col>
								<Col>
									<ShowAll product={entity} mode="purchases" />
								</Col>
							</Row>
						</Col>
						<Col flex="auto" span={24}>
							<PurchasesView product={entity} />
						</Col>
					</Row>
				)}
			</Row>
		</Drawer>
	);
}
