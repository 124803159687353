import { Trans, t } from '@lingui/macro';
import { Button, Empty } from 'antd';
import dayjs from 'dayjs';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useDrawer } from '../../../../../../components/Page';
import stores from '../../../../../../stores/index.mobx';

function CustomEmptyBase() {
	const {
		warehouses: {
			available: availableWarehouses,
			isCreating: isCreatingWarehouse,
		},
		initialStocks: {
			hasInitial,
			loadingInitial,
			isCreating: isCreatingInitial,
		},
	} = stores;

	const [, openCreateWarehouse] = useDrawer('create/settings/warehouses');
	const [, openEditInitial] = useDrawer('edit/stocks/initial');

	if (availableWarehouses.length === 0) {
		return (
			<Empty
				image={<img src={`/images/icons/new/trolley.svg`} alt="" />}
				description={t`Како бисте били у могућности да пратите залихе, морате креирати складиште.`}
			>
				<Button
					type="link"
					loading={isCreatingWarehouse}
					disabled={isCreatingWarehouse}
					onClick={() => {
						openCreateWarehouse();
					}}
				>
					<Trans>Додај складиште</Trans>
				</Button>
			</Empty>
		);
	}

	if (!hasInitial) {
		return (
			<Empty
				image={<img src={`/images/icons/new/trolley.svg`} alt="" />}
				description={t`Како бисте били у могућности да пратите залихе, морате креирати почетно стање.`}
			>
				<Button
					type="link"
					loading={loadingInitial || isCreatingInitial}
					disabled={loadingInitial || isCreatingInitial}
					onClick={async () => {
						const { initialStocks } = stores;
						const response = await flowResult(
							initialStocks.create({
								date: dayjs(),
								items: [],
							})
						);
						openEditInitial(response.id);
					}}
				>
					<Trans>Додај почетно стање</Trans>
				</Button>
			</Empty>
		);
	}
}

export const CustomEmpty = observer(CustomEmptyBase);
