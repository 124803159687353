import { t, Trans } from '@lingui/macro';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { useEffect, useImperativeHandle, useState } from 'react';

import styles from './SDC.module.less';
import DisablePasswordAutocomplete from '../../../../components/DisablePasswordAutocomplete';
import { StaticComponents } from '../../../../components/StaticComponents';
import { Title } from '../../../../components/Title';
import { getApplicationName } from '../../../../constants/application';
import { getStatusCodes, getStatusPerMRC } from '../../../../constants/esdc';
import { usePermissions } from '../../../../hooks/permissions';
import stores from '../../../../stores/index.mobx';

function SDC(undefined, ref) {
	const STATUS_CODES = getStatusCodes();
	const STATUS_PER_MRC = getStatusPerMRC();

	const APPLICATION_NAME = getApplicationName();
	const [form] = Form.useForm();

	const [isFormTouched, setIsFormTouched] = useState(false);
	const {
		sdc,
		company: { package: currentPackage },
	} = stores;

	const { browsePfx, saveConfiguration, isSaving } = sdc;

	const [currentValues, setCurrentValues] = useState({
		url: null,
	});

	useEffect(() => {
		form.setFieldsValue({
			sdc,
		});
	}, [form, sdc, sdc.pac]);

	useImperativeHandle(ref, () => ({
		getDocsLink: () =>
			'koriscenje/moduli-aplikacije/podesavanja/procesor-fiskalnih-racuna/',
	}));

	async function onSubmit() {
		try {
			const values = await form.validateFields();
			if (values.url) {
				try {
					if (window.electron) {
						await window.electron.esdcRequest({
							url: `${values.url}/api/v3/status`,
							method: 'get',
						});
					} else {
						await axios.get(`${values.url}/api/v3/status`);
					}
				} catch (e) {
					form.setFields([
						{
							name: 'url',
							value: values.url,
							errors: [
								t`Локални процесор фискалних рачуна није доступан на овој адреси`,
							],
						},
					]);
					return;
				}
			}

			await saveConfiguration(values);
			StaticComponents.notification.success({
				message: t`Подешавања су успешно сачувана`,
			});
			setIsFormTouched(false);
		} catch (error) {
			StaticComponents.notification.error({
				message: t`Дошло је до грешке приликом чувања подешавања`,
			});
		}
	}

	function onValuesChange(changedValues, allValues) {
		setIsFormTouched(true);
		setCurrentValues({ ...currentValues, ...allValues });
	}

	async function onBrowseClick() {
		try {
			const path = await browsePfx();
			form.setFieldsValue({
				pfx: path,
			});
		} catch (e) {
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: e.message,
			});
		}
	}

	const hasSdcPermission = usePermissions('settings', 'sdc', 'edit');

	return (
		<div className={styles.wrapper}>
			<Form
				layout="vertical"
				className={styles.form}
				initialValues={sdc}
				onFinish={onSubmit}
				form={form}
				onValuesChange={onValuesChange}
				disabled={!hasSdcPermission}
			>
				<DisablePasswordAutocomplete />

				<Title>
					<Trans>Локални процесор фискалних рачуна</Trans>
				</Title>
				<>
					<Form.Item>
						<Trans>
							Локални процесор фискалних рачуна је обавезан у пословном простору
							уколико делатност није даљинска трговина.
						</Trans>{' '}
						<br />
						<>
							<Trans>
								Као корисник {APPLICATION_NAME} апликације, можете потпуно
								бесплатно користити {APPLICATION_NAME} ЛПФР.
							</Trans>
							<br />
							<Trans>
								Преузмите {APPLICATION_NAME} ЛПФР{' '}
								<a href="https://lpfr.badi.rs/" target="_blank">
									овде
								</a>
								.
							</Trans>
						</>
					</Form.Item>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="url"
								label={t`Адреса до локалног процесора фискалних рачуна`}
								rules={[
									{
										type: 'url',
										message: t`Морате унети исправну адресу`,
									},
								]}
								extra={
									sdc.url && (
										<span className={styles.status}>
											{!sdc.esdcStatus?.available && (
												<>
													<img src="/images/icons/new/esdc/small/decline.svg" />
													<Trans>Није доступан</Trans>&nbsp;&nbsp;&nbsp;
												</>
											)}
											{sdc.esdcStatus?.available && (
												<>
													<img src="/images/icons/new/esdc/small/accept.svg" />
													<Trans>Доступан</Trans>&nbsp;&nbsp;&nbsp;
													{(sdc.esdcStatus?.gsc || []).map((statusCode) => {
														const status = STATUS_CODES[`${statusCode}`];
														if (!status) {
															return '';
														}
														return (
															<>
																<img
																	src={`/images/icons/new/esdc/small/${status[1]}.svg`}
																/>{' '}
																{status[0]}&nbsp;&nbsp;&nbsp;
															</>
														);
													})}
													{(sdc.esdcStatus?.mssc || []).map((statusCode) => {
														const MRC =
															sdc.esdcStatus?.deviceSerialNumber.substring(
																0,
																7
															);
														const status =
															STATUS_PER_MRC[MRC]?.[`${statusCode}`];
														if (!status) {
															return '';
														}
														return (
															<>
																<img
																	src={`/images/icons/new/esdc/small/${status[1]}.svg`}
																/>{' '}
																{status[0]}&nbsp;&nbsp;&nbsp;
															</>
														);
													})}
												</>
											)}
										</span>
									)
								}
							>
								<Input type="text" />
							</Form.Item>
						</Col>
					</Row>
				</>
				<Title>
					<Trans>Виртуални процесор фискалних рачуна</Trans>
				</Title>
				{!window.electron && (
					<Form.Item>
						<Trans>
							Како бисте користили виртуални процесор фискалних рачуна из
							интернет прегледача, потребно је да инсталирате сертификат који
							сте добили од Пореске управе и да унесете ПАК.
						</Trans>
					</Form.Item>
				)}
				<Form.Item name="useVsdc" valuePropName="checked">
					<Checkbox>
						<Trans>
							Користи виртуални процесор фискалних рачуна када је доступна
							интернет веза
						</Trans>
					</Checkbox>
				</Form.Item>
				{window.electron && (
					<Row gutter={16}>
						<Col span={18}>
							<Form.Item label={t`PFX Фајл`} name="pfx">
								<Input type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label=" ">
								<Button block onClick={onBrowseClick}>
									<Trans>Пронађи</Trans>
								</Button>
							</Form.Item>
						</Col>
					</Row>
				)}
				<Row gutter={16}>
					{window.electron && (
						<>
							<Col span={8}>
								<Form.Item label={t`ЈИД`} name="uid">
									<Input type="text" disabled />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label={t`Лозинка`} name="password">
									<Input type="password" />
								</Form.Item>
							</Col>
						</>
					)}
					<Col span={8}>
						<Form.Item label={t`ПАК`} name="pac">
							<Input type="text" />
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button
						size="large"
						type="primary"
						htmlType="submit"
						disabled={!isFormTouched}
						loading={isSaving}
					>
						<Trans>Сачувај</Trans>
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}

export default observer(SDC, { forwardRef: true });
