import { t } from '@lingui/macro';
import { Input, Checkbox, DatePicker } from 'antd';
import dayjs from 'dayjs';

import { TableInput } from '../../../../../../components/TableInput';
import { CreditDebitNoteReference } from '../../../../../../stores/Invoice.mobx';
import { InvoiceSelect } from '../InvoiceSelect';

interface Props {
	partnerId: string;
}

function CreditDebitNoteReferencesInput({ partnerId, ...props }: Props) {
	return (
		<TableInput
			tableProps={{ scroll: { x: 500 } }}
			addButtonText={t`Додај изворну фактуру`}
			emptyText={t`Нема изворних фактура`}
			iconPath="/images/icons/new/invoice-sheet.svg"
			newRowValue={{
				inSystem: true,
				invoiceNumber: undefined,
				invoiceId: undefined,
				issueDate: undefined,
			}}
			columns={[
				{
					title: t`У систему`,
					dataIndex: 'inSystem',
					width: 95,
					editable: true,
					valuePropName: 'checked',
					component: (
						record: Partial<CreditDebitNoteReference>,
						index,
						handleEditObject
					) => (
						<Checkbox
							onChange={(event) => {
								handleEditObject(index, {
									inSystem: event.target.checked,
									invoiceNumber: undefined,
									invoiceId: undefined,
									issueDate: undefined,
								});
							}}
						/>
					),
					style: { textAlign: 'center' },
				},
				{
					title: t`Фактура`,
					dataIndex: 'invoiceNumber',
					editable: true,
					component: (
						record: Partial<CreditDebitNoteReference>,
						index,
						handleEditObject
					) => {
						return record.inSystem ? (
							<InvoiceSelect
								partnerId={partnerId}
								direction="outgoing"
								types={['invoice']}
								hideNotInSystem
								onSelect={(value) => {
									if (!value) {
										handleEditObject(index, {
											invoiceNumber: undefined,
											invoiceId: undefined,
											issueDate: undefined,
										});

										return;
									}

									handleEditObject(index, {
										invoiceNumber: value.number,
										invoiceId: value.id,
										issueDate: dayjs(value.issueDate),
									});
								}}
							/>
						) : (
							<Input style={{ width: '100%' }} />
						);
					},
					rules: [
						{
							required: true,
							message: t`Фактура је обавезна`,
						},
					],
				},
				{
					title: t`Датум фактуре`,
					dataIndex: 'issueDate',
					editable: true,
					width: 150,
					component: (
						record: Partial<CreditDebitNoteReference>,
						index,
						handleEditObject
					) => {
						return record.inSystem ? (
							<DatePicker format="L" disabled style={{ width: '100%' }} />
						) : (
							<DatePicker
								format="L"
								style={{ width: '100%' }}
								onChange={(value) => {
									handleEditObject(index, {
										issueDate: value,
									});
								}}
							/>
						);
					},
				},
			]}
			{...props}
		/>
	);
}

export default CreditDebitNoteReferencesInput;
