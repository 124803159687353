import { t, Trans } from '@lingui/macro';
import { useLatest } from 'ahooks';
import { Button, Input, List, Modal, Space, Tag } from 'antd';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'react-use';

import styles from './POS.module.less';
import { NoImage } from '../../../components/NoImage';
import { StaticComponents } from '../../../components/StaticComponents';
import { useBarcodeScanner } from '../../../hooks/useBarcodeScanner';
import numberFormat from '../../../lib/numberFormat';
import stores from '../../../stores/index.mobx';
import { bignumber, evaluate } from 'mathjs';
interface Props {
	visible: boolean;
	value: string;
	onConfirm: (sku: number, multiplier: number) => void;
	onCancel: () => void;
	addByEan: (ean: string, quantity: number) => void;
	currentSaleChannelId?: string | null;
}

function SkuEntryModal({
	visible,
	value,
	onConfirm,
	onCancel,
	addByEan,
	currentSaleChannelId,
}: Props) {
	const [skuPopoverValue, setSkuPopoverValue] = useState('');
	const [currentSku, setCurrentSku] = useState(null);
	const [currentMultiplier, setCurrentMultiplier] = useState(null);
	const [defaultQuantity, setDefaultQuantity] = useState(true);

	const {
		devices: { scales },
		products: { bySku, getById },
	} = stores;

	useEffect(() => {
		if (visible) {
			setSkuPopoverValue(value);
		}
	}, [visible, value]);

	useEffect(() => {
		if (visible) {
			if (
				skuPopoverValue.toLowerCase().includes('x') ||
				skuPopoverValue.includes('*')
			) {
				const parts = skuPopoverValue.toLowerCase().split(/[x*]/);
				const [quantity, sku] = parts;
				const quantityMultiplier = round(
					parseFloat(quantity.replace(/,/g, '.')),
					3
				);
				setCurrentSku(parseInt(sku));
				setCurrentMultiplier(quantityMultiplier);
				setDefaultQuantity(false);
			} else {
				setCurrentSku(parseInt(skuPopoverValue));
				setCurrentMultiplier(1);
				setDefaultQuantity(true);
			}
		}
	}, [visible, skuPopoverValue]);

	const product = useMemo(() => {
		const p = bySku[currentSku];
		if (!currentSaleChannelId) {
			return p;
		}

		return p && p.saleChannelIds.includes(currentSaleChannelId) ? p : null;
	}, [bySku, currentSaleChannelId, currentSku]);

	useInterval(async () => {
		if (
			visible &&
			product &&
			product.quantityFromScale &&
			scales.length > 0 &&
			defaultQuantity
		) {
			try {
				setCurrentMultiplier(await window.electron.scale.getWeight());
			} catch (e) {
				setCurrentMultiplier(null);
			}
		}
	}, 500);

	const parentProduct = useMemo(() => {
		if (!product) {
			return null;
		}

		return product.parentId ? getById(product.parentId) : null;
	}, [product, getById]);

	const latestVisible = useLatest(visible);
	const latestCurrentMultiplier = useLatest(currentMultiplier);

	useBarcodeScanner((ean) => {
		if (latestVisible.current) {
			addByEan(ean, latestCurrentMultiplier.current);
			onCancel();
		}
	});

	const submit = useCallback(() => {
		const value = skuPopoverValue;
		if (value === '') {
			return;
		}

		if (currentMultiplier === 0) {
			return StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Неуспешно очитавање тежине са ваге. Проверите да ли је вага исправно повезана и конфигурисана.`,
			});
		}

		if (currentMultiplier <= 0) {
			return StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Количина не може бити мања од 0,001`,
			});
		}
		if (currentMultiplier > 99999999999999) {
			return StaticComponents.notification.error({
				message: t`Грешка`,
				description: `Количина не може бити већа од 99.999.999.999.999,00`,
			});
		}

		if (!product) {
			return StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Артикал са шифром ${value} не постоји`,
			});
		}

		onConfirm(product, currentMultiplier);
	}, [currentMultiplier, skuPopoverValue, product]);

	return (
		<Modal
			centered
			open={visible}
			width={900}
			title={t`Унесите шифру`}
			footer={null}
			onCancel={onCancel}
			destroyOnClose
		>
			<Space.Compact style={{ width: '100%' }}>
				<Input
					style={{ width: 'calc(100% - 100px)' }}
					ref={(skuInput) => {
						if (skuInput) {
							setTimeout(() => skuInput.focus());
						}
					}}
					onChange={(event) => {
						const { value } = event.target;
						setSkuPopoverValue(value);
					}}
					value={skuPopoverValue}
					onPressEnter={submit}
				/>

				<Button type="primary" onClick={submit} style={{ width: 100 }}>
					<Trans>Потврди</Trans>
				</Button>
			</Space.Compact>
			{/* Not variant */}
			{product && !product.parentId && (
				<List>
					<List.Item>
						<List.Item.Meta
							avatar={
								product.coverImage ? (
									<img
										className={styles.largeImage}
										src={product.coverImage.urls['64x64']}
										alt={product.name}
									/>
								) : (
									<NoImage size="large" name={product.name} />
								)
							}
							title={product.name}
							description={product.subtitle}
						></List.Item.Meta>
						{currentMultiplier === null && <div>---</div>}
						{currentMultiplier === 1 && (
							<div>{numberFormat(product.currentStorePrice, true)}</div>
						)}
						{currentMultiplier !== 1 && currentMultiplier !== null && (
							<div>
								{numberFormat(currentMultiplier, false, 3, false)} x{' '}
								{numberFormat(product.currentStorePrice, true)} ={' '}
								{numberFormat(
									evaluate('currentMultiplier * price', {
										currentMultiplier: bignumber(currentMultiplier),
										price: bignumber(product.currentStorePrice),
									}).toNumber(),
									true
								)}
							</div>
						)}
					</List.Item>
				</List>
			)}
			{product && product.parentId && (
				<List>
					<List.Item>
						<List.Item.Meta
							avatar={
								parentProduct.coverImage ? (
									<img
										className={styles.largeImage}
										src={parentProduct.coverImage.urls['64x64']}
										alt={parentProduct.name}
									/>
								) : (
									<div></div>
								)
							}
							title={
								<>
									{parentProduct.name}&nbsp;&nbsp;
									<Tag bordered={false} color="magenta">
										{product.variantName}
									</Tag>
								</>
							}
							description={parentProduct.subtitle}
						></List.Item.Meta>
						{currentMultiplier === null && <div>---</div>}
						{currentMultiplier === 1 && (
							<div>{numberFormat(product.currentStorePrice, true)}</div>
						)}
						{currentMultiplier !== 1 && currentMultiplier !== null && (
							<div>
								{numberFormat(currentMultiplier, false, 3, false)} x{' '}
								{numberFormat(product.currentStorePrice, true)} ={' '}
								{numberFormat(
									evaluate('currentMultiplier * currentStorePrice', {
										currentMultiplier: bignumber(currentMultiplier),
										currentStorePrice: bignumber(product.currentStorePrice),
									}).toNumber(),
									true
								)}
							</div>
						)}
					</List.Item>
				</List>
			)}
		</Modal>
	);
}

export default observer(SkuEntryModal);
