import { t } from '@lingui/macro';
import { Table, Tooltip, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import styles from './InvoiceItemsView.module.less';
import { getTaxFeeCategory } from '../../../constants/documentInvoice';
import numberFormat from '../../../lib/numberFormat';
import { InvoiceItem } from '../../../stores/Invoice.mobx';

interface Props {
	value?: InvoiceItem[];
}

function InvoiceItemsView({ value }: Props) {
	const TAX_FEE_CATEGORY = getTaxFeeCategory();
	if (!value) {
		return null;
	}

	return (
		<Table
			className={styles.table}
			dataSource={value}
			pagination={false}
			size="small"
			bordered
			scroll={{ x: 1100 }}
		>
			<Table.Column
				key="index"
				title="#"
				render={(value, record, index) => {
					return index + 1;
				}}
				width={60}
				align="right"
			/>
			<Table.Column
				key="name"
				dataIndex="name"
				title={t`Назив`}
				render={(text, record: InvoiceItem) => {
					return (
						<>
							<div>{text}</div>
							{record.description && record.description !== text && (
								<small>
									<Typography.Text type="secondary">
										{record.description}
									</Typography.Text>
								</small>
							)}
						</>
					);
				}}
			/>
			<Table.Column
				key="sellerItemIdentification"
				dataIndex="sellerItemIdentification"
				title={t`Шифра`}
				width={120}
			/>
			<Table.Column
				key="quantity"
				title={t`Количина`}
				width={100}
				align="right"
				render={(record) =>
					`${numberFormat(record.quantity, false, 3, false)} ${
						record.unit?.label || ''
					}`
				}
			/>
			<Table.Column
				key="unitPrice"
				dataIndex="unitPrice"
				title={t`Јединична цена`}
				width={150}
				align="right"
				render={(text, record: InvoiceItem) =>
					numberFormat(text, true, 2, true, record.currencyId)
				}
			/>
			<Table.Column
				key="discountPercentage"
				title={t`Попуст`}
				width={80}
				align="right"
				render={(record: InvoiceItem) =>
					record.allowances
						?.map(
							(allowance) =>
								`${numberFormat(allowance.percentage, false, 2, true)}%`
						)
						.join(', ') || '0%'
				}
			/>
			<Table.Column
				key="discountAmount"
				title={t`Износ попуста`}
				width={150}
				align="right"
				render={(record: InvoiceItem) =>
					numberFormat(
						record.allowances?.reduce(
							(acc, allowance) => acc + (allowance.amount || 0),
							0
						) || 0,
						true,
						2,
						true,
						record.currencyId
					)
				}
				responsive={['lg']}
			/>

			<Table.Column
				key="taxPercentage"
				dataIndex="taxPercentage"
				title={t`ПДВ`}
				width={90}
				align="right"
				render={(text, record: InvoiceItem) => (
					<>
						{numberFormat(text, false, 2, true)}%
						{record.taxCode !== 'S' && (
							<>
								{' '}
								<Tooltip title={TAX_FEE_CATEGORY[record.taxCode]}>
									<i className="fi fi-rr-info"></i>
								</Tooltip>
							</>
						)}
					</>
				)}
			/>

			<Table.Column
				key="totalAmount"
				dataIndex="totalAmount"
				title={t`Укупно`}
				width={170}
				align="right"
				render={(text, record: InvoiceItem) =>
					numberFormat(text, true, 2, true, record.currencyId)
				}
				fixed="right"
			/>
		</Table>
	);
}

export default observer(InvoiceItemsView);
