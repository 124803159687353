import { t, Trans } from '@lingui/macro';
import {
	Alert,
	Button,
	Form,
	Input,
	message,
	Modal,
	notification,
	Progress,
	Spin,
} from 'antd';
import { observer } from 'mobx-react-lite';
import React, {
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';

import { useDrawer } from '../../../../../../../../components/Page';
import { StaticComponents } from '../../../../../../../../components/StaticComponents';
import { Title } from '../../../../../../../../components/Title';
import config from '../../../../../../../../config.json';
import { v2Client } from '../../../../../../../../store/client';
import stores from '../../../../../../../../stores/index.mobx';
function ApplicationSettingsEfaktura(props, ref) {
	const [loading, setLoading] = useState(false);
	const [id, , close, ,] = useDrawer('marketplace-application-settings');
	const [currentApiKey, setCurrentApiKey] = useState('');

	const {
		users: { authenticatedUser },
		invoices: { isImporting, setImporting },
	} = stores;

	useEffect(() => {
		if (id) {
			setLoading(true);
			v2Client
				.get(`/marketplace-applications/installed/${id}`)
				.then((response) => {
					form.setFieldsValue({
						apiKey: response.data.credentials.apiKey,
					});
					setCurrentApiKey(response.data.credentials.apiKey);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [id]);

	const [form] = Form.useForm();
	useImperativeHandle(ref, () => ({
		save: () => {
			form.submit();
		},
	}));

	const startImport = useCallback(() => {
		v2Client.patch(`/marketplace-applications/${id}/import-invoices`);
		StaticComponents.notification.info({
			key: 'eFakturaSync',
			message: t`Увоз е-фактура`,
			description: (
				<span>
					<Trans>Увоз постојећих е-фактура је у току</Trans>
					<Progress percent={0} status="active" />
				</span>
			),
			duration: 0,
		});
		setImporting(true);
	}, [id]);

	const handleSubmit = async () => {
		const values = await form.validateFields();

		setLoading(true);
		try {
			const response = await v2Client.patch(
				`/marketplace-applications/${id}/settings`,
				{
					settings: {},
					credentials: values,
				}
			);

			if (response.data.hasInvoices && currentApiKey !== values.apiKey) {
				StaticComponents.modal.confirm({
					title: t`Подешавања су сачувана`,
					content: t`Подешавања су успешно сачувана, да ли желите да увезете постојеће е-фактуре?`,
					okText: t`Увези`,
					cancelText: t`Не`,
					onOk: async () => {
						startImport();
						close();
					},
					onCancel: () => {
						close();
					},
				});
			} else {
				close();
			}

			setCurrentApiKey(values.apiKey);
		} catch (error) {
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Дошло је до грешке приликом чувања подешавања`,
			});
		}
		setLoading(false);
	};
	return (
		<Spin spinning={loading}>
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				<Title>
					<Trans>Креденцијали</Trans>
				</Title>

				<Form.Item
					name="apiKey"
					label={t`АПИ кључ`}
					rules={[
						{
							required: true,
							message: t`Обавезан је унос АПИ кључа`,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Title>
					<Trans>Параметри за конфигурисање</Trans>
				</Title>
				<Form.Item
					label={t`Адреса за примање нотификација`}
					name="notificationUrl"
					initialValue={`${config.apiV2}/marketplace-applications/${id}/webhooks/${authenticatedUser.companyId}`}
				>
					<Input.TextArea readOnly onClick={(event) => event.target.select()} />
				</Form.Item>
				<Form.Item>
					<Alert
						// message="Napomena"
						description={t`Због начина на који функционишу нотификације на систему е-фактура, нове е-фактуре и измене постојећих, ћете почети да добијате тек од наредног дана (од поноћи). У међувремену, можете користити увоз постојећих е-фактура.`}
						type="warning"
					/>
				</Form.Item>
				<Title>
					<Trans>Акције</Trans>
				</Title>
				<Form.Item label={t`Увоз постојећих е-фактура`} name="import">
					<Button
						type="primary"
						onClick={startImport}
						disabled={
							isImporting || !currentApiKey || currentApiKey.length === 0
						}
						loading={isImporting}
					>
						<Trans>Увези е-фактуре</Trans>
					</Button>
				</Form.Item>
				<input type="submit" style={{ display: 'none' }} />
			</Form>
		</Spin>
	);
}

export default observer(ApplicationSettingsEfaktura, { forwardRef: true });
