import { t } from '@lingui/macro';
import { Button, Popconfirm } from 'antd';

import ActionSettings from './Actions/Settings';
import { CreatePage, useDrawer } from '../../../../../../components/Page';
import styles from '../../Marketplace.module.less';

function SettingsDrawerWrapper() {
	const [, , , , , SettingsDrawer] = useDrawer(
		'marketplace-application-settings'
	);

	return <SettingsDrawer />;
}

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'installed-applications',
		model: 'marketplaceApplications',
		view: {
			useEdit: true,
		},
		edit: {
			width: 800,
			title: {},
			fields: [],
		},
		page: {
			docsLink:
				'koriscenje/moduli-aplikacije/podesavanja/aplikacije/instalirane/',
			empty: {
				image: 'inbox',
				text: t`Нема инсталираних апликација`,
			},
			shouldFetch: false,
			table: {
				actions: [
					(record) => <ActionSettings record={record} />,
					(record) => (
						<Popconfirm
							placement="topRight"
							title={t`Да ли сте сигурни да желите да деинсталирате ову апликацију?`}
							onConfirm={() => {
								record?.uninstall();
							}}
							okText={t`Да`}
							cancelText={t`Не`}
						>
							<Button icon={<i className="fi fi-rr-trash"></i>} />
						</Popconfirm>
					),
				],
				columns: [
					{
						title: '',
						dataIndex: 'logoFile',
						key: 'logoFile',
						width: 50,
						render: (image) => {
							return image ? (
								<>
									<img
										src={image.urls['64x64']}
										alt=""
										className={styles.image}
									/>
								</>
							) : (
								<img
									src={'/images/icons/new/interface.svg'}
									alt=""
									className={styles.image}
								/>
							);
						},
					},
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
						shouldLink: false,
					},
					{
						title: t`Верзија`,
						dataIndex: 'version',
						key: 'version',
						shouldLink: false,
						width: 100,
					},
					{
						title: t`Аутор`,
						dataIndex: ['publisher', 'name'],
						key: 'publisher',
						shouldLink: false,
						width: 400,
						ellipsis: true,
					},
				],
				listProp: 'installedApplications',
			},
			additionalContent: [<SettingsDrawerWrapper />],
		},
	});
