import { computed, makeObservable, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';

const { Store, Entity } = CreateStore({
	name: 'warehouse',
	paginated: false,
	persistFields: ['all'],
});

export enum InventoryManagement {
	'purchaseValue' = 'purchaseValue',
	'salesValue' = 'salesValue',
}
export enum InventoryBookkeeping {
	'purchaseValue' = 'purchaseValue',
	'salesValue' = 'salesValue',
}

class Warehouse extends Entity {
	@observable name?: string;
	@observable address?: string;
	@observable phone?: string;
	@observable city?: string;
	@observable zip?: string;
	@observable gln?: string;
	@observable createDPU?: boolean;

	@observable inventoryManagement?: InventoryManagement;
	@observable inventoryManagementByValue?: boolean;
	@observable sellingPriceInput?: boolean;
	@observable inventoryBookkeeping?: InventoryBookkeeping;

	@observable accountStocks?: string;
	@observable accountPriceDifference?: string;
	@observable accountVat?: string;

	@computed
	get fullAddress() {
		const address = `${this.address || ''}, ${this.zip || ''} ${
			this.city || ''
		}`
			.replace(/  /g, ' ')
			.replace(/\n/g, ', ');

		return address === ', ' ? '' : address;
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Warehouses extends Store<Warehouse> {
	constructor() {
		super(Warehouse);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await this.fetchAll();
		}
	}
}

export { Warehouses, Warehouse };
