import './crypto';
import 'reflect-metadata';
import { t } from '@lingui/macro';
import isObject from 'lodash/isObject';
import mixin from 'lodash/mixin';
import transform from 'lodash/transform';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import { StaticComponents } from './components/StaticComponents';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

declare global {
	interface Window {
		electron: any;
	}
}

function deepMap(obj, iterator, context) {
	return transform(obj, (result, val, key) => {
		result[key] = isObject(val)
			? /* && !_.isDate(val) */ deepMap(val, iterator, context)
			: iterator.call(context, val, key, obj);
	});
}

mixin({
	deepMap,
});

// Wrap the rendering in a function:
const root = createRoot(document.getElementById('root'));
root.render(<App />);
serviceWorkerRegistration.register({
	onUpdate: (registration) => {
		const waitingServiceWorker = registration.waiting;

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', (event) => {
				if (event.target.state === 'activated') {
					StaticComponents.notification.info({
						message: t`Нова верзија је доступна`,
						description: t`Кликни овде да ажурираш апликацију`,
						key: 'update',
						duration: 0,
						onClick: () => {
							window.location.reload();
						},
					});
				}
			});
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
		}
	},
});
