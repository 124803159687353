import Bugsnag from '@bugsnag/browser';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Form, Input, Spin, Typography } from 'antd';
import sortBy from 'lodash/sortBy';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { AnanasItem } from './types';
import DisablePasswordAutocomplete from '../../../../../../../../components/DisablePasswordAutocomplete';
import { useDrawer } from '../../../../../../../../components/Page';
import { StaticComponents } from '../../../../../../../../components/StaticComponents';
import { Title } from '../../../../../../../../components/Title';
import { ERROR_BAD_REQUEST_INVALID_CREDENTIALS } from '../../../../../../../../constants/errors';
import { v2Client } from '../../../../../../../../store/client';
import ConnectProductsDrawer from '../../../../Drawers/ConnectProductsDrawer';

type Venue = {
	id: number;
	name: string;
	address: string;
};

interface Props {
	storeId: string;
}

function ApplicationSettingsAnanas({ storeId }, ref) {
	const [loading, setLoading] = useState(false);
	const [id, , close, ,] = useDrawer('marketplace-application-settings');
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const [clientId, setClientId] = useState('');

	const [areItemsLoading, setAreItemsLoading] = useState(false);
	const [items, setItems] = useState<AnanasItem[]>([]);

	const [isConnectProductsVisible, setIsConnectProductsVisible] =
		useState(false);

	const [form] = Form.useForm();

	useEffect(() => {
		if (id) {
			setLoading(true);
			v2Client
				.get(`/marketplace-applications/installed/${id}`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setClientId(response.data.credentials?.clientId);
					setIsLoggedIn(!!response.data.settings?.loggedIn);
					form.setFieldValue('venueId', response.data.settings?.venueId);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [id, storeId, form]);

	useImperativeHandle(ref, () => ({
		save: () => {
			form.submit();
		},
	}));

	const showConnectProductsDrawer = useCallback(
		async (alwaysOpen = true) => {
			setAreItemsLoading(true);
			try {
				const itemsResponse = await v2Client.get(
					`/marketplace-applications/${id}/functions/get-items`
				);

				// setItems(sortBy(itemsResponse.data, 'name.0.value'));
				setItems(sortBy(itemsResponse.data, 'name'));
				if (alwaysOpen || itemsResponse.data.find((item) => !item.product)) {
					setIsConnectProductsVisible(true);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setAreItemsLoading(false);
			}
		},
		[id]
	);

	const handleConnectProductsButtonClick = useCallback(() => {
		showConnectProductsDrawer(true);
	}, [showConnectProductsDrawer]);

	const handleSubmit = useCallback(async () => {
		const values = await form.validateFields();
		setLoading(true);
		try {
			const response = await v2Client.patch(
				`/marketplace-applications/${id}/settings`,
				{
					settings: {
						venueId: values.venueId,
					},
					credentials: {
						clientId: values.clientId,
						clientSecret: values.clientSecret,
					},
				}
			);

			setIsLoggedIn(!!response.data.settings?.loggedIn);

			StaticComponents.notification.success({
				message: t`Подешавања су успешно сачувана`,
			});
		} catch (error) {
			if (
				error?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_INVALID_CREDENTIALS
			) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`ИД клијента или тајни кључ нису исправни`,
				});
			}
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Дошло је до грешке приликом чувања подешавања`,
			});
		} finally {
			setLoading(false);
		}
	}, [form, id]);

	const closeConnectProductsDrawer = useCallback(() => {
		setIsConnectProductsVisible(false);
	}, [setIsConnectProductsVisible]);

	const formatItem = useCallback(
		(item) => ({
			externalId: item.id,
			name: item.product?.name || item.name,
			description: item.product?.description || item.description,
			productId: item.product?.productId,
			productName: item.product?.product?.parent
				? `${item.product?.product?.parent?.name} ${item.product?.product?.name}`
				: item.product?.product?.name,
			salePrice: item.product?.salePrice,
			saleQuantity: item.product?.saleQuantity,
			active: item.active,
			metadata: {
				...(item.metadata || {}),
				groupId: item.metadata?.groupId || item.groupId,
			},
			imageUrl: item.imageUrl,
			price: item.displayPrice,
			product: item.product,
		}),
		[]
	);

	return (
		<Spin spinning={loading}>
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				<Title>
					<Trans>Креденцијали</Trans>
				</Title>
				<DisablePasswordAutocomplete />
				{!isLoggedIn && (
					<>
						<Form.Item
							name="clientId"
							label={t`ИД клијента`}
							rules={[
								{
									required: true,
									message: t`Обавезан је унос ИД клијента`,
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="clientSecret"
							label={t`Тајни кључ`}
							rules={[
								{
									required: true,
									message: t`Обавезан је унос тајног кључа`,
								},
							]}
						>
							<Input.Password />
						</Form.Item>
					</>
				)}
				{isLoggedIn && (
					<>
						<Form.Item>
							<Alert
								type="info"
								message={
									<Trans>
										Пријављени сте са ИД клијента <strong>{clientId}</strong>.{' '}
										<Typography.Link onClick={() => setIsLoggedIn(false)}>
											Кликните овде
										</Typography.Link>{' '}
										да промените креденцијале.
									</Trans>
								}
							/>
						</Form.Item>
						<Title>
							<Trans>Администрација</Trans>
						</Title>
						<Form.Item label={t`Повезивање артикала`}>
							<Typography.Text type="secondary">
								<Trans>
									Уколико већ имате артикле на платформи Ananas, можете их
									повезати са артиклима на вашем продајном месту.
								</Trans>
							</Typography.Text>
							<br />
							<br />
							<Button
								loading={areItemsLoading}
								onClick={handleConnectProductsButtonClick}
							>
								<Trans>Повежи артикле</Trans>
							</Button>
						</Form.Item>
					</>
				)}

				<input type="submit" style={{ display: 'none' }} />
			</Form>
			<ConnectProductsDrawer
				visible={isConnectProductsVisible}
				items={items}
				onClose={closeConnectProductsDrawer}
				formatItem={formatItem}
				categoryProperty="groupId"
				enabledProperty="active"
				platformName="Ananas"
			/>
		</Spin>
	);
}

export default observer(ApplicationSettingsAnanas, { forwardRef: true });
