import { t, Trans } from '@lingui/macro';
import { Form, Modal } from 'antd';

import TaxRateSelect from '../../../../../../../components/TaxRateSelect';
import stores from '../../../../../../../stores/index.mobx';

interface Props {
	visible: boolean;
	newStatus: boolean;
	onCancel: () => void;
	onOk: (string) => void;
}

export default function VATChangeModal({
	visible,
	newStatus,
	onCancel,
	onOk,
}: Props) {
	const [form] = Form.useForm();

	const {
		taxRates: { noVat: noVatList },
	} = stores;

	const noVatRate = noVatList[0];

	async function submitForm() {
		try {
			const values = await form.validateFields();
			onOk(values.taxRateLabel || noVatRate.label);
		} catch (error) {
			return;
		}
	}

	return (
		<Modal
			centered
			open={visible}
			title={t`Промена ПДВ статуса`}
			closable={false}
			onCancel={onCancel}
			onOk={() => {
				form.submit();
			}}
		>
			<Form form={form} onFinish={submitForm}>
				{newStatus && (
					<>
						<p>
							<Trans>
								Уласком у систем ПДВ-а, свим постојећим артиклима је потребно
								доделити нову пореску ознаку. Одаберите нову пореску ознаку:
							</Trans>
						</p>
						<Form.Item
							name="taxRateLabel"
							extra={t`Напомена: одабрана пореска ознака можда неће одговарати свим артиклима, па ћете неким артиклима морати ручно да доделите исправну пореску ознаку. `}
							rules={[
								{
									required: true,
									message: t`Морате одабрати пореску ознаку`,
								},
							]}
						>
							<TaxRateSelect style={{ width: '100%' }} vat={true} />
						</Form.Item>
					</>
				)}
				{!newStatus && (
					<>
						<p>
							<Trans>
								Изласком из система ПДВ-а, свим артиклима ће се пореска ознака
								променити на пореску ознаку
							</Trans>{' '}
							<strong>
								{noVatRate.name} - {noVatRate.label}
							</strong>
							. <Trans>Да ли сте сигурни да желите да наставите?</Trans>
						</p>
					</>
				)}
			</Form>
		</Modal>
	);
}
