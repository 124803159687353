import { Input } from 'antd';
import { useRef } from 'react';
import { useKey } from 'react-use';
import { StringParam, useQueryParam } from 'use-query-params';

type Props = {
	searchPlaceholder?: string;
};

export function SearchInput({ searchPlaceholder = t`Претражи` }) {
	const searchInputRef = useRef(null);
	const [searchQueryParam, setSearchQueryParam] = useQueryParam(
		'search',
		StringParam
	);

	useKey(
		(e) => {
			return e.key === 'f3' || ((e.ctrlKey || e.metaKey) && e.key === 'f');
		},
		(e) => {
			if (searchInputRef.current) {
				searchInputRef.current.focus();
				e.preventDefault();
			}
		},
		undefined,
		[]
	);
	return (
		<Input
			prefix={<i className="fi fi-rr-search"></i>}
			onChange={(e) => setSearchQueryParam(e.target.value)}
			placeholder={searchPlaceholder}
			value={searchQueryParam}
			ref={searchInputRef}
		/>
	);
}
