import { t } from '@lingui/macro';

export type DocumentType =
	| 'invoice'
	| 'creditNote'
	| 'debitNote'
	| 'advanceInvoice';

export const DOCUMENT_TYPE = {
	INVOICE: 'invoice',
	CREDIT_NOTE: 'creditNote',
	DEBIT_NOTE: 'debit_note',
	ADVANCE_INVOICE: 'advanceInvoice',
};

export const DOCUMENT_TAX_INVOICE_PERIOD = {
	ISSUE_DATE: 'issueDate',
	PAYMENT_DATE: 'dueDate',
	DELIVERY_DATE: 'deliveryDate',
};

export const getDocumentTaxInvoicePeriodMap = () => ({
	[DOCUMENT_TAX_INVOICE_PERIOD.ISSUE_DATE]: t`Датум слања фактуре`,
	[DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE]: t`ПДВ се обрачунава на дан плаћања`,
	[DOCUMENT_TAX_INVOICE_PERIOD.DELIVERY_DATE]: t`Датум промета`,
});

export const DOCUMENT_CREDIT_DEBIT_NOTE_TYPE = {
	SINGLE_INVOICE: 'singleInvoice',
	PERIOD: 'period',
};

export const getDocumentCreditDebitNoteTypeMap = () => ({
	[DOCUMENT_CREDIT_DEBIT_NOTE_TYPE.SINGLE_INVOICE]: t`Појединачну фактуру`,
	[DOCUMENT_CREDIT_DEBIT_NOTE_TYPE.PERIOD]: t`Фактуре у периоду`,
});

export const getDocumentTypeMap = () => ({
	[DOCUMENT_TYPE.INVOICE]: t`Фактура`,
	[DOCUMENT_TYPE.CREDIT_NOTE]: t`Књижно одобрење`,
	[DOCUMENT_TYPE.DEBIT_NOTE]: t`Књижно задужење`,
	[DOCUMENT_TYPE.ADVANCE_INVOICE]: t`Авансни рачун`,
});

export const getEfakturaErrorCode = () => ({
	UBLUploadRequestIdDuplicate: t`Овај документ је већ послат`,
	EInvoiceNumberDublicate: t`Број документа је већ у употреби`,
	IssueDateCannotBeDifferentFromTodays: t`Датум документа мора бити данашњи датум`,
});

export const getTaxFeeCategory = () => ({
	S: t`Стандардна стопа`,
	AE: t`Обрнуто обрачунавање ПДВ`,
	O: t`Није предмет опорезивања ПДВ`,
	E: t`Пореско ослобођење без права на одбитак претходног пореза`,
	R: t`Изузимање од ПДВ`,
	Z: t`Пореско ослобођење са правом на одбитак претходног пореза`,
	SS: t`Посебни поступци опорезивања`,
	OE: t`Није предмет опорезивања ПДВ 2`,
	N: t`Анулирање`,
});

export const TAXCORE_INVOICE_TAX_CATEGORY_MAPPING =
	window.location.hostname === `localhost` ||
	window.location.hostname.includes('sandbox')
		? {
				A: 'S',
				Ж: 'S',
				B: 'O',
				N: 'SS',
		  }
		: {
				Ђ: 'S',
				Е: 'S',
				Г: 'O',
				А: 'SS',
		  };

export const TAX_LABEL_INVOICE_MAPPING = {
	Ђ: 20,
	Е: 10,
	Ж: 20,
	A: 10,
};

export const INVOICE_STATUS = {
	NEW: 'new',
	DRAFT: 'draft',
	SEEN: 'seen',
	SENT: 'sent',
	PAID: 'paid',
	MISTAKE: 'mistake',
	OVERDUE: 'overdue',
	ARCHIVED: 'archived',
	SENDING: 'sending',
	DELETED: 'deleted',
	APPROVED: 'approved',
	REJECTED: 'rejected',
	CANCELLED: 'cancelled',
	STORNO: 'storno',
	UNKNOWN: 'unknown',
};

export const getInvoiceStatusMap = () => ({
	[INVOICE_STATUS.NEW]: t`Нови`,
	[INVOICE_STATUS.SEEN]: t`Виђен`,
	[INVOICE_STATUS.DRAFT]: t`Нацрт`,
	[INVOICE_STATUS.SENT]: t`Послат`,
	[INVOICE_STATUS.PAID]: t`Плаћен`,
	[INVOICE_STATUS.MISTAKE]: t`Грешка`,
	[INVOICE_STATUS.OVERDUE]: t`Доспео`,
	[INVOICE_STATUS.ARCHIVED]: t`Архивиран`,
	[INVOICE_STATUS.SENDING]: t`Слање`,
	[INVOICE_STATUS.DELETED]: t`Обрисан`,
	[INVOICE_STATUS.APPROVED]: t`Прихваћен`,
	[INVOICE_STATUS.REJECTED]: t`Одбијен`,
	[INVOICE_STATUS.CANCELLED]: t`Отказан`,
	[INVOICE_STATUS.STORNO]: t`Сторниран`,
	[INVOICE_STATUS.UNKNOWN]: t`Непознато`,
});

export const getTaxExemptionCode = () => ({
	// AE
	'PDV-RS-10-2-1': `Закон о Порезу на додату вредност: Порески дужник је прималац добара или услуга, обвезник ПДВ, за промет секундарних сировина и услуга које су непосредно повезане са тим добрима, извршен од стране другог обвезника ПДВ`,
	'PDV-RS-10-2-2': `Закон о Порезу на додату вредност: Порески дужник је прималац добара, обвезник ПДВ, за промет грађевинских објеката и економски дељивих целина у оквиру тих објеката, укључујући и власничке уделе на тим добрима, извршен од стране другог обвезника ПДВ, у случају када је уговором на основу којег се врши промет тих добара предвиђено да ће се на тај промет обрачунати ПДВ у складу са овим законом`,
	'PDV-RS-10-2-3': `Закон о Порезу на додату вредност: Порески дужник је прималац добара и услуга из области грађевинарства, обвезник ПДВ, односно лице из члана 9. став 1. овог закона, за промет извршен од стране обвезника ПДВ, ако је вредност тог промета већа од 500.000 динара, без ПДВ`,
	'PDV-RS-10-2-4': `Закон о Порезу на додату вредност: Порески дужник је прималац електричне енергије и природног гаса који се испоручују преко преносне, транспортне и дистрибутивне мреже, обвезник ПДВ који је ова добра набавио ради даље продаје, за промет електричне енергије и природног гаса извршен од стране другог обвезника ПДВ`,
	'PDV-RS-10-2-5-1': `Закон о Порезу на додату вредност: Порески дужник је прималац добара или услуга, обвезник ПДВ, за промет извршен од стране другог обвезника ПДВ, и то код промета хипотековане непокретности код реализације хипотеке у складу са законом којим се уређује хипотека`,
	'PDV-RS-10-2-5-2': `Закон о Порезу на додату вредност: Порески дужник је прималац добара или услуга, обвезник ПДВ, за промет извршен од стране другог обвезника ПДВ, и то код промета предмета заложног права код реализације уговора о залози у складу са законом којим се уређује заложно право на покретним стварима`,
	'PDV-RS-10-2-5-3': `Закон о Порезу на додату вредност: Порески дужник је прималац добара или услуга, обвезник ПДВ, за промет извршен од стране другог обвезника ПДВ, и то код промета добара или услуга над којима се спроводи извршење у извршном поступку у складу са законом`,
	'PDV-RS-36b-6': `Закон о Порезу на додату вредност: Порески дужник за промет инвестиционог злата из става 5. овог члана је обвезник ПДВ којем је тај промет извршен`,
	// O
	'PDV-RS-11-1-1': `Закон о Порезу на додату вредност: Место промета добара је иностранство - место у којем се добро налази у тренутку слања или превоза до примаоца или, по његовом налогу, до трећег лица, ако добро шаље или превози испоручилац, прималац или треће лице, по његовом налогу`,
	'PDV-RS-11-1-2': `Закон о Порезу на додату вредност: Место промета добара је иностранство - место уградње или монтаже добра, ако се оно уграђује или монтира од стране испоручиоца или, по његовом налогу, од стране трећег лица`,
	'PDV-RS-11-1-3': `Закон о Порезу на додату вредност: Место промета добара је иностранство - место у којем се добро налази у тренутку испоруке, ако се добро испоручује без отпреме, односно превоза`,
	'PDV-RS-11-1-4': `Закон о Порезу на додату вредност: Место промета добара је иностранство - место у којем прималац електричне енергије, природног гаса и енергије за грејање, односно хлађење, чија се испорука врши преко преносне, транспортне и дистрибутивне мреже, а који је ова добра набавио ради даље продаје, има седиште или сталну пословну јединицу којима се добра испоручују`,
	'PDV-RS-11-1-5': `Закон о Порезу на додату вредност: Место промета добара је иностранство - место пријема воде, електричне енергије, природног гаса и енергије за грејање, односно хлађење, за крајњу потрошњу`,
	'PDV-RS-11-3': `Закон о Порезу на додату вредност: Изузетно од става 1. тачка 3) овог члана, ако се промет добара врши на броду, односно у летилици или возу у току превоза путника, местом промета сматра се место поласка брода, летилице или воза, које се налази у иностранству`,
	'PDV-RS-12-4': `Закон о Порезу на додату вредност: Ако се промет услуга врши пореском обвезнику, местом промета услуга сматра се место у иностранству у којем прималац услуга има седиште или сталну пословну јединицу ако се промет услуга врши сталној пословној јединици која се не налази у месту у којем прималац услуга има седиште, односно место у иностранству у којем прималац услуга има пребивалиште или боравиште`,
	'PDV-RS-12-6-1': `Закон о Порезу на додату вредност: Местом промета услуга у вези са непокретностима, укључујући и услуге посредовања код промета непокретности, сматра се место у иностранству у којем се налази непокретност`,
	'PDV-RS-12-6-2': `Закон о Порезу на додату вредност: Местом промета услуга превоза лица, сматра се место где се обавља превоз, а ако се превоз обавља и у Републици и у иностранству, одредбе овог закона примењују се само на део превоза извршен у Републици - део услуге превоза који се врши у иностранству`,
	'PDV-RS-12-6-3': `Закон о Порезу на додату вредност: Местом промета услуга превоза добара која се пружа лицу које није порески обвезник, сматра се место где се обавља превоз, а ако се превоз обавља и у Републици и у иностранству, одредбе овог закона примењују се само на део превоза извршен у Републици - део услуге превоза који се врши у иностранству`,
	'PDV-RS-12-6-4-1': `Закон о Порезу на додату вредност: Местом промета услуга сматра се место где су услуге стварно пружене, ако се ради о услугама које се односе на присуствовање културним, уметничким, спортским, научним, образовним, забавним или сличним догађајима (сајмови, изложбе и др.), укључујући и помоћне услуге у вези са присуствовањем тим догађајима, чије је место промета у иностранству`,
	'PDV-RS-12-6-4-2': `Закон о Порезу на додату вредност: Местом промета услуга сматра се место где су услуге стварно пружене, ако се ради о услугама организатора догађаја из подтачке (1) ове тачке, пружених лицу које није порески обвезник, чије је место промета у иностранству`,
	'PDV-RS-12-6-4-3': `Закон о Порезу на додату вредност: Местом промета услуга сматра се место где су услуге стварно пружене, ако се ради о помоћним услугама у вези са превозом, као што су утовар, истовар, претовар и слично, пружених лицу које није порески обвезник, чије је место промета у иностранству`,
	'PDV-RS-12-6-4-4': `Закон о Порезу на додату вредност: Местом промета услуга сматра се место где су услуге стварно пружене, ако се ради о услугама процене покретних ствари, односно радова на покретним стварима пружених лицу које није порески обвезник, чије је место промета у иностранству`,
	'PDV-RS-12-6-4-5': `Закон о Порезу на додату вредност: Местом промета услуга сматра се место где су услуге стварно пружене, ако се ради о услугама предаје јела и пића за конзумацију на лицу места, чије је место промета у иностранству`,
	'PDV-RS-12-6-5': `Закон о Порезу на додату вредност: Местом промета услуга изнајмљивања превозних средстава на краћи временски период, сматра се место у иностранству у којем се превозно средство стварно ставља на коришћење примаоцу услуге`,
	'PDV-RS-12-6-6': `Закон о Порезу на додату вредност: Местом промета услуга изнајмљивања превозних средстава, осим из тачке 5) овог става, пружених лицу које није порески обвезник, сматра се место у иностранству у којем то лице има седиште, пребивалиште или боравиште`,
	'PDV-RS-12-6-7-1': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама преноса, уступања и давања на коришћење ауторских и сродних права, права на патенте, лиценце, заштитне знакове и друга права интелектуалне својине`,
	'PDV-RS-12-6-7-2': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама оглашавања`,
	'PDV-RS-12-6-7-3': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама саветника, инжењера, адвоката, ревизора и сличних услуга, као и преводилаца за услуге превођења, укључујући и превођење у писаном облику`,
	'PDV-RS-12-6-7-4': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама обраде података и уступања, односно давања информација, укључујући и информације о пословним поступцима и искуству`,
	'PDV-RS-12-6-7-5': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама преузимања обавезе да се у потпуности или делимично одустане од обављања неке делатности или од коришћења неког права из ове тачке`,
	'PDV-RS-12-6-7-6': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама банкарског и финансијског пословања и пословања у области осигурања, укључујући реосигурање, осим изнајмљивања сефова`,
	'PDV-RS-12-6-7-7': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама стављања на располагање особља`,
	'PDV-RS-12-6-7-8': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама изнајмљивања покретних ствари, осим превозних средстава`,
	'PDV-RS-12-6-7-9': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама омогућавања приступа мрежи природног гаса, мрежи за пренос електричне енергије и мрежи за грејање, односно хлађење, транспорта и дистрибуције путем тих мрежа, као и других услуга које су непосредно повезане са тим услугама`,
	'PDV-RS-12-6-7-10': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама телекомуникација`,
	'PDV-RS-12-6-7-11': `Закон о Порезу на додату вредност: Местом промета услуга које се пружају лицу које није порески обвезник, сматра се место седишта, пребивалишта или боравишта примаоца услуга, које се налази у иностранству, ако се ради о услугама радијског и телевизијског емитовања`,
	'PDV-RS-12-6-7-12': `Закон о Порезу на додату вредност: Местом промета услуга посредовања код промета добара или услуга која се пружа лицу које није порески обвезник, сматра се место у којем је извршен промет добара или услуга који је предмет посредовања, које је у иностранству`,
	'PDV-RS-12-9': `Закон о Порезу на додату вредност: Изузетно од става 6. тачка 4) подтачка (5) овог члана, ако се услуге предаје јела и пића за конзумацију на лицу места фактички пружају на броду, односно у летилици или возу у току превоза путника, местом промета сматра се место поласка брода, летилице или воза, које је у иностранству.`,
	'PDV-RS-61': `Закон о порезу на додату вредност: Промет добара са територије Републике ван АПКМ на територију АПКМ`,
	// E
	'PDV-RS-25-1-1': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза у промету новца и капитала, и то код пословања и посредовања у пословању законским средствима плаћања, осим папирног и кованог новца који се не користи као законско средство плаћања или има нумизматичку вредност`,
	'PDV-RS-25-1-1a': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза у промету новца и капитала, и то код преноса виртуелних валута и замене виртуелних валута за новчана средства у складу са законом којим се уређује дигитална имовина`,
	'PDV-RS-25-1-2': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза у промету новца и капитала, и то код пословања и посредовања у пословању акцијама, уделима у друштвима и удружењима, обвезницама и другим хартијама од вредности, осим пословања које се односи на чување и управљање хартијама од вредности`,
	'PDV-RS-25-1-3': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза у промету новца и капитала, и то код кредитних послова, укључујући посредовање, као и новчаних позајмица`,
	'PDV-RS-25-1-4': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза у промету новца и капитала, и то код преузимања обавеза, гаранција и других средстава обезбеђења, укључујући посредовање`,
	'PDV-RS-25-1-5': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза у промету новца и капитала, и то код пословања и посредовања у пословању депозитима, текућим и жиро рачунима, налозима за плаћање, као и платним прометом и дознакама`,
	'PDV-RS-25-1-6': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза у промету новца и капитала, и то код пословања и посредовања у пословању новчаним потраживањима, чековима, меницама и другим сличним хартијама од вредности, осим наплате потраживања за друга лица`,
	'PDV-RS-25-1-7': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза у промету новца и капитала, и то код пословања друштава за управљање инвестиционим фондовима у складу са прописима којима се уређују инвестициони фондови`,
	'PDV-RS-25-1-8': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза у промету новца и капитала, и то код пословања друштава за управљање добровољним пензијским фондовима у складу са прописима којима се уређују добровољни пензијски фондови и пензијски планови`,
	'PDV-RS-25-2-1': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга осигурања и реосигурања, укључујући пратеће услуге посредника и агента (заступника) у осигурању`,
	'PDV-RS-25-2-2': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет земљишта, као и на давање у закуп тог земљишта`,
	'PDV-RS-25-2-3': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет објеката, осим првог преноса права располагања на новоизграђеним грађевинским објектима или економски дељивим целинама у оквиру тих објеката и првог преноса власничког удела на новоизграђеним грађевинским објектима или економски дељивим целинама у оквиру тих објеката, као и промета објеката и економски дељивих целина у оквиру тих објеката, укључујући и власничке уделе на тим добрима, у случају када је уговором на основу којег се врши промет тих добара, закљученим између обвезника ПДВ, предвиђено да ће се на тај промет обрачунати ПДВ, под условом да стицалац обрачунати ПДВ може у потпуности одбити као претходни порез`,
	'PDV-RS-25-2-3a': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет добара и услуга за које при набавци обвезник није имао право на одбитак претходног пореза`,
	'PDV-RS-25-2-3b': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет добара за која је у претходној фази промета постојала обавеза плаћања пореза у складу са законом којим се уређују порези на имовину`,
	'PDV-RS-25-2-5': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет удела, хартија од вредности, поштанских вредносница, таксених и других важећих вредносница по њиховој утиснутој вредности у Републици, осим власничких удела из члана 4. овог закона`,
	'PDV-RS-25-2-6': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет поштанских услуга од стране јавног предузећа, као и са њима повезаних испорука добара`,
	'PDV-RS-25-2-7': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга које пружају здравствене установе у складу са прописима који регулишу здравствену заштиту, укључујући и смештај, негу и исхрану болесника у тим установама, осим апотека и апотекарских установа`,
	'PDV-RS-25-2-8': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга које пружају лекари, стоматолози или друга лица у складу са прописима који регулишу здравствену заштиту`,
	'PDV-RS-25-2-9': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга и испоруке зубне протетике у оквиру делатности зубног техничара, као и испорука зубне протетике од стране стоматолога`,
	'PDV-RS-25-2-10': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет људских органа, ткива, телесних течности и ћелија, крви и мајчиног млека`,
	'PDV-RS-25-2-11': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга социјалног старања и заштите, дечје заштите и заштите младих, услуга установа социјалне заштите, као и са њима непосредно повезаног промета добара и услуга од стране лица регистрованих за обављање тих делатности`,
	'PDV-RS-25-2-12': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга смештаја и исхране ученика и студената у школским и студентским домовима или сличним установама, као и са њима непосредно повезан промет добара и услуга`,
	'PDV-RS-25-2-13': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга образовања (предшколско, основно, средње, више и високо) и професионалне преквалификације, као и са њима непосредно повезаног промета добара и услуга од стране лица регистрованих за обављање тих делатности, ако се ове делатности обављају у складу са прописима који уређују ту област`,
	'PDV-RS-25-2-14': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга из области културе и са њима непосредно повезаног промета добара и услуга, од стране лица чија делатност није усмерена ка остваривању добити, а која су регистрована за ту делатност`,
	'PDV-RS-25-2-15': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга из области науке и са њима непосредно повезаног промета добара и услуга, од стране лица чија делатност није усмерена ка остваривању добити, а која су регистрована за ту делатност`,
	'PDV-RS-25-2-16': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга верског карактера од стране регистрованих цркава и верских заједница и са њима непосредно повезаног промета добара и услуга`,
	'PDV-RS-25-2-17': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга јавног радиодифузног сервиса, осим услуга комерцијалног карактера`,
	'PDV-RS-25-2-18': `Закон о Порезу на додату вредност: Пореско ослобођење без права на одбитак претходног пореза за промет услуга приређивања игара на срећу`,
	// R
	'PDV-RS-6-1-1': `Закон о порезу на додату вредност: Сматра се да промет добара и услуга није извршен код преноса целокупне или дела имовине, са или без накнаде, или као улог, ако је стицалац порески обвезник или тим преносом постане порески обвезник и ако продужи да обавља исту делатност`,
	'PDV-RS-6a': `Закон о порезу на додату вредност: Сматра се да, у смислу овог закона, промет добара и услуга који врши давалац концесије концесионару, односно концесионар даваоцу концесије у оквиру реализације уговора о јавно-приватном партнерству са елементима концесије, закљученог у складу са законом којим се уређују јавно-приватно партнерство и концесије, није извршен, ако су давалац концесије и концесионар обвезници ПДВ који би, у случају када би се тај промет сматрао извршеним, имали у потпуности право на одбитак претходног пореза у складу са овим законом`,
	// Z
	'PDV-RS-24-1-1': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходнок пореза за превозне и остале услуге, које су повезане са увозом добара, ако је вредност тих услуга садржана у основици из члана 19. став 2. овог закона`,
	'PDV-RS-24-1-2': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара која обвезник или треће лице, по његовом налогу, шаље или отпрема у иностранство`,
	'PDV-RS-24-1-3': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара која инострани прималац или треће лице, по његовом налогу, шаље или отпрема у иностранство`,
	'PDV-RS-24-1-5': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за унос добара у слободну зону, превозне и друге услуге корисницима слободних зона које су непосредно повезане са тим уносом и промет добара и услуга у слободној зони, за које би обвезник - корисник слободне зоне имао право на одбитак претходног пореза када би та добра или услуге набављао за потребе обављања делатности ван слободне зоне`,
	'PDV-RS-24-1-5a': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара која се уносе у слободну зону, превозне и друге услуге које су непосредно повезане са тим уносом и промет добара у слободној зони, који се врши страном лицу које има закључен уговор са обвезником ПДВ - корисником слободне зоне да та добра угради у добра намењена отпремању у иностранство`,
	'PDV-RS-24-1-6': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара која су у поступку царинског складиштења`,
	'PDV-RS-24-1-6a': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за отпремање добара у слободне царинске продавнице отворене на ваздухопловним пристаништима отвореним за међународни саобраћај на којима је организована пасошка и царинска контрола ради продаје путницима у складу са царинским прописима, као и на испоруку добара из слободних царинских продавница`,
	'PDV-RS-24-1-7': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за услуге радова на покретним добрима набављеним од стране иностраног примаоца услуге у Републици, или која су увезена ради оплемењивања, оправке или уградње, а која после оплемењивања, оправке или уградње, испоручилац услуге, инострани прималац или треће лице, по њиховом налогу, превози или отпрема у иностранство`,
	'PDV-RS-24-1-7a': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара која су у поступку активног оплемењивања за која би обвезник - стицалац имао право на одбитак претходног пореза када би та добра набављао са обрачунатим ПДВ`,
	'PDV-RS-24-1-8': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за превозне и остале услуге које су у вези са извозом, транзитом или привременим увозом добара, осим услуга које су ослобођене од ПДВ без права на порески одбитак у складу са овим законом`,
	'PDV-RS-24-1-9': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за услуге међународног превоза лица у ваздушном саобраћају, с тим што за нерезидентно ваздухопловно предузеће пореско ослобођење важи само у случају узајамности`,
	'PDV-RS-24-1-10': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за испоруке летилица, сервисирање, поправке, одржавање, чартерисање и изнајмљивање летилица, које се претежно користе уз накнаду у међународном ваздушном саобраћају, као и испоруке, изнајмљивање, поправке и одржавање добара намењених опремању тих летилица`,
	'PDV-RS-24-1-11': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара и услуга намењених непосредним потребама летилица из тачке 10) овог става`,
	'PDV-RS-24-1-12': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за услуге међународног превоза лица бродовима у речном саобраћају, с тим што за нерезидентно предузеће које врши међународни превоз лица бродовима у речном саобраћају, пореско ослобођење важи само у случају узајамности`,
	'PDV-RS-24-1-13': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за испоруке бродова, сервисирање, поправке, одржавање и изнајмљивање бродова, који се претежно користе уз накнаду у међународном речном саобраћају, као и испоруке, изнајмљивање, поправке и одржавање добара намењених опремању тих бродова`,
	'PDV-RS-24-1-14': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара и услуга намењених непосредним потребама бродова из тачке 13) овог става`,
	'PDV-RS-24-1-15': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за испоруке злата Народној банци Србије`,
	'PDV-RS-24-1-16-1': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за добра и услуге намењене за службене потребе дипломатских и конзуларних представништава`,
	'PDV-RS-24-1-16-2': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за добра и услуге намењене за службене потребе међународних организација, ако је то предвиђено међународним уговором`,
	'PDV-RS-24-1-16-3': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за добра и услуге намењене за личне потребе страног особља дипломатских и конзуларних представништава, укључујући и чланове њихових породица`,
	'PDV-RS-24-1-16-4': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за добра и услуге намењене за личне потребе страног особља међународних организација, укључујући чланове њихових породица, ако је то предвиђено међународним уговором`,
	'PDV-RS-24-1-16a': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара и услуга који се врши у складу са уговорима о донацији закљученим са државном заједницом Србија и Црна Гора, односно Републиком, ако је тим уговором предвиђено да се из добијених новчаних средстава неће плаћати трошкови пореза, у делу који се финансира добијеним новчаним средствима осим ако ратификованим међународним уговором није друкчије предвиђено`,
	'PDV-RS-24-1-16b': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара и услуга који се врши у складу са уговорима о кредиту, односно зајму, закљученим између државне заједнице Србија и Црна Гора, односно Републике и међународне финансијске организације, односно друге државе, као и између треће стране и међународне финансијске организације, односно друге државе у којем се Република Србија појављује као гарант, односно контрагарант, у делу који се финансира добијеним новчаним средствима, ако је тим уговорима предвиђено да се из добијених новчаних средстава неће плаћати трошкови пореза`,
	'PDV-RS-24-1-16v': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара и услуга који се врши на основу међународних уговора, ако је тим уговорима предвиђено пореско ослобођење, осим међународних уговора из тач. 16а) и 16б) овог става`,
	'PDV-RS-24-1-16g': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за промет добара и услуга који се врши у оквиру реализације инфраструктурних пројеката изградње аутопутева за које је посебним законом утврђен јавни интерес`,
	'PDV-RS-24-1-17': `Закон о порезу на додату вредност: Пореско ослобођење са правом на одбитак претходног пореза за услуге посредовања које се односе на промет добара и услуга из тач. 1) - 16) овог става`,
	// SS
	'PDV-RS-33': `Закон о порезу на додату вредност: Промет добара и услуга који врши мали обвезник`,
	'PDV-RS-35-7': `Закон о порезу на додату вредност: Туристичка агенција за туристичке услуге из става 1. овог члана не може да исказује ПДВ у рачунима или другим документима и нема право на одбитак претходног пореза на основу претходних туристичких услуга које су јој исказане у рачуну`,
	'PDV-RS-36-5': `Закон о порезу на додату вредност: Код промета добара из става 1. овог члана на који се примењује опорезивање разлике, обвезник не може исказивати ПДВ у рачунима или другим документима`,
	'PDV-RS-36b-4': `Закон о порезу на додату вредност: ПДВ се не плаћа на:\n1) промет и увоз инвестиционог злата, укључујући и инвестиционо злато чија је вредност наведена у потврдама о алоцираном или неалоцираном злату, злато којим се тргује преко рачуна за трговање златом, укључујући и зајмове и замене злата(своп послови) који подразумевају право власништва или потраживања у вези са златом, као и активности у вези са инвестиционим златом на основу фјучерс и форвард уговора чији је резултат пренос права располагања или права потраживања у вези са инвестиционим златом;\n2) промет услуга посредника који у име и за рачун налогодавца врши промет инвестиционог злата.`,
	// OE
	'PDV-RS-17-4-2': `Закон о порезу на додату вредност: Основица не садржи износе које обвезник наплаћује у име и за рачун другог, ако те износе преноси лицу у чије име и за чији рачун је извршио наплату и ако су ти износи посебно евидентирани`,
	'PDV-RS-17-4-3': `Закон о порезу на додату вредност: Основица не садржи износе које обвезник потражује за издатке које је платио у име и за рачун примаоца добара или услуга, ако су ти износи посебно евидентирани`,
	'PDV-RS-9': `Закон о порезу на додату вредност: У циљу обављања послова за које лице није порески обвезник из члана 9. став 1. ЗПДВ`,
	'PDV-RS-7a-4': `Закон о порезу на додату вредност: Вишенаменски вредносни ваучер`,
	'PDV-RS-4-4-2NP': `Закон о порезу на додату вредност: Промет добара без накнаде који није предмет опорезивања`,
	'PDV-RS-5-4-2NP': `Закон о порезу на додату вредност: Промет услуга без накнаде који није предмет опорезивања`,
	'PDV-RS-3NP-ostalo': `Закон о порезу на додату вредност: Остала потраживања која нису предмет опорезивања ПДВ`,
	// N
	'PDV-RS-4': `Закон о порезу на додату вредност: Промет добара без накнаде, независно од тога да ли је предмет опорезивања ПДВ `,
	'PDV-RS-5': `Закон о порезу на додату вредност: Промет услуга без накнаде, независно од тога да ли је предмет опорезивања ПДВ`,
	'PDV-RS-6-1-1 (BN)': `Закон о порезу на додату вредност: Пренос имовине или дела имовине у складу са чланом 6. став 1. тачка 1) ЗПДВ, без накнаде`,
	'PDV-RS-3-DZ': `Закон о порезу на додату вредност: Умањење износа за плаћање због примене другог закона`,
});

export const TAX_EXEMPTION_CODE_CATEGORY = {
	AE: [
		'PDV-RS-10-2-1',
		'PDV-RS-10-2-2',
		'PDV-RS-10-2-3',
		'PDV-RS-10-2-4',
		'PDV-RS-10-2-5-1',
		'PDV-RS-10-2-5-2',
		'PDV-RS-10-2-5-3',
	],
	O: [
		'PDV-RS-11-1-1',
		'PDV-RS-11-1-2',
		'PDV-RS-11-1-3',
		'PDV-RS-11-1-4',
		'PDV-RS-11-1-5',
		'PDV-RS-11-3',
		'PDV-RS-12-4',
		'PDV-RS-12-6-1',
		'PDV-RS-12-6-2',
		'PDV-RS-12-6-3',
		'PDV-RS-12-6-4-1',
		'PDV-RS-12-6-4-2',
		'PDV-RS-12-6-4-3',
		'PDV-RS-12-6-4-4',
		'PDV-RS-12-6-4-5',
		'PDV-RS-12-6-5',
		'PDV-RS-12-6-6',
		'PDV-RS-12-6-7-1',
		'PDV-RS-12-6-7-2',
		'PDV-RS-12-6-7-3',
		'PDV-RS-12-6-7-4',
		'PDV-RS-12-6-7-5',
		'PDV-RS-12-6-7-6',
		'PDV-RS-12-6-7-7',
		'PDV-RS-12-6-7-8',
		'PDV-RS-12-6-7-9',
		'PDV-RS-12-6-7-10',
		'PDV-RS-12-6-7-11',
		'PDV-RS-12-6-7-12',
		'PDV-RS-12-9',
		'PDV-RS-61',
	],
	E: [
		'PDV-RS-25-1-1',
		'PDV-RS-25-1-1a',
		'PDV-RS-25-1-2',
		'PDV-RS-25-1-3',
		'PDV-RS-25-1-4',
		'PDV-RS-25-1-5',
		'PDV-RS-25-1-6',
		'PDV-RS-25-1-7',
		'PDV-RS-25-1-8',
		'PDV-RS-25-2-1',
		'PDV-RS-25-2-2',
		'PDV-RS-25-2-3',
		'PDV-RS-25-2-3a',
		'PDV-RS-25-2-3b',
		'PDV-RS-25-2-5',
		'PDV-RS-25-2-6',
		'PDV-RS-25-2-7',
		'PDV-RS-25-2-8',
		'PDV-RS-25-2-9',
		'PDV-RS-25-2-10',
		'PDV-RS-25-2-11',
		'PDV-RS-25-2-12',
		'PDV-RS-25-2-13',
		'PDV-RS-25-2-14',
		'PDV-RS-25-2-15',
		'PDV-RS-25-2-16',
		'PDV-RS-25-2-17',
		'PDV-RS-25-2-18',
	],
	R: ['PDV-RS-6-1-1', 'PDV-RS-6a'],
	Z: [
		'PDV-RS-24-1-1',
		'PDV-RS-24-1-2',
		'PDV-RS-24-1-3',
		'PDV-RS-24-1-5',
		'PDV-RS-24-1-5a',
		'PDV-RS-24-1-6',
		'PDV-RS-24-1-6a',
		'PDV-RS-24-1-7',
		'PDV-RS-24-1-7a',
		'PDV-RS-24-1-8',
		'PDV-RS-24-1-9',
		'PDV-RS-24-1-10',
		'PDV-RS-24-1-11',
		'PDV-RS-24-1-12',
		'PDV-RS-24-1-13',
		'PDV-RS-24-1-14',
		'PDV-RS-24-1-15',
		'PDV-RS-24-1-16-1',
		'PDV-RS-24-1-16-2',
		'PDV-RS-24-1-16-3',
		'PDV-RS-24-1-16-4',
		'PDV-RS-24-1-16a',
		'PDV-RS-24-1-16b',
		'PDV-RS-24-1-16v',
		'PDV-RS-24-1-16g',
		'PDV-RS-24-1-17',
	],
	SS: ['PDV-RS-33', 'PDV-RS-35-7', 'PDV-RS-36-5', 'PDV-RS-36b-4'],
	OE: [
		'PDV-RS-17-4-2',
		'PDV-RS-17-4-3',
		'PDV-RS-9',
		'PDV-RS-7a-4',
		'PDV-RS-4-4-2NP',
		'PDV-RS-5-4-2NP',
		'PDV-RS-3NP-ostalo',
	],
	N: ['PDV-RS-4', 'PDV-RS-5', 'PDV-RS-6-1-1 (BN)', 'PDV-RS-3-DZ'],
};

export const getBudgetUserType = () => ({
	0: t`Носиоци буџета`,
	1: t`Директни корисници буџетских средстава`,
	2: t`Индиректни корисници буџетских средстава`,
	4: t`Организационе јединице директног корисника буџетских средстава`,
	5: t`Намена средстава директних корисника буџетских средстава`,
	6: t`Други корисници јавних средстава`,
	7: t`Остали корисници јавних средстава`,
	8: t`Правна лица и други субјекти који не припадају јавном сектору`,
	9: t`Организације за обавезно социјално осигурање (ООСО)`,
	10: t`Корисници средстава Републичког фонда за здравствено осигурање`,
	11: t`Организационе јединице у саставу организација за обавезно социјално осигурање (ОЈ ООСО)`,
});
