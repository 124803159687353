import { Dayjs } from 'dayjs';
import { computed, flow, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { StockTurnover, StockTurnoverStatus } from './StockTurnover.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { DayjsTransformer } from './transformers/Dayjs';

const { Store, Entity } = CreateStore({
	name: 'initialStock',
	paginated: true,
});

class InitialStockItem extends Entity {
	@observable productId?: string;
	@observable warehouseId?: string;
	@observable quantity?: number;
	@observable purchasePrice?: number;
	@observable purchaseAmount?: number;
	@observable salePrice?: number;
	@observable salePriceWithVat?: number;
	@observable saleAmountWithVat?: number;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class InitialStock extends Entity {
	@observable name?: string;
	@observable number?: string;

	@observable stockTurnoverItem?: StockTurnover;

	@observable isConfirming = false;

	@DayjsTransformer
	@observable
	date?: Dayjs;

	@ArrayTypeTransformer(InitialStockItem)
	@observable
	items?: InitialStockItem[] = [];

	@flow.bound
	*confirm() {
		this.isConfirming = true;
		try {
			const { data } = yield this.getClient().patch(
				`/initial-stocks/${this.id}/confirm`
			);
			this.replace(data);
		} catch (e) {
			//
		} finally {
			this.isConfirming = false;
		}
	}

	@flow.bound
	*unconfirm() {
		this.isConfirming = true;
		try {
			const { data } = yield this.getClient().patch(
				`/initial-stocks/${this.id}/unconfirm`
			);
			this.replace(data);
		} catch (e) {
			throw e;
		} finally {
			this.isConfirming = false;
		}
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class InitialStocks extends Store<InitialStock> {
	@observable loadingInitial = false;
	@observable hasInitial?: boolean;
	@observable initial?: InitialStock;

	constructor() {
		super(InitialStock);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.fetchInitial();
		}
	}

	get isCreatable() {
		return true;
	}

	@computed
	get isInitialDraft() {
		return (
			this.initial?.stockTurnoverItem?.status === StockTurnoverStatus.DRAFT
		);
	}

	@flow.bound
	*fetchInitial() {
		this.loadingInitial = true;
		try {
			const { data } = yield this.getClient().get('/initial-stocks');
			if (data) {
				this.hasInitial = true;
				this.initial = new InitialStock(data, this);
				return this.initial;
			}
		} catch (e) {
			//
		} finally {
			this.loadingInitial = false;
		}
	}

	*create(postData: Partial<InitialStock>): Generator<any, any, any> {
		const data = yield super.create(postData);
		this.hasInitial = true;
		this.initial = data;
		return data;
	}

	receiveFromSocket(data: any[]): void {
		if (data.length) {
			const [item] = data;
			if (item.deletedAt) {
				this.hasInitial = false;
				this.initial = undefined;
			} else {
				this.hasInitial = true;
				this.initial = new InitialStock(item, this);
			}
		}

		super.receiveFromSocket(data);
	}
}

export { InitialStocks, InitialStock };
