import { t } from '@lingui/macro';
import { Input } from 'antd';

import { TableInput } from '../../../../../../../components/TableInput';

type Props = {
	value?: any[];
	onChange?: (any) => void;
	disabled?: boolean;
};

export function TiersRulesInput({ value = [], disabled, ...props }: Props) {
	return (
		<TableInput
			addButtonText={t`Додај ниво`}
			emptyText={t`Нема нивоа`}
			iconPath="/images/icons/new/gift-box.svg"
			columns={[
				{
					title: t`Назив нивоа`,
					dataIndex: 'tierName',
					key: 'tierName',
					editable: true,
					width: 220,
					component: <Input />,
					rules: [
						{
							required: true,
							message: t`Назив је обавезан`,
						},
					],
				},
				{
					title: t`Укупан потрошени износ`,
					dataIndex: 'totalSpentAmount',
					key: 'totalSpentAmount',
					editable: true,
					width: 220,
					component: <Input type="number" addonAfter="RSD" />,
					rules: [
						{
							required: true,
							message: t`Укупан потрошени износ је обавезан`,
						},
					],
				},
				{
					title: t`Попуст`,
					dataIndex: 'discount',
					key: 'discount',
					editable: true,
					component: <Input type="number" addonAfter="%" max={100} min={0} />,
					rules: [
						{
							required: true,
							message: t`Попуст је обавезан`,
						},
					],
				},
			]}
			value={value}
			disabled={disabled}
			{...props}
		/>
	);
}
