import { t } from '@lingui/macro';
import { Tag } from 'antd';

import { HeaderComponent } from './Components/HeaderComponent';
import { NoImage } from '../../../../components/NoImage';
import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';
import styles from '../../Products/Products/Products.module.less';

export default () =>
	CreatePage({
		module: 'stocks',
		submodule: 'stock',
		model: 'stock',
		view: {},
		edit: {},
		page: {
			shouldFetch: false,
			empty: {
				image: 'inventory',
				text: t`Нема артикала`,
			},
			searchable: true,
			searchPlaceholder: t`Претражи артикле`,
			table: {
				showDisabledActions: false,
				columns: () => [
					{
						title: '',
						dataIndex: 'coverImage',
						key: 'product.coverImage',
						width: 50,
						render: (image, record) => {
							return image ? (
								<>
									<img
										src={image.urls['64x64']}
										alt="Slika"
										className={styles.image}
									/>
								</>
							) : (
								<NoImage name={record.product?.name || ''} size="small" />
							);
						},
					},
					{
						title: t`Шифра`,
						dataIndex: ['product', 'sku'],
						key: 'sku',
						width: 100,
					},
					{
						title: t`Артикал`,
						dataIndex: 'product',
						key: 'product',
						render: (product) => {
							return product?.parent ? (
								<>
									{product.parent.name}{' '}
									<Tag color="magenta" bordered={false}>
										{product.variantName}
									</Tag>
								</>
							) : (
								product.name
							);
						},
					},
					{
						title: t`Количина`,
						dataIndex: 'quantity',
						key: 'quantity',
						render: (quantity) => {
							return numberFormat(quantity, false, 3, false);
						},
						width: 100,
					},
				],
			},
			customCreateButton: () => <HeaderComponent />,
		},
	});
