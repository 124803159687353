import { t } from '@lingui/macro';
import dayjs from 'dayjs';

import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'exchangeRates',
		model: 'exchangeRate',
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/kursna-lista/',
			shouldFetch: false,
			table: {
				listProp: 'active',
				showActions: false,
				columns: [
					{
						title: t`Датум`,
						dataIndex: 'date',
						key: 'date',
						width: 170,
						render: (text) => dayjs(text).format('LL'),
					},
					{
						title: t`Валута`,
						dataIndex: 'currencyId',
						key: 'currencyId',
						width: 70,
					},
					{
						title: t`Назив валуте`,
						dataIndex: ['currency', 'name'],
						key: 'currency.name',
						responsive: ['sm'],
					},
					{
						title: t`Средњи курс`,
						dataIndex: 'rate',
						key: 'rate',
						width: 120,
						render: (text) => `${numberFormat(text, false, 6, false)}`,
					},
				],
			},
		},
	});
