import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, flow, flowResult, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { DayjsTransformer } from './transformers/Dayjs';
import { ReferenceTransformer } from './transformers/Reference';
import { Product } from './Product.mobx';

const { Store, Entity } = CreateStore({
	name: 'offer',
	paginated: true,
	persistFields: ['all', 'allOffers'],
});

class Offer extends Entity {
	@observable name?: string;
	@observable description?: string;
	@observable type?: 'percentage' | 'fixed-price' | 'buy-n-get-m';
	@observable percentage?: number;
	@observable fixedPrice?: number;
	@observable requiredForPurchase?: number;
	@observable promotedProductPrice?: number;
	@observable promotedProductQuantity?: number;
	@DayjsTransformer
	beginAt?: Dayjs;
	@DayjsTransformer
	endAt?: Dayjs;
	@observable status?: 'active' | 'pending' | 'rejected';
	@observable rejectedReason?: string;

	@observable productId?: string;
	@observable promotedProductId?: string;

	@ReferenceTransformer('product', 'productId') product?: Product;
	@ReferenceTransformer('product', 'promotedProductId')
	promotedProduct?: Product;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	*update(postData): Generator<any, any, any> {
		yield super.update(postData);
		this.getParent().receiveFromSocket([this.toPlain()]);
		return this;
	}

	*destroy(): Generator<any, void, unknown> {
		this.getParent().receiveFromSocket([
			{ ...this.toPlain(), deletedAt: dayjs() },
		]);
		yield super.destroy();
	}
}

class Offers extends Store<Offer> {
	@observable allOffers?: Offer[] = [];

	constructor() {
		super(Offer);
	}

	@computed
	get activeOffers(): Offer[] {
		return this.allOffers?.filter(
			(item) =>
				(!item.beginAt || dayjs(item.beginAt).isSameOrBefore(dayjs())) &&
				(!item.endAt || dayjs(item.endAt).isSameOrAfter(dayjs())) &&
				!item.deletedAt &&
				item.status === 'active'
		);
	}

	receiveFromSocket(data: any[]): void {
		data.forEach((item: Offer) => {
			if (
				(!item.beginAt || dayjs(item.beginAt).isSameOrBefore(dayjs())) &&
				(!item.endAt || dayjs(item.endAt).isSameOrAfter(dayjs()))
			) {
				const index = (this.allOffers || []).findIndex(
					(offer) => offer.id === item.id
				);
				if (index === -1) {
					this.allOffers.push(new Offer(item, this));
				} else {
					this.allOffers[index] = new Offer(item, this);
				}
			}
		});

		super.receiveFromSocket(data);
	}

	*create(postData): Generator<any, any, any> {
		const entity = yield super.create(postData);
		this.receiveFromSocket([entity.toPlain()]);
		return entity;
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await flowResult(this.fetchAll());
		}
	}
}

export { Offers, Offer };
