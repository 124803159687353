import Bugsnag from '@bugsnag/browser';
import { t, Trans } from '@lingui/macro';
import {
	Alert,
	Button,
	Form,
	Input,
	message,
	notification,
	Select,
	Skeleton,
	Space,
	Spin,
	Typography,
} from 'antd';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import { observer } from 'mobx-react-lite';
import papaparse from 'papaparse';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { MisterdItem } from './types';
import DisablePasswordAutocomplete from '../../../../../../../../components/DisablePasswordAutocomplete';
import { useDrawer } from '../../../../../../../../components/Page';
import { StaticComponents } from '../../../../../../../../components/StaticComponents';
import { Title } from '../../../../../../../../components/Title';
import { ERROR_BAD_REQUEST_INVALID_CREDENTIALS } from '../../../../../../../../constants/errors';
import { v2Client } from '../../../../../../../../store/client';
import ConnectProductsDrawer from '../../../../Drawers/ConnectProductsDrawer';

type Venue = {
	id: number;
	name: string;
	address: string;
};

interface Props {
	storeId: string;
}

function ApplicationSettingsMisterd({ storeId }, ref) {
	const [loading, setLoading] = useState(false);
	const [id, , close, ,] = useDrawer('marketplace-application-settings');
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const [username, setUsername] = useState('');

	const [areItemsLoading, setAreItemsLoading] = useState(false);
	const [items, setItems] = useState<MisterdItem[]>([]);

	const [areChangesLoading, setAreChangesLoading] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const [isConnectProductsVisible, setIsConnectProductsVisible] =
		useState(false);

	const [areVenuesLoading, setAreVenuesLoading] = useState(false);
	const [venues, setVenues] = useState<Venue[] | null>(null);
	const [form] = Form.useForm();

	useEffect(() => {
		if (id) {
			setLoading(true);
			v2Client
				.get(`/marketplace-applications/installed/${id}`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setUsername(response.data.credentials?.username);
					setIsLoggedIn(!!response.data.settings?.loggedIn);
					form.setFieldValue('venueId', response.data.settings?.venueId);
					// setMenuId(response.data.settings?.menuId);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [id, storeId, form]);

	useImperativeHandle(ref, () => ({
		save: () => {
			form.submit();
		},
	}));

	useEffect(() => {
		if (isLoggedIn) {
			setAreVenuesLoading(true);
			v2Client
				.get(`/marketplace-applications/${id}/functions/get-venues`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setVenues(response.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setAreVenuesLoading(false);
				});
		}
	}, [isLoggedIn, storeId]);

	const showConnectProductsDrawer = useCallback(async (alwaysOpen = true) => {
		setAreItemsLoading(true);
		try {
			const itemsResponse = await v2Client.get(
				`/marketplace-applications/${id}/functions/get-items`
			);

			// setItems(sortBy(itemsResponse.data, 'name.0.value'));
			setItems(sortBy(itemsResponse.data, 'name'));
			if (alwaysOpen || itemsResponse.data.find((item) => !item.product)) {
				setIsConnectProductsVisible(true);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setAreItemsLoading(false);
		}
	}, []);

	const handleConnectProductsButtonClick = useCallback(() => {
		showConnectProductsDrawer(true);
	}, [showConnectProductsDrawer]);

	const handleDownloadChangesButtonClick = useCallback(() => {
		setAreChangesLoading(true);
		v2Client
			.get(`/marketplace-applications/${id}/functions/get-changes`)
			.then((response) => {
				const { data } = response;
				if (!data.length) {
					return StaticComponents.notification.info({
						message: t`Нема промена`,
						description: t`Нема додатих или измењених артикала`,
					});
				}
				const csv = papaparse.unparse(
					data.map((item) => {
						const changes = [];
						if (!item.externalId) {
							changes.push('Novi proizvod');
						} else {
							if (item.previousName) {
								changes.push('Naziv');
							}
							if (typeof item.groupName !== 'undefined') {
								changes.push('Kategorija');
							}
							if (item.description) {
								changes.push('Opis');
							}
							if (item.price) {
								changes.push('Cena');
							}
							if (item.imageId) {
								changes.push('Fotografija');
							}
						}

						return {
							Naziv: item.name,
							'Stari naziv': item.previousName,
							Kategorija: item.groupName,
							Opis: item.description,
							Cena: item.price,
							Fotografija: item.imageId
								? `${v2Client.defaults.baseURL}/files/${item.imageId}?size=800x450`
								: '',
							Izmene: changes.join(', '),
						};
					})
				);
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
				// download csv
				const link = document.createElement('a');
				const url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute(
					'download',
					`misterd-promene-${dayjs().toISOString()}.csv`
				);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((error) => {
				StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Дошло је до грешке приликом преузимања промена`,
				});
				Bugsnag.notify(error);
			})
			.finally(() => {
				setAreChangesLoading(false);
			});
	}, []);

	const handleDeleteButtonClick = useCallback(() => {
		setIsDeleteLoading(true);
		v2Client
			.post(`/marketplace-applications/${id}/functions/delete-changes`, {})
			.then((response) => {
				StaticComponents.notification.success({
					message: t`Брисање је успешно`,
					description: t`Фајл са променама је обрисан`,
				});
			})
			.catch((error) => {
				StaticComponents.notification.error({
					message: t`Брисање није успело`,
					description: t`Дошло је до грешке приликом брисања фајла са променама`,
				});
				Bugsnag.notify(error);
			})
			.finally(() => {
				setIsDeleteLoading(false);
			});
	}, []);

	const handleSubmit = useCallback(async () => {
		const values = await form.validateFields();
		setLoading(true);
		try {
			const response = await v2Client.patch(
				`/marketplace-applications/${id}/settings`,
				{
					settings: {
						venueId: values.venueId,
					},
					credentials: {
						username: values.username,
						password: values.password,
					},
				}
			);

			setIsLoggedIn(!!response.data.settings?.loggedIn);

			StaticComponents.notification.success({
				message: t`Подешавања су успешно сачувана`,
			});
		} catch (error) {
			if (
				error?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_INVALID_CREDENTIALS
			) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Корисничко име или лозинка нису исправни`,
				});
			}
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Дошло је до грешке приликом чувања подешавања`,
			});
		} finally {
			setLoading(false);
		}
	}, [form, id, venues]);

	const closeConnectProductsDrawer = useCallback(() => {
		setIsConnectProductsVisible(false);
	}, [setIsConnectProductsVisible]);

	const formatItem = useCallback(
		(item) => ({
			externalId: item.id,
			name: item.product?.name || item.name,
			description: item.product?.description || item.description,
			productId: item.product?.productId,
			productName: item.product?.product?.parent
				? `${item.product?.product?.parent?.name} ${item.product?.product?.name}`
				: item.product?.product?.name,
			salePrice: item.product?.salePrice,
			saleQuantity: item.product?.saleQuantity,
			active: item.active,
			metadata: {
				...(item.metadata || {}),
				groupId: item.metadata?.groupId || item.groupId,
			},
			imageUrl: item.imageUrl,
			price: item.displayPrice,
			product: item.product,
		}),
		[]
	);

	return (
		<Spin spinning={loading || areVenuesLoading}>
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				<Title>
					<Trans>Креденцијали</Trans>
				</Title>
				<DisablePasswordAutocomplete />
				{!isLoggedIn && (
					<>
						<Form.Item>
							<Alert
								type="info"
								message={
									<Trans>
										Користите креденцијале за приступ Mister D власничком
										порталу
									</Trans>
								}
							/>
						</Form.Item>

						<Form.Item
							name="username"
							label={t`Адреса електронске поште`}
							rules={[
								{
									required: true,
									message: t`Обавезан је унос адресе електронске поште`,
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="password"
							label={t`Лозинка`}
							rules={[
								{
									required: true,
									message: t`Обавезан је унос лозинке`,
								},
							]}
						>
							<Input.Password />
						</Form.Item>
					</>
				)}
				{isLoggedIn && (
					<>
						<Form.Item>
							<Alert
								type="info"
								message={
									<Trans>
										Пријављени сте као корисник <strong>{username}</strong>.{' '}
										<Typography.Link onClick={() => setIsLoggedIn(false)}>
											Кликните овде
										</Typography.Link>{' '}
										да промените корисника.
									</Trans>
								}
							/>
						</Form.Item>
						<Title>
							<Trans>Објекат</Trans>
						</Title>
						{areVenuesLoading && <Skeleton />}
						{!areVenuesLoading && (
							<Form.Item name="venueId">
								<Select placeholder={t`Изаберите објекат`}>
									{venues?.map((venue) => (
										<Select.Option key={venue.id} value={venue.id}>
											{venue.name}
											<br />
											<small>{venue.address}</small>
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						)}
						<Title>
							<Trans>Администрација</Trans>
						</Title>
						<Form.Item label={t`Повезивање артикала`}>
							<Typography.Text type="secondary">
								<Trans>
									Уколико већ имате артикле на платформи Mister D, можете их
									повезати са артиклима на вашем продајном месту.
								</Trans>
							</Typography.Text>
							<br />
							<br />
							<Button
								loading={areItemsLoading}
								onClick={handleConnectProductsButtonClick}
							>
								<Trans>Повежи артикле</Trans>
							</Button>
						</Form.Item>
						<Form.Item label="Preuzimanje izmena">
							<Typography.Text type="secondary">
								<Trans>
									Платформа Mister D тренутно не омогућава аутоматско ажурирање
									артикала, већ је потребно ручно послати фајл са изменама на
									адресу електронске поште{' '}
									<a href="mailto:kontakt@misterd.rs">kontakt@misterd.rs</a>.
									Након што преузмете фајл са изменама, кликните на дугме{' '}
									<strong>Обриши измене</strong>, како би се бележиле само нове
									измене.
								</Trans>
							</Typography.Text>
							<br />
							<br />
							<Space>
								<Button
									loading={areChangesLoading}
									disabled={areChangesLoading}
									onClick={handleDownloadChangesButtonClick}
								>
									<Trans>Преузми измене</Trans>
								</Button>
								<Button
									danger
									loading={isDeleteLoading}
									disabled={isDeleteLoading}
									onClick={handleDeleteButtonClick}
								>
									<Trans>Обриши измене</Trans>
								</Button>
							</Space>
						</Form.Item>
					</>
				)}

				<input type="submit" style={{ display: 'none' }} />
			</Form>
			<ConnectProductsDrawer
				visible={isConnectProductsVisible}
				items={items}
				onClose={closeConnectProductsDrawer}
				formatItem={formatItem}
				categoryProperty="groupId"
				enabledProperty="active"
				platformName="Mister D"
			/>
		</Spin>
	);
}

export default observer(ApplicationSettingsMisterd, { forwardRef: true });
