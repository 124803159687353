import { t } from '@lingui/macro';
import { List, Modal } from 'antd';

import numberFormat from '../../../../../lib/numberFormat';
import { Product } from '../../../../../stores/Product.mobx';
import styles from '../../POS.module.less';
interface Props {
	product: Product;
	visible: boolean;
	onSelect: (product: Product) => void;
	onCancel: () => void;
	currentSaleChannelId?: string | null;
}
function VariantPicker({
	product,
	visible,
	onCancel,
	onSelect,
	currentSaleChannelId,
}: Props) {
	return (
		<Modal
			centered
			width={500}
			open={visible}
			onCancel={onCancel}
			footer={null}
			title={t`Изаберите варијанту`}
		>
			<List>
				{product &&
					product.variants
						.filter((variant) => {
							if (!currentSaleChannelId) {
								return true;
							}
							return variant.saleChannelIds.includes(currentSaleChannelId);
						})
						.map((variant) => (
							<List.Item onClick={() => onSelect(variant)}>
								<List.Item.Meta
									avatar={
										product.coverImage ? (
											<img
												className={styles.image}
												src={product.coverImage.urls['64x64']}
												alt={product.name}
											/>
										) : (
											<div></div>
										)
									}
									title={variant.variantName}
								></List.Item.Meta>
								<div>
									{numberFormat(
										variant.priceBySaleChannel(currentSaleChannelId),
										true,
										2,
										true,
										variant.currencyId || undefined
									)}
								</div>
							</List.Item>
						))}
			</List>
		</Modal>
	);
}

export default VariantPicker;
