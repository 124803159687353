import { t } from '@lingui/macro';

import { PartnerForm } from './Components/PartnerForm';
import { CreatePage } from '../../../components/Page';
import {
	ERROR_CONFLICT_DUPLICATE_INTERNAL_CODE,
	ERROR_CONFLICT_DUPLICATE_PARTNER_CODE,
} from '../../../constants/errors';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'partners',
		model: 'partner',
		view: {
			useEdit: true,
		},
		edit: {
			width: 700,
			title: {
				new: t`Додавање партнера`,
				existing: (entity) => t`Измена партнера ${entity?.name || ''}`,
			},
			shouldFetch: true,
			onError: (error, entity, form) => {
				if (
					error.response?.data?.errorCode ===
					ERROR_CONFLICT_DUPLICATE_INTERNAL_CODE
				) {
					form.setFields([
						{
							name: 'internalCode',
							errors: [t`Унета шифра већ постоји`],
						},
					]);
				}
				if (
					error.response?.data?.errorCode ===
					ERROR_CONFLICT_DUPLICATE_PARTNER_CODE
				) {
					form.setFields([
						{
							name: 'code',
							errors: [t`Унета шифра већ постоји`],
						},
					]);
				}
			},
			beforeSave: (values) => {
				if (
					values.internalCode &&
					values.locations.find((l) => l.internalCode === values.internalCode)
				) {
					throw {
						response: {
							data: { errorCode: ERROR_CONFLICT_DUPLICATE_INTERNAL_CODE },
						},
					};
				}
				return {
					...values,
					sellerIdentifier: values.jbkjs ? `JBKJS:${values.jbkjs}` : null,
					jbkjs: undefined,
					locations: (values.locations || []).map((location) => ({
						id: location.id,
						name: location.name,
						address: location.address,
						city: location.city,
						country: location.country,
						internalCode: location.internalCode,
						internalRoutingNumber: location.internalRoutingNumber,
						gln: location.gln,
					})),
				};
			},
			fields: <PartnerForm />,
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/partneri/',
			empty: {
				image: 'portfolio',
				text: t`Нема партнера`,
			},
			shouldFetch: false,
			searchable: true,
			searchPlaceholder: t`Претражи партнере`,
			table: {
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
						shouldLink: true,
						ellipsis: true,
					},
					// {
					// 	title: 'E-Mail',
					// 	dataIndex: 'email',
					// 	key: 'email',
					// 	ellipsis: true,
					// 	responsive: ['xl'],
					// },
					// {
					// 	title: 'Web Sajt',
					// 	dataIndex: 'website',
					// 	key: 'website',
					// 	ellipsis: true,
					// 	responsive: ['xl'],
					// },
				],
				showDisabledActions: true,
			},
			createButtonText: t`Додај партнера`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете овог партнера?`,
		},
	});
