import { Trans, t } from '@lingui/macro';
import { Button, Empty, Space, Table } from 'antd';
import { useCallback, useState } from 'react';

import LocationEditDrawer from './Components/LocationEditDrawer';
import styles from './LocationsInput.module.less';
import { PartnerLocation } from '../../../../../stores/Partner.mobx';

type Props = {
	value?: PartnerLocation[];
	onChange?: (value: PartnerLocation[]) => void;
};

function LocationsInput(props: Props) {
	const { value = [], onChange = () => null } = props;
	const [
		[editLocationVisible, editLocationEntity, editLocationIndex],
		setEditLocation,
	] = useState([false, null, null]);

	const save = useCallback(
		(location, index) => {
			const newLocations = [...(value || [])];
			if (index !== null) {
				newLocations[index] = {
					...newLocations[index],
					...location,
				};
			} else {
				newLocations.push(location);
			}
			onChange(newLocations);
			setEditLocation([false, null, null]);
		},
		[value, setEditLocation]
	);

	function handleDeleteLocation(index) {
		const newLocations = (value || []).slice();
		newLocations.splice(index, 1);

		onChange(newLocations);
	}

	if ((value || []).length === 0) {
		return (
			<div className={styles.container}>
				<Empty
					image={<img src="/images/icons/new/place.svg" alt="" />}
					imageStyle={{
						height: 64,
					}}
					description={t`Нема издвојених локација`}
				>
					<Button
						type="link"
						onClick={() => {
							setEditLocation([true, null, null]);
						}}
					>
						<Trans>Додај издвојену локацију</Trans>
					</Button>
				</Empty>
				<LocationEditDrawer
					visible={editLocationVisible}
					entity={editLocationEntity}
					save={save}
					close={() => setEditLocation([false, null, null])}
					locations={value || []}
					index={editLocationIndex}
				/>
			</div>
		);
	}

	return (
		<>
			<div className={styles.container}>
				<Table
					pagination={false}
					size="small"
					rowKey="id"
					dataSource={value || []}
					footer={() => (
						<Button onClick={() => setEditLocation([true, null, null])}>
							<Trans>Додај издвојену локацију</Trans>
						</Button>
					)}
					scroll={{ x: 640 }}
				>
					<Table.Column
						title={t`Назив`}
						dataIndex="name"
						key="name"
						width={200}
						ellipsis
					/>
					<Table.Column
						title={t`Адреса`}
						dataIndex="address"
						key="address"
						ellipsis
						render={(text, record: PartnerLocation) =>
							[
								...(record.address ? [record.address] : []),
								...(record.city ? [record.city] : []),
								...(record.country ? [record.country] : []),
							].join(', ')
						}
					/>
					<Table.Column
						align="right"
						width={80}
						fixed="right"
						render={(text, record, index) => (
							<Space.Compact>
								<Button
									icon={<i className="fi fi-rr-pencil"></i>}
									onClick={() => {
										setEditLocation([true, record, index]);
									}}
								/>
								<Button
									icon={<i className="fi fi-rr-trash"></i>}
									onClick={() => handleDeleteLocation(index)}
								/>
							</Space.Compact>
						)}
					/>
				</Table>
			</div>
			<LocationEditDrawer
				visible={editLocationVisible}
				entity={editLocationEntity}
				save={save}
				close={() => setEditLocation([false, null, null])}
				locations={value || []}
				index={editLocationIndex}
			/>
		</>
	);
}

export default LocationsInput;
