interface Props {
	type?: 'simple' | 'multicolor';
	icon: string;
	size?: number;
}

export function Icon({ type = 'simple', icon, size = 16 }: Props) {
	return (
		<img
			src={`/images/icons/new/${
				type === 'multicolor' ? '/' : '/simple/'
			}${icon}.svg`}
			alt={icon}
			style={{ width: size, verticalAlign: 'middle' }}
			className="anticon"
		/>
	);
}
