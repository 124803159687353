import { Typography } from 'antd';
import React from 'react';

interface Props {
	children: React.ReactNode;
}

export function Title({ children }: Props) {
	return (
		<Typography.Title level={5} style={{ marginTop: 0, fontWeight: 'bold' }}>
			{children}
		</Typography.Title>
	);
}
