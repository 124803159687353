import { Trans, t } from '@lingui/macro';
import { Input, Select } from 'antd';

import Editor from '../../../../../../components/Editor';
import { CreatePage } from '../../../../../../components/Page';
import PhotosEdit from '../../../../../../components/PhotosEdit';
import SingleImageUpload from '../../../../../../components/SingleImageUpload';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'my-applications',
		model: 'myMarketplaceApplications',
		view: {
			useEdit: true,
		},
		edit: {
			width: 800,
			title: {
				new: t`Додавање апликације`,
				existing: (entity) => t`Измена апликације ${entity?.name || ''}`,
			},
			beforeSave: (values) => {
				return {
					...values,
					// dimensions: values.dimensions === 'xx' ? null : values.dimensions,
					images: values.images
						? values.images.map((image) => image.response?.id || image?.id)
						: [],
					logoFileId: values.logoFileId?.id,
				};
			},
			fields: (record) => [
				{
					key: 'basic',
					label: t`Основне информације`,
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							rules: [{ required: true, message: t`Назив је обавезан` }],
							component: <Input />,
							span: 10,
						},
						{
							key: 'packageId',
							label: t`ИД пакета`,
							rules: [
								{ required: true, message: t`ИД пакета је обавезан` },
								{
									type: 'string',
									pattern: /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/,
									message: (
										<Trans>
											ИД пакета мора бити у{' '}
											<a
												href="https://en.wikipedia.org/wiki/Reverse_domain_name_notation#:~:text=Reverse%20domain%20name%20notation%20(or,components%20reversed%20for%20grouping%20purposes."
												target="_blank"
											>
												"reverse domain name"
											</a>{' '}
											нотацији
										</Trans>
									),
								},
							],
							component: <Input />,
							span: 10,
						},
						{
							key: 'version',
							label: t`Верзија`,
							rules: [
								{ required: true, message: t`Верзија је обавезна` },
								{
									type: 'string',
									pattern: /^\d+\.\d+\.\d+$/,
									message: t`Верзија мора бити у формату x.y.z`,
								},
							],
							component: <Input />,
							span: 4,
						},
						{
							key: 'description',
							label: t`Опис`,
							rules: [{ required: true, message: t`Опис је обавезан` }],
							component: <Editor />,
							span: 24,
						},

						{
							key: 'scopes',
							label: t`Опсег дозвола приступа`,
							rules: [{ required: true, message: t`Опсег је обавезан` }],
							component: (
								<Select mode="tags">
									<Select.Option value="company">
										<Trans>Предузеће</Trans>
									</Select.Option>
									<Select.Option value="users">
										<Trans>Корисници</Trans>
									</Select.Option>
									<Select.Option value="categories">
										<Trans>Категорије</Trans>
									</Select.Option>
									<Select.Option value="products">
										<Trans>Артикли</Trans>
									</Select.Option>
									<Select.Option value="receipts">
										<Trans>Фискални рачуни</Trans>
									</Select.Option>
									<Select.Option value="invoices">
										<Trans>Фактуре</Trans>
									</Select.Option>
								</Select>
							),
							span: 24,
						},
						{
							key: 'applicationUrl',
							label: t`УРЛ апликације`,
							rules: [
								{ required: true, message: t`УРЛ апликације је обавезан` },
								{
									type: 'url',
									message: t`УРЛ апликације мора бити валидан`,
								},
							],
							component: <Input />,
							span: 24,
						},
						{
							key: 'websiteUrl',
							label: t`Веб сајт`,
							rules: [
								{
									type: 'url',
									message: t`УРЛ мора бити валидан`,
								},
							],
							component: <Input />,
							span: 24,
						},
						{
							key: 'supportEmail',
							label: t`Адреса електронске поште за подршку`,
							rules: [
								{
									type: 'email',
									message: t`Адреса електронске поште мора бити валидна`,
								},
							],
							component: <Input />,
							span: 24,
						},
					],
				},
				{
					key: 'images',
					label: t`Слике`,
					fields: [
						{
							key: 'logoFileId',
							label: t`Лого`,
							component: (
								<SingleImageUpload
									resource="applicationLogo"
									record={record}
									recordProperty="logoFile"
								/>
							),
							span: 24,
							rerenderOnChange: true,
						},
						{
							key: 'images',
							label: t`Слике`,
							component: <PhotosEdit resource="applicationImage" />,
							span: 24,
							rerenderOnChange: true,
						},
					],
				},
				{
					key: 'legal',
					label: t`Правне информације`,
					fields: [
						{
							key: 'privacyPolicyUrl',
							label: t`УРЛ политике приватности`,
							rules: [
								{
									type: 'url',
									message: t`УРЛ политике приватности мора бити валидан`,
								},
							],
							component: <Input />,
							span: 24,
						},
						{
							key: 'termsOfServiceUrl',
							label: t`УРЛ услова коришћења`,
							rules: [
								{
									type: 'url',
									message: t`УРЛ услова коришћења мора бити валидан`,
								},
							],
							component: <Input />,
							span: 24,
						},
					],
				},
			],
		},
		page: {
			docsLink:
				'koriscenje/moduli-aplikacije/podesavanja/aplikacije/za-programere/moje-aplikacije/',
			empty: {
				image: 'chip',
				text: t`Нема апликација`,
			},
			shouldFetch: true,
			table: {
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
						shouldLink: false,
					},
				],
			},
			createButtonText: t`Додај апликацију`,
		},
	});
