import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { useDrawer } from '../../../../../../components/Page';
import { usePermissions } from '../../../../../../hooks/permissions';

type Props = {
	parentId: string;
};
function AddButtonComponent({ parentId }: Props) {
	const hasCreatePermission = usePermissions('catalog', 'categories', 'create');

	const [, open] = useDrawer('create/catalog/categories', undefined, undefined);
	const handleButtonClick = useCallback(() => {
		open('true', { categoryId: parentId });
	}, [open, parentId]);

	return (
		<Button
			disabled={!hasCreatePermission}
			icon={<i className="fi fi-rr-add-folder" onClick={handleButtonClick} />}
		/>
	);
}

export const AddButton = observer(AddButtonComponent);
