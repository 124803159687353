import { t, Trans } from '@lingui/macro';
import { Button, Dropdown, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import { useDrawer } from '../../../../../../components/Page';
import { StaticComponents } from '../../../../../../components/StaticComponents';
import { usePermissions } from '../../../../../../hooks/permissions';
import stores from '../../../../../../stores/index.mobx';
import { StockTurnoverStatus } from '../../../../../../stores/StockTurnover.mobx';

interface Props {
	createButtonHandler: () => void;
}

function StocksTurnoverCreateButtonBase({ createButtonHandler }: Props) {
	const {
		initialStocks: { loadingInitial, hasInitial, initial },
		warehouses: { isFetching, available },
	} = stores;

	const [, openEditInitial] = useDrawer('edit/stocks/initial');
	const [, openEditCalculation] = useDrawer('edit/stocks/calculation');
	const [, openEditPricechange] = useDrawer('edit/stocks/pricechange');

	const [, openCreateWarehouse] = useDrawer('create/settings/warehouses');

	const canCreateInitialPermission = usePermissions(
		'stocks',
		'initial',
		'create'
	);
	const canCreateWarehousePermission = usePermissions(
		'stocks',
		'initial',
		'create'
	);

	const handleMenuClick = useCallback(async (e) => {
		if (e.key === 'priceChange') {
			const { pricechanges } = stores;
			const response = await flowResult(
				pricechanges.create({
					date: dayjs(),
					items: [],
				})
			);
			openEditPricechange(response.id);
			return;
		}
		StaticComponents.modal.warning({
			title: t`Упозорење`,
			content: t`Ова функционалност је у развоју.`,
		});
	}, []);

	const menu = useMemo(() => {
		return {
			items: [
				{
					label: t`Додај нивелацију`,
					key: 'priceChange',
				},
				{
					label: t`Попис`,
					key: 'inventory',
				},
				// TODO new year
			],
			onClick: handleMenuClick,
		};
	}, [handleMenuClick]);

	if (!canCreateInitialPermission) {
		return null;
	}

	if (loadingInitial || isFetching) {
		return (
			<Button type="primary" loading>
				{t`Додај калкулацију`}
			</Button>
		);
	}

	if (available.length === 0) {
		return (
			<>
				<Button
					type="primary"
					onClick={() => {
						openCreateWarehouse();
					}}
				>
					<Trans>Додај складиште</Trans>
				</Button>
			</>
		);
	}

	if (!hasInitial) {
		return (
			<>
				<Button
					type="primary"
					onClick={async () => {
						const { initialStocks } = stores;
						const response = await flowResult(
							initialStocks.create({
								date: dayjs(),
								items: [],
							})
						);
						setTimeout(() => {
							openEditInitial(response.id);
						}, 100);
					}}
				>
					<Trans>Додај почетно стање</Trans>
				</Button>
			</>
		);
	}

	if (
		hasInitial &&
		initial.stockTurnoverItem.status === StockTurnoverStatus.DRAFT
	) {
		return (
			<Tooltip
				title={t`Почетно стање мора бити потврђено како бисте могли да наставите са уносом залиха`}
			>
				<Button type="primary" disabled>
					<Trans>Додај почетно стање</Trans>
				</Button>
			</Tooltip>
		);
	}

	return (
		<Dropdown.Button
			icon={<i className="fi fi-rr-angle-small-down"></i>}
			menu={menu}
			type="primary"
			onClick={async () => {
				const { calculations } = stores;
				const response = await flowResult(
					calculations.create({
						date: dayjs(),
						items: [],
					})
				);
				openEditCalculation(response.id);
			}}
		>
			{t`Додај калкулацију`}
		</Dropdown.Button>
	);
}

export const StocksTurnoverCreateButton = observer(
	StocksTurnoverCreateButtonBase
);
