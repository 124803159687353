import { Skeleton, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Product } from '../../../../../../stores/Product.mobx';
import { LoadingReference } from '../../../../../../stores/transformers/Reference';
import styles from '../../Products.module.less';

interface Props {
	record: Product;
}

function CategoriesView({ record }: Props) {
	return (
		<>
			{record.categories?.map((category) => (
				<Tag bordered={false}>
					{category instanceof LoadingReference ? (
						<Skeleton
							className={styles.skeleton}
							active
							paragraph={{ rows: 1 }}
							title={false}
						/>
					) : (
						category?.name
					)}
				</Tag>
			))}
		</>
	);
}

export default observer(CategoriesView);
