import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, flow, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { DayjsTransformer } from './transformers/Dayjs';

export type DiscountRule = {
	type?: 'categories' | 'products' | 'receipt-total' | 'all';
	value?: any;
};
const { Store, Entity } = CreateStore({
	name: 'discount',
	paginated: true,
	persistFields: ['all', 'allDiscounts'],
});

class Discount extends Entity {
	@observable name?: string;
	@observable type?: 'percentage' | 'fixed';
	@observable percentage?: number;
	@observable fixed?: number;
	rules?: DiscountRule[];
	@DayjsTransformer
	beginAt?: Dayjs;
	@DayjsTransformer
	endAt?: Dayjs;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	*update(postData): Generator<any, any, any> {
		yield super.update(postData);
		this.getParent().receiveFromSocket([this.toPlain()]);
		return this;
	}

	*destroy(): Generator<any, void, unknown> {
		this.getParent().receiveFromSocket([
			{ ...this.toPlain(), deletedAt: dayjs() },
		]);
		yield super.destroy();
	}
}

class Discounts extends Store<Discount> {
	@observable allDiscounts?: Discount[];

	constructor() {
		super(Discount);
	}

	@computed
	get activeDiscounts(): Discount[] {
		return this.allDiscounts?.filter(
			(item) =>
				(!item.beginAt || dayjs(item.beginAt).isSameOrBefore(dayjs())) &&
				(!item.endAt || dayjs(item.endAt).isSameOrAfter(dayjs())) &&
				!item.deletedAt
		);
	}

	@flow.bound
	*fetchAllActive() {
		this.isFetching = true;
		try {
			const { data }: any = yield this.getClient()({
				method: 'GET',
				url: `/discounts/active-discounts`,
			});

			this.allDiscounts = data;
			this.isFetching = false;
		} catch (error) {
			this.isFetching = false;
			throw error;
		}
	}

	receiveFromSocket(data: any[]): void {
		data.forEach((item: Discount) => {
			if (
				(!item.beginAt || dayjs(item.beginAt).isSameOrBefore(dayjs())) &&
				(!item.endAt || dayjs(item.endAt).isSameOrAfter(dayjs()))
			) {
				const index = this.allDiscounts.findIndex(
					(discount) => discount.id === item.id
				);
				if (index === -1) {
					this.allDiscounts.push(new Discount(item, this));
				} else {
					this.allDiscounts[index] = new Discount(item, this);
				}
			}
		});

		super.receiveFromSocket(data);
	}

	*create(postData): Generator<any, any, any> {
		const entity = yield super.create(postData);
		this.receiveFromSocket([entity.toPlain()]);
		return entity;
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await this.fetchAllActive();
		}
	}
}

export { Discounts, Discount };
