import { t } from '@lingui/macro';
import { useDebounceFn } from 'ahooks';
import { Select, SelectProps } from 'antd';
import { flowResult } from 'mobx';
import { useCallback, useState, useEffect, useMemo } from 'react';

import stores from '../../../../../../stores/index.mobx';

interface Props {
	direction?: 'incoming' | 'outgoing';
	types?: string[];
	onChange?: (value: any) => void;
	onSelect?: (option: any) => void;
	partnerId?: string;
	hideNotInSystem?: boolean;
	initialOptions?: {
		label: string;
		value: string;
	}[];
	value?: string;
	shouldFetch?: boolea;
}

export function InvoiceSelect({
	direction,
	partnerId,
	types,
	onChange,
	onSelect,
	hideNotInSystem,
	initialOptions = [],
	value,
	shouldFetch = true,
	...props
}: Props) {
	const [data, setData] = useState<SelectProps['options']>([]);
	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		if (initialOptions.length > 0) {
			setData(initialOptions);
		}
	}, [initialOptions]);

	const {
		invoices: { searchInvoices, isSearching, fetchSingle },
	} = stores;

	const displayValue = useMemo(() => {
		if (data.length === 0) {
			return undefined;
		}

		const item = data.find((item) => item.value === value);

		if (!item) {
			return undefined;
		}

		return item.label;
	}, [data, value]);

	useEffect(() => {
		if (value) {
			const item = data.find((item) => item.value === value);

			if (!item && shouldFetch) {
				setIsFetching(true);
				flowResult(fetchSingle(value))
					.then((data) => {
						if (data) {
							setData((prev) => [
								...prev,
								{
									label: data.number,
									value: data.id,
								},
							]);
						}
					})
					.finally(() => {
						setIsFetching(false);
					});
			}
		}
	}, [data, fetchSingle, shouldFetch, value]);

	const callSearch = async (newValue) => {
		try {
			const responseData = await searchInvoices(
				newValue,
				direction,
				partnerId,
				types
			);

			const newData = responseData.map((item: any) => ({
				...item,
				label: item.number,
				value: item.id,
			}));

			setData(newData);
			return newData;
		} catch (error) {
			return [];
		}
	};

	const { run: debouncedCallSearch } = useDebounceFn(callSearch, { wait: 500 });

	const handleSearch = useCallback(
		async (newValue: string) => {
			if (newValue.length < 3) {
				setData([]);
				return;
			}

			await debouncedCallSearch(newValue);
		},
		[debouncedCallSearch]
	);

	const handleSelect = useCallback(
		async (value, data: any) => {
			onChange(value);
			if (onSelect) {
				onSelect(data);
			}
		},
		[onChange, onSelect]
	);

	return (
		<Select
			loading={isSearching}
			value={displayValue}
			showSearch
			onSearch={handleSearch}
			onSelect={handleSelect}
			notFoundContent={null}
			filterOption={false}
			popupMatchSelectWidth={false}
			options={[
				{ label: '', value: null },
				...data,
				...((!hideNotInSystem && [
					{
						label: t`Документ није у систему`,
						value: '00000000-0000-0000-00000000',
					},
				]) ||
					[]),
			]}
			{...props}
		/>
	);
}
