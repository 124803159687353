import { t } from '@lingui/macro';
import { Form, Input, Modal, Spin } from 'antd';

import { Invoice } from '../../../../../../stores/Invoice.mobx';

interface Props {
	visible?: boolean;
	close: () => void;
	onOk: (any) => void;
	record: Invoice;
}

export default function CancelModal({ visible, close, onOk, record }: Props) {
	const [form] = Form.useForm();

	return (
		<Modal
			visible={visible}
			title={t`Сторнирање документа`}
			okText={t`Сторнирај`}
			cancelText={t`Одустани`}
			onOk={() => {
				form.submit();
			}}
			onCancel={close}
			destroyOnClose
		>
			<Spin spinning={record?.isUpdating}>
				<Form
					form={form}
					layout="vertical"
					onFinish={(values) => {
						onOk(values);
						close();
					}}
					preserve={false}
				>
					<Form.Item name="cancelNumber" label={t`Сторно број`}>
						<Input />
					</Form.Item>
					<Form.Item
						name="cancelReason"
						label={t`Разлог сторнирања`}
						rules={[
							{
								required: true,
								message: t`Разлог је обавезан`,
							},
						]}
					>
						<Input.TextArea rows={4} />
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	);
}
