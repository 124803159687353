import { t } from '@lingui/macro';
import { notification } from 'antd';

import { StaticComponents } from '../../components/StaticComponents';
import stores from '../../stores/index.mobx';

function getThermalPrinter() {
	return stores.devices.thermalPrinters[0];
}

export function print(data) {
	const device = getThermalPrinter();
	if (!device) {
		StaticComponents.notification.error({
			message: t`Грешка`,
			description: t`Термални штампач није подешен`,
		});

		throw new Error('Thermal printer not configured');
	}

	if (window.electron) {
		window.electron.thermalPrinter.print(data);
	}
}
