import { t } from '@lingui/macro';
import { Input } from 'antd';

import { TableInput } from '../../../../../../../components/TableInput';

type Props = {
	disabled?: boolean;
};

export function StickersRulesInput({ disabled, ...props }: Props) {
	return (
		<TableInput
			addButtonText={t`Додај правило`}
			emptyText={t`Нема правила`}
			iconPath="/images/icons/new/gift-box.svg"
			disabled={disabled}
			columns={[
				{
					title: t`Број налепница`,
					dataIndex: 'stickerCount',
					editable: true,
					width: 180,
					component: <Input type="number" />,
					rules: [
						{
							required: true,
							message: t`Број налепница је обавезан`,
						},
					],
				},
				{
					title: t`Попуст`,
					dataIndex: 'discount',
					editable: true,
					component: <Input type="number" addonAfter="%" max={100} min={0} />,
					rules: [
						{
							required: true,
							message: t`Попуст је обавезан`,
						},
					],
				},
			]}
			{...props}
		/>
	);
}
