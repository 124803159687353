import { t, Trans } from '@lingui/macro';
import { Alert, Checkbox, Input, Select } from 'antd';
import gs1 from 'gs1-key';

import PaymentMethodsOrder from './Components/PaymentMethodsOrder';
import { CreatePage } from '../../../../../components/Page';
import WarehouseSelect from '../../../../../components/Selects/WarehouseSelect';
import { PAYMENT_TYPE_MAP } from '../../../../../constants/journal';
import stores from '../../../../../stores/index.mobx';

const { company } = stores;

const hasFeature = company?.hasFeature;

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'stores',
		model: 'store',
		view: {
			useEdit: true,
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/prodajna-mesta/',
			shouldFetch: false,
			table: {
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'displayName',
						key: 'displayName',
						shouldLink: true,
					},
					{
						title: t`Адреса`,
						dataIndex: 'fullAddress',
						ellipsis: true,
						key: 'fullAddress',
						responsive: ['sm'],
					},
					{
						title: t`Телефон`,
						dataIndex: 'phone',
						key: 'phone',
						ellipsis: true,
						responsive: ['sm'],
					},
					...(hasFeature?.('stock')
						? [
								{
									title: t`Складиште`,
									dataIndex: ['warehouse', 'name'],
									key: 'warehouse',
									ellipsis: true,
									responsive: ['sm'],
								},
						  ]
						: []),
				],
				showDisabledActions: true,
			},
			createButtonText: t`Додај продајно место`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете ово продајно место?`,
		},
		edit: {
			width: 600,
			title: {
				new: t`Додавање продајног места`,
				existing: (entity) =>
					t`Измена продајног места ${entity?.displayName || ''}`,
			},
			shouldFetch: true,
			beforeSave: (entity) => {
				const paymentMethodsOrder = entity.paymentMethodsOrder;
				Object.keys(PAYMENT_TYPE_MAP).forEach((paymentType) => {
					if (!paymentMethodsOrder.includes(Number(paymentType))) {
						paymentMethodsOrder.push(Number(paymentType));
					}
				});
				return {
					...entity,
				};
			},
			fields: () => [
				{
					key: 'info',
					fields: [
						{
							component: (
								<Alert
									type="info"
									message={t`Подаци продајног места се користе за идентификацију
								продајног места у апликацији. На фискалним рачунима ће бити
								одштампани подаци који су уписани на безбедносном елементу.`}
								/>
							),
							span: 24,
						},
					],
				},
				{
					key: 'basic',
					label: t`Основни подаци`,
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							rules: [
								{
									required: true,
									message: t`Назив продајног места је обавезан`,
								},
							],
							component: <Input />,
							span: 24,
						},
						{
							key: 'address',
							label: t`Адреса`,
							component: <Input />,
							span: 24,
						},
						{
							key: 'zip',
							label: t`Поштански број`,
							component: <Input />,
							xs: 24,
							sm: 12,
						},
						{
							key: 'city',
							label: t`Град`,
							component: <Input />,
							xs: 24,
							sm: 12,
						},
						{
							key: 'gln',
							label: t`ГЛН`,
							component: <Input />,
							span: 24,
							rules: [
								{
									validator: (_, value) => {
										if (!value || value.length === 0) {
											return Promise.resolve();
										}

										if (!gs1.validate(value)) {
											return Promise.reject();
										}
										return Promise.resolve();
									},
									message: t`ГЛН није исправан`,
								},
							],
						},
						{
							key: 'phone',
							label: t`Телефон`,
							component: <Input />,
							span: 24,
						},
					],
				},
				...(hasFeature?.('stock')
					? [
							{
								key: 'warehouse',
								label: t`Инвентар`,
								fields: [
									{
										key: 'warehouseId',
										label: t`Складиште`,
										component: <WarehouseSelect />,
										span: 24,
									},
								],
							},
					  ]
					: []),

				{
					key: 'print',
					label: t`Начини издавања рачуна`,
					fields: [
						{
							key: ['printMethod', 'thermal'],
							valuePropName: 'checked',
							initialValue: true,
							component: (
								<Checkbox>
									<Trans>Термални штампач</Trans>
								</Checkbox>
							),
							style: { marginBottom: '0px' },
						},
						{
							key: ['printMethod', 'a4'],
							initialValue: true,
							valuePropName: 'checked',
							component: (
								<Checkbox>
									<Trans>А4 штампач</Trans>
								</Checkbox>
							),
							style: { marginBottom: '0px' },
						},
						{
							key: ['printMethod', 'email'],
							initialValue: true,
							valuePropName: 'checked',
							component: (
								<Checkbox>
									<Trans>Електронска пошта</Trans>
								</Checkbox>
							),
						},
					],
				},
				{
					key: 'paymentMethods',
					label: t`Начини плаћања`,
					fields: [
						{
							label: t`Редослед начина плаћања`,
							key: 'paymentMethodsOrder',
							initialValue: [1, 3, 2, -1, 4, 5, 6, 0],
							component: <PaymentMethodsOrder />,
							style: { width: '100%' },
							span: 24,
						},
					],
				},
				{
					key: 'fiscalReceipt',
					label: t`Фискални рачун`,
					fields: [
						{
							key: 'printBankAccounts',
							valuePropName: 'checked',
							span: 24,
							component: (
								<Checkbox style={{ width: '100%' }}>
									<Trans>
										Штампај бројеве текућег рачуна предузећа уколико је врста
										плаћања пренос на рачун
									</Trans>
								</Checkbox>
							),
						},
						{
							key: 'printDiscounts',
							valuePropName: 'checked',
							span: 24,
							component: (
								<Checkbox style={{ width: '100%' }}>
									<Trans>Штампај остварене попусте</Trans>
								</Checkbox>
							),
						},
						{
							key: 'language',
							label: t`Писмо`,
							initialValue: 'sr-Cyrl-RS',
							component: (
								<Select>
									<Select.Option value="sr-Cyrl-RS">Ћирилица</Select.Option>
									<Select.Option value="sr-Latn-RS">Лatinica</Select.Option>
									{/* <Select.Option value="en-US">English</Select.Option> */}
								</Select>
							),
						},
						{
							key: 'defaultUnknownAmountAdvance',
							valuePropName: 'checked',
							component: (
								<Checkbox>
									<Trans>
										Подразумевано издавање аванса без познатог коначног износа
									</Trans>
								</Checkbox>
							),
						},
					],
				},
				{
					key: 'settings',
					label: t`Подешавања`,
					fields: [
						{
							label: t`Начин приказа артикала на каси`,
							key: 'productPickerType',
							initialValue: 'list',
							component: (
								<Select>
									<Select.Option value="list">
										<Trans>Листа</Trans>
									</Select.Option>
									<Select.Option value="grid">
										<Trans>Мрежа</Trans>
									</Select.Option>
								</Select>
							),
						},
						{
							key: 'isSoundEnabled',
							valuePropName: 'checked',
							component: (
								<Checkbox>
									<Trans>
										Репродукуј звучне сигнале приликом додавања и брисања
										артикала са рачуна, као и приликом грешке при додавању
										артикла на рачун
									</Trans>
								</Checkbox>
							),
						},
					],
				},
			],
		},
	});
