class BaseError extends Error {
	constructor(
		message,
		error,
		variables = undefined,
		additionalErrors = undefined
	) {
		const [errorCode, errorMessage] = error;

		const derivedDescription = Object.keys(variables || {}).reduce(
			(prev, curr) =>
				prev.replace(
					new RegExp(`:${curr}`, 'g'),
					Array.isArray(variables[curr])
						? variables[curr].join(', ')
						: variables[curr]
				),
			errorMessage
		);

		super(message);

		this.message = message;
		this.description = derivedDescription;
		this.errorCode = errorCode;
		this.variables = variables;
		this.additionalErrors = additionalErrors;
	}

	toJSON() {
		return {
			...this,
		};
	}
}

export default BaseError;
