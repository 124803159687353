import { t } from '@lingui/macro';
import { Input } from 'antd';

import { CreatePage } from '../../../../components/Page';
import stores from '../../../../stores/index.mobx';
import styles from './SaleChannels.module.less';
import { NoImage } from '../../../../components/NoImage';
import SingleImageUpload from '../../../../components/SingleImageUpload';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'saleChannels',
		model: 'saleChannel',
		view: {
			useEdit: true,
		},
		edit: {
			width: 500,
			title: {
				new: t`Додавање канала продаје`,
				existing: (entity) => t`Измена канала продаје ${entity?.name || ''}`,
			},
			beforeSave: (values) => {
				return {
					...values,
					logoFileId: values.logoFileId?.id,
				};
			},
			shouldFetch: false,
			fields: (record) => [
				{
					key: 'row1',
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							rules: [{ required: true, message: t`Назив је обавезан` }],
							component: <Input />,
							span: 24,
						},
						{
							key: 'logoFileId',
							label: t`Лого`,
							component: (
								<SingleImageUpload
									resource="saleChannelLogo"
									record={record}
									recordProperty="logoFile"
								/>
							),
							span: 24,
							rerenderOnChange: true,
						},
					],
				},
			],
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/kanali-prodaje/',
			empty: {
				image: 'online-store',
				text: t`Нема канала продаје`,
			},
			shouldFetch: false,
			table: {
				columns: [
					{
						title: '',
						key: 'logo',
						width: 50,
						render: (_, record) => {
							if (record.applicationId) {
								const sc =
									stores.marketplaceApplications.installedByApplicationId?.[
										record.applicationId
									];
								const image = sc?.logoFile;
								return (
									<img
										src={image?.urls['64x64']}
										alt=""
										className={styles.image}
									/>
								);
							} else if (record.logoFile) {
								return (
									<img
										src={record.logoFile.urls['64x64']}
										alt=""
										className={styles.image}
									/>
								);
							} else {
								return <NoImage name={record.name} size="small" />;
							}
						},
					},
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
						shouldLink: true,
					},
				],
			},
			createButtonText: t`Додај канал продаје`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете овај канал продаје?`,
		},
	});
