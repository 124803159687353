import { t } from '@lingui/macro';
import { Form, FormInstance, Input, Modal, Spin } from 'antd';
import { useCallback, useState } from 'react';
import { Calculation } from '../../../../../../../../stores/Calculation.mobx';
import { flowResult } from 'mobx';
import { InvoiceSelect } from '../../../../../../Documents/OutgoingInvoices/Components/InvoiceSelect';
interface Props {
	open: boolean;
	onCancel: () => void;
	onOk: () => void;
	calculation?: Calculation;
	form: FormInstance;
}

export default function ConnectInvoiceModal({
	open,
	onCancel = () => null,
	onOk = () => null,
	calculation,
	form: parentForm,
}: Props) {
	const [form] = Form.useForm();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const submit = useCallback(async () => {
		setIsSubmitting(true);
		try {
			await flowResult(
				calculation.connectInvoice(form.getFieldValue('invoiceId'))
			);
			parentForm.setFieldValue('invoiceId', form.getFieldValue('invoiceId'));
			onOk();
		} catch (e) {
			//
		} finally {
			setIsSubmitting(false);
		}
	}, [calculation, form, onOk, parentForm]);

	return (
		<Modal
			centered
			open={open}
			width={400}
			title={t`Повезивање калкулације са фактуром`}
			onCancel={onCancel}
			onOk={submit}
		>
			<Spin spinning={isSubmitting}>
				<Form form={form} onFinish={submit}>
					<Form.Item
						label={t`Фактура`}
						name="invoiceId"
						rules={[
							{
								required: true,
								message: t`Обавезан је унос фактуре`,
							},
						]}
					>
						<InvoiceSelect
							direction="incoming"
							types={['invoice']}
							partnerId={calculation?.partnerId}
							hideNotInSystem
						/>
					</Form.Item>
					<input type="submit" style={{ display: 'none' }}></input>
				</Form>
			</Spin>
		</Modal>
	);
}
