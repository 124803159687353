import { t, Trans } from '@lingui/macro';
import { DatePicker, Input, InputNumber, Select } from 'antd';

import { RulesInput } from './Components/RulesInput';
import { CreatePage } from '../../../../components/Page';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'discounts',
		model: 'discount',
		view: {
			useEdit: true,
		},
		edit: {
			width: 900,
			title: {
				new: t`Додавање попуста`,
				existing: (entity) => t`Измена попуста ${entity?.name || ''}`,
			},
			beforeSave: (entity) => {
				entity.beginAt = entity.interval?.[0];
				entity.endAt = entity.interval?.[1];
				delete entity.interval;
				return entity;
			},
			beforeSetFormFields: (entity) => {
				entity.interval = [entity.beginAt, entity.endAt];
				return entity;
			},
			shouldFetch: true,
			fields: [
				{
					key: 'basic',
					label: t`Основне информације`,
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							rules: [{ required: true, message: t`Назив је обавезан` }],
							component: <Input />,
							span: 24,
						},
						{
							dataIndex: 'type',
							label: t`Тип`,
							key: 'type',
							rules: [{ required: true, message: t`Тип је обавезан` }],
							component: (
								<Select>
									<Select.Option value="percentage">
										<Trans>Проценат</Trans>
									</Select.Option>
									<Select.Option value="fixed" disabled>
										<Trans>Износ</Trans>
									</Select.Option>
								</Select>
							),
							span: 12,
						},
						{
							key: 'percentage',
							label: t`Проценат`,
							rules: [{ required: true, message: t`Проценат је обавезан` }],
							component: (
								<InputNumber
									addonAfter="%"
									step={0.01}
									min={0}
									max={100}
									style={{ width: '100%' }}
								/>
							),
							span: 12,
						},
					],
				},
				{
					key: 'interval',
					label: t`Период`,
					fields: [
						{
							key: 'interval',
							dataIndex: 'interval',
							component: (
								<DatePicker.RangePicker
									showTime
									format="LL HH:mm:ss"
									style={{ width: '100%' }}
									allowEmpty={[true, true]}
								/>
							),
							span: 24,
						},
					],
				},
				{
					key: 'rules',
					label: t`Правила`,
					fields: [
						{
							key: 'rules',
							dataIndex: 'rules',
							component: <RulesInput />,
							span: 24,
						},
					],
				},
			],
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/podesavanja/popusti/',
			empty: {
				image: 'tag',
				text: t`Нема попуста`,
			},
			shouldFetch: true,
			table: {
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
						shouldLink: true,
						ellipsis: true,
					},
					{
						title: t`Проценат`,
						dataIndex: 'percentage',
						key: 'percentage',
						render: (value) => `${value}%`,
						ellipsis: true,
						width: 100,
					},
					{
						title: t`Период`,
						key: 'interval',
						render: (value, entity) => {
							return `${
								entity?.beginAt
									? t`Од ${entity.beginAt.format('LL HH:mm:ss')} `
									: ''
							}${
								entity?.endAt
									? `${entity?.beginAt ? t`до` : t`До`} ${entity.endAt.format(
											'LL HH:mm:ss'
									  )}`
									: ''
							}`;
						},
						ellipsis: true,
						responsive: ['xl'],
					},
				],
			},
			createButtonText: t`Додај попуст`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете овај попуст?`,
		},
	});
