import { Button } from 'antd';
import { Product } from '../../../../../../stores/Product.mobx';
import { observer } from 'mobx-react-lite';
import { t } from '@lingui/macro';

import { useDrawer } from '../../../../../../components/Page';
interface Props {
	product?: Product;
	mode: 'sales' | 'purchases';
}

const ShowAllBase = (props: Props) => {
	const { product } = props;

	const [, openDrawer, , , , Drawer] = useDrawer(`product-${props.mode}`);
	return (
		<>
			<Button
				type="link"
				size="small"
				onClick={() => openDrawer(product.id)}
			>{t`Прикажи све`}</Button>
			<Drawer />
		</>
	);
};

export const ShowAll = observer(ShowAllBase);
