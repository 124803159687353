import { t } from '@lingui/macro';
import { ConfigProvider, Drawer, Empty, Input, Table, Tag } from 'antd';
import clamp from 'lodash/clamp';
import { observer } from 'mobx-react-lite';
import { useState, useRef, useEffect, useMemo } from 'react';
import { useKey, useWindowSize } from 'react-use';
import { useVT } from 'virtualizedtableforantd4';

import styles from './Search.module.less';
import { NoImage } from '../../../../../../components/NoImage';
import {
	addToDrawersRegistry,
	useDrawer,
} from '../../../../../../components/Page';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import { StringParam, useQueryParam } from 'use-query-params';
const Search = () => {
	const inputRef = useRef(null);
	const [inputText, setInputText] = useState('');
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [visualIndicatorPos, setVisualIndicatorPos] = useState(0);
	const [data, setData] = useState([]);
	const { height } = useWindowSize();

	const [searchLoading, setSearchLoading] = useState(false);
	const searchTimeout = useRef(null);

	const [queryParam, , close, visible, emit] = useDrawer('product-search');
	const [extraParam] = useQueryParam(`product-search/extra`, StringParam);

	const { currentSaleChannelId } = useMemo(() => {
		try {
			return JSON.parse(decodeURIComponent(extraParam));
		} catch {
			return {};
		}
	}, [extraParam]);

	const {
		products: { search },
	} = stores;

	useEffect(() => {
		if (inputText && inputText !== '') {
			setSearchLoading(true);
			setData([]);

			clearTimeout(searchTimeout.current);
			searchTimeout.current = setTimeout(async () => {
				const searchResults = search(inputText).reduce(
					(acc, { item, matches }: any) => {
						if (item.hasVariants) {
							const variantMatches = matches.filter((match) =>
								match.key.startsWith('variants')
							);
							if (variantMatches.length === 0) {
								acc.push(
									...item.variants
										.filter((variant) => {
											if (!currentSaleChannelId) {
												return true;
											}

											return variant.saleChannelIds.includes(
												currentSaleChannelId
											);
										})
										.map((variant) => ({
											...variant,
											name: item.name,
											coverImage: item.coverImage,
											categories: item.categories,
											product: item,
											variant,
											priceWithoutTaxInCurrency:
												variant.priceWithoutTaxInCurrency.bind(variant),

											currentStorePrice: variant.currentStorePrice,
										}))
								);
							} else {
								item.variants
									.filter((variant) => {
										if (!currentSaleChannelId) {
											return true;
										}

										return variant.saleChannelIds.includes(
											currentSaleChannelId
										);
									})
									.forEach((variant) => {
										const variantMatch = variantMatches.find(
											(match) =>
												`${variant[match.key.split('.')?.[1]]}` === match.value
										);
										if (variantMatch) {
											acc.push({
												...variant,
												name: item.name,
												coverImage: item.coverImage,
												categories: item.categories,
												product: item,
												variant,
												priceWithoutTaxInCurrency:
													variant.priceWithoutTaxInCurrency.bind(variant),
												currentStorePrice: variant.currentStorePrice,
											});
										}
									});
							}
						} else {
							if (
								!currentSaleChannelId ||
								(currentSaleChannelId &&
									item.saleChannelIds.includes(currentSaleChannelId))
							) {
								acc.push({
									...item,
									product: item,
									variant: null,
									priceWithoutTaxInCurrency:
										item.priceWithoutTaxInCurrency.bind(item),
									currentStorePrice: item.currentStorePrice,
								});
							}
						}

						return acc;
					},
					[]
				);

				setData(searchResults);
				setSelectedIndex(0);
				setSearchLoading(false);
			}, 500);
		} else {
			setData([]);
		}
	}, [search, inputText, currentSaleChannelId]);

	const tableHeight = (height - 49 - 30 - 40) | 0;
	const maxVisualIndicatorPos = (tableHeight / 36 - 1) | 0;

	const [vt, , vtRef] = useVT(
		() => ({ scroll: { y: tableHeight } }),
		[tableHeight]
	);

	useKey(
		'ArrowUp',
		() => {
			if (!visible) return;

			const newSelectedIndex =
				selectedIndex > 0 ? selectedIndex - 1 : data.length - 1;
			const newVisualIndicatorPos = clamp(
				newSelectedIndex === data.length - 1
					? maxVisualIndicatorPos
					: visualIndicatorPos - 1,
				0,
				maxVisualIndicatorPos
			);

			if (visualIndicatorPos === 0 && data.length > maxVisualIndicatorPos) {
				vtRef?.current?.scrollToIndex(newSelectedIndex);
			}

			setSelectedIndex(newSelectedIndex);
			setVisualIndicatorPos(newVisualIndicatorPos);
		},
		undefined,
		[selectedIndex, data.length, visualIndicatorPos, visible]
	);

	useKey(
		'ArrowDown',
		() => {
			if (!visible) return;

			const newSelectedIndex =
				selectedIndex < data.length - 1 ? selectedIndex + 1 : 0;

			const newVisualIndicatorPos = clamp(
				newSelectedIndex === 0 ? 0 : visualIndicatorPos + 1,
				0,
				maxVisualIndicatorPos
			);

			if (newVisualIndicatorPos === maxVisualIndicatorPos) {
				vtRef?.current?.scrollToIndex(newSelectedIndex - maxVisualIndicatorPos);
			}
			if (newVisualIndicatorPos === 0 && data.length > maxVisualIndicatorPos) {
				vtRef?.current?.scrollToIndex(0);
			}

			setSelectedIndex(newSelectedIndex);
			setVisualIndicatorPos(newVisualIndicatorPos);
		},
		undefined,
		[selectedIndex, data.length, visualIndicatorPos, visible]
	);

	useKey(
		'Enter',
		() => {
			if (!visible || !data[selectedIndex]) return;
			emit('select', data[selectedIndex], queryParam);
			close();
		},
		undefined,
		[visible, selectedIndex, data]
	);

	useEffect(() => {
		if (!visible) {
			setSelectedIndex(0);
			setVisualIndicatorPos(0);
		} else {
			setTimeout(() => {
				inputRef?.current?.input?.focus();
				inputRef?.current?.input?.select();
			}, 250);
		}
	}, [visible]);

	const columns = [
		{
			title: '',
			width: '3px',
			render: (text, record, index) =>
				index === selectedIndex ? (
					<div className={styles.selectedProduct} />
				) : (
					<div />
				),
		},
		{
			dataIndex: 'coverImage',
			key: 'coverImage',
			width: 50,
			render: (image, record) => {
				return image ? (
					<>
						<img src={image?.urls['64x64']} alt="" className={styles.image} />
					</>
				) : (
					<NoImage size="small" name={record.product?.name || ''} />
				);
			},
		},
		{
			title: t`Шифра`,
			dataIndex: 'sku',
			width: 80,
		},
		{
			title: t`Шиф. произв.`,
			dataIndex: 'manufacturerSku',
			width: 120,
			responsive: ['md'],
		},
		{
			title: t`Назив`,
			render: (record) => {
				return (
					<>
						{record.name}&nbsp;&nbsp;&nbsp;
						{record.variantName ? (
							<Tag bordered={false} color="magenta">
								{record.variantName}
							</Tag>
						) : (
							''
						)}
					</>
				);
			},
		},
		{
			title: t`Цена`,
			key: 'price',
			dataIndex: 'price',
			width: 120,
			render: (text, record) =>
				`${numberFormat(
					record.productType === 'product' &&
						stores.stores.currentStore?.warehouseId
						? record.currentStorePrice || record.price
						: record.price,
					true
				)}`,
		},
		{
			title: t`Бар код`,
			key: 'ean',
			dataIndex: 'ean',
			width: 160,
			responsive: ['md'],
		},
	];

	function handleInputKeyDown(event) {
		if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
			event.preventDefault();
		}
	}

	return (
		<Drawer
			open={visible}
			title={t`Претрага артикала`}
			width={1200}
			className="noPadding"
			onClose={close}
		>
			<Input
				bordered={false}
				autoFocus
				suffix={<i className="fi fi-rr-search"></i>}
				ref={inputRef}
				value={inputText}
				placeholder={t`Унесите назив, шифру или бар код`}
				onChange={({ target: { value } }) => {
					setInputText(value);
				}}
				onKeyDown={handleInputKeyDown}
			/>
			<ConfigProvider
				renderEmpty={() =>
					searchLoading ? (
						<Empty
							image={<img src="/images/icons/new/search.svg" alt="" />}
							description={t`Претрага је у току...`}
						/>
					) : inputText ? (
						<Empty
							image={
								<img
									src="/images/icons/new/search-no-results.svg"
									alt={t`Нема резултата`}
								/>
							}
							description={t`Нема резултата. Унесите други термин за претрагу.`}
						/>
					) : (
						<Empty
							image={
								<img src="/images/icons/new/search.svg" alt={t`Претрага`} />
							}
							description={t`Унесите термин за претрагу`}
						/>
					)
				}
			>
				<Table
					size="small"
					columns={columns}
					dataSource={searchLoading ? [] : data}
					pagination={false}
					rowKey="id"
					scroll={{ y: tableHeight, x: 500 }}
					components={vt}
					onRow={(record) => ({
						onClick: () => {
							emit('select', record, queryParam);
							close();
						},
					})}
				/>
			</ConfigProvider>
		</Drawer>
	);
};

const ObservedSearch = observer(Search);

addToDrawersRegistry('products-search', ObservedSearch);

export default ObservedSearch;
