import { Trans } from '@lingui/macro';
import { Button, Empty, Upload } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import styles from './AttachmentsEdit.module.less';
import { v2Client } from '../../store/client';

interface Props {
	onChange?: (files: any) => void;
	value?: any;
	resource?: string;
}

function AttachmentsEdit({
	onChange,
	value,
	resource = 'invoiceAttachment',
}: Props) {
	const uploadRef = useRef(null);

	const [fileList, setFileList] = useState([]);

	const handleChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		onChange(newFileList);
	};

	useEffect(() => {
		if (value) {
			setFileList(
				value.map((file) =>
					file.uid
						? file
						: {
								uid: file.id,
								name: file.originalFilename || file.filename,
								status: 'done',
								response: file,
						  }
				)
			);
		}
	}, [value]);

	return (
		<div className={styles.container}>
			<Upload
				ref={uploadRef}
				// key={uuid()}
				className={`${fileList.length === 0 ? styles.empty : ''}`}
				headers={{
					'x-access-token': v2Client.defaults.headers.common['x-access-token'],
				}}
				action={`${v2Client.defaults.baseURL}/files/${resource}/true`}
				multiple
				onChange={handleChange}
				fileList={fileList}
				accept="application/pdf"
				maxCount={2}
				listType="picture"
				showUploadList={{
					removeIcon: (file) => <i className="fi fi-rr-trash"></i>,
				}}
				iconRender={(file) => (
					<img src="/images/icons/new/pdf-file.svg" alt="" />
				)}
			>
				<Upload.Dragger
					showUploadList={false}
					headers={{
						'x-access-token':
							v2Client.defaults.headers.common['x-access-token'],
					}}
					action={`${v2Client.defaults.baseURL}/files/${resource}/true`}
					multiple
					onChange={handleChange}
					className={`${styles.uploadButton}`}
					openFileDialogOnClick={false}
					accept="application/pdf"
					maxCount={2}
				>
					{fileList.length === 0 && (
						<Empty
							image="/images/icons/new/paperclip.svg"
							imageStyle={{
								height: 64,
							}}
							description={
								<Trans>
									Нема прилога. Превуци прилоге или
									<br />
									кликни овде да додаш прилог.
								</Trans>
							}
						>
							<Button type="link">
								<Trans>Додај прилоге</Trans>
							</Button>
						</Empty>
					)}
					{fileList.length > 0 && (
						<Button
							className={styles.uploadButtonInner}
							icon={<i className="fi fi-rr-cloud-upload"></i>}
							disabled={fileList.length === 2}
						>
							<Trans context="Upload button">Отпреми још прилога</Trans>
						</Button>
					)}
				</Upload.Dragger>
			</Upload>
		</div>
	);
}

export default AttachmentsEdit;
