import { t } from '@lingui/macro';
import { Button, Grid } from 'antd';
import classNames from 'classnames';
import {
	Fragment,
	ReactNode,
	useImperativeHandle,
	useState,
	forwardRef,
	useEffect,
} from 'react';

import styles from './Header.module.less';
import { getApplicationName } from '../../constants/application';
import UserMenu from '../UserMenu';

type Props = {
	breadcrumb: {
		[key: string]: {
			name: string;
			icon?: ReactNode;
			hideHeader?: boolean;
		};
	};
	location: {
		pathname: string;
	};
};

export function parseProps(props) {
	const {
		breadcrumb,
		location: { pathname },
	} = props;
	const titleParts = breadcrumb[pathname]?.headerTitle?.split(' / ');
	const headerTitle = titleParts?.shift();
	const title = titleParts?.join(' / ') || breadcrumb[pathname]?.name || '';
	const icon = breadcrumb[pathname] ? breadcrumb[pathname].icon : '';
	const headerIcon = breadcrumb[pathname]
		? breadcrumb[pathname].headerIcon
		: '';

	return { title, icon, headerIcon, headerTitle };
}

function Header(props: Props, ref) {
	const APPLICATION_NAME = getApplicationName();
	const [headerExtra, setHeaderExtra] = useState(null);
	const [docsLink, setDocsLink] = useState('https://badi.rs/docs/intro');
	useImperativeHandle(ref, () => ({
		renderHeaderExtra: (extra) => setHeaderExtra(extra),
		setDocsLink: (link) => setDocsLink(`https://badi.rs/docs/${link}`),
	}));

	const { title, icon, headerIcon, headerTitle } = parseProps(props);
	const renderIcon = headerIcon || icon;
	const renderTitle = headerTitle || title;
	const renderSubTitle = headerTitle ? title : '';

	useEffect(() => {
		setTimeout(() => {
			document.title = `${APPLICATION_NAME} / ${renderTitle}${
				renderSubTitle ? ` / ${renderSubTitle}` : ''
			}`;
		});
	}, [props.breadcrumb, renderTitle, renderSubTitle, APPLICATION_NAME]);

	const screens = Grid.useBreakpoint();

	return (
		<Fragment>
			<div className={styles.header}>
				<div className={classNames(styles.section, 'header-title')}>
					{renderIcon && <div className={styles.icon}>{renderIcon}</div>}
					<div className={styles.headerTitle}>
						<div className={styles.title}>{renderTitle}</div>
						{renderSubTitle && <div className={styles.subtitle}>{title}</div>}
					</div>
				</div>
				<div className={styles.section}>
					{screens.md && (
						<div className={`${styles.extraHeader} extra-header`}>
							{headerExtra}
						</div>
					)}

					{/* <Button
						shape="circle"
						type="text"
						onClick={() => {
							//
						}}
						icon={
							<Badge count={3} size="small">
								<i className="fi fi-rr-bell"></i>
							</Badge>
						}
					></Button> */}

					<Button
						title={t`Документација`}
						shape="circle"
						type="text"
						href={docsLink}
						target="_blank"
						icon={<i className="fi fi-rr-book-alt"></i>}
						style={{ marginLeft: 8 }}
					></Button>
					{/* <Popover
						placement="bottomRight"
						content={
							<List style={{ width: 300 }}>
								<List.Item>
									<List.Item.Meta
										avatar={<i className="fi fi-rr-bell"></i>}
										title="Ново обавештење"
										description="Опис обавештења"
									/>
									<Badge dot />
								</List.Item>
							</List>
						}
						trigger="click"
					>
						<Badge dot offset={[-10, 10]}>
							<Button
								title={t`Обавештења`}
								shape="circle"
								type="text"
								target="_blank"
								icon={<i className="fi fi-rr-bell"></i>}
								style={{ marginLeft: 8 }}
							></Button>
						</Badge>
					</Popover> */}
					<UserMenu />
				</div>
			</div>
		</Fragment>
	);
}

export default forwardRef(Header);
