import axios from 'axios';
import localforage from 'localforage';
import keyBy from 'lodash/keyBy';
import { stopPersisting } from 'mobx-persist-store';

import { v2Client } from '../store/client';

let oldAppLocation = 'https://storebuddy.rs';
let shouldMigrate = false;

if (window.location.hostname === 'localhost') {
	if (window.location.port === '3003') {
		shouldMigrate = true;
	}
	oldAppLocation = 'http://localhost:3000';
}

if (window.location.hostname === 'sandbox.badi.rs') {
	shouldMigrate = true;
	oldAppLocation = 'https://sandbox.storebuddy.rs';
}

if (window.location.hostname === 'app.badi.rs') {
	shouldMigrate = true;
	oldAppLocation = 'https://storebuddy.rs';
}
export async function migrate() {
	const request = indexedDB.open('storeBuddy');

	const oldStores = await new Promise((resolve) => {
		request.onsuccess = () => {
			const stores = Object.values(request.result.objectStoreNames).filter(
				(storeName) =>
					storeName !== 'settings' &&
					storeName !== 'data' &&
					storeName !== 'local-forage-detect-blob-support' &&
					!storeName.startsWith('data_v2_')
			);
			request.result.close();
			resolve(stores);
		};
		request.onupgradeneeded = (event) => {
			(event.target as IDBOpenDBRequest).transaction.abort();
			resolve([]);
		};
	});

	const settingsStore = localforage.createInstance({
		name: 'storeBuddy',
		version: 1.0,
		storeName: 'settings',
	});

	const convertedStatus = await Promise.all(
		(oldStores as any).map(async (storeName) => {
			const store = localforage.createInstance({
				name: 'storeBuddy',
				version: 1.0,
				storeName: storeName,
			});
			const store_v2 = localforage.createInstance({
				name: 'storeBuddy',
				version: 1.0,
				storeName: storeName.replace(/_/g, '-').replace('data-', 'data_v2_'),
			});

			const converted = (await store.getItem('converted')) as boolean;

			if (converted) {
				return false;
			}

			const applicationStore = (await store.getItem('applicationStore')) as any;
			const deviceStore = (await store.getItem('deviceStore')) as any;
			const localSaleStore = (await store.getItem('localSaleStore')) as any;
			const sdcStore = (await store.getItem('sdcStore')) as any;
			const userStore = (await store.getItem('userStore')) as any;

			await store_v2.setItem('stores.store', {
				currentStoreId: applicationStore?.posSettings?.storeId || null,
			});

			await store_v2.setItem('applications.store', {
				posSettings: {
					paneWidth: applicationStore?.posSettings?.paneWidth || 0,
				},
			});
			await store_v2.setItem('users.store', {
				token: userStore?.token || null,
				authenticatedUserId: userStore?.authenticatedUserId || null,
				// all: (userStore?.list || []).map((user) => ({
				// 	...user,
				// 	_original: {
				// 		createdAt: user.createdAt,
				// 		updatedAt: user.updatedAt,
				// 		deletedAt: user.deletedAt,
				// 	},
				// })),
			});

			await store_v2.setItem('local-sales.store', {
				active: `${localSaleStore.active}`,
				all: localSaleStore.sales.map((localSale) => ({
					activeProductId: localSale.activeProductId,
					id: `${localSale.id}`,
					isDestroying: false,
					isFetching: false,
					isUpdating: false,
					items: keyBy(
						Object.values(localSale.items).map((item: any) => ({
							productId: item.product,
							variantId: item.variant,
							quantity: item.quantity,
							price: item.price,
							discount: item.discount,
						})),
						'productId'
					),
					payment: [],
					uniqueId: localSale.uniqueId,
					vatExempt: false,
					_original: {
						date: localSale.date,
					},
				})),
				saleCount: localSaleStore.saleCount,
			});

			await store_v2.setItem('sdcs.store', {
				pac: sdcStore?.sdc?.pac || null,
				password: sdcStore?.sdc?.password || null,
				url: sdcStore?.sdc?.url || null,
				useVsdc: sdcStore?.sdc?.useVsdc || null,
				pfx: sdcStore?.sdc?.pfx || null,
			});

			await store_v2.setItem('devices.store', {
				all: deviceStore.list.map((device) => ({
					name: device.name,
					id: device.id,
					type: device.type,
					connectionType: device.connectionType,
					device: device.device,
					configuration: device.configuration,
					_original: {
						createdAt: device.createdAt,
						updatedAt: device.updatedAt,
						deletedAt: device.deletedAt,
					},
				})),
			});

			const oldCurrentCompanyId = await settingsStore.getItem(
				'currentCompanyId'
			);
			if (oldCurrentCompanyId) {
				await settingsStore.setItem('session', {
					currentCompanyId: oldCurrentCompanyId,
				});
			}

			await store.setItem('converted', true);
			return true;
		})
	);
	if (convertedStatus.some((status) => status)) {
		setTimeout(() => window.location.reload());
		return true;
	}

	// Migrate from v2 to v3

	const isMigrated = await settingsStore.getItem('isMigratedFromV2ToV3');

	if (!isMigrated && shouldMigrate) {
		const params = new URL(document.location).searchParams;
		const migrationId = params.get('migrationId');

		if (migrationId) {
			const response = await v2Client.get(`/migrate/${migrationId}`);

			const entries = Object.entries(response.data);

			if (entries.length === 0) {
				await settingsStore.setItem('isMigratedFromV2ToV3', true);
				setTimeout(() => window.location.reload());
				return true;
			} else {
				for (let i = 0; i < entries.length; i++) {
					const storeName = entries[i][0];
					const data = entries[i][1] as any[];

					const store = localforage.createInstance({
						name: 'storeBuddy',
						version: 1.0,
						storeName: storeName,
					});

					const valueEntries = Object.entries(data);

					for (let k = 0; k < valueEntries.length; k++) {
						const itemKey = valueEntries[k][0];
						const itemValue = valueEntries[k][1];
						await store.setItem(itemKey, itemValue);
						console.log(storeName, itemKey, itemValue);
					}
				}
				await settingsStore.setItem('isMigratedFromV2ToV3', true);
				setTimeout(() => {
					window.location.href = `${window.location.protocol}//${window.location.hostname}`;
				}, 1000);
				return true;
			}
		} else {
			window.location.href = `${oldAppLocation}/migrate.html?returnTo=${encodeURIComponent(
				window.location.href
			)}`;
			return true;
		}
	}

	return false;
}
