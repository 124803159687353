import { t } from '@lingui/macro';
import { computed, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import stores from './index.mobx';

export type Permissions = Record<
	string,
	Record<string, Record<string, boolean>>
>;

const { Store, Entity } = CreateStore({
	name: 'role',
	paginated: false,
	persistFields: ['all'],
});

class Role extends Entity {
	@observable name?: string;
	@observable active?: boolean = true;
	@observable isAdmin?: boolean = false;
	@observable permissions?: Permissions;
	@observable stores?: string[] = null;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@computed
	get displayName() {
		if (this.name === 'Administrator') {
			return t`Администратор`;
		}
		return this.name;
	}

	get isEditable() {
		return !this.isAdmin;
	}

	get isDeletable() {
		return !this.isAdmin;
	}

	@computed
	get hasUsers() {
		return stores.users.available.some((user) => user.roleId === this.id);
	}

	hasPermission(module: string, submodule: string, action: string) {
		return this.permissions?.[module]?.[submodule]?.[action] ?? false;
	}
}

class Roles extends Store<Role> {
	constructor() {
		super(Role);
	}

	async receiveFromSocket(data: any[]): void {
		await stores.stores.afterAuth(true);
		super.receiveFromSocket(data);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await this.fetchAll();
		}
	}
}

export { Roles, Role };
