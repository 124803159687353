import { Trans } from '@lingui/macro';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import styles from './Logo.module.less';

type Props = {
	small?: boolean;
	color?: 'light' | 'dark';
	version?: string;
	showIcon?: boolean;
	showTitle?: boolean;
};

export default function Logo({
	small = false,
	color = 'light',
	version = null,
	showIcon = true,
	showTitle = true,
}: Props) {
	return (
		<span
			className={`${styles.wrapper} ${small ? styles.small : ''} ${
				color === 'dark' ? `${styles.dark} logo-dark` : ''
			}`}
		>
			<>
				{showIcon && (
					<img className={styles.logo} src="/images/newlogo.png" alt="Logo" />
				)}
				{showTitle && (
					<span className={styles.title}>
						<Trans context="logo">Бади</Trans>
					</span>
				)}
			</>
			{version && (
				<Typography.Text type="secondary" className={styles.version}>
					{version}
				</Typography.Text>
			)}
		</span>
	);
}
