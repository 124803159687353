import { useImperativeHandle, useState, forwardRef, useEffect } from 'react';

import styles from './MobileHeader.module.less';

function MobileHeader(props, ref) {
	const [headerExtra, setHeaderExtra] = useState(null);
	useImperativeHandle(ref, () => ({
		renderHeaderExtra: (extra) => setHeaderExtra(extra),
	}));

	useEffect(() => {
		document.querySelector('#root').classList.remove('has-mobile-header');
		if (headerExtra) {
			document.querySelector('#root').classList.add('has-mobile-header');
		}

		if (
			window.electron &&
			(!window.electron.platform || window.electron.platform === 'darwin')
		) {
			document.querySelector('body').classList.add('electron-mac');
		} else {
			document.querySelector('body').classList.remove('electron-mac');
		}
	}, [headerExtra]);

	return (
		headerExtra && (
			<div className={`${styles.extraHeader} mobile-header`}>{headerExtra}</div>
		)
	);
}

export default forwardRef(MobileHeader);
