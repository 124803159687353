import { ProLayout } from '@ant-design/pro-layout/es';
import { Capacitor } from '@capacitor/core';
import { t, Trans } from '@lingui/macro';
import { Grid, Row, Col } from 'antd';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { observer } from 'mobx-react-lite';
import { memo, useMemo, useRef, useCallback, useEffect } from 'react';
import { Route, Link, Routes, Navigate, useLocation } from 'react-router-dom';
import { useKey } from 'react-use';
import semverLt from 'semver/functions/lt';

import styles from './App.module.less';
// import ApplicationDocumentsCalculations from './Documents/Calculations';
import ApplicationIncomingInvoices from './Documents/IncomingInvoices';
import ApplicationDocumentsKpo from './Documents/Kpo';
import ApplicationOutgoingInvoices from './Documents/OutgoingInvoices';
import UnderConstruction from './Global/UnderConstruction';
import Home from './Home';
import ApplicationPartners from './Partners';
import ApplicationPOS from './POS';
// import PrintDeclarations from './Print/Declarations';
// import PrintPrices from './Print/Prices';
import ApplicationProductAvailability from './Products/Availability';
import ApplicationProductCategories from './Products/Categories';
import ApplicationProductProducts from './Products/Products';
import ApplicationReceiptsCalendar from './Receipts/Calendar';
import ApplicationReceiptsList from './Receipts/List';
import ApplicationSettings from './Settings';
import ApplicationSettingsAbout from './Settings/About';
import ApplicationSettingsDevices from './Settings/Devices';
import ApplicationSettingsDiscounts from './Settings/Discounts';
import ApplicationSettingsExchangeRates from './Settings/ExchangeRates';
import ApplicationSettingsSaleChannels from './Settings/SaleChannels';
import ApplicationSettingsIntegrationsApiKeys from './Settings/Integrations/ApiKeys';
import ApplicationSettingsIntegrationsLocalApi from './Settings/Integrations/LocalApi';
// import ApplicationSettingsIntegrationsWebhooks from './Settings/Integrations/Webhooks';
// import ApplicationSettingsLabels from './Settings/Labels';
import ApplicationSettingsMarketplace from './Settings/Marketplace';
import ApplicationSettingsMarketplaceInstalled from './Settings/Marketplace/Pages/Installed';
import ApplicationSettingsMarketplaceMy from './Settings/Marketplace/Pages/My';
import ApplicationSettingsOrganizationCompany from './Settings/Organization/Company';
import ApplicationSettingsMojBadiSettings from './Settings/MojBadi/Settings';
import ApplicationSettingsMojBadiOffers from './Settings/MojBadi/Offers';
import ApplicationSettingsOrganizationStores from './Settings/Organization/Stores';
import ApplicationSettingsOrganizationWarehouses from './Settings/Organization/Warehouses';
import ApplicationSettingsRoles from './Settings/Roles';
import ApplicationSettingsSDC from './Settings/SDC';
import ApplicationSettingsTaxRates from './Settings/TaxRates';
import ApplicationSettingsUnits from './Settings/Units';
import ApplicationUsers from './Settings/Users';
import ApplicationStocksCalculation from './Stocks/Calculation';
import ApplicationStocksInitial from './Stocks/Initial';
import ApplicationStocksPricechange from './Stocks/Pricechange';
import ApplicationStocksStock from './Stocks/Stock';
import ApplicationStocksTurnover from './Stocks/Turnover';
import Header from '../../components/Header';
import Logo from '../../components/Logo';
import MobileHeader from '../../components/MobileHeader';
import SDCStatusModal from '../../components/SDCStatusModal';
import { StaticComponents } from '../../components/StaticComponents';
import {
	APPLICATION_VERSION,
	MINIMUM_CLIENT_VERSION,
} from '../../constants/application';
import { useFeature } from '../../hooks/feature';
import { usePermissions } from '../../hooks/permissions';
import { GlobalNavigator } from '../../lib/GlobalNavigator';
import stores from '../../stores/index.mobx';

SafeArea.getSafeAreaInsets().then(({ insets }) => {
	document.documentElement.style.setProperty('--inset-top', insets.top + 'px');
});

const RouteRender = memo(({ route, headerRef, mobileHeaderRef }) => {
	const location = useLocation();

	if (route.path === '/app') {
		return <Navigate to={'/app/home'} />;
	}

	if (
		route.routes?.length &&
		route.path.replace('/*', '') === location.pathname
	) {
		return <Navigate to={route.routes[0].path} />;
	}

	if (route.page) {
		return (
			<>
				<route.page.Page
					ref={(ref) => {
						headerRef?.current?.renderHeaderExtra?.(
							ref?.renderHeader?.() || null
						);
						headerRef?.current?.setDocsLink?.(ref?.getDocsLink?.() || 'intro');

						mobileHeaderRef?.current?.renderHeaderExtra?.(
							ref?.renderHeader?.() || null
						);
					}}
				/>
			</>
		);
	}

	return route.component ? (
		<>
			<route.component
				routes={route.routes}
				onRouteChange={(path, ref) => {
					headerRef?.current?.renderHeaderExtra?.(
						ref?.renderHeader?.() || null
					);
					headerRef?.current?.setDocsLink?.(ref?.getDocsLink?.() || 'intro');
					mobileHeaderRef?.current?.renderHeaderExtra?.(
						ref?.renderHeader?.() || null
					);
				}}
				ref={(ref) => {
					headerRef?.current?.renderHeaderExtra?.(
						ref?.renderHeader?.() || null
					);
					headerRef?.current?.setDocsLink?.(ref?.getDocsLink?.() || 'intro');

					mobileHeaderRef?.current?.renderHeaderExtra?.(
						ref?.renderHeader?.() || null
					);
				}}
			/>
		</>
	) : null;
});

const RoutesRender = memo(({ routes, headerRef, mobileHeaderRef }) => (
	<Routes>
		{routes.map((route) => (
			<Route
				path={`${(route.routePath || route.path).replace('/app', '')}${
					!route.exact ? '/*' : ''
				}`}
				key={route.path}
				element={
					<RouteRender
						route={route}
						headerRef={headerRef}
						mobileHeaderRef={mobileHeaderRef}
					/>
				}
			/>
		))}
	</Routes>
));

function Application() {
	useEffect(() => {
		if (window.electron) {
			if (
				semverLt(
					window.electron.clientVersion || '0.0.0',
					MINIMUM_CLIENT_VERSION
				)
			) {
				StaticComponents.notification.error({
					message: t`Верзија апликације`,
					style: {
						width: 600,
					},
					description: (
						<Trans>
							Верзија апликације коју имате инсталирану је застарела. <br />
							Молимо вас да ажурирате апликацију. Нову верзију апликације можете
							преузети са{' '}
							<a href="https://dvsoftware.rs/proizvodi/badi" target="_blank">
								https://dvsoftware.rs/proizvodi/badi{' '}
							</a>
							<br />
							<br />
							<strong>Пажња: </strong> коришћењем старије верзије апликације
							може доћи до непредвиђених проблема у раду.
						</Trans>
					),
					duration: 0,
				});
			}
		}
	}, []);

	const {
		marketplaceCategories: { isFetching, available },
		company: { flatRateTax },
		initialStocks: { hasInitial, isInitialDraft },
	} = stores;

	const screens = Grid.useBreakpoint();

	const marketplaceRoutes = useMemo(() => {
		const defaultRoutes = [
			// {
			// 	path: '/app/settings/applications/marketplace/featured',
			// 	name: 'Preporučeno',
			// 	exact: true,
			// 	hideInMenu: screens.md,
			// 	isInSubmenu: !screens.md,
			// 	headerTitle: 'Podešavanja / Aplikacije / Prodavnica / Preporučeno',
			// 	icon: (
			// 		<img
			// 			src="/images/icons/new/marketplace/star.svg"
			// 			alt="Preporučeno"
			// 			className="anticon"
			// 		/>
			// 	),
			// 	headerIcon: (
			// 		<img
			// 			src="/images/icons/new/marketplace/star.svg"
			// 			alt="Preporučeno"
			// 			className="anticon"
			// 		/>
			// 	),
			// },
		];

		if (isFetching) {
			return defaultRoutes;
		}

		return [
			...defaultRoutes,
			...available.map(({ id, name, icon }) => ({
				name,
				path: `/app/settings/applications/marketplace/category/${id}`,
				routePath: `/app/settings/applications/marketplace/category/:id`,
				exact: true,
				hideInMenu: screens.md,
				isInSubmenu: !screens.md,

				headerTitle: t`Подешавања / Апликације / Продавница / ${name}`,
				icon: (
					<img
						src={`/images/icons/new/marketplace/${icon}.svg`}
						alt={name}
						className="anticon"
					/>
				),
				headerIcon: (
					<img
						src={`/images/icons/new/marketplace/${icon}.svg`}
						alt={name}
						className="anticon"
					/>
				),
				page: ApplicationSettingsMarketplace(),
			})),
		];
	}, [isFetching, available, screens]);

	const [
		hasUsersFeature,
		hasIntegrationsFeature,
		hasStockFeature,
		hasDocumentsFeature,
		hasReportsFeature,
	] = useFeature([
		'users',
		'integrations',
		'stock',
		'documents',
		'store_management',
		'reports',
	]);

	useKey(
		(e) => {
			return (
				(e.metaKey || e.ctrlKey) &&
				e.shiftKey &&
				e.key === 'd' &&
				window.electron
			);
		},
		() => {
			window.electron.openDevTools();
		},
		undefined,
		[]
	);

	// POS
	const hasPosAccessPermission = usePermissions('pos', 'pos', 'access');
	// Documents
	const hasDocumentsIncomingInvoicesPermission = usePermissions(
		'documents',
		'incoming-invoices',
		'view'
	);

	const hasDocumentsOutgoingInvoicesPermission = usePermissions(
		'documents',
		'outgoing-invoices',
		'view'
	);
	const hasDocumentsKpoPermission = usePermissions('documents', 'kpo', 'view');

	const hasReceiptsAccessPermission = usePermissions(
		'receipts',
		'receipts',
		'access'
	);

	const hasStockTurnoverAccessPermission = usePermissions(
		'stocks',
		'turnover',
		'access'
	);

	const hasStockCalculationAccessPermission = usePermissions(
		'stocks',
		'calculation',
		'access'
	);

	const hasStockStockAccessPermission = usePermissions(
		'stocks',
		'stock',
		'access'
	);

	const hasStockAccessPermission = useMemo(
		() => hasStockTurnoverAccessPermission || hasStockStockAccessPermission,
		[hasStockTurnoverAccessPermission, hasStockStockAccessPermission]
	);

	const hasDocumentsCalculationsPermission = usePermissions(
		'documents',
		'calculations',
		'view'
	);
	const hasDocumentsCorrectionsPermission = usePermissions(
		'documents',
		'corrections',
		'view'
	);
	const hasDocumentsLevelingPermission = usePermissions(
		'documents',
		'leveling',
		'list'
	);
	const hasDocumentsCashBillsPermission = usePermissions(
		'documents',
		'cashbills',
		'list'
	);
	const hasDocumentsInventoryPermission = usePermissions(
		'documents',
		'inventory',
		'list'
	);
	const hasDocumentsWriteoffsPermission = usePermissions(
		'documents',
		'writeoffs',
		'list'
	);

	const hasIntegrationsPermission = usePermissions(
		'settings',
		'integrations',
		'access'
	);

	const hasSettingsRolesViewPermission = usePermissions(
		'settings',
		'roles',
		'view'
	);
	const hasSettingsUsersViewPermission = usePermissions(
		'settings',
		'users',
		'view'
	);
	const hasSettingsCompanyViewPermission = usePermissions(
		'settings',
		'company',
		'view'
	);
	const hasSettingsSdcViewPermission = usePermissions(
		'settings',
		'sdc',
		'view'
	);
	const hasSaleChannels = stores.saleChannels.available.length > 0;

	const hasDocumentsPermissions =
		hasDocumentsIncomingInvoicesPermission ||
		hasDocumentsOutgoingInvoicesPermission ||
		hasDocumentsKpoPermission ||
		hasDocumentsCalculationsPermission ||
		hasDocumentsCorrectionsPermission ||
		hasDocumentsLevelingPermission ||
		hasDocumentsCashBillsPermission ||
		hasDocumentsInventoryPermission ||
		hasDocumentsWriteoffsPermission;

	const routes = useMemo(
		() => [
			{
				path: '/app',
				hideInMenu: true,
			},
			{
				path: '/app/home',
				name: t`Почетна`,
				icon: (
					<img
						src="/images/icons/new/home-page.svg"
						alt=""
						className="anticon"
					/>
				),
				page: Home(),
				// component: ApplicationPOS,
				// hideInMenu: !hasPosAccessPermission,
			},
			{
				path: '/app/pos',
				exact: true,
				name: t`Каса`,
				icon: (
					<img
						src="/images/icons/new/cash-register.svg"
						alt=""
						className="anticon"
					/>
				),
				component: ApplicationPOS,
				hideInMenu: !hasPosAccessPermission,
			},
			{
				name: t`Каталог`,
				path: '/app/catalog',
				exact: true,
				icon: (
					<img src="/images/icons/new/flyer.svg" alt="" className="anticon" />
				),
				component: ApplicationProductCategories,
				routes: [
					{
						icon: (
							<img
								src="/images/icons/new/folder.svg"
								alt=""
								className="anticon"
							/>
						),
						path: '/app/catalog/categories',
						routePath: '/app/catalog/categories/:page?',
						name: t`Категорије`,
						headerTitle: t`Каталог / Категорије`,
						page: ApplicationProductCategories(),
						isInSubmenu: true,
					},
					{
						icon: (
							<img
								src="/images/icons/new/delivery.svg"
								alt="Artikli"
								className="anticon"
							/>
						),
						path: '/app/catalog/products',
						name: t`Артикли`,
						headerTitle: t`Каталог / Артикли`,
						page: ApplicationProductProducts(),
						isInSubmenu: true,
					},
					{
						icon: (
							<img
								src="/images/icons/new/select-all.svg"
								alt=""
								className="anticon"
							/>
						),
						path: '/app/catalog/availability',
						name: t`Доступност`,
						headerTitle: t`Каталог / Доступност`,
						page: ApplicationProductAvailability(),
						isInSubmenu: true,
						hideInMenu: !hasSaleChannels,
					},
				],
			},
			...((hasDocumentsPermissions && hasDocumentsFeature) ||
			(hasDocumentsKpoPermission &&
				(flatRateTax || stores.company.hasKpoEntries))
				? [
						{
							name: t`Документи`,
							path: '/app/documents',
							exact: true,
							icon: (
								<img
									src="/images/icons/new/copy.svg"
									alt=""
									className="anticon"
								/>
							),
							routes: [
								...(flatRateTax || stores.company.hasKpoEntries
									? [
											{
												path: '/app/documents/kpo',
												icon: (
													<img
														src="/images/icons/new/book.svg"
														alt="KPO knjiga"
														className="anticon"
													/>
												),
												exact: true,
												name: t`КПО књига`,
												headerTitle: t`Документи / КПО књига`,
												page: ApplicationDocumentsKpo(),
												hideInMenu: !hasDocumentsKpoPermission,
												isInSubmenu: true,
											},
									  ]
									: []),
								{
									path: '/app/documents/invoices/incoming',
									icon: (
										<img
											src="/images/icons/new/download.svg"
											alt="Ulazni dokumenti"
											className="anticon"
										/>
									),
									exact: true,
									name: t`Улазни документи`,
									headerTitle: t`Документи / Улазни документи`,
									page: ApplicationIncomingInvoices(),
									hideInMenu: !hasDocumentsIncomingInvoicesPermission,
									isInSubmenu: true,
								},
								{
									path: '/app/documents/invoices/outgoing',
									icon: (
										<img
											src="/images/icons/new/upload.svg"
											alt=""
											className="anticon"
										/>
									),
									exact: true,
									name: t`Излазни документи`,
									headerTitle: t`Документи / Излазни документи`,
									page: ApplicationOutgoingInvoices(),
									hideInMenu:
										!hasDocumentsPermissions ||
										!hasDocumentsFeature ||
										!hasDocumentsOutgoingInvoicesPermission,
									isInSubmenu: true,
								},
							],
						},
				  ]
				: []),
			...(hasStockAccessPermission && hasStockFeature
				? [
						{
							name: t`Залихе`,
							path: '/app/stocks',
							exact: true,
							icon: (
								<img
									src="/images/icons/new/shelf.svg"
									alt=""
									className="anticon"
								/>
							),
							routes: [
								{
									path: '/app/stocks/turnover',
									icon: (
										<img
											src="/images/icons/new/trolley.svg"
											alt=""
											className="anticon"
										/>
									),
									exact: true,
									name: t`Промет залиха`,
									headerTitle: t`Залихе / Промет залиха`,
									page: ApplicationStocksTurnover(),
									hideInMenu: !hasStockTurnoverAccessPermission,
									isInSubmenu: true,
								},
								{
									path: '/app/stocks/calculations',
									icon: (
										<img
											src="/images/icons/new/calculator.svg"
											alt=""
											className="anticon"
										/>
									),
									exact: true,
									name: t`Калкулације`,
									headerTitle: t`Залихе / Калкулације`,
									disabled: !hasInitial || isInitialDraft,
									page: ApplicationStocksCalculation(),
									hideInMenu: !hasStockCalculationAccessPermission,
									isInSubmenu: true,
								},
								{
									path: '/app/stocks/price-changes',
									icon: (
										<img
											src="/images/icons/new/price-change.svg"
											alt=""
											className="anticon"
										/>
									),
									exact: true,
									disabled: !hasInitial || isInitialDraft,
									name: t`Нивелације`,
									headerTitle: t`Залихе / Нивелације`,
									page: ApplicationStocksPricechange(),
									hideInMenu: !hasStockTurnoverAccessPermission,
									isInSubmenu: true,
								},
								{
									path: '/app/stocks/stock',
									icon: (
										<img
											src="/images/icons/new/inventory.svg"
											alt=""
											className="anticon"
										/>
									),
									exact: true,
									disabled: !hasInitial || isInitialDraft,
									name: t`Инвентар`,
									headerTitle: t`Залихе / Инвентар`,
									page: ApplicationStocksStock(),
									hideInMenu: !hasStockStockAccessPermission,
									isInSubmenu: true,
								},
								{
									path: '/app/stocks/initial',
									exact: true,
									name: t`Почетно стање`,
									headerTitle: t`Залихе / Почетно стање`,
									page: ApplicationStocksInitial(),
									hideInMenu: true,
									isInSubmenu: true,
								},
								{
									path: '/app/stocks/kep',
									icon: (
										<img
											src="/images/icons/new/book.svg"
											alt=""
											className="anticon"
										/>
									),
									exact: true,
									disabled: !hasInitial || isInitialDraft,
									name: t`КЕП књига`,
									headerTitle: t`Залихе / КЕП књига`,
									page: UnderConstruction(),
									hideInMenu: !hasStockStockAccessPermission,
									isInSubmenu: true,
								},
							],
						},
				  ]
				: []),
			...(hasReceiptsAccessPermission
				? [
						{
							path: '/app/receipts',
							exact: true,
							name: t`Фискални рачуни`,
							icon: (
								<img
									src="/images/icons/new/receipt.svg"
									alt=""
									className="anticon"
								/>
							),
							routes: [
								{
									path: '/app/receipts/list',
									icon: (
										<img
											src="/images/icons/new/invoice.svg"
											alt=""
											className="anticon"
										/>
									),
									exact: true,
									name: t`Издати рачуни`,
									headerTitle: t`Фискални рачуни / Издати рачуни`,
									isInSubmenu: true,
									page: ApplicationReceiptsList(),
								},
								{
									path: '/app/receipts/calendar',
									icon: (
										<img
											src="/images/icons/new/calendar.svg"
											alt=""
											className="anticon"
										/>
									),
									exact: true,
									name: t`По данима`,
									headerTitle: t`Фискални рачуни / По данима`,
									isInSubmenu: true,
									page: ApplicationReceiptsCalendar(),
								},
							],
						},
				  ]
				: []),
			{
				path: '/app/settings',
				exact: false,
				hideInMenu: screens.md,
				name: t`Подешавања`,
				icon: (
					<img
						src="/images/icons/new/settings.svg"
						alt=""
						className="anticon"
					/>
				),
				routes: [
					{
						path: '/app/settings/about',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`О апликацији`,
						headerTitle: t`Подешавања / О апликацији`,
						icon: (
							<img
								src="/images/icons/new/information.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/information.svg"
								alt=""
								className="anticon"
							/>
						),
						component: ApplicationSettingsAbout,
					},

					{
						path: '/app/settings/organization/company',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Предузеће`,
						headerTitle: t`Подешавања / Предузеће`,
						hideInMenu: !hasSettingsCompanyViewPermission,
						icon: (
							<img
								src="/images/icons/new/office-building.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/office-building.svg"
								alt=""
								className="anticon"
							/>
						),
						component: ApplicationSettingsOrganizationCompany,
					},
					{
						// hideInMenu: true,
						// isInSubmenu: false,
						path: '/app/settings/moj-badi',
						exact: true,
						name: t`Мој Бади`,
						headerTitle: t`Подешавања / Мој Бади`,
						isInSubmenu: !screens.md,
						icon: (
							<img
								src="/images/icons/new/gift-box.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/gift-box.svg"
								alt=""
								className="anticon"
							/>
						),
						// page: ApplicationSettingsIntegrationsApiKeys(),
						routes: [
							{
								path: '/app/settings/moj-badi/settings',
								name: t`Подешавања`,
								headerTitle: t`Подешавања / Мој Бади / Подешавања`,
								isInSubmenu: true,
								icon: (
									<img
										src="/images/icons/new/control-panel.svg"
										alt=""
										className="anticon"
									/>
								),
								headerIcon: (
									<img
										src="/images/icons/new/control-panel.svg"
										alt=""
										className="anticon"
									/>
								),
								component: ApplicationSettingsMojBadiSettings,
							},
							{
								path: '/app/settings/moj-badi/offers',
								name: t`Понуде`,
								headerTitle: t`Подешавања / Мој Бади / Понуде`,
								isInSubmenu: true,
								icon: (
									<img
										src="/images/icons/new/ticket.svg"
										alt=""
										className="anticon"
									/>
								),
								headerIcon: (
									<img
										src="/images/icons/new/ticket.svg"
										alt=""
										className="anticon"
									/>
								),
								page: ApplicationSettingsMojBadiOffers(),
							},
							// {
							// 	path: '/app/settings/moj-badi/analytics',
							// 	name: t`Аналитика`,
							// 	headerTitle: t`Подешавања / Мој Бади / Аналитика`,
							// 	isInSubmenu: true,
							// 	icon: (
							// 		<img
							// 			src="/images/icons/new/statistics.svg"
							// 			alt=""
							// 			className="anticon"
							// 		/>
							// 	),
							// 	headerIcon: (
							// 		<img
							// 			src="/images/icons/new/statistics.svg"
							// 			alt=""
							// 			className="anticon"
							// 		/>
							// 	),
							// 	component: ApplicationSettingsMojBadiSettings,
							// },
						],
					},
					{
						path: '/app/settings/organization/roles',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Улоге корисника`,
						headerTitle: t`Подешавања / Улоге корисника`,
						icon: (
							<img
								src="/images/icons/new/users.svg"
								alt=""
								className="anticon"
							/>
						),
						hideInMenu: !hasUsersFeature || !hasSettingsRolesViewPermission,
						headerIcon: (
							<img
								src="/images/icons/new/users.svg"
								alt=""
								className="anticon"
							/>
						),
						page: ApplicationSettingsRoles(),
					},
					{
						path: '/app/settings/organization/users',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Корисници`,
						headerTitle: t`Подешавања / Корисници`,
						hideInMenu: !hasUsersFeature || !hasSettingsUsersViewPermission,
						icon: (
							<img
								src="/images/icons/new/user.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/user.svg"
								alt=""
								className="anticon"
							/>
						),
						page: ApplicationUsers(),
					},
					{
						path: '/app/settings/organization/warehouses',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Складишта`,
						headerTitle: t`Подешавања / Складишта`,
						hideInMenu: !hasStockFeature,
						icon: (
							<img
								src="/images/icons/new/warehouse.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/warehouse.svg"
								alt=""
								className="anticon"
							/>
						),
						page: ApplicationSettingsOrganizationWarehouses(),
					},
					{
						path: '/app/settings/organization/stores',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Продајна места`,
						headerTitle: t`Подешавања / Продајна места`,
						icon: (
							<img
								src="/images/icons/new/supermarket.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/supermarket.svg"
								alt=""
								className="anticon"
							/>
						),
						page: ApplicationSettingsOrganizationStores(),
					},
					{
						path: '/app/settings/sale-channels',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Канали продаје`,
						headerTitle: t`Подешавања / Канали продаје`,
						icon: (
							<img
								src="/images/icons/new/online-store.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/online-store.svg"
								alt=""
								className="anticon"
							/>
						),
						page: ApplicationSettingsSaleChannels(),
					},
					{
						path: '/app/settings/organization/partners',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Партнери`,
						headerTitle: t`Подешавања / Партнери`,
						icon: (
							<img
								src="/images/icons/new/portfolio.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/portfolio.svg"
								alt=""
								className="anticon"
							/>
						),
						page: ApplicationPartners(),
					},
					{
						path: '/app/settings/units',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Јединице мере`,
						headerTitle: t`Подешавања / Јединице мере`,
						icon: (
							<img
								src="/images/icons/new/measuring-tape.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/measuring-tape.svg"
								alt=""
								className="anticon"
							/>
						),
						page: ApplicationSettingsUnits(),
					},
					{
						path: '/app/settings/discounts',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Попусти`,
						headerTitle: t`Подешавања / Попусти`,
						icon: (
							<img src="/images/icons/new/tag.svg" alt="" className="anticon" />
						),
						headerIcon: (
							<img src="/images/icons/new/tag.svg" alt="" className="anticon" />
						),
						page: ApplicationSettingsDiscounts(),
					},
					...(hasSettingsSdcViewPermission && !process.env.REACT_APP_IS_DEMO
						? [
								{
									path: '/app/settings/sdc',
									exact: true,
									isInSubmenu: !screens.md,
									name: t`Процесор фискалних рачуна`,
									headerTitle: t`Подешавања / Процесор фискалних рачуна`,
									icon: (
										<img
											src="/images/icons/new/card-payment.svg"
											alt=""
											className="anticon"
										/>
									),
									headerIcon: (
										<img
											src="/images/icons/new/card-payment.svg"
											alt=""
											className="anticon"
										/>
									),
									component: ApplicationSettingsSDC,
								},
						  ]
						: []),
					...(window.electron
						? [
								{
									path: '/app/settings/devices',
									exact: true,
									isInSubmenu: !screens.md,
									name: t`Уређаји`,
									headerTitle: t`Подешавања / Уређаји`,
									icon: (
										<img
											src="/images/icons/new/plug.svg"
											alt=""
											className="anticon"
										/>
									),
									headerIcon: (
										<img
											src="/images/icons/new/plug.svg"
											alt=""
											className="anticon"
										/>
									),
									page: ApplicationSettingsDevices(),
									// hideInMenu: !window.electron,
								},
						  ]
						: []),
					{
						path: '/app/settings/tax-rates',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Пореске стопе`,
						headerTitle: t`Подешавања / Пореске стопе`,
						icon: (
							<img
								src="/images/icons/new/taxes.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/taxes.svg"
								alt=""
								className="anticon"
							/>
						),
						page: ApplicationSettingsTaxRates(),
					},
					{
						path: '/app/settings/exchange-rates',
						exact: true,
						isInSubmenu: !screens.md,
						name: t`Курсна листа`,
						headerTitle: t`Подешавања / Курсна листа`,
						icon: (
							<img
								src="/images/icons/new/exchange.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/exchange.svg"
								alt=""
								className="anticon"
							/>
						),
						page: ApplicationSettingsExchangeRates(),
					},
					{
						path: '/app/settings/applications',
						exact: true,
						name: t`Апликације`,
						headerTitle: t`Подешавања / Апликације`,
						isInSubmenu: !screens.md,
						icon: (
							<img
								src="/images/icons/new/application.svg"
								alt=""
								className="anticon"
							/>
						),
						headerIcon: (
							<img
								src="/images/icons/new/application.svg"
								alt=""
								className="anticon"
							/>
						),
						hideInMenu:
							!hasIntegrationsPermission || process.env.REACT_APP_IS_DEMO,
						// page: ApplicationSettingsIntegrationsApiKeys(),
						routes: [
							{
								path: '/app/settings/applications/marketplace',
								routePath: '/app/settings/applications/marketplace/*',
								name: t`Продавница`,
								headerTitle: t`Подешавања / Апликације / Продавница`,
								isInSubmenu: true,
								icon: (
									<img
										src="/images/icons/new/online-store.svg"
										alt=""
										className="anticon"
									/>
								),
								headerIcon: (
									<img
										src="/images/icons/new/online-store.svg"
										alt=""
										className="anticon"
									/>
								),
								routes: marketplaceRoutes,
								hideInMenu:
									!hasIntegrationsFeature || !hasIntegrationsPermission,
								page: ApplicationSettingsMarketplace(),
							},
							{
								path: '/app/settings/applications/installed',
								exact: true,
								name: t`Инсталиране`,
								headerTitle: t`Подешавања / Апликације / Инсталиране`,
								isInSubmenu: true,
								hideInMenu:
									!hasIntegrationsFeature || !hasIntegrationsPermission,
								icon: (
									<img
										src="/images/icons/new/inbox.svg"
										alt=""
										className="anticon"
									/>
								),
								headerIcon: (
									<img
										src="/images/icons/new/inbox.svg"
										alt=""
										className="anticon"
									/>
								),
								page: ApplicationSettingsMarketplaceInstalled(),
							},

							{
								path: '/app/settings/applications/developer',
								exact: true,
								name: t`За програмере`,
								headerTitle: t`Подешавања / Апликације / За програмере`,
								isInSubmenu: true,
								icon: (
									<img
										src="/images/icons/new/code.svg"
										alt=""
										className="anticon"
									/>
								),
								headerIcon: (
									<img
										src="/images/icons/new/code.svg"
										alt=""
										className="anticon"
									/>
								),
								routes: [
									{
										path: '/app/settings/applications/developer/my-applications',
										exact: true,
										name: t`Моје апликације`,
										headerTitle: t`Подешавања / Апликације / За програмере / Моје апликације`,
										isInSubmenu: true,
										icon: (
											<img
												src="/images/icons/new/chip.svg"
												alt=""
												className="anticon"
											/>
										),
										headerIcon: (
											<img
												src="/images/icons/new/chip.svg"
												alt=""
												className="anticon"
											/>
										),
										hideInMenu:
											!hasIntegrationsFeature || !hasIntegrationsPermission,
										page: ApplicationSettingsMarketplaceMy(),
									},
									{
										path: '/app/settings/integrations/api-keys',
										exact: true,
										name: t`АПИ кључеви`,
										headerTitle: t`Подешавања / Апликације / За програмере / АПИ кључеви`,
										isInSubmenu: true,
										icon: (
											<img
												src="/images/icons/new/key.svg"
												alt=""
												className="anticon"
											/>
										),
										headerIcon: (
											<img
												src="/images/icons/new/key.svg"
												alt=""
												className="anticon"
											/>
										),
										page: ApplicationSettingsIntegrationsApiKeys(),
									},
									...(window.electron
										? [
												{
													path: '/app/settings/integrations/local-api',
													exact: true,
													name: t`Локални АПИ`,
													headerTitle: t`Подешавања / Апликације / За програмере / Локални АПИ`,
													isInSubmenu: true,
													icon: (
														<img
															src="/images/icons/new/network.svg"
															alt=""
															className="anticon"
														/>
													),
													headerIcon: (
														<img
															src="/images/icons/new/network.svg"
															alt=""
															className="anticon"
														/>
													),
													component: ApplicationSettingsIntegrationsLocalApi,
												},
										  ]
										: []),
								],
							},
						],
					},
				],
				component: ApplicationSettings,
			},
		],
		[
			hasPosAccessPermission,
			hasSaleChannels,
			hasDocumentsPermissions,
			hasDocumentsFeature,
			hasDocumentsKpoPermission,
			flatRateTax,
			hasDocumentsIncomingInvoicesPermission,
			hasDocumentsOutgoingInvoicesPermission,
			hasStockAccessPermission,
			hasStockFeature,
			hasStockTurnoverAccessPermission,
			hasInitial,
			isInitialDraft,
			hasStockCalculationAccessPermission,
			hasStockStockAccessPermission,
			hasReceiptsAccessPermission,
			screens.md,
			hasSettingsCompanyViewPermission,
			hasUsersFeature,
			hasSettingsRolesViewPermission,
			hasSettingsUsersViewPermission,
			hasSettingsSdcViewPermission,
			hasIntegrationsFeature,
			hasIntegrationsPermission,
			marketplaceRoutes,
		]
	);

	const reducedRoutes = useMemo(() => {
		return routes.reduce((prev, curr) => {
			prev.push(curr);
			function getChildren(route) {
				if (route.routes && !route.path.startsWith('/app/settings')) {
					route.routes.forEach((child) => {
						prev.push(child);
						getChildren(child);
					});
				}
			}
			getChildren(curr);
			return prev;
		}, []);
	}, [routes]);

	const headerRef = useRef();
	const mobileHeaderRef = useRef();

	const menuItemRender = useCallback(
		(item, defaultDom) => (
			<Link
				to={!item.disabled ? item.path : '#'}
				className={item.isInSubmenu && styles.submenu}
			>
				<span className="ant-pro-base-menu-inline-item-icon">
					{item.isInSubmenu && item.icon}
				</span>
				{defaultDom}
			</Link>
		),
		[]
	);

	const menuProps = useMemo(
		() => ({
			triggerSubMenuAction:
				'ontouchstart' in window || navigator.msMaxTouchPoints
					? 'click'
					: 'hover',
		}),
		[]
	);

	const subMenuItemRender = useCallback(
		(item, defaultDom) => (
			<>
				{item.isInSubmenu && item.icon}
				{defaultDom}
			</>
		),
		[]
	);

	const menuDataRender = useCallback(() => routes, [routes]);

	const headerContentRender = useCallback(
		(p) => {
			return <Header {...p} ref={headerRef} />;
		},
		[headerRef]
	);

	const memoizedLogo = useMemo(
		() => <Logo color="dark" showTitle small version={APPLICATION_VERSION} />,
		[]
	);

	const memoizedToken = useMemo(
		() => ({
			pageContainer: {
				paddingBlockPageContainerContent: 0,
				paddingInlinePageContainerContent: 0,
			},
			sider: {
				colorMenuBackground: '#ffffff',
			},
			header: {
				heightLayoutHeader: 48,
			},
		}),
		[]
	);

	const headerRender = useCallback(
		(p, defaultDom) => {
			return (
				<>
					{defaultDom}
					{(screens.sm || screens.xs) && <MobileHeader ref={mobileHeaderRef} />}
				</>
			);
		},
		[screens.sm, screens.xs]
	);

	const menuHeaderRender = useCallback((logo) => {
		return logo;
	}, []);

	const menuFooterRender = useCallback(
		() => (
			<>
				<Row
					gutter={8}
					className={styles.footerRow}
					align="middle"
					wrap={false}
				>
					{stores.company?.logoFile && (
						<Col flex="48px">
							<img
								src={stores.company?.logoFile?.urls?.['64x64']}
								className={styles.footerLogo}
							/>
						</Col>
					)}
					<Col flex="auto" className={styles.footerCompanyName}>
						<small>{stores.company?.name}</small>
					</Col>
				</Row>
				{stores.company?.logoFile && (
					<div className={styles.footerRowCollapsed}>
						<img
							src={stores.company?.logoFile?.urls?.['64x64']}
							className={styles.footerLogo}
						/>
					</div>
				)}
			</>
		),
		[stores.company?.logoFile?.urls?.['64x64'], stores.company?.name]
	);

	return (
		<>
			<ProLayout
				className={`${styles.layout} ${
					(window.electron &&
						(window.electron.platform === 'darwin' ||
							!window.electron.platform)) ||
					window.__TAURI__
						? styles.electron
						: ''
				} ${Capacitor.isNativePlatform() ? styles.capacitor : ''}`}
				title=""
				defaultCollapsed={false}
				layout="sidemenu"
				siderWidth={screens.md ? 240 : 320}
				menuItemRender={menuItemRender}
				menuProps={menuProps}
				subMenuItemRender={subMenuItemRender}
				menuDataRender={menuDataRender}
				fixedHeader
				fixSiderbar
				disableContentMargin
				collapsedButtonRender={false}
				headerContentRender={headerContentRender}
				headerRender={headerRender}
				logo={memoizedLogo}
				token={memoizedToken}
				menuFooterRender={menuFooterRender}
				menuHeaderRender={menuHeaderRender}
			>
				<RoutesRender
					routes={reducedRoutes}
					headerRef={headerRef}
					mobileHeaderRef={mobileHeaderRef}
				/>
			</ProLayout>
			<SDCStatusModal />
			<GlobalNavigator />
		</>
	);
}

export default observer(Application);
