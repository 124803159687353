import { Select } from 'antd';
import { observer } from 'mobx-react-lite';

import stores from '../../stores/index.mobx';
import { Trans } from '@lingui/macro';

function SaleChannelSelectBase(props) {
	const {
		saleChannels: { isFetching, available },
	} = stores;

	return (
		<Select
			style={{ minWidth: '150px' }}
			loading={isFetching}
			popupMatchSelectWidth={false}
			{...props}
		>
			<Select.Option value={null}>
				<Trans>Продајно место</Trans>
			</Select.Option>
			{available.map((saleChannel) => (
				<Select.Option key={saleChannel.id} value={saleChannel.id}>
					{saleChannel.name}
				</Select.Option>
			))}
		</Select>
	);
}

export const SaleChannelSelect = observer(SaleChannelSelectBase);
