import { t } from '@lingui/macro';
import { useDebounceFn } from 'ahooks';
import { AutoComplete, FormInstance, SelectProps } from 'antd';
import { useCallback, useState } from 'react';

import { StaticComponents } from '../../../../../components/StaticComponents';
import { v2Client } from '../../../../../store/client';

interface Props {
	form: FormInstance;
	setLoading: (loading: boolean) => void;
}

export function NameInput({
	form,
	setLoading: setFormLoading,
	...props
}: Props) {
	const [data, setData] = useState<SelectProps['options']>([]);
	const [loading, setLoading] = useState(false);

	const callSearch = async (newValue) => {
		try {
			setLoading(true);

			const { data: responseData } = await v2Client.get(
				`/efaktura-companies/search/${newValue}`
			);

			setData(
				responseData.map((item: any) => ({
					...item,
					label: item.name,
					value: item.name,
				}))
			);
			return responseData;
		} catch (error) {
			return [];
		} finally {
			setLoading(false);
		}
	};

	const { run: debouncedCallSearch } = useDebounceFn(callSearch, { wait: 500 });

	const handleSearch = useCallback(async (newValue: string) => {
		if (newValue.length < 3) {
			setData([]);
			return;
		}

		await debouncedCallSearch(newValue);
	}, []);

	const handleSelect = useCallback(
		async (value, data: any) => {
			form.setFieldsValue({
				tin: data.tin,
				companyRegistrationNumber: data.companyRegistrationNumber,
				name: data.name,
				jbkjs: data.jbkjs,
				budgetUserType: '',
			});

			setFormLoading(true);
			try {
				const { data: responseData } = await v2Client.get(
					`/partners/info/by-tin/${data.tin}${
						data.jbkjs ? `?jbkjs=${data.jbkjs}` : ''
					}`
				);

				if (typeof responseData.vat === 'undefined') {
					StaticComponents.notification.warning({
						message: t`Упозорење`,
						description: t`Неуспело преузимање информације о ПДВ статусу обвезника.`,
					});
				}

				form.setFieldsValue({
					vat: responseData.vat,
					address: responseData.address,
					city: responseData.place,
					zip: responseData.zip,
					country: t`Република Србија`,
					bankAccounts: responseData.bank.map((bank, index) => ({
						bankId: bank.account.split('-')[0],
						number: bank.account.replace(/-/g, ''),
						primary: index === 0,
					})),
					budgetUserType: responseData.budgetUserType
						? `${responseData.budgetUserType}`
						: '',
				});
			} catch (error) {
				StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Неуспешно преузимање информација о партнеру са јавно доступних сервиса. Покушајте поново или ручно унесите податке.`,
				});

				form.setFieldsValue({
					vat: false,
					address: '',
					city: '',
					country: '',
					zip: '',
					bankAccounts: [],
					// tin: '',
					// companyRegistrationNumber: '',
				});
			}
			setFormLoading(false);
		},
		[form]
	);

	return (
		<AutoComplete
			loading={loading}
			showSearch
			onSearch={handleSearch}
			onSelect={handleSelect}
			notFoundContent={null}
			options={data}
			{...props}
		/>
	);
}
