import { t } from '@lingui/macro';
import { message } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import round from 'lodash/round';
import { action, autorun, computed, flow, observable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { CreateStore } from './Crud.mobx';
import { Discounts } from './Discount.mobx';
import { Offer, Offers } from './Offer.mobx';
import stores from './index.mobx';
import { Product } from './Product.mobx';
import { DayjsTransformer } from './transformers/Dayjs';
import { ReferenceTransformer } from './transformers/Reference';
import { StaticComponents } from '../components/StaticComponents';
import { InvoiceTypeAPI } from '../constants/journal';
import { bignumber, evaluate } from 'mathjs';
import union from 'lodash/union';

export type LocalSalePayment = {
	paymentType:
		| 'card'
		| 'check'
		| 'cash'
		| 'mobilemoney'
		| 'wiretransfer'
		| 'other';
	amount: number;
};

const { Store, Entity } = CreateStore({
	name: 'localSale',
	paginated: false,
	persistFields: ['all', 'active', 'saleCount'],
	clientVersion: 'local',
	persistDelay: 0,
});

export class Customer extends Entity {
	@observable firstName?: string;
	@observable lastName?: string;
	@observable code?: string;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@computed
	get fullNameShort() {
		return `${this.firstName} ${this.lastName[0]}.`;
	}

	@computed
	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}
}

class LocalSaleItem extends Entity {
	@observable key?: string;
	@observable productId?: string;
	@ReferenceTransformer('product', 'productId') product?: Product;
	@observable variantId?: string;
	@ReferenceTransformer('product', 'variantId') variant?: Product;
	@observable quantity = 0;
	@observable price = 0;
	@observable originalPrice = 0;
	@observable discount = 0;
	@observable offerId?: string;

	@ReferenceTransformer('offer', 'offerId') offer?: Offer;

	constructor(data, parent) {
		super(parent);
		this.init({ ...data, key: data.key || data.productId });
	}

	*update(data) {
		const oldQuantity = this.quantity;
		yield this.replace(data);

		if (data.quantity > oldQuantity) {
			this.getParent().recalculateOffersAdd(this.product);
		} else if (data.quantity < oldQuantity) {
			this.getParent().recalculateOffersRemove(this.product);
		}
		return this;
	}

	finalPriceOnDate(date: Dayjs, discounted = true) {
		const { taxRates, exchangeRates } = stores;
		let price = this.price;

		if (this.variant?.['isResolving'] || this.product?.['isResolving']) {
			return 0;
		}

		const currencyId = this.variant
			? this.variant?.currencyId
			: this.product?.currencyId;

		if (this.getParent().vatExempt) {
			const taxRateLabel = this.variant
				? this.variant?.taxRateLabel
				: this.product?.taxRateLabel;
			const vatRate = taxRates.byLabel(taxRateLabel).rate;
			price = round(
				evaluate('price / (1 + vatRate / 100)', {
					price: bignumber(this.price),
					vatRate: bignumber(vatRate),
				}).toNumber(),
				2
			);
		}

		if (!currencyId || currencyId === 'RSD') {
			return this.discount
				? round(
						evaluate('price * (1 - discount / 100)', {
							price: bignumber(price),
							discount: bignumber(this.discount),
						}).toNumber(),
						2
				  )
				: price;
		}

		const data = exchangeRates.getByDate(date);
		const exchangeRate = data?.[0]?.[currencyId]?.rate || 0;
		return this.discount && discounted
			? round(
					evaluate('price * exchangeRate * (1 - discount / 100)', {
						price: bignumber(price),
						exchangeRate: bignumber(exchangeRate),
						discount: bignumber(this.discount),
					}).toNumber(),
					2
			  )
			: round(
					evaluate('price * exchangeRate', {
						price: bignumber(price),
						exchangeRate: bignumber(exchangeRate),
					}).toNumber(),
					2
			  );
	}

	@computed
	get priceWithoutDiscount() {
		const { taxRates, exchangeRates } = stores;
		let price = this.price;

		const currencyId = this.variant
			? this.variant?.currencyId
			: this.product?.currencyId;

		if (this.getParent().vatExempt) {
			const taxRateLabel = this.variant
				? this.variant?.taxRateLabel
				: this.product?.taxRateLabel;
			const vatRate = taxRates.byLabel(taxRateLabel).rate;
			price = round(
				evaluate('price / (1 + vatRate / 100)', {
					price: bignumber(this.price),
					vatRate: bignumber(vatRate),
				}).toNumber(),
				2
			);
		}

		if (!currencyId || currencyId === 'RSD') {
			return price;
		}

		const lastRates = exchangeRates.lastRates;
		const exchangeRate = lastRates[currencyId].rate;
		return round(
			evaluate('price * exchangeRate', {
				price: bignumber(price),
				exchangeRate: bignumber(exchangeRate),
			}).toNumber(),
			2
		);
	}

	@computed
	get finalPrice() {
		return this.finalPriceOnDate(this.getParent().date || dayjs());
	}

	@computed
	get finalPriceWithoutDiscount() {
		return this.finalPriceOnDate(this.getParent().date || dayjs(), false);
	}
}

class LocalSale extends Entity {
	@observable items: Record<string, LocalSaleItem> = {};
	@observable activeProductId: string;
	@observable uniqueId: string;
	@DayjsTransformer date?: Dayjs;
	@observable vatExempt = false;
	@observable payment: LocalSalePayment[] = [];
	@observable invoiceType: InvoiceTypeAPI;
	@observable currentSaleChannelId?: string = null;
	@observable customer?: Customer;
	@observable isFetchingCustomer = false;

	constructor(data, parent) {
		super(parent);
		this.init(data);

		autorun(() => {
			if (stores.application.isInitialized) {
				let hasRemovedItems = false;
				this.itemsAsArray.forEach((item) => {
					if (
						!item.product?.['isResolving'] &&
						(!item.product || (item.product.hasVariants && !item.variant))
					) {
						this.removeItem(item.key);
						hasRemovedItems = true;
					}
				});

				if (hasRemovedItems) {
					StaticComponents.notification.warning({
						message: t`Обрисани артикли`,
						description: t`Неки артикли који су били на рачуну у припреми су обрисани. Артикли су уклоњени са рачуна у припреми.`,
					});
				}
			}
		});
	}

	@flow.bound
	*fetchCustomer(code: Number) {
		this.isFetchingCustomer = true;
		try {
			const response = yield this.getClient('v2').get(`/customers/${code}`);
			this.customer = new Customer(response.data, this);
		} finally {
			this.isFetchingCustomer = false;
		}
	}

	@computed
	get itemsAsArray() {
		return Object.values(this.items);
	}

	@computed
	get itemsQuantityForOffers() {
		const itemsQuantity = this.itemsAsArray.reduce((items, item) => {
			items[item.productId] = round(
				evaluate('total + quantity', {
					total: bignumber(bignumber(items[item.productId] || 0)),
					quantity: bignumber(item.quantity),
				}).toNumber(),
				3
			);
			return items;
		}, {});

		stores.offers.activeOffers.forEach((offer) => {
			if (offer.type === 'buy-n-get-m') {
				const foundItem = itemsQuantity[offer.productId];
				const foundPromotedItem = itemsQuantity[offer.promotedProductId];

				if (
					foundItem &&
					foundPromotedItem &&
					foundItem >= offer.requiredForPurchase &&
					foundPromotedItem >= offer.promotedProductQuantity
				) {
					if (offer.productId !== offer.promotedProductId) {
						const quantity = Math.floor(foundItem / offer.requiredForPurchase);
						const promotedQuantity = Math.floor(
							foundPromotedItem / offer.promotedProductQuantity
						);
						const minQuantity = Math.min(quantity, promotedQuantity);
						itemsQuantity[offer.productId] = round(
							evaluate('total - requiredForPurchase * minQuantity', {
								total: bignumber(itemsQuantity[offer.productId]),
								requiredForPurchase: bignumber(offer.requiredForPurchase),
								minQuantity: bignumber(minQuantity),
							}).toNumber(),
							3
						);
						itemsQuantity[offer.promotedProductId] = round(
							evaluate('total - promotedProductQuantity * minQuantity', {
								total: bignumber(itemsQuantity[offer.promotedProductId]),
								promotedProductQuantity: bignumber(
									offer.promotedProductQuantity
								),
								minQuantity: bignumber(minQuantity),
							}).toNumber(),
							3
						);
					} else {
						const quantity = Math.floor(
							foundItem /
								(offer.requiredForPurchase + offer.promotedProductQuantity)
						);
						itemsQuantity[offer.productId] = round(
							evaluate('total - requiredForPurchase * minQuantity', {
								total: bignumber(itemsQuantity[offer.productId]),
								requiredForPurchase: bignumber(offer.requiredForPurchase),
								minQuantity: bignumber(quantity),
							}).toNumber(),
							3
						);
					}
				}
			}
		});

		return itemsQuantity;
	}

	@computed
	get availableOfferIds() {
		const offers = [];

		(stores.offers as Offers).activeOffers.forEach((offer) => {
			Object.entries(this.itemsQuantityForOffers).forEach(
				([productId, quantity]) => {
					if (offer.type === 'buy-n-get-m' && productId === offer.productId) {
						if ((quantity as number) >= offer.requiredForPurchase) {
							if (!offers.includes(offer.id)) {
								offers.push(offer.id);
							}
						}
					}
				}
			);
		});
		return offers;
	}

	@ReferenceTransformer('offer', 'availableOfferIds') availableOffers: Offer[] =
		[];

	@computed
	get activeSaleItem() {
		return this.items[this.activeProductId];
	}

	@computed
	get total() {
		return this.itemsAsArray.reduce(
			(total, item) =>
				round(
					evaluate('total + quantity * finalPrice', {
						total: bignumber(total),
						quantity: bignumber(round(item.quantity, 3)),
						finalPrice: bignumber(item.finalPrice),
					}).toNumber(),
					2
				),
			0
		);
	}

	@computed
	get hasForeignCurrency() {
		return this.itemsAsArray.some((item) => {
			const currencyId = item.variant
				? item.variant?.currencyId
				: item.product?.currencyId;
			return currencyId !== 'RSD' && currencyId;
		});
	}

	@action.bound
	setCurrentSaleChannelId(id: string) {
		this.currentSaleChannelId = id;
	}

	@action.bound
	setPayment(payment: LocalSalePayment[]) {
		this.payment = payment;
	}

	@action.bound
	setInvoiceType(invoiceType: InvoiceTypeAPI) {
		this.invoiceType = invoiceType;
	}

	@action.bound
	setDate(date: Dayjs) {
		this.date = date;
	}

	@action.bound
	setTaxFree(vatExempt: boolean) {
		this.vatExempt = vatExempt;
	}

	@action.bound
	pushItem(item) {
		if (this.items[item.key]) {
			const current = this.items[item.key];
			current.replace({
				quantity: round(
					evaluate('cquantity + quantity', {
						cquantity: bignumber(current.quantity),
						quantity: bignumber(item.quantity),
					}).toNumber(),
					3
				),
				discount: this.items[item.key].discount,
			});
		} else {
			this.items[item.key] = new LocalSaleItem(item, this);
		}
	}

	@action.bound
	recalculateOffersRemove(product) {
		const itemsQuantity = Object.values(this.items).reduce((items, item) => {
			items[item.productId] = round(
				evaluate('total + quantity', {
					total: bignumber(bignumber(items[item.productId] || 0)),
					quantity: bignumber(item.quantity),
				}).toNumber(),
				3
			);
			return items;
		}, {});
		(stores.offers as Offers).activeOffers.forEach((offer) => {
			if (
				offer.type === 'buy-n-get-m' &&
				(product.id === offer.productId ||
					product.id === offer.promotedProductId)
			) {
				const foundItem = itemsQuantity[offer.productId];
				const foundPromotedItem = itemsQuantity[offer.promotedProductId];

				const foundOfferItem = Object.values(this.items).find(
					(item) => item.offerId === offer.id
				);

				if (foundItem && foundPromotedItem && foundOfferItem) {
					const requiredQuantity = Math.floor(
						foundItem / offer.requiredForPurchase
					);
					const promotedQuantity = Math.floor(
						foundPromotedItem / offer.promotedProductQuantity
					);

					const minQuantity =
						offer.productId !== offer.promotedProductId
							? Math.min(requiredQuantity, promotedQuantity)
							: Math.floor(
									foundItem /
										(offer.requiredForPurchase + offer.promotedProductQuantity)
							  );

					const quantityToProcess = evaluate(
						'minQuantity * promotedProductQuantity',
						{
							minQuantity: bignumber(minQuantity),
							promotedProductQuantity: bignumber(offer.promotedProductQuantity),
						}
					).toNumber();

					if (foundOfferItem.quantity < quantityToProcess) {
						foundOfferItem.offerId = null;
						foundOfferItem.price = foundOfferItem.originalPrice;
					} else if (foundOfferItem.quantity > quantityToProcess) {
						// this means that we need to split this item into two
						const newItem = foundOfferItem.clone();
						newItem.quantity = evaluate('quantity - quantityToProcess', {
							quantity: bignumber(foundOfferItem.quantity),
							quantityToProcess: bignumber(quantityToProcess),
						}).toNumber();
						newItem.offerId = null;
						newItem.key = foundOfferItem.key.split(':')[0];
						newItem.price = foundOfferItem.originalPrice;

						this.pushItem(newItem);

						if (quantityToProcess === 0) {
							this.removeItem(foundOfferItem.key, false);
						} else {
							foundOfferItem.quantity = quantityToProcess;
						}
					}
				}
			}
		});

		this.recalculateOffersAdd(product);
	}

	@action.bound
	recalculateOffersAdd(product) {
		const itemsQuantity = Object.values(this.items).reduce((items, item) => {
			items[item.productId] = round(
				evaluate('total + quantity', {
					total: bignumber(bignumber(items[item.productId] || 0)),
					quantity: bignumber(item.quantity),
				}).toNumber(),
				3
			);
			return items;
		}, {});
		(stores.offers as Offers).activeOffers.forEach((offer) => {
			if (
				offer.type === 'buy-n-get-m' &&
				(product.id === offer.productId ||
					product.id === offer.promotedProductId)
			) {
				const foundItem = itemsQuantity[offer.productId];
				const foundPromotedItem = itemsQuantity[offer.promotedProductId];

				if (foundItem && foundPromotedItem) {
					const requiredQuantity = Math.floor(
						foundItem / offer.requiredForPurchase
					);
					const promotedQuantity = Math.floor(
						foundPromotedItem / offer.promotedProductQuantity
					);
					const minQuantity =
						offer.productId !== offer.promotedProductId
							? Math.min(requiredQuantity, promotedQuantity)
							: Math.floor(
									foundItem /
										(offer.requiredForPurchase + offer.promotedProductQuantity)
							  );

					const quantityToProcess = evaluate(
						'minQuantity * promotedProductQuantity',
						{
							minQuantity: bignumber(minQuantity),
							promotedProductQuantity: bignumber(offer.promotedProductQuantity),
						}
					).toNumber();

					let processedQuantity = Object.values(this.items)
						.filter(
							(item) =>
								item.productId === offer.promotedProductId && item.offerId
						)
						.reduce((prev, curr) => {
							return prev + curr.quantity;
						}, 0);

					if (minQuantity > 0) {
						while (processedQuantity < quantityToProcess) {
							const item = Object.values(this.items).find(
								(item) =>
									item.productId === offer.promotedProductId && !item.offerId
							);

							if (item) {
								const remaining = evaluate(
									'quantity - quantityToProcess + processedQuantity',
									{
										quantity: bignumber(item.quantity),
										quantityToProcess: bignumber(quantityToProcess),
										processedQuantity: bignumber(processedQuantity),
									}
								).toNumber();

								if (remaining >= 0) {
									const newItem = item.clone();
									newItem.key = `${item.key}:${offer.id}`;
									newItem.quantity = evaluate('itemQuantity - remaining', {
										itemQuantity: bignumber(item.quantity),
										remaining: bignumber(remaining),
									}).toNumber();
									newItem.offerId = offer.id;
									newItem.price = offer.promotedProductPrice;

									this.pushItem(newItem);

									if (remaining > 0) {
										item.quantity = remaining;
									} else {
										this.removeItem(item.key, false);
									}
								}

								if (remaining < 0) {
									const newItem = item.clone();
									newItem.key = `${item.key}:${offer.id}`;
									newItem.offerId = offer.id;
									newItem.price = offer.promotedProductPrice;
									newItem.quantity = item.quantity;

									this.pushItem(newItem);
									this.removeItem(item.key, false);
								}
								processedQuantity += quantityToProcess;
							}
						}
					}

					return true;
				}
			}
		});
	}

	@action.bound
	addItem(product: Product, quantity = 0, overridePrice = undefined) {
		let discount = 0;
		let productPrice = this.currentSaleChannelId
			? product.priceBySaleChannel(this.currentSaleChannelId)
			: product.currentStorePrice || 0;
		const originalPrice = productPrice;
		let offerId = null;

		(stores.discounts as Discounts).activeDiscounts.forEach(
			(activeDiscount) => {
				if (activeDiscount.rules) {
					activeDiscount.rules.forEach((rule) => {
						if (
							rule.type === 'all' ||
							(rule.type === 'products' && rule?.value.includes(product.id)) ||
							(rule.type === 'categories' &&
								rule.value?.find((categoryId) =>
									(product.parent ? product.parent : product).categories.find(
										(category) => category.id === categoryId
									)
								))
						) {
							discount = Math.max(discount, activeDiscount.percentage);
						}
					});
				}
			}
		);

		const parent = product.parent;

		if (quantity === 0) {
			if (product.quantityFromScale && stores.devices.scales.length > 0) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Са ваге је очитана тежина 0. Проверите да ли је артикал постављен на вагу.`,
				});
			}
			return StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Количина не може бити 0`,
			});
		}

		(stores.offers as Offers).activeOffers.forEach((offer) => {
			if (offer.type === 'percentage' && product.id === offer.productId) {
				discount = Math.max(discount, offer.percentage);
				offerId = offer.id;
			}

			if (offer.type === 'fixed-price' && product.id === offer.productId) {
				productPrice = offer.fixedPrice;
				offerId = offer.id;
			}
		});

		const id = `${product.id}${product.multiplePerReceipt ? `:${uuid()}` : ''}`;

		if (product.parentId) {
			this.pushItem({
				id: product.id,
				key: id,
				price: overridePrice || productPrice,
				originalPrice,
				productId: parent.id,
				variantId: product.id,
				quantity,
				discount,
				offerId,
			});
		} else {
			this.pushItem({
				id: product.id,
				key: id,
				price: overridePrice || productPrice,
				originalPrice,
				productId: product.id,
				quantity,
				discount,
				offerId,
			});
		}

		this.recalculateOffersAdd(product);

		this.activeProductId = id;
		return true;
	}

	@action.bound
	removeItem(id: string, recalculate = true) {
		const currentIndex = this.itemsAsArray.findIndex(
			(value) => value.id === this.activeProductId
		);

		if (currentIndex === this.itemsAsArray.length - 1) {
			this.selectPreviousItem();
		} else {
			this.selectNextItem();
		}

		if (this.items[id]) {
			if (recalculate) {
				this.recalculateOffersRemove(this.items[id].product);
			}
			delete this.items[id];
		}
	}

	@action.bound selectPreviousItem() {
		const currentIndex = this.itemsAsArray.findIndex(
			(value) => value.key === this.activeProductId
		);

		if (currentIndex === -1) {
			this.activeProductId = this.itemsAsArray[0].key;
		} else if (currentIndex === 0) {
			this.activeProductId =
				this.itemsAsArray[this.itemsAsArray.length - 1].key;
		} else {
			this.activeProductId = this.itemsAsArray[currentIndex - 1].key;
		}
	}

	@action.bound selectNextItem() {
		const currentIndex = this.itemsAsArray.findIndex(
			(value) => value.key === this.activeProductId
		);

		if (currentIndex === this.itemsAsArray.length - 1) {
			this.activeProductId = this.itemsAsArray[0].key;
		} else {
			this.activeProductId = this.itemsAsArray[currentIndex + 1].key;
		}
	}

	@action.bound updateQuantity(id: string, quantity: number) {
		if (this.items[id]) {
			const product = this.items[id];
			const oldQuantity = product.quantity;
			if (quantity === 0) {
				this.removeItem(id);
				return;
			}

			product.replace({
				quantity,
			});
			if (quantity > oldQuantity) {
				this.recalculateOffersAdd(product.product);
			} else if (quantity < oldQuantity) {
				this.recalculateOffersRemove(product.product);
			}
		}
		this.activeProductId = id;
	}

	replace(data) {
		data.items = Object.fromEntries(
			Object.entries(data.items).map(([key, item]) => [
				key,
				new LocalSaleItem(item, this),
			])
		);
		super.replace(data);
	}
}

class LocalSales extends Store<LocalSale> {
	@observable active?: string;
	@observable saleCount = 0;

	constructor() {
		super(LocalSale);
	}

	@action.bound
	createSale() {
		this.saleCount += 1;
		const sale = new LocalSale(
			{
				items: {},
				id: `${this.saleCount}`,
				uniqueId: uuid(),
				activeProductId: null,
			},
			this
		);
		this.all.push(sale);
		this.active = `${this.saleCount}`;
		return sale;
	}

	@action.bound
	setActive(id: string) {
		this.active = id;
	}

	@action.bound
	removeSale(sale: LocalSale, forceCreate = false) {
		this.all = this.all.filter((s) => s.id !== sale.id);

		if (this.all.length === 0 || forceCreate) {
			// if (!otherSale || otherSale.itemsAsArray.length > 0) {
			return this.createSale();
		}

		if (!this.all.find((s) => s.id === `${this.active}`)) {
			this.active = this.all[this.all.length - 1].id;
		}
	}

	@computed
	get byUniqueId() {
		return this.available.reduce((acc, sale) => {
			acc[sale.uniqueId] = sale;
			return acc;
		}, {});
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			if (!this.all.length) {
				this.createSale();
			}

			for (const sale of this.all) {
				if (!sale.date) {
					sale.date = null;
				}
				if (sale.vatExempt) {
					sale.vatExempt = false;
				}
			}
		}
	}
}

export { LocalSales, LocalSale, LocalSaleItem };
