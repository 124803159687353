import { t, Trans } from '@lingui/macro';
import { Button, Table } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { usePermissions } from '../../../../../../hooks/permissions';
import numberFormat from '../../../../../../lib/numberFormat';
import CassaScontoEditModal from '../CassaScontoEditModal';

type CassaSconto = {
	dueDate?: string;
	amount?: string;
	id?: string;
};

interface Props {
	value?: Array<CassaSconto>;
}

function CassaScontoView({ value = [] }: Props) {
	const canEditPermission = usePermissions('documents', 'invoices', 'edit');
	const [editVisible, setEditVisible] = useState(false);

	return (
		<>
			{value?.length > 0 && (
				<Table
					size="small"
					bordered
					style={{ width: '100%' }}
					pagination={false}
					columns={[
						{
							title: t`Валута плаћања`,
							key: 'dueDate',
							dataIndex: 'dueDate',
							render: (text) => dayjs(text).format('LL'),
							sortOrder: 'ascend',
							sorter: (a, b) => dayjs(a.dueDate).diff(dayjs(b.dueDate)),
						},
						{
							title: t`Износ`,
							key: 'amount',
							dataIndex: 'amount',
							render: (text) => numberFormat(text, true),
						},
					]}
					dataSource={value}
					footer={() =>
						canEditPermission && (
							<Button
								icon={<i className="fi fi-rr-pencil"></i>}
								onClick={() => setEditVisible(true)}
							>
								<Trans>Измени износе за плаћање у року</Trans>
							</Button>
						)
					}
				/>
			)}
			{!value?.length && (
				<>
					<span>
						<Trans>Нема података</Trans>
					</span>
					{canEditPermission && (
						<>
							.{' '}
							<Button type="link" onClick={() => setEditVisible(true)}>
								<Trans>Додај износе за плаћање у року</Trans>
							</Button>
						</>
					)}
				</>
			)}
			<CassaScontoEditModal
				visible={editVisible}
				close={() => setEditVisible(false)}
			/>
		</>
	);
}

export default observer(CassaScontoView);
