import { t, Trans } from '@lingui/macro';
import { DatePicker, Form, Modal, Spin } from 'antd';
import { Dayjs } from 'dayjs';
import { useState } from 'react';

interface Props {
	visible: boolean;
	onCancel: () => void;
	onOk: (startDate: Dayjs) => Promise<void>;
}

export default function FlatRateTaxChangeModal({
	visible,
	onCancel,
	onOk,
}: Props) {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);

	async function submitForm() {
		try {
			const values = await form.validateFields();
			setIsLoading(true);
			await onOk(values.startDate);
		} catch (error) {
			return;
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Modal
			centered
			visible={visible}
			title={t`Креирање КПО књиге`}
			closable={false}
			onCancel={onCancel}
			onOk={() => {
				form.submit();
			}}
			confirmLoading={isLoading}
		>
			<Spin spinning={isLoading}>
				<Form form={form} onFinish={submitForm} layout="vertical">
					<>
						<p>
							<Trans>
								Да ли желите да креирате КПО књигу од до сада креираних
								докумената (фискалних рачуна и фактура)? Ова акција може
								потрајати неколико минута.
							</Trans>
						</p>
						<Form.Item
							name="startDate"
							label={t`Датум почетка паушалног опорезивања`}
							rules={[
								{
									required: true,
									message: t`Морате одабрати датум почетка паушалног опорезивања`,
								},
							]}
						>
							<DatePicker format={'L'} style={{ width: '100%' }} />
						</Form.Item>
					</>
				</Form>
			</Spin>
		</Modal>
	);
}
