import { createContext, useCallback, useMemo, useRef, useState } from 'react';

export const DrawerContext = createContext(null);

export const DrawerProvider = ({ children }) => {
	const data = useRef([]);
	const registeredRef = useRef({});
	const [isDrawerOpen, setIsDrawerOpen] = useState([]);

	const isOpen = useMemo(() => isDrawerOpen.length > 0, [isDrawerOpen]);
	const [registeredDrawers, setRegisteredDrawers] = useState({});
	const getRegisteredDrawer = useCallback(
		(name) => registeredDrawers[name] || (() => <></>),
		[registeredDrawers]
	);

	const registerDrawer = useCallback((name, drawer) => {
		if (registeredRef.current[name]) {
			return;
		}
		registeredRef.current[name] = drawer;
		setRegisteredDrawers(registeredRef.current);
	}, []);

	const setIsDrawerClosed = useCallback(
		(name) => {
			data.current = data.current.filter((item) => item !== name);
			setIsDrawerOpen(data.current);
		},
		[setIsDrawerOpen]
	);

	const setIsDrawerOpenCallback = useCallback(
		(name) => {
			data.current = [...data.current, name];
			setIsDrawerOpen(data.current);
		},
		[setIsDrawerOpen]
	);

	return (
		<DrawerContext.Provider
			value={{
				isDrawerOpen: isOpen,
				setIsDrawerOpen: setIsDrawerOpenCallback,
				setIsDrawerClosed,
				registerDrawer,
				getRegisteredDrawer,
			}}
		>
			{children}
		</DrawerContext.Provider>
	);
};
