import { t } from '@lingui/macro';
import { Checkbox, Tag, Tooltip } from 'antd';
import { Observer } from 'mobx-react-lite';

import { NoImage } from '../../../../components/NoImage';
import { CreatePage } from '../../../../components/Page';
import stores from '../../../../stores/index.mobx';
import styles from '../Products/Products.module.less';
import { SaleChannelFilter } from './Components/SaleChannelFilter';
export default () =>
	CreatePage({
		module: 'catalog',
		submodule: 'products',
		model: 'product',
		view: {
			useEdit: true,
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/katalog/dostupnost/',
			shouldFetch: false,
			empty: {
				text: t`Нема артикала`,
				image: 'select-all',
			},
			searchable: true,
			searchPlaceholder: t`Претражи артикле`,
			searchFilter: (values) => {
				return values.filter((value) => {
					return value.saleChannels.length > 0;
				});
			},
			customCreateButton: () => <SaleChannelFilter />,
			table: {
				listProp: 'variantsWithSaleChannels',
				showActions: false,
				useMemoizedColumns: false,
				getRecordId: (record) => record.parentId || record.id,
				columns: () => {
					return [
						{
							title: '',
							dataIndex: 'coverImage',
							key: 'coverImage',
							width: 50,
							render: (image, record) => {
								return image ? (
									<>
										<img
											src={image.urls['64x64']}
											alt="Slika"
											className={styles.image}
										/>
									</>
								) : (
									<NoImage name={record.name || ''} size="small" />
								);
							},
							shouldLink: true,
						},
						{
							title: t`Назив`,
							dataIndex: 'name',
							key: 'name',
							shouldLink: true,
							ellipsis: true,
							render: (value, record) => {
								return (
									<>
										{record.name}
										{record.variantName && (
											<>
												&nbsp;&nbsp;
												<Tag bordered={false} color="magenta">
													{record.variantName}
												</Tag>
											</>
										)}
									</>
								);
							},
						},
						{
							title: t`Сви`,
							key: 'all',
							width: 90,
							align: 'center',
							render: (value, record) => (
								<Observer>
									{() => (
										<Checkbox
											onChange={(e) => {
												record.saleChannels
													.filter(
														(sc) =>
															sc.storeId === stores.stores.currentStoreId &&
															sc.externalId &&
															!sc.trackStock
													)
													.map((sc) => sc.setAvailability(e.target.checked));
											}}
											checked={record.saleChannels
												.filter(
													(sc) => sc.storeId === stores.stores.currentStoreId
												)
												.every((sc) => sc.enabled)}
											indeterminate={
												record.saleChannels
													.filter(
														(sc) => sc.storeId === stores.stores.currentStoreId
													)
													.some((sc) => sc.enabled) &&
												!record.saleChannels
													.filter(
														(sc) => sc.storeId === stores.stores.currentStoreId
													)
													.every((sc) => sc.enabled)
											}
										/>
									)}
								</Observer>
							),
						},
						...stores.saleChannels.available
							.filter((saleChannel) => {
								console.log(
									stores.products.currentAvailabilityFilter,
									saleChannel.id,
									stores.products.currentAvailabilityFilter.includes(
										saleChannel.id
									)
								);
								return stores.products.currentAvailabilityFilter.includes(
									saleChannel.id
								);
							})
							.map((saleChannel) => {
								return {
									title: saleChannel.name,
									key: saleChannel.id,
									width: 90,
									align: 'center',
									render: (value, record) => (
										<Observer>
											{() => (
												<Tooltip
													title={(() => {
														if (
															record.saleChannels.find(
																(sc) =>
																	sc.saleChannelId === saleChannel.id &&
																	sc.storeId === stores.stores.currentStoreId
															)?.trackStock
														) {
															return t`Доступност се аутоматски мења на основу стања залиха`;
														}
														if (
															record.saleChannels.find(
																(sc) =>
																	sc.saleChannelId === saleChannel.id &&
																	sc.storeId === stores.stores.currentStoreId &&
																	!sc.externalId
															)
														) {
															return t`Артикал није потпуно повезан са овим каналом продаје`;
														}

														if (
															!record.saleChannels.find(
																(sc) =>
																	sc.saleChannelId === saleChannel.id &&
																	sc.storeId === stores.stores.currentStoreId
															)
														) {
															return t`Артикал није доступан на овом каналу продаје`;
														}

														return undefined;
													})()}
												>
													<Checkbox
														onChange={(e) => {
															record.saleChannels
																.find(
																	(sc) =>
																		sc.saleChannelId === saleChannel.id &&
																		sc.storeId === stores.stores.currentStoreId
																)
																?.setAvailability(e.target.checked);
														}}
														disabled={
															!record.saleChannels.find(
																(sc) =>
																	sc.saleChannelId === saleChannel.id &&
																	sc.storeId === stores.stores.currentStoreId &&
																	sc.externalId
															)
														}
														checked={
															record.saleChannels.find(
																(sc) =>
																	sc.saleChannelId === saleChannel.id &&
																	sc.storeId === stores.stores.currentStoreId
															)?.enabled
														}
													/>
												</Tooltip>
											)}
										</Observer>
									),
								};
							}),
					];
				},
			},
		},
	});
