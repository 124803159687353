import { SwapRightOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import {
	getMergedStatus,
	getStatusClassNames,
	InputStatus,
} from 'antd/es/_util/statusUtils';
import { ConfigContext } from 'antd/es/config-provider';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import useSize from 'antd/es/config-provider/hooks/useSize';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import useStyle from 'antd/es/date-picker/style';
import { FormItemInputContext } from 'antd/es/form/context';
import { useCompactItemContext } from 'antd/es/space/Compact';
import classNames from 'classnames';
import { useContext, useRef, useState, useEffect } from 'react';

import styles from './DimensionsInput.module.less';
import { InputNumber } from 'antd';

interface Props {
	onChange?: (value: number[]) => void;
	placeholder?: string[];
	prefixCls?: string;
	status?: InputStatus;
	size?: SizeType;
	className?: string;
	bordered?: boolean;
	value?: number[];
	focus?: boolean;
	disabled?: boolean;
	onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

function DimensionsInput({
	placeholder,
	prefixCls: customizePrefixCls,
	status: customStatus,
	size: customizeSize,
	className,
	bordered = true,
	value = [null, null, null],
	onChange,
	focus = false,
	onKeyUp,
	disabled: propsDisabled = false,
}: Props) {
	const contextDisabled = useContext(DisabledContext);
	const disabled = propsDisabled || contextDisabled;

	const placeholderW =
		placeholder?.[0] || t({ message: `Ш`, context: 'Short for Width' });
	const placeholderD =
		placeholder?.[1] || t({ message: `Д`, context: 'Short for Depth' });
	const placeholderH =
		placeholder?.[1] || t({ message: `В`, context: 'Short for Height' });

	const wInputDivRef = useRef<HTMLDivElement>(null);
	const dInputDivRef = useRef<HTMLDivElement>(null);
	const hInputDivRef = useRef<HTMLDivElement>(null);
	const dInputRef = useRef<HTMLInputElement>(null);
	const hInputRef = useRef<HTMLInputElement>(null);
	const separatorRef = useRef<HTMLDivElement>(null);

	const { getPrefixCls, direction, getPopupContainer } =
		useContext(ConfigContext);

	const prefixCls = getPrefixCls('picker', customizePrefixCls);
	const rootPrefixCls = getPrefixCls();
	const { compactSize, compactItemClassnames } = useCompactItemContext(
		prefixCls,
		direction
	);

	const [focused, setFocused] = useState([false, false, false]);

	const mergedSize = useSize((ctx) => customizeSize ?? compactSize ?? ctx);

	const [wrapSSR, hashId] = useStyle(prefixCls);

	const [activeInput, setActiveInput] = useState(null);

	useEffect(() => {
		if (!focused.some(Boolean) && activeInput !== null) {
			setActiveInput(null);
		}
	}, [focused, activeInput]);

	const formItemContext = useContext(FormItemInputContext);
	const { hasFeedback, status: contextStatus, feedbackIcon } = formItemContext;

	let activeBarWidth = 0;
	let activeBarLeft = 0;

	if (
		wInputDivRef.current &&
		dInputDivRef.current &&
		separatorRef.current &&
		activeInput !== null
	) {
		if (activeInput === 0) {
			activeBarWidth = wInputDivRef.current.offsetWidth;
		} else if (activeInput === 1) {
			activeBarLeft =
				wInputDivRef.current.offsetWidth + separatorRef.current.offsetWidth;
			activeBarWidth = dInputDivRef.current.offsetWidth;
		} else {
			activeBarLeft =
				wInputDivRef.current.offsetWidth +
				dInputDivRef.current.offsetWidth +
				separatorRef.current.offsetWidth * 2;
			activeBarWidth = hInputDivRef.current.offsetWidth;
		}
	}

	return wrapSSR(
		<div
			className={classNames(
				{
					[`${prefixCls}-${mergedSize}`]: mergedSize,
					[`${prefixCls}-borderless`]: !bordered,
					[`${prefixCls}-focused`]: activeInput !== null,
					[`${prefixCls}-disabled`]: disabled,
					[`${rootPrefixCls}-input-group-wrapper-disabled`]: disabled,
				},
				getStatusClassNames(
					prefixCls,
					getMergedStatus(contextStatus, customStatus),
					hasFeedback
				),
				hashId,
				compactItemClassnames,
				className,
				`${rootPrefixCls}-picker`,
				`${rootPrefixCls}-picker-range`,
				`${rootPrefixCls}-input-wrapper`,
				`${rootPrefixCls}-input-group`,
				`${rootPrefixCls}-input-group-wrapper`,
				styles.wrapper
			)}
		>
			<div
				className={classNames(
					`${prefixCls}-input`,
					activeInput === 0 ? `${prefixCls}-input-active` : ''
				)}
				ref={wInputDivRef}
			>
				<InputNumber
					step="any"
					min={0}
					type="number"
					controls={false}
					placeholder={placeholderW}
					value={value?.[0]}
					onFocus={() => {
						setActiveInput(0);
						setFocused([true, focused[1], focused[2]]);
					}}
					onBlur={() => {
						setFocused([false, focused[1], focused[2]]);
					}}
					onChange={(val) => {
						const newValue = [...value];
						newValue[0] = Number(val);
						onChange(newValue);
					}}
					ref={(input) => {
						setTimeout(() => {
							if (input && focus && activeInput === null) {
								input.focus();
							}
						});
					}}
					onKeyUp={onKeyUp}
					disabled={disabled}
				/>
			</div>
			<div className={`${prefixCls}-range-separator`} ref={separatorRef}>
				<span aria-label="to" className={`${prefixCls}-separator`}>
					<i className="fi fi-rr-cross-small"></i>
				</span>
			</div>
			<div
				className={classNames(
					`${prefixCls}-input`,
					activeInput === 1 ? `${prefixCls}-input-active` : ''
				)}
				ref={dInputDivRef}
			>
				<InputNumber
					step="any"
					min={0}
					type="number"
					controls={false}
					ref={dInputRef}
					placeholder={placeholderD}
					value={value?.[1]}
					onFocus={() => {
						setActiveInput(1);
						setFocused([focused[0], true, focused[2]]);
					}}
					onBlur={() => {
						setFocused([focused[0], false, focused[2]]);
					}}
					// onBlur={() => setActiveInput(null)}
					onChange={(val) => {
						const newValue = [...value];
						newValue[1] = Number(val);
						onChange(newValue);
					}}
					onKeyUp={onKeyUp}
					disabled={disabled}
				/>
			</div>
			<div className={`${prefixCls}-range-separator`} ref={separatorRef}>
				<span aria-label="to" className={`${prefixCls}-separator`}>
					<i className="fi fi-rr-cross-small"></i>
				</span>
			</div>
			<div
				className={classNames(
					`${prefixCls}-input`,
					activeInput === 2 ? `${prefixCls}-input-active` : ''
				)}
				ref={hInputDivRef}
			>
				<InputNumber
					step="any"
					min={0}
					type="number"
					controls={false}
					ref={hInputRef}
					placeholder={placeholderH}
					value={value?.[2]}
					onFocus={() => {
						setActiveInput(2);
						setFocused([focused[0], focused[1], true]);
					}}
					onBlur={() => {
						setFocused([focused[0], focused[1], false]);
					}}
					onChange={(val) => {
						const newValue = [...value];
						newValue[2] = Number(val);
						onChange(newValue);
					}}
					onKeyUp={onKeyUp}
					disabled={disabled}
				/>
			</div>
			<div
				className={`${prefixCls}-active-bar`}
				style={{
					position: 'absolute',
					left: activeBarLeft,
					width: activeBarWidth,
				}}
			></div>
			<span
				className={classNames(
					hashId,
					`${rootPrefixCls}-input-group-addon`,
					styles.addon
				)}
			>
				<Trans>цм</Trans>
			</span>
		</div>
	);
}

export default DimensionsInput;
