import { t } from '@lingui/macro';
import { ConfigProvider, Empty, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';

import stores from '../../stores/index.mobx';
import { Product } from '../../stores/Product.mobx';

interface Props extends Omit<React.ComponentProps<typeof Select>, 'value'> {
	value?: Product[] | void;
}

function ProductSelect({ onChange, value, ...rest }: Props) {
	const {
		products: { isFetching, list },
	} = stores;

	const [searchValue, setSearchValue] = useState('');
	console.log(searchValue);
	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/new/delivery.svg" />}
						imageStyle={{ height: 64 }}
						description={t`Нема артикала`}
					></Empty>
				)}
			>
				<Select
					value={value}
					{...rest}
					mode="multiple"
					style={{ width: '100%' }}
					placeholder={t`Одаберите артикле`}
					onChange={onChange}
					onSearch={(value) => {
						setSearchValue(value);
					}}
					searchValue={searchValue}
					onSelect={() => {
						setSearchValue(searchValue);
					}}
					loading={isFetching}
					virtual
					filterOption={(input, option) => {
						return (
							option.children?.toLowerCase().indexOf(input.toLowerCase()) !== -1
						);
					}}
				>
					{list.map((product: Product) => {
						if (product.hasVariants) {
							return (
								<>
									{product.variants.map((variant: Product) => {
										return (
											<Select.Option key={variant.id} value={variant.id}>
												{`${product.name} ${variant.variantName}`}
											</Select.Option>
										);
									})}
								</>
							);
						}

						return (
							<Select.Option key={product.id} value={product.id}>
								{product.name}
							</Select.Option>
						);
					})}
				</Select>
			</ConfigProvider>
		</>
	);
}

export default observer(ProductSelect);
