import { t, Trans } from '@lingui/macro';
import { Button, DatePicker, Dropdown, Grid, Menu, Space, Upload } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { StaticComponents } from '../../../../../../components/StaticComponents';
import { usePermissions } from '../../../../../../hooks/permissions';
import { v2Client } from '../../../../../../store/client';
import stores from '../../../../../../stores/index.mobx';

interface Props {
	createButtonHandler: () => void;
}

export default function CustomCreateButton({ createButtonHandler }: Props) {
	const [isUploading, setIsUploading] = useState(false);
	const canCreatePermission = usePermissions('documents', 'kpo', 'create');
	const [currentDate, setCurrentDate] = useQueryParam('date', StringParam);
	const [, setCurrentPage] = useQueryParam('page', StringParam);

	useEffect(() => {
		if (!currentDate) {
			return setCurrentDate(dayjs().toISOString());
		}
		// setCurrentDateMobx(currentDate);
	}, [currentDate, setCurrentDate]);

	function onChange(date) {
		setCurrentDate(date.toISOString());
		setCurrentPage('1');
	}

	const menu = useMemo(() => {
		return (
			<Menu
				onClick={(item) => {
					if (item.key === '2') {
						window.open(
							v2Client.defaults.baseURL +
								'/products/export?token=' +
								v2Client.defaults.headers.common['x-access-token']
						);
					}
				}}
				items={[
					{
						label: (
							<Upload
								disabled={isUploading}
								name="file"
								action={v2Client.defaults.baseURL + '/kpos/import/pausal'}
								headers={{
									'x-access-token': String(
										v2Client.defaults.headers.common['x-access-token']
									),
								}}
								accept=".pdf"
								showUploadList={false}
								onChange={(info) => {
									if (info.file.status !== 'uploading') {
										setIsUploading(true);
									}
									if (info.file.status === 'done') {
										return StaticComponents.notification.success({
											message: t`Увоз КПО књиге је завршен.`,
										});
									} else if (info.file.status === 'error') {
										return StaticComponents.notification.error({
											message: t`Грешка`,
											description: t`Увоз КПО књиге није успешан, проверите да ли је фајл у исправном формату`,
										});
									}
								}}
							>
								<Trans>Увоз са Pausal.rs</Trans>
							</Upload>
						),
						key: '1',
					},
				]}
			/>
		);
	}, [isUploading]);

	const screens = Grid.useBreakpoint();

	return (
		<Space>
			<Space.Compact>
				<Button
					icon={<i className="fi fi-rr-angle-left"></i>}
					onClick={() => onChange(dayjs(currentDate).subtract(1, 'year'))}
				/>
				<DatePicker
					onChange={onChange}
					picker="year"
					format="YYYY"
					style={{ width: 80 }}
					allowClear={false}
					value={dayjs(currentDate)}
				/>
				<Button
					icon={<i className="fi fi-rr-angle-right"></i>}
					onClick={() => onChange(dayjs(currentDate).add(1, 'year'))}
				/>
			</Space.Compact>
			<Button
				onClick={() => {
					window.open(
						v2Client.defaults.baseURL +
							`/kpos/${currentDate}/download?token=${v2Client.defaults.headers.common['x-access-token']}`
					);
				}}
			>
				<Trans>Преузми</Trans>
			</Button>
			{canCreatePermission && (
				<Dropdown.Button
					loading={isUploading}
					disabled={isUploading}
					icon={<i className="fi fi-rr-angle-small-down"></i>}
					overlay={menu}
					type="primary"
					onClick={createButtonHandler}
				>
					{screens.xs ? t`Додај` : t`Додај ставку`}
				</Dropdown.Button>
			)}
		</Space>
	);
}
