import { SwapRightOutlined } from '@ant-design/icons';
import { t } from '@lingui/macro';
import {
	getMergedStatus,
	getStatusClassNames,
	InputStatus,
} from 'antd/es/_util/statusUtils';
import { ConfigContext } from 'antd/es/config-provider';
import useSize from 'antd/es/config-provider/hooks/useSize';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import useStyle from 'antd/es/date-picker/style';
import { FormItemInputContext } from 'antd/es/form/context';
import { useCompactItemContext } from 'antd/es/space/Compact';
import classNames from 'classnames';
import { useContext, useRef, useState } from 'react';
interface Props {
	onChange: (value: number[]) => void;
	placeholder?: string[];
	prefixCls?: string;
	status?: InputStatus;
	size?: SizeType;
	className?: string;
	bordered?: boolean;
	value?: number[];
	focus?: boolean;
	onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}
export function InputRange({
	placeholder,
	prefixCls: customizePrefixCls,
	status: customStatus,
	size: customizeSize,
	className,
	bordered = true,
	value = [null, null],
	onChange,
	focus = false,
	onKeyUp,
}: Props) {
	const placeholderFrom = placeholder?.[0] || t`Од`;
	const placeholderTo = placeholder?.[1] || t`До`;

	const startInputDivRef = useRef<HTMLDivElement>(null);
	const endInputDivRef = useRef<HTMLDivElement>(null);
	const endInputRef = useRef<HTMLInputElement>(null);
	const separatorRef = useRef<HTMLDivElement>(null);

	const { getPrefixCls, direction, getPopupContainer } =
		useContext(ConfigContext);

	const prefixCls = getPrefixCls('picker', customizePrefixCls);
	const rootPrefixCls = getPrefixCls();
	const { compactSize, compactItemClassnames } = useCompactItemContext(
		prefixCls,
		direction
	);

	const mergedSize = useSize((ctx) => customizeSize ?? compactSize ?? ctx);

	const [wrapSSR, hashId] = useStyle(prefixCls);

	const [activeInput, setActiveInput] = useState(null);

	const formItemContext = useContext(FormItemInputContext);
	const { hasFeedback, status: contextStatus, feedbackIcon } = formItemContext;

	let activeBarWidth = 0;
	let activeBarLeft = 0;

	if (
		startInputDivRef.current &&
		endInputDivRef.current &&
		separatorRef.current &&
		activeInput !== null
	) {
		if (activeInput === 0) {
			activeBarWidth = startInputDivRef.current.offsetWidth;
		} else {
			activeBarLeft =
				startInputDivRef.current.offsetWidth + separatorRef.current.offsetWidth;
			activeBarWidth = endInputDivRef.current.offsetWidth;
		}
	}

	return wrapSSR(
		<div
			className={classNames(
				{
					[`${prefixCls}-${mergedSize}`]: mergedSize,
					[`${prefixCls}-borderless`]: !bordered,
					[`${prefixCls}-focused`]: activeInput !== null,
				},
				getStatusClassNames(
					prefixCls,
					getMergedStatus(contextStatus, customStatus),
					hasFeedback
				),
				hashId,
				compactItemClassnames,
				className,
				`${rootPrefixCls}-picker`,
				`${rootPrefixCls}-picker-range`
			)}
		>
			<div
				className={classNames(
					`${prefixCls}-input`,
					activeInput === 0 ? `${prefixCls}-input-active` : ''
				)}
				ref={startInputDivRef}
			>
				<input
					placeholder={placeholderFrom}
					autocomplete="off"
					value={value[0]}
					onFocus={() => setActiveInput(0)}
					// onBlur={() => setActiveInput(null)}
					onChange={(e) => {
						const newValue = [...value];
						newValue[0] = Number(e.target.value);
						onChange(newValue);
					}}
					ref={(input) => {
						setTimeout(() => {
							if (input && focus && activeInput === null) {
								input.focus();
							}
						});
					}}
					onKeyUp={(e) => {
						onKeyUp?.(e);
						if (e.key === 'Tab') {
							endInputRef.current.focus();
							e.stopPropagation();
						}
					}}
				/>
			</div>
			<div className={`${prefixCls}-range-separator`} ref={separatorRef}>
				<span aria-label="to" className={`${prefixCls}-separator`}>
					<SwapRightOutlined />
				</span>
			</div>
			<div
				className={classNames(
					`${prefixCls}-input`,
					activeInput === 1 ? `${prefixCls}-input-active` : ''
				)}
				ref={endInputDivRef}
			>
				<input
					ref={endInputRef}
					placeholder={placeholderTo}
					autocomplete="off"
					value={value[1]}
					onFocus={() => setActiveInput(1)}
					// onBlur={() => setActiveInput(null)}
					onChange={(e) => {
						const newValue = [...value];
						newValue[1] = Number(e.target.value);
						onChange(newValue);
					}}
					onKeyUp={onKeyUp}
				/>
			</div>
			<div
				className={`${prefixCls}-active-bar`}
				style={{
					position: 'absolute',
					left: activeBarLeft,
					width: activeBarWidth,
				}}
			></div>
		</div>
	);
}
