import { t, Trans } from '@lingui/macro';
import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useDrawer } from '../../../../../../components/Page';
import { MarketplaceApplication } from '../../../../../../stores/MarketplaceApplication.mobx';
import '../../Drawers/ApplicationSettings';
interface Props {
	application: MarketplaceApplication;
	visible?: boolean;
	close: () => void;
}

const InstallSuccessModal = ({ application, visible, close }: Props) => {
	const [, openApplicationSettings, , , , ApplicationSettings] = useDrawer(
		'marketplace-application-settings'
	);
	return (
		<>
			<Modal
				open={visible}
				title={t`Инсталирање апликације ${application?.name}`}
				okText={t`Отвори подешавања`}
				cancelText={t`Затвори`}
				onOk={() => {
					close();
					openApplicationSettings(application.id);
				}}
				onCancel={close}
			>
				<p>
					<Trans>Апликација {application?.name} је успешно инсталирана</Trans>
					<br />
				</p>
			</Modal>
			<ApplicationSettings />
		</>
	);
};

export default observer(InstallSuccessModal);
