import { t, Trans } from '@lingui/macro';
import {
	Alert,
	Button,
	Form,
	Input,
	message,
	notification,
	Select,
	Skeleton,
	Spin,
	Typography,
} from 'antd';
import sortBy from 'lodash/sortBy';
import { observer } from 'mobx-react-lite';
import React, {
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';

import { GlovoItem } from './types';
import DisablePasswordAutocomplete from '../../../../../../../../components/DisablePasswordAutocomplete';
import { useDrawer } from '../../../../../../../../components/Page';
import { StaticComponents } from '../../../../../../../../components/StaticComponents';
import { Title } from '../../../../../../../../components/Title';
import { ERROR_BAD_REQUEST_INVALID_CREDENTIALS } from '../../../../../../../../constants/errors';
import { v2Client } from '../../../../../../../../store/client';
import ConnectProductsDrawer from '../../../../Drawers/ConnectProductsDrawer';

type Store = {
	city: {
		name: string;
		code: string;
		currencyCode: string;
		countryCode: string;
		timeZone: string;
	};
	id: string;
	imageId: string;
	name: string;
	vertical: string;
};

type Address = {
	address: string;
	id: string;
	isMarketplace: boolean;
	store: Store;
};

interface Props {
	storeId: string;
}

function ApplicationSettingsGlovo({ storeId }, ref) {
	const [loading, setLoading] = useState(false);
	const [id, , close, ,] = useDrawer('marketplace-application-settings');
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const [username, setUsername] = useState('');

	const [areItemsLoading, setAreItemsLoading] = useState(false);
	const [items, setItems] = useState<GlovoItem[]>([]);

	const [isConnectProductsVisible, setIsConnectProductsVisible] =
		useState(false);

	const [areStoresLoading, setAreStoresLoading] = useState(false);
	const [stores, setStores] = useState<Address[] | null>(null);
	const [form] = Form.useForm();

	useEffect(() => {
		if (id) {
			setLoading(true);
			v2Client
				.get(`/marketplace-applications/installed/${id}`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setUsername(response.data.credentials?.username);
					setIsLoggedIn(!!response.data.settings?.loggedIn);
					form.setFieldValue('addressId', response.data.settings?.addressId);
					// setMenuId(response.data.settings?.menuId);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [id, storeId, form]);

	useImperativeHandle(ref, () => ({
		save: () => {
			form.submit();
		},
	}));

	useEffect(() => {
		if (isLoggedIn) {
			setAreStoresLoading(true);
			v2Client
				.get(`/marketplace-applications/${id}/functions/get-venues`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					const allStores = [];
					for (const store of response.data) {
						store.storeAddresses.forEach((address) => {
							allStores.push({
								...address,
								store,
							});
						});
					}

					setStores(allStores);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setAreStoresLoading(false);
				});
		}
	}, [isLoggedIn, storeId]);

	const showConnectProductsDrawer = useCallback(async (alwaysOpen = true) => {
		setAreItemsLoading(true);
		try {
			const itemsResponse = await v2Client.get(
				`/marketplace-applications/${id}/functions/get-items`
			);

			// setItems(sortBy(itemsResponse.data, 'name.0.value'));
			setItems(sortBy(itemsResponse.data, 'name'));
			if (alwaysOpen || itemsResponse.data.find((item) => !item.product)) {
				setIsConnectProductsVisible(true);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setAreItemsLoading(false);
		}
	}, []);

	const handleConnectProductsButtonClick = useCallback(() => {
		showConnectProductsDrawer(true);
	}, [showConnectProductsDrawer]);

	const handleSubmit = useCallback(async () => {
		const values = await form.validateFields();
		setLoading(true);
		try {
			const response = await v2Client.patch(
				`/marketplace-applications/${id}/settings`,
				{
					settings: {
						addressId: values.addressId,
						storeId: stores
							? stores.find((address) => address.id === values.addressId).store
									.id
							: null,
					},
					credentials: {
						username: values.username,
						password: values.password,
					},
				}
			);

			setIsLoggedIn(!!response.data.settings?.loggedIn);
			// setMenuId(response.data.settings?.menuId);

			// showConnectProductsDrawer(false, response.data.settings?.menuId);

			StaticComponents.notification.success({
				message: t`Подешавања су успешно сачувана`,
			});
		} catch (error) {
			if (
				error?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_INVALID_CREDENTIALS
			) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Корисничко име или лозинка нису исправни`,
				});
			}
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Дошло је до грешке приликом чувања подешавања`,
			});
		} finally {
			setLoading(false);
		}
	}, [form, id, stores]);

	const closeConnectProductsDrawer = useCallback(() => {
		setIsConnectProductsVisible(false);
	}, [setIsConnectProductsVisible]);

	const formatItem = useCallback(
		(item) => ({
			externalId: item.id,
			name: item.product?.name || item.name,
			description: item.product?.description || item.description,
			productId: item.product?.productId,
			productName: item.product?.product?.parent
				? `${item.product?.product?.parent?.name} ${item.product?.product?.name}`
				: item.product?.product?.name,
			salePrice: item.product?.salePrice,
			saleQuantity: item.product?.saleQuantity,
			enabled: item.enabled,
			metadata: {
				...(item.metadata || {}),
				collectionId: item.metadata?.collectionId || item.collectionId,
			},
			imageUrl: item.imageUrl,
			price: item.price,
			product: item.product,
		}),
		[]
	);

	return (
		<Spin spinning={loading || areStoresLoading}>
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				<Title>
					<Trans>Креденцијали</Trans>
				</Title>
				<DisablePasswordAutocomplete />
				{!isLoggedIn && (
					<>
						<Form.Item>
							<Alert
								type="info"
								message={
									<Trans>
										Користите креденцијале за приступ Glovo порталу за менаџере
									</Trans>
								}
							/>
						</Form.Item>

						<Form.Item
							name="username"
							label={t`Корисничко име`}
							rules={[
								{
									required: true,
									message: t`Обавезан је унос корисничког имена`,
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="password"
							label={t`Лозинка`}
							rules={[
								{
									required: true,
									message: t`Обавезан је унос лозинке`,
								},
							]}
						>
							<Input.Password />
						</Form.Item>
					</>
				)}
				{isLoggedIn && (
					<>
						<Form.Item>
							<Alert
								type="info"
								message={
									<Trans>
										Пријављени сте као корисник <strong>{username}</strong>.{' '}
										<Typography.Link onClick={() => setIsLoggedIn(false)}>
											Кликните овде
										</Typography.Link>{' '}
										да промените корисника.
									</Trans>
								}
							/>
						</Form.Item>
						<Title>Objekat</Title>
						{areStoresLoading && <Skeleton />}
						{!areStoresLoading && (
							<Form.Item name="addressId">
								<Select placeholder={t`Изаберите објекат`}>
									{stores?.map((address) => (
										<Select.Option key={address.id} value={address.id}>
											{address.store.name}
											<br />
											<small>{address.address}</small>
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						)}
						<Title>Administracija</Title>
						<Form.Item label={t`Повезивање артикала`}>
							<Typography.Text type="secondary">
								<Trans>
									Уколико већ имате артикле на платформи Glovo, можете их
									повезати са артиклима на вашем продајном месту.
								</Trans>
							</Typography.Text>
							<br />
							<br />
							<Button
								loading={areItemsLoading}
								onClick={handleConnectProductsButtonClick}
							>
								<Trans>Повежи артикле</Trans>
							</Button>
						</Form.Item>
					</>
				)}

				<input type="submit" style={{ display: 'none' }} />
			</Form>
			<ConnectProductsDrawer
				visible={isConnectProductsVisible}
				items={items}
				onClose={closeConnectProductsDrawer}
				formatItem={formatItem}
				categoryProperty="collectionId"
				enabledProperty="enabled"
				platformName="Glovo"
			/>
		</Spin>
	);
}

export default observer(ApplicationSettingsGlovo, { forwardRef: true });
