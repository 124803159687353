import { Trans } from '@lingui/macro';
import { Button, Drawer, Form, Space, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';

import ApplicationSettingsAnanas from './Applications/rs.badi.ananas';
import ApplicationSettingsShoppster from './Applications/rs.badi.shoppster';
import ApplicationSettingsEfaktura from './Applications/rs.storebuddy.efaktura';
import ApplicationSettingsGlovo from './Applications/rs.storebuddy.glovo';
import ApplicationSettingsMisterd from './Applications/rs.storebuddy.misterd';
import ApplicationSettingsWolt from './Applications/rs.storebuddy.wolt';
import {
	addToDrawersRegistry,
	useDrawer,
} from '../../../../../../components/Page';
import StoreSelect from '../../../../../../components/StoreSelect';
import { Title } from '../../../../../../components/Title';
import stores from '../../../../../../stores/index.mobx';
import styles from '../../Marketplace.module.less';
const ApplicationSettings = () => {
	const internalApplications = {
		'rs.storebuddy.efaktura': ApplicationSettingsEfaktura,
		'rs.storebuddy.wolt': ApplicationSettingsWolt,
		'rs.storebuddy.glovo': ApplicationSettingsGlovo,
		'rs.storebuddy.misterd': ApplicationSettingsMisterd,
		'rs.badi.ananas': ApplicationSettingsAnanas,
		'rs.badi.shoppster': ApplicationSettingsShoppster,
	};
	const [applicationId, , close, visible] = useDrawer(
		'marketplace-application-settings'
	);

	const [currentStoreId, setCurrentStoreId] = useState(
		stores.stores.currentStoreId
	);

	const {
		marketplaceApplications: { single, fetchSingle },
	} = stores;
	useEffect(() => {
		if (
			visible &&
			applicationId &&
			!single?.isFetching &&
			single?.id !== applicationId
		) {
			fetchSingle(applicationId);
		}
	}, [visible, applicationId, single?.id, single?.isFetching, fetchSingle]);

	const Component = internalApplications[single?.packageId];
	const componentRef = useRef<any>();

	const isFetching = useMemo(() => {
		return typeof single?.isFetching === 'boolean' ? single?.isFetching : true;
	}, [single?.isFetching]);

	const title = useMemo(() => {
		return isFetching ? undefined : (
			<Trans>Подешавања апликације {single?.name || ''}</Trans>
		);
	}, [isFetching, single?.name]);

	return (
		<Drawer
			open={visible}
			onClose={close}
			placement="right"
			width={800}
			title={title}
			footer={
				<>
					<Space className={styles.leftButtons}>
						<Button onClick={close}>
							<Trans>Затвори</Trans>
						</Button>
					</Space>
					<Space className={styles.rightButtons}>
						<Button
							type="primary"
							onClick={() => {
								componentRef?.current?.save();
							}}
						>
							<Trans>Сачувај</Trans>
						</Button>
					</Space>
				</>
			}
		>
			<Spin spinning={isFetching}>
				{single?.configurationPerStore && (
					<>
						<Title>
							<Trans>Продајно место</Trans>
						</Title>
						<Form.Item
							labelCol={{ span: 24 }}
							label={
								<Trans>Апликација се конфигурише по продајном месту</Trans>
							}
						>
							<StoreSelect
								value={currentStoreId}
								onChange={setCurrentStoreId}
							/>
						</Form.Item>
					</>
				)}
				{single?.internal && Component ? (
					<Component ref={componentRef} storeId={currentStoreId} />
				) : null}
			</Spin>
		</Drawer>
	);
};

const ObservedApplicationSettings = observer(ApplicationSettings);

addToDrawersRegistry(
	'marketplace-application-settings',
	ObservedApplicationSettings
);

export default ObservedApplicationSettings;
