import { Trans } from '@lingui/macro';
import { Grid, Select, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import stores from '../../../../../../stores/index.mobx';
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';
import { useCallback, useEffect } from 'react';

function SaleChannelFilterBase() {
	const { saleChannels, products } = stores;
	const screens = Grid.useBreakpoint();
	const SaleChannelQuaryParam = withDefault(
		ArrayParam,
		saleChannels.available.map((saleChannel) => saleChannel.id)
	);
	const [selectedSaleChannels, setSelectedSaleChannels] = useQueryParam(
		'saleChannels',
		SaleChannelQuaryParam
	);

	const setSelectedSaleChannelsCallback = useCallback(
		(value) => {
			const valueToSet =
				value.length === 0
					? saleChannels.available.map((saleChannel) => saleChannel.id)
					: value;

			setSelectedSaleChannels(valueToSet);
			products.setCurrentAvailabilityFilter(valueToSet);
		},
		[products, saleChannels.available, setSelectedSaleChannels]
	);

	useEffect(() => {
		products.setCurrentAvailabilityFilter(selectedSaleChannels);
	}, [products, selectedSaleChannels]);

	return (
		<Space>
			{screens.xxl && (
				<div>
					<Trans>Канали продаје</Trans>
				</div>
			)}
			<Select
				style={{ minWidth: 200 }}
				mode="multiple"
				value={selectedSaleChannels}
				options={saleChannels.available.map((saleChannel) => ({
					label: saleChannel.name,
					value: saleChannel.id,
				}))}
				onChange={(value) => {
					setSelectedSaleChannelsCallback(value);
				}}
			/>
		</Space>
	);
}

export const SaleChannelFilter = observer(SaleChannelFilterBase);
