import { t, Trans } from '@lingui/macro';
import {
	Alert,
	Button,
	Form,
	Input,
	message,
	notification,
	Skeleton,
	Spin,
	Typography,
} from 'antd';
import sortBy from 'lodash/sortBy';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { WoltItem } from './types';
import DisablePasswordAutocomplete from '../../../../../../../../components/DisablePasswordAutocomplete';
import { useDrawer } from '../../../../../../../../components/Page';
import { StaticComponents } from '../../../../../../../../components/StaticComponents';
import { Title } from '../../../../../../../../components/Title';
import { ERROR_BAD_REQUEST_INVALID_CREDENTIALS } from '../../../../../../../../constants/errors';
import { v2Client } from '../../../../../../../../store/client';
import ConnectProductsDrawer from '../../../../Drawers/ConnectProductsDrawer';

type Venue = {
	id: string;
	name: string;
	city: string;
	menuId: string;
};

interface Props {
	storeId: string;
}

function ApplicationSettingsWolt({ storeId }, ref) {
	const [loading, setLoading] = useState(false);
	const [id, , close, ,] = useDrawer('marketplace-application-settings');
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [menuId, setMenuId] = useState<string | null>(null);

	const [username, setUsername] = useState('');

	const [areItemsLoading, setAreItemsLoading] = useState(false);
	const [items, setItems] = useState<WoltItem[]>([]);

	const [isConnectProductsVisible, setIsConnectProductsVisible] =
		useState(false);

	const [isVenueLoading, setIsVenueLoading] = useState(false);
	const [venue, setVenue] = useState<Venue | null>(null);

	useEffect(() => {
		if (id) {
			setLoading(true);
			v2Client
				.get(`/marketplace-applications/installed/${id}`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setUsername(response.data.credentials?.username);
					setIsLoggedIn(!!response.data.settings?.loggedIn);
					setMenuId(response.data.settings?.menuId);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [id, storeId]);

	const [form] = Form.useForm();
	useImperativeHandle(ref, () => ({
		save: () => {
			form.submit();
		},
	}));

	useEffect(() => {
		if (isLoggedIn) {
			setIsVenueLoading(true);
			v2Client
				.get(`/marketplace-applications/${id}/functions/get-venue`, {
					headers: {
						'store-id': storeId,
					},
				})
				.then((response) => {
					setVenue(response.data.venue);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setIsVenueLoading(false);
				});
		}
	}, [isLoggedIn, storeId]);

	const showConnectProductsDrawer = useCallback(async (alwaysOpen = true) => {
		setAreItemsLoading(true);
		try {
			const itemsResponse = await v2Client.get(
				`/marketplace-applications/${id}/functions/get-items`
			);

			setItems(sortBy(itemsResponse.data, 'name.0.value'));
			if (alwaysOpen || itemsResponse.data.find((item) => !item.product)) {
				setIsConnectProductsVisible(true);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setAreItemsLoading(false);
		}
	}, []);

	const handleConnectProductsButtonClick = useCallback(() => {
		showConnectProductsDrawer(true);
	}, [showConnectProductsDrawer]);

	const handleSubmit = useCallback(async () => {
		const values = await form.validateFields();

		setLoading(true);
		try {
			const response = await v2Client.patch(
				`/marketplace-applications/${id}/settings`,
				{
					settings: {},
					credentials: {
						username: values.username,
						password: values.password,
					},
				}
			);

			setIsLoggedIn(!!response.data.settings?.loggedIn);
			setMenuId(response.data.settings?.menuId);

			showConnectProductsDrawer(false);

			StaticComponents.notification.success({
				message: t`Подешавања су успешно сачувана`,
			});
		} catch (error) {
			if (
				error?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_INVALID_CREDENTIALS
			) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Корисничко име или лозинка нису исправни`,
				});
			}
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Дошло је до грешке приликом чувања подешавања`,
			});
		} finally {
			setLoading(false);
		}
	}, [form, id]);

	const closeConnectProductsDrawer = useCallback(() => {
		setIsConnectProductsVisible(false);
	}, [setIsConnectProductsVisible]);

	const formatItem = useCallback(
		(item) => ({
			externalId: item.id,
			name: item.product?.name || item.name?.[0]?.value,
			description: item.product?.description || item.description?.[0]?.value,
			productId: item.product?.productId,
			productName: item.product?.product?.parent
				? `${item.product?.product?.parent?.name} ${item.product?.product?.name}`
				: item.product?.product?.name,
			salePrice: item.product?.salePrice,
			saleQuantity: item.product?.saleQuantity,
			enabled: item.enabled,
			metadata: {
				...(item.metadata || {}),
				category: item.metadata?.category || item.category,
			},
			imageUrl: item.image,
			price: item.baseprice?.amount,
			product: item.product,
		}),
		[]
	);

	return (
		<Spin spinning={loading || isVenueLoading}>
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				<Title>
					<Trans>Креденцијали</Trans>
				</Title>
				<DisablePasswordAutocomplete />
				{!isLoggedIn && (
					<>
						<Form.Item>
							<Alert
								type="info"
								message={
									<Trans>
										Користите креденцијале за апликацију које можете креирати
										путем Wolt merchant портала на страници{' '}
										<strong>Приступ за управљање</strong>{' '}
										<i className="fi fi-rr-arrow-small-right"></i>{' '}
										<strong>Креденцијали за апликацију</strong>, затим кликните
										на дугме <strong>Додај креденцијале</strong> и унесите
										жељено корисничко име, а за права корисника одаберите{' '}
										<strong>Менаџер објекта</strong>.
									</Trans>
								}
							/>
						</Form.Item>

						<Form.Item
							name="username"
							label={t`Корисничко име`}
							rules={[
								{
									required: true,
									message: t`Обавезан је унос корисничког имена`,
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="password"
							label={t`Лозинка`}
							rules={[
								{
									required: true,
									message: t`Обавезан је унос лозинке`,
								},
							]}
						>
							<Input.Password />
						</Form.Item>
					</>
				)}
				{isLoggedIn && (
					<>
						<Form.Item>
							<Alert
								type="info"
								message={
									<Trans>
										Пријављени сте као корисник <strong>{username}</strong>.{' '}
										<Typography.Link onClick={() => setIsLoggedIn(false)}>
											Кликните овде
										</Typography.Link>{' '}
										да промените корисника.
									</Trans>
								}
							/>
						</Form.Item>
						<Title>
							<Trans>Објекат</Trans>
						</Title>
						<Form.Item>
							{isVenueLoading && <Skeleton />}
							{!isVenueLoading && (
								<>
									<Typography.Text>
										<strong>{venue?.name}</strong>
									</Typography.Text>
									<br />
									<Typography.Text type="secondary">
										{venue?.city}
									</Typography.Text>
								</>
							)}
						</Form.Item>
						<Title>
							<Trans>Администрација</Trans>
						</Title>
						<Form.Item label={t`Повезивање артикала`}>
							<Typography.Text type="secondary">
								<Trans>
									Уколико већ имате артикле на платформи Wolt, можете их
									повезати са артиклима на вашем продајном месту.
								</Trans>
							</Typography.Text>
							<br />
							<br />
							<Button
								loading={areItemsLoading}
								onClick={handleConnectProductsButtonClick}
							>
								<Trans>Повежи артикле</Trans>
							</Button>
						</Form.Item>
					</>
				)}

				<input type="submit" style={{ display: 'none' }} />
			</Form>
			<ConnectProductsDrawer
				visible={isConnectProductsVisible}
				items={items}
				onClose={closeConnectProductsDrawer}
				formatItem={formatItem}
				categoryProperty="category"
				enabledProperty="enabled"
				platformName="Wolt"
			/>
		</Spin>
	);
}

export default observer(ApplicationSettingsWolt, { forwardRef: true });
