import { t, Trans } from '@lingui/macro';
import { Select } from 'antd';
import sortBy from 'lodash/sortBy';
import { observer } from 'mobx-react-lite';

import stores from '../../stores/index.mobx';

interface Props {
	multiple?: boolean;
}

function PaymentMeansSelect({ multiple = true, ...props }: Props) {
	const {
		company: { bankAccounts },
		application: { banks },
	} = stores;

	return (
		<Select
			showSearch
			filterOption={(input, option) => {
				return (
					option?.props.children
						.join('')
						.toLowerCase()
						.indexOf(input.toLowerCase()) !== -1
				);
			}}
			showArrow
			maxTagCount="responsive"
			popupMatchSelectWidth={false}
			optionLabelProp="label"
			mode={multiple ? 'multiple' : undefined}
			{...props}
		>
			{sortBy(bankAccounts, 'primary').map((bankAccount) => (
				<Select.Option
					key={bankAccount.number}
					value={`30:${bankAccount.number}`}
					label={<Trans>Уплатом на рачун {bankAccount.formattedNumber}</Trans>}
				>
					<Trans>Уплатом на рачун {bankAccount.formattedNumber}</Trans>{' '}
					<small>({banks[Number(bankAccount.number.substring(0, 3))]})</small>
				</Select.Option>
			))}
			<Select.Option key="10" value="10" label={t`У готовини`}>
				<Trans>У готовини</Trans>
			</Select.Option>
		</Select>
	);
}

export default observer(PaymentMeansSelect);
