import Deferred from 'promise-deferred';

import { ThermalPrinter } from './thermalPrinter';
import enUS from '../assets/locales/journal/en-US.json';
import srCyrlRS from '../assets/locales/journal/sr-Cyrl-RS.json';
import srLatnRS from '../assets/locales/journal/sr-Latn-RS.json';
import VariableFontReceipt from '../components/VariableFontReceipt';
import stores from '../stores/index.mobx';

const locales = {
	'en-US': enUS,
	'sr-Cyrl-RS': srCyrlRS,
	'sr-Latn-RS': srLatnRS,
};

export class VariableFontJournal {
	locale: Record<string, string>;
	invoiceRequest = null;
	invoiceResponse = null;
	includeSignature = false;
	advancePayments = undefined;
	advanceItems = undefined;
	lastAdvanceSale = undefined;
	additionalText = undefined;
	unknownAmountAdvance = undefined;
	printer = null;
	deferred = new Deferred();
	constructor(
		invoiceRequest: any,
		invoiceResponse: any,
		includeSignature = false,
		advancePayments = undefined,
		advanceItems = undefined,
		lastAdvanceSale = undefined,
		additionalText = undefined,
		unknownAmountAdvance = undefined
	) {
		const language = stores.stores.currentStore?.language || 'sr-Cyrl-RS';

		this.locale = locales[language];
		this.invoiceRequest = invoiceRequest;
		this.invoiceResponse = invoiceResponse;
		this.includeSignature = includeSignature;
		this.advancePayments = advancePayments;
		this.advanceItems = advanceItems;
		this.lastAdvanceSale = lastAdvanceSale;
		this.additionalText = additionalText;
		this.unknownAmountAdvance = unknownAmountAdvance;
		this.printer = new ThermalPrinter();
	}

	getRenderDeferred() {
		return this.deferred?.promise || Promise.resolve();
	}

	getComponent() {
		return (
			<VariableFontReceipt
				locale={this.locale}
				invoiceRequest={this.invoiceRequest}
				invoiceResponse={this.invoiceResponse}
				includeSignature={this.includeSignature}
				advancePayments={this.advancePayments}
				advanceItems={this.advanceItems}
				lastAdvanceSale={this.lastAdvanceSale}
				deferred={this.deferred}
				additionalText={this.additionalText}
				unknownAmountAdvance={this.unknownAmountAdvance}
			/>
		);
	}
}
