import { t, Trans } from '@lingui/macro';
import { Button, Empty, Table, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, useCallback } from 'react';

import styles from './BankAccountsInput.module.less';
import BankAccountEditDrawer from './Components/BankAccountEditDrawer';
import stores from '../../stores/index.mobx';
import { BankAccount } from '../../stores/shared/BankAccount';

type Props = {
	value?: BankAccount[];
	onChange?: (any) => void;
	showInvoiceSwitch?: boolean;
};

function BankAccountsInput(props: Props, ref) {
	const { application } = stores;
	const [
		[editBankAccountVisible, editBankAccountEntity, editBankAccountIndex],
		setEditBankAccount,
	] = useState([false, null, null]);

	const { value = [], onChange = () => null } = props;
	const { banks } = application;

	const handleDeleteBankAccount = useCallback(
		(index) => {
			const newBankAccounts = value.slice();
			newBankAccounts.splice(index, 1);

			onChange(newBankAccounts);
		},
		[value]
	);

	const save = useCallback(
		(account) => {
			const newAccounts = [
				...(value || []).map(
					(v) =>
						new BankAccount({
							...v,
							primary: account.primary ? false : v.primary,
						})
				),
			];
			if (editBankAccountIndex !== null) {
				newAccounts[editBankAccountIndex] = new BankAccount({
					...newAccounts[editBankAccountIndex],
					...account,
				});
			} else {
				newAccounts.push(account);
			}
			onChange(newAccounts);
			setEditBankAccount([false, null, null]);
		},
		[editBankAccountIndex, value]
	);

	if ((value || []).length === 0) {
		return (
			<div className={styles.container}>
				<Empty
					image={
						<img src="/images/icons/new/bank.svg" alt={t`Текући рачуни`} />
					}
					imageStyle={{
						height: 64,
					}}
					description={t`Нема текућих рачуна`}
				>
					<Button
						type="link"
						onClick={() => {
							setEditBankAccount([true, null, null]);
						}}
					>
						<Trans>Додај текући рачун</Trans>
					</Button>
				</Empty>
				<BankAccountEditDrawer
					visible={editBankAccountVisible}
					entity={editBankAccountEntity}
					save={save}
					close={() => setEditBankAccount([false, null, null])}
					showInvoiceSwitch={props.showInvoiceSwitch}
				/>
			</div>
		);
	}

	return (
		<>
			<div className={styles.container}>
				<Table
					pagination={false}
					size="small"
					rowKey="id"
					dataSource={value || []}
					footer={() => (
						<Button onClick={() => setEditBankAccount([true, null, null])}>
							<Trans>Додај текући рачун</Trans>
						</Button>
					)}
				>
					{/* <Table.Column
						title={t`Примаран`}
						dataIndex="primary"
						key="primary"
						render={(value) => <Checkbox checked={value} readOnly />}
						width={100}
					/> */}
					<Table.Column
						title={t`Банка`}
						dataIndex="bankId"
						key="bankId"
						ellipsis
						render={(value) => banks[Number(value)]}
					/>
					<Table.Column
						title={t`Број рачуна`}
						dataIndex="number"
						key="number"
						width={260}
						ellipsis
						render={(value, record: BankAccount) => {
							const bankId = value.substring(0, 3);
							const accountNumber = value.substring(3, value.length - 2);
							const controlNumber = value.substring(value.length - 2);
							return (
								<>
									{`${bankId}-${accountNumber}-${controlNumber}`}

									{record.primary && (
										<>
											&nbsp;&nbsp;
											<Tag bordered={false} color="green">
												<Trans context="Primary bank account">Примаран</Trans>
											</Tag>
										</>
									)}
								</>
							);
						}}
					/>

					<Table.Column
						title=""
						width={80}
						fixed="right"
						render={(text, record, index) => (
							<Button.Group>
								<Button
									icon={<i className="fi fi-rr-pencil" />}
									onClick={() => {
										setEditBankAccount([true, record, index]);
									}}
								/>
								<Button
									icon={<i className="fi fi-rr-trash" />}
									onClick={() => handleDeleteBankAccount(index)}
								/>
							</Button.Group>
						)}
					/>
				</Table>
			</div>
			<BankAccountEditDrawer
				visible={editBankAccountVisible}
				entity={editBankAccountEntity}
				save={save}
				close={() => setEditBankAccount([false, null, null])}
				showInvoiceSwitch={props.showInvoiceSwitch}
			/>
		</>
	);
}

export default observer(BankAccountsInput, { forwardRef: true });
