import classNames from 'classnames';

import styles from './Folder.module.less';
type Props = {
	size?: 'small' | 'large';
};

export function Folder({ size = 'large' }: Props) {
	return (
		<div
			className={classNames(
				styles.noImage,
				size === 'small' && styles.small,
				'noImage'
			)}
		>
			<i className="fi fi-rr-folder" />
		</div>
	);
}
