import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import cc from 'currency-codes';

import styles from './VariableFontPosTerminal.module.less';
import numberFormat from '../../lib/numberFormat';
import { Store } from '../../stores/Store.mobx';
import { Receipt } from '../../stores/Receipt.mobx';
import stores from '../../stores/index.mobx';
import { PosTerminalDeviceConfiguration } from '../../stores/Device.mobx';
import { hex2a } from '../../lib/receipt';
import { ReactComponent as Contactless } from '../../assets/inline-svg/contactless.svg';
import padStart from 'lodash/padStart';

type Props = {
	locale: Record<string, string>;
	store: Store;
	posTerminalResponse: Receipt['posTerminalResponse'];
	type: 'acquirer' | 'buyer';
	language: string;
};

export default function VariableFontPosTerminal({
	locale,
	store,
	posTerminalResponse,
	type,
	language,
}: Props) {
	function formatDate(date: string): string {
		return dayjs(date, 'DDMMYY').format('DD.MM.YY');
	}

	function formatTime(time: string): string {
		return dayjs(time, 'HHmmss').format('HH:mm:ss');
	}

	return (
		<div className={`${styles.page} page`}>
			{type === 'buyer' && (
				<>
					<h3 className={styles.subTitle}>
						<span>{locale.cardTransactionDetails}</span>
					</h3>
					<br />
				</>
			)}
			{(
				stores.devices.posTerminals?.[0]
					?.configuration as PosTerminalDeviceConfiguration
			)?.printSeparately && (
				<>
					<Row>
						<Col flex="auto">{stores.company.name}</Col>
					</Row>
					<Row>
						<Col flex="auto">{store.displayName}</Col>
					</Row>
					{store.address && (
						<Row>
							<Col flex="auto">{store.address}</Col>
						</Row>
					)}
					{store.city && (
						<Row>
							<Col flex="auto">{store.city}</Col>
						</Row>
					)}
					<br />
				</>
			)}
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['terminalId']}</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{posTerminalResponse.TIDNumber}
				</Col>
			</Row>
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['acquirer']}</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{posTerminalResponse.MIDNumber}
				</Col>
			</Row>

			<h2 className={styles.alignLeft}>
				<span>{posTerminalResponse.CompanyName}</span>
			</h2>

			{posTerminalResponse.EMVData['84'] &&
				posTerminalResponse.EMVData['9F12'] && (
					<Row>
						<Col className={styles.alignLeft}>
							<strong>AID</strong> {posTerminalResponse.EMVData['84']}
						</Col>
						<Col flex="auto" className={styles.alignRight}>
							<strong>APN</strong> {hex2a(posTerminalResponse.EMVData['9F12'])}
						</Col>
					</Row>
				)}
			{posTerminalResponse.EMVData['95'] &&
				posTerminalResponse.EMVData['95'] !== '0000000000' &&
				posTerminalResponse.EMVData['9F34'] &&
				posTerminalResponse.EMVData['9F34'] !== '000000' && (
					<Row>
						<Col className={styles.alignLeft}>
							<strong>TVR</strong> {posTerminalResponse.EMVData['95']}
						</Col>
						<Col flex="auto" className={styles.alignRight}>
							<strong>CVMR</strong> {posTerminalResponse.EMVData['9F34']}
						</Col>
					</Row>
				)}

			<Row>
				<Col className={styles.alignLeft}>
					{posTerminalResponse.CardNumber.replace(/.(?=.{4})/g, '*')}
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					<strong>{locale['validUntil']}</strong>{' '}
					{posTerminalResponse.ExpirationDate.replace(/9/g, '*').replace(
						/(..)/,
						'$1/'
					)}
				</Col>
			</Row>

			{posTerminalResponse.CardDataSource === 'C' && (
				<Row>
					<Col className={styles.alignLeft}>
						<Contactless className={styles.contactless} />
					</Col>
				</Row>
			)}

			<h2 className={styles.alignLeft}>
				<span>{locale['sale']}</span>
			</h2>
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['transactionNumber']}</strong>{' '}
					{padStart(`${posTerminalResponse.BatchNumber}`, 6, '0')}
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					<strong>{locale['confirmationNumber']}</strong>{' '}
					{padStart(`${posTerminalResponse.TransactionNumber}`, 6, '0')}
				</Col>
			</Row>
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['date']}</strong>{' '}
					{formatDate(posTerminalResponse.TransactionDate)}
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					<strong>{locale['time']}</strong>{' '}
					{formatTime(posTerminalResponse.TransactionTime)}
				</Col>
			</Row>

			<Row>
				<Col className={styles.alignLeft}>
					<strong>RRN</strong> {posTerminalResponse.RRN}
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					({posTerminalResponse.TransactionStatus})
					<strong>{locale.authCode}</strong>{' '}
					{posTerminalResponse.AuthorizationCode}
				</Col>
			</Row>
			<Row>
				<Col className={styles.alignLeft}>
					<strong>RESP</strong> {posTerminalResponse.FullResponseCode}
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{posTerminalResponse.DisplayMessage.trim()}
				</Col>
			</Row>
			{posTerminalResponse.FullResponseCode !== '00' && (
				<h2 className={styles.tdCenter}>
					<span>{locale['unsuccessful']}</span>
				</h2>
			)}
			<br />
			<Row>
				<Col className={styles.alignLeft}>
					<h2>
						<span>{locale['amount']}</span>
					</h2>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					<h2>
						<span>
							{numberFormat(
								posTerminalResponse.TransactionAmount / 100,
								true,
								2,
								true,
								cc.number(`${posTerminalResponse.AmountCurrency}`).code
							)}
						</span>
					</h2>
				</Col>
			</Row>
			<Row>
				<Col className={styles.alignLeft}>
					{posTerminalResponse.CardholderName}
				</Col>
			</Row>
			<br />
			{posTerminalResponse.FullResponseCode === '00' && (
				<>
					{type === 'acquirer' && (
						<>
							{posTerminalResponse.SignatureLinePrintFlag && (
								<>
									<Row>
										<Col className={styles.alignLeft}>
											<strong>{locale['signature']}</strong>
										</Col>
									</Row>
									<br />
									<br />
									<hr />
								</>
							)}
							<Row>
								<Col flex="auto">{locale.billMyAccount}</Col>
							</Row>
						</>
					)}
					<Row>
						<Col flex="auto">{locale.thankYou}</Col>
					</Row>

					{type === 'acquirer' && (
						<Row>
							<Col flex="auto">{locale.acquirerCopy}</Col>
						</Row>
					)}
				</>
			)}

			{type === 'buyer' && (
				<Row>
					<Col flex="auto">{locale.buyerCopy}</Col>
				</Row>
			)}

			{posTerminalResponse.EMVData['9F27'] && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>
							{posTerminalResponse.EMVData['9F27'] === '80' ? 'ARQC' : 'TC'}
						</strong>{' '}
						({posTerminalResponse.EMVData['9F27']})
						{posTerminalResponse.EMVData['9F26']}
					</Col>
				</Row>
			)}
			{/* 
			{invoiceRequest.buyerId && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['buyer']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{invoiceRequest.buyerId}
					</Col>
				</Row>
			)}
			{invoiceRequest.buyerCostCenterId && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['buyerCostCenter']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{invoiceRequest.buyerCostCenterId}
					</Col>
				</Row>
			)}
			{invoiceRequest.invoiceNumber && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['posNumber']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{invoiceRequest.invoiceNumber}
					</Col>
				</Row>
			)}
			{invoiceRequest.dateAndTimeOfIssue && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['posTime']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{formatDate(invoiceRequest.dateAndTimeOfIssue)}
					</Col>
				</Row>
			)}
			{invoiceRequest.referentDocumentNumber && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['refNo']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{invoiceRequest.referentDocumentNumber}
					</Col>
				</Row>
			)}
			{invoiceRequest.referentDocumentDT && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['refDT']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{formatDate(invoiceRequest.referentDocumentDT)}
					</Col>
				</Row>
			)}

			<h3 className={styles.subTitle}>
				<span>{locale[getInvoiceType()]}</span>
			</h3>

			<p>
				<strong>{locale['items']}</strong>
			</p>

			<h3 className={`${styles.title} title`}>&nbsp;</h3>

			<table className={`${styles.items} items`}>
				<thead>
					<tr>
						<th className={`${styles.tdLeft} tdLeft`}>{locale['itemName']}</th>
						<th width={100} className={`${styles.tdRight} tdRight`}>
							{locale['price']}
						</th>
						<th width={50} className={`${styles.tdRight} tdRight`}>
							{locale['quantity']}
						</th>
						<th width={100} className={`${styles.tdRight} tdRight`}>
							{locale['itemTotal']}
						</th>
					</tr>
				</thead>
				<tbody>
					{invoiceRequest.items.map((item, index) => (
						<Fragment key={index}>
							<tr>
								<td className={`${styles.tdLeft} tdLeft`} colSpan={4}>{`${
									item.name
								} (${item.labels.join(', ')})${
									item.unit && item.isPieceUnitOfMeasure
										? ` / ${item.unit}`
										: ''
								}`}</td>
							</tr>
							<tr>
								<td></td>
								<td className={`${styles.tdRight} tdRight`}>
									{numberFormat(item.unitPrice, false, 2, true)}
								</td>
								<td className={`${styles.tdRight} tdRight`}>
									{numberFormat(item.quantity, false, 3, false)}
								</td>
								<td className={`${styles.tdRight} tdRight`}>
									{numberFormat(
										invoiceRequest.transactionType === TransactionType.SALE
											? item.totalAmount
											: -item.totalAmount,
										false,
										2,
										true
									)}
								</td>
							</tr>
						</Fragment>
					))}
				</tbody>
			</table>
			<hr />

			<Row>
				<Col className={styles.alignLeft}>
					<strong>
						{invoiceRequest.transactionType == TransactionType.SALE
							? locale['totalPurchase']
							: locale['totalRefund']}
						:
					</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{numberFormat(invoiceResponse.totalAmount, false, 2, true)}
				</Col>
			</Row>
			{typeof advancePayments !== 'undefined' && (
				<>
					<Row>
						<Col className={styles.alignLeft}>
							<strong>{locale['advancePayments']}:</strong>
						</Col>
						<Col flex="auto" className={styles.alignRight}>
							{numberFormat(advancePayments, false, 2, true)}
						</Col>
					</Row>
					<Row>
						<Col className={styles.alignLeft}>
							<strong>{locale['advanceTaxes']}:</strong>
						</Col>
						<Col flex="auto" className={styles.alignRight}>
							{numberFormat(advanceTax, false, 2, true)}
						</Col>
					</Row>
				</>
			)}

			{invoiceRequest.payment.map((payment) => (
				<Row key={payment.paymentType}>
					<Col className={styles.alignLeft}>
						<strong>{locale[PAYMENT_TYPE_MAP[payment.paymentType]]}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{numberFormat(payment.amount, false, 2, true)}
					</Col>
				</Row>
			))}
			{typeof advancePayments !== 'undefined' && (
				<Row>
					<Col className={styles.alignLeft}>
						<strong>{locale['remaining']}:</strong>
					</Col>
					<Col flex="auto" className={styles.alignRight}>
						{numberFormat(0, false, 2, true)}
					</Col>
				</Row>
			)}
			{invoiceRequest.transactionType === TransactionType.SALE &&
				(cashPayment?.amount || 0) > 0 && (
					<>
						<Row>
							<Col className={styles.alignLeft}>
								<strong>{locale['paidCash']}:</strong>
							</Col>
							<Col flex="auto" className={styles.alignRight}>
								{numberFormat(
									Number(cashPayment.amount) +
										Number(invoiceRequest.paymentChange),
									false,
									2,
									true
								)}
							</Col>
						</Row>

						<Row>
							<Col className={styles.alignLeft}>
								<strong>{locale['change']}:</strong>
							</Col>
							<Col flex="auto" className={styles.alignRight}>
								{numberFormat(invoiceRequest.paymentChange, false, 2, true)}
							</Col>
						</Row>
					</>
				)}

			<h3 className={`${styles.title} title`}>&nbsp;</h3>
			{!isFiscalInvoice() && (
				<>
					<h2 className={`${styles.notFiscalReceipt} notFiscalReceipt`}>
						<span>{locale['notFiscalInvoice']}</span>
					</h2>
					<h3 className={`${styles.title} title`}>&nbsp;</h3>
				</>
			)}

			<table className={`${styles.taxItems} taxItems`}>
				<thead>
					<tr>
						<th width={40} className={`${styles.tdLeft} tdLeft`}>
							{locale['label']}
						</th>
						<th width={70} className={`${styles.tdLeft} tdLeft`}>
							{locale['taxName']}
						</th>
						<th width={40} className={`${styles.tdLeft} tdLeft`}>
							{locale['rate']}
						</th>
						<th width={100} className={`${styles.tdRight} tdRight`}>
							{locale['tax']}
						</th>
					</tr>
				</thead>
				<tbody>
					{invoiceResponse.taxItems.map((taxItem, index) => (
						<tr key={index}>
							<td className={`${styles.tdLeft} tdLeft`}>{taxItem.label}</td>
							<td className={`${styles.tdLeft} tdLeft`}>
								{taxItem.categoryName}
							</td>
							<td className={`${styles.tdLeft} tdLeft`}>
								{numberFormat(taxItem.rate, false, 2, true)}%
							</td>
							<td className={`${styles.tdRight} tdRight`}>
								{numberFormat(taxItem.amount, false, 2, true)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<hr />
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['totalTax']}:</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{numberFormat(
						invoiceResponse.taxItems.reduce(
							(acc, curr) => acc + Number(curr.amount),
							0
						),
						false,
						2,
						true
					)}
				</Col>
			</Row>

			<h3 className={`${styles.title} title`}>&nbsp;</h3>

			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['sdcTime']}:</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{formatDate(invoiceResponse.sdcDateTime)}
				</Col>
			</Row>
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['sdcInvoiceNo']}:</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{invoiceResponse.invoiceNumber}
				</Col>
			</Row>
			<Row>
				<Col className={styles.alignLeft}>
					<strong>{locale['invoiceCounter']}:</strong>
				</Col>
				<Col flex="auto" className={styles.alignRight}>
					{invoiceResponse.invoiceCounter}
				</Col>
			</Row>

			<h3 className={`${styles.title} title`}>&nbsp;</h3>

			<Row>
				<Col flex="auto">
					<QR
						verificationUrl={invoiceResponse.verificationUrl}
						className={`${styles.qr} qr`}
						style={{}}
						deferred={deferred}
					/>
					<br />
					<br />
				</Col>
			</Row>
			{includeSignature && (
				<>
					<Row>
						<Col className={styles.alignLeft}>
							<strong>{locale['signature']}:</strong>
						</Col>
					</Row>
					<br />
					<br />
					<hr />
				</>
			)}

			{isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					<span>{locale['endInvoice']}</span>
				</h3>
			)}
			{!isFiscalInvoice() && (
				<h3 className={`${styles.title} title`}>
					<span> {locale['notFiscalReceipt']}</span>
				</h3>
			)}
			<div className={`${styles.small} small`}>
				{lastAdvanceSale && (
					<>
						<Row>
							<Col className={styles.alignLeft}>
								<strong>{locale['lastAdvance']}:</strong>
							</Col>
						</Row>
						<Row>
							<Col flex="auto" className={styles.alignLeft}>
								{lastAdvanceSale.invoiceNumber}{' '}
								{formatDate(lastAdvanceSale.sdcTime, true)}
							</Col>
						</Row>
						<hr />
					</>
				)}

				{advanceItems && advanceItems.length > 0 && (
					<div>
						<p>
							<strong>{locale['advanceItems']}</strong>
						</p>

						<table className={`${styles.items} items`}>
							<thead>
								<tr>
									<th className={`${styles.tdLeft} tdLeft`}>
										{locale['itemName']}
									</th>
									{!unknownAmountAdvance && (
										<th width={100} className={`${styles.tdRight} tdRight`}>
											{locale['price']}
										</th>
									)}
									<th width={50} className={`${styles.tdRight} tdRight`}>
										{locale['quantity']}
									</th>
									{!unknownAmountAdvance && (
										<th width={100} className={`${styles.tdRight} tdRight`}>
											{locale['itemTotal']}
										</th>
									)}
								</tr>
							</thead>
							<tbody>
								{advanceItems.map((item, index) => (
									<Fragment key={index}>
										<tr>
											<td className={`${styles.tdLeft} tdLeft`} colSpan={4}>
												{`${item.product.name}${
													item.variant?.variantName
														? ` ${item.variant?.variantName}`
														: ''
												}`}{' '}
												(
												{`${(item.labels || [item.product.taxRateLabel]).join(
													', '
												)})${(() => {
													const showUnit =
														item.product.saleUnit?.label && item.variant
															? item.variant.saleUnit?.isPieceUnitOfMeasure
															: item.product?.saleUnit?.isPieceUnitOfMeasure;
													if (showUnit) {
														return item.variant
															? ` / ${item.variant?.saleUnit?.label}`
															: ` / ${item.product?.saleUnit?.label}` || '';
													}
													return '';
												})()}`}
											</td>
										</tr>
										<tr>
											<td></td>
											{!unknownAmountAdvance && (
												<td className={`${styles.tdRight} tdRight`}>
													{numberFormat(item.finalPrice, false, 2, true)}
												</td>
											)}
											<td className={`${styles.tdRight} tdRight`}>
												{numberFormat(item.quantity, false, 3, false)}
											</td>
											{!unknownAmountAdvance && (
												<td className={`${styles.tdRight} tdRight`}>
													{numberFormat(
														item.finalPrice * item.quantity,
														false,
														2,
														true
													)}
												</td>
											)}
										</tr>
									</Fragment>
								))}
							</tbody>
						</table>
						<hr />
					</div>
				)}

				{currentStore?.printBankAccounts &&
					bankAccounts.length &&
					invoiceRequest.transactionType === TransactionType.SALE &&
					invoiceRequest.payment.find(
						(payment) => payment.paymentType === PaymentType.WIRE_TRANSFER
					) && (
						<p className={`${styles.small} ${styles.alignLeft} small`}>
							{locale['paymentInstructions']}:<br />
							<br />
							<strong>
								{bankAccounts.length > 1
									? locale['bankAccounts']
									: locale['bankAccount']}
								:
							</strong>
							<br />
							{bankAccounts.map((bankAccount, index) => (
								<span key={index}>
									{banks[bankAccount.bankId]}
									<br />
									{bankAccount.formattedNumber}
									<br />
								</span>
							))}
							<br />
							<strong>{locale['model']}:</strong> 97
							<br />
							<strong>{locale['referenceNo']}:</strong>{' '}
							{Mod97_10.checksum(normalize(invoiceResponse.invoiceNumber))}-
							{invoiceResponse.invoiceNumber}
							<br />
							<hr />
						</p>
					)}

				{totalDiscount > 0 && currentStore?.printDiscounts && (
					<>
						<p className={`${styles.small} ${styles.alignLeft} small`}>
							{locale['discounts']}:<br />
							<br />
							<Row>
								<Col className={styles.alignLeft}>
									<strong>{locale['itemName']}</strong>
								</Col>
								<Col flex="auto" className={styles.alignRight}>
									<strong>{locale['discount']}</strong>
								</Col>
							</Row>
							{invoiceRequest.items
								.filter((item) => item.discount > 0)
								.map((item) => (
									<Row>
										<Col className={styles.alignLeft}>{item.name}</Col>
										<Col flex="auto" className={styles.alignRight}>
											{numberFormat(
												item.discount * item.quantity,
												false,
												2,
												true
											)}
										</Col>
									</Row>
								))}
						</p>
						<hr />
						<Row>
							<Col className={styles.alignLeft}>
								<strong>{locale['itemTotal']}</strong>
							</Col>
							<Col flex="auto" className={styles.alignRight}>
								<strong>{numberFormat(totalDiscount, false, 2, true)}</strong>
							</Col>
						</Row>
						<hr />
					</>
				)}

				{additionalText && (
					<p className={`${styles.small} ${styles.alignLeft} small`}>
						{additionalText}
					</p>
				)}

				{devices.thermalPrinters[0].configuration.additionalText !== '' && (
					<>
						<small>
							{devices.thermalPrinters[0].configuration.additionalText}
						</small>
					</>
				)}
				<br /> */}
		</div>
	);
}
