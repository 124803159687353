import { t, Trans } from '@lingui/macro';
import { Button, Col, Drawer, Form, Input, Row, Space } from 'antd';
import { useEffect } from 'react';

import { Title } from '../../../../../../../components/Title';

interface Props {
	visible?: boolean;
	entity?: any;
	close: () => void;
	save: (entity: Product) => void;
}

export default function ContactEditDrawer({
	visible,
	entity,
	close,
	save,
}: Props) {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(entity);
	}, [entity, form]);

	return (
		<Drawer
			width={842}
			open={visible}
			title={entity ? t`Измена контакта ${entity?.name}` : t`Додавање контакта`}
			onClose={close}
			footerStyle={{ textAlign: 'right' }}
			footer={
				<Space>
					<Button key="cancel" onClick={close}>
						<Trans>Поништи</Trans>
					</Button>
					<Button key="save" type="primary" onClick={form.submit}>
						{entity ? t`Измени` : t`Додај`}
					</Button>
				</Space>
			}
		>
			<Form
				form={form}
				onFinish={(newValue) => {
					form.resetFields();
					save(newValue);
				}}
				layout="vertical"
			>
				<Title>
					<Trans>Основне информације</Trans>
				</Title>
				<Row gutter={8}>
					<Col md={14} xs={24}>
						<Form.Item
							label={t`Име и презиме / Назив`}
							name="name"
							rules={[
								{
									required: true,
									message: t`Име и презиме / Назив је обавезан`,
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col md={10} xs={24}>
						<Form.Item label={t`Улога код партнера`} name="role">
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Title>
					<Trans>Контакт информације</Trans>
				</Title>
				<Row gutter={8}>
					<Col md={6} xs={24}>
						<Form.Item
							label={t`Електронска пошта`}
							name="email"
							rules={[
								{
									type: 'email',
									message: t`Адреса електронске поште није исправна`,
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col md={6} xs={24}>
						<Form.Item label={t`Телефон`} name="phone">
							<Input />
						</Form.Item>
					</Col>
					<Col md={6} xs={24}>
						<Form.Item label={t`Мобилни телефон`} name="mobilePhone">
							<Input />
						</Form.Item>
					</Col>
					<Col md={6} xs={24}>
						<Form.Item label={t`Факс`} name="fax">
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Title>Остало</Title>
				<Row gutter={8}>
					<Col span={24}>
						<Form.Item label={t`Напомена`} name="note">
							<Input.TextArea />
						</Form.Item>
					</Col>
				</Row>
				<input type="submit" style={{ display: 'none' }} />
			</Form>
		</Drawer>
	);
}
