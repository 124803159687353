import { Trans } from '@lingui/macro';
import {
	Button,
	Carousel,
	Col,
	Divider,
	Drawer,
	Row,
	Space,
	Tag,
	Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';

import {
	addToDrawersRegistry,
	useDrawer,
} from '../../../../../../components/Page';
import { getMarketplaceApplicationScope } from '../../../../../../constants/marketplace';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import styles from '../../Marketplace.module.less';
import InstallConfirmation from '../../Modals/InstallConfirmation';
import InstallSuccessModal from '../../Modals/InstallSuccessModal';
let prev;
const Application = () => {
	const [applicationId, , close, visible] = useDrawer(
		'marketplace-application'
	);
	const [installConfirmationVisible, setInstallConfirmationVisible] =
		useState(false);
	const [installSuccessVisible, setInstallSuccessVisible] = useState(false);

	const {
		marketplaceApplications: { fetchSingle, single },
	} = stores;
	useEffect(() => {
		if (
			visible &&
			applicationId &&
			!single?.isFetching &&
			single?.id !== applicationId
		) {
			fetchSingle(applicationId);
		}
	}, [visible, applicationId, fetchSingle, single?.id, single?.isFetching]);

	const MARKETPLACE_APPLICATION_SCOPE = useMemo(
		() => getMarketplaceApplicationScope(),
		[]
	);

	return (
		<Drawer
			open={visible}
			onClose={close}
			placement="right"
			width={800}
			title={
				<Space style={{ maxWidth: 'calc(100% - 24px)' }}>
					<div>
						{single?.logoFile ? (
							<img
								src={single.logoFile.urls['64x64']}
								style={{ width: 32, height: 32 }}
							/>
						) : (
							<img
								src="/images/icons/new/interface.svg"
								style={{ width: 32, height: 32 }}
							/>
						)}
					</div>
					<div>
						<Row gutter={[8, 8]}>
							<Col flex="auto">
								{single?.name}
								<Divider type="vertical" />
								<Typography.Text
									type="secondary"
									className={styles.regularText}
								>
									{single?.version}
								</Typography.Text>
								<Divider type="vertical" />
								<Typography.Text
									type="secondary"
									className={styles.regularText}
								>
									<i className="fi fi-rr-cloud-download-alt"></i>{' '}
									{numberFormat(single?.installationCount, false, 0, false)}
								</Typography.Text>
								{single?.isInstalled && (
									<Tag
										bordered={false}
										className={styles.regularText}
										color="green"
									>
										<Trans>Инсталирана</Trans>
									</Tag>
								)}
							</Col>
						</Row>
						<Row gutter={[8, 8]} className={styles.regularText}>
							<Col flex="auto">
								<Typography.Text type="secondary">
									{single?.publisher?.name}
								</Typography.Text>
							</Col>
						</Row>
					</div>
				</Space>
			}
			footer={
				<>
					<Space className={styles.leftButtons}>
						<Button onClick={close}>
							<Trans>Затвори</Trans>
						</Button>
					</Space>
					<Space className={styles.rightButtons}>
						{single?.isInstalled && (
							<Button
								danger
								type="primary"
								onClick={single?.uninstall}
								disabled={single?.isInstalling}
								loading={single?.isInstalling}
							>
								<Trans>Уклони</Trans>
							</Button>
						)}
						{!single?.isInstalled && (
							<Button
								type="primary"
								onClick={() => setInstallConfirmationVisible(true)}
								disabled={single?.isInstalling}
								loading={single?.isInstalling}
							>
								<Trans>Инсталирај</Trans>
							</Button>
						)}
					</Space>
				</>
			}
		>
			{single?.images?.length > 0 && (
				<Carousel
					slidesToShow={3}
					slidesToScroll={1}
					autoplay={true}
					autoplaySpeed={3000}
					infinite={true}
					className={styles.carousel}
				>
					{single?.images?.map((screenshot) => (
						<div className={styles.carouselItem}>
							<img src={screenshot.urls['1024x1024']} alt="" />
						</div>
					))}
				</Carousel>
			)}
			{/* <div className="ant-descriptions-title">Opis</div> */}
			<p dangerouslySetInnerHTML={{ __html: single?.description }}></p>
			<p>
				<b>
					<Trans>Апликација ће имати приступ следећим подацима:</Trans>
				</b>
				<br />
				<ul>
					{single?.scopes?.map((scope) => (
						<li>{MARKETPLACE_APPLICATION_SCOPE[scope]}</li>
					))}
				</ul>
			</p>
			<p>
				<strong>
					<Trans>Веб сајт:</Trans>{' '}
				</strong>{' '}
				{single?.website}
				<br />
				{single?.supportEmail && (
					<>
						<strong>
							<Trans>Адреса електронске поште за подршку:</Trans>{' '}
						</strong>{' '}
						{single?.supportEmail}
						<br />
					</>
				)}
				{single?.termsOfServiceUrl && (
					<>
						<strong>
							<Trans>Услови коришћења:</Trans>{' '}
						</strong>{' '}
						{single?.termsOfServiceUrl}
						<br />
					</>
				)}
				{single?.privacyPolicyUrl && (
					<>
						<strong>
							<Trans>Политика приватности:</Trans>{' '}
						</strong>{' '}
						{single?.privacyPolicyUrl}
						<br />
					</>
				)}
			</p>
			<InstallConfirmation
				application={single}
				visible={installConfirmationVisible}
				close={() => setInstallConfirmationVisible(false)}
				openSuccessModal={() => setInstallSuccessVisible(true)}
			/>
			<InstallSuccessModal
				application={single}
				visible={installSuccessVisible}
				close={() => setInstallSuccessVisible(false)}
			/>
		</Drawer>
	);
};

const ObservedApplication = observer(Application);

addToDrawersRegistry('marketplace-application', ObservedApplication);
export default ObservedApplication;
