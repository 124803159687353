import { t } from '@lingui/macro';
import { Tabs, Divider, Spin, Grid, Empty } from 'antd';
import { observer } from 'mobx-react-lite';
import { useImperativeHandle, useMemo } from 'react';
import { useWindowSize } from 'react-use';

import SelectStore from './Components/SelectStore';
import styles from './POS.module.less';
import Sale from './sale';
import Key from '../../../components/Key';
import { PaymentType } from '../../../constants/invoice';
import { getPaymentTypeText } from '../../../constants/journal';
import stores from '../../../stores/index.mobx';
// import EnterKeyModal from './Components/EnterKeyModal';

function POS(_, ref) {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const PAYMENT_TYPE_TEXT = getPaymentTypeText();
	const {
		company: { availablePaymentMethods },
		products: { isFetching },
		stores: { currentStore, currentStoreId },
		localSales: { active, setActive, available, createSale, removeSale, byId },
	} = stores;
	const paymentMethods = useMemo(() => {
		if (!currentStoreId || currentStore?.isResolving || !currentStore) {
			return [];
		}

		return (
			availablePaymentMethods === 'all'
				? currentStore.paymentMethodsOrder
				: currentStore.paymentMethodsOrder.filter((pm) => {
						return [
							PaymentType.CASH,
							PaymentType.VOUCHER,
							PaymentType.WIRE_TRANSFER,
							PaymentType.OTHER,
						].includes(pm);
				  })
		).filter((pm) => pm !== -1);
	}, [currentStore, currentStoreId, availablePaymentMethods]);

	const { height, width } = useWindowSize();

	const screens = Grid.useBreakpoint();

	useImperativeHandle(ref, () => ({
		renderHeader: () => screens.xs && <div />,
		getDocsLink: () => 'koriscenje/moduli-aplikacije/kasa/',
	}));

	const headerTitleWidth =
		document.querySelector('.header-title')?.clientWidth || 0;
	const headerTitleLeft = useMemo(() => {
		if (screens.lg) {
			return 256;
		}
		if (screens.md) {
			return 80;
		}

		if (screens.xs || screens.sm) {
			return 50;
		}
	}, [screens.xs, screens.sm, screens.md, screens.lg]);

	if (Object.keys(screens).length === 0) {
		return (
			<Spin wrapperClassName={styles.container}>
				<div className={styles.container}></div>
			</Spin>
		);
	}

	if (!currentStoreId) {
		return (
			<div className={styles.container}>
				<Empty
					image={'/images/icons/new/supermarket.svg'}
					description={t`Продајно место није одабрано`}
				/>
				<SelectStore />
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<Spin spinning={isFetching}>
				<Tabs
					className={styles.tabs}
					activeKey={`${active}`}
					tabPosition="top"
					addIcon={<i className="fi fi-rr-plus"></i>}
					moreIcon={<i className="fi fi-rr-menu-dots"></i>}
					type="editable-card"
					onChange={(key) => {
						setActive(key);
					}}
					tabBarGutter={0}
					animated={{ inkBar: false, tabPane: false }}
					onEdit={(targetKey, action) => {
						if (action === 'remove') {
							removeSale(byId[targetKey]);
						}

						if (action === 'add') {
							createSale();
						}
					}}
					tabBarStyle={{
						width: width - headerTitleWidth - headerTitleLeft - 152,
					}}
				>
					{available.map((sale) => (
						<Tabs.TabPane
							closeIcon={<i className="fi fi-rr-cross-small"></i>}
							style={{ padding: '24px 24px 0' }}
							key={sale.id}
							tab={t`Продаја ${sale.id}`}
							closable
						>
							<Sale
								height={height}
								sale={sale}
								loading={isFetching}
								active={sale.id === active}
							/>
						</Tabs.TabPane>
					))}
				</Tabs>

				{/* TODO: implement shortcuts */}
				{screens.md && (
					<div className={styles.keys}>
						<Key text={t`Унос шифре`}>0-9</Key>
						<Divider type="vertical" />
						<Key text={t`Потврди`}>enter</Key>
						<Divider type="vertical" />
						<Key text={t`Одабир артикла`}>↑/↓</Key>
						<Divider type="vertical" />
						<Key text={t`Сторно`}>⌫</Key>
						<Divider type="vertical" />
						<Key text={t`Измена`}>+</Key>
						<Divider type="vertical" />
						<Key text="">⌘ command / ⌃ control</Key>
						<Key text={t`Претрага`}>f</Key>
						{currentStoreId && (
							<>
								<Divider type="vertical" />
								<Key text={PAYMENT_TYPE_TEXT[paymentMethods[0]]}>F10 / F16</Key>
								<Divider type="vertical" />
								<Key text={PAYMENT_TYPE_TEXT[paymentMethods[1]]}>F11 / F17</Key>
								<Divider type="vertical" />
								<Key text={PAYMENT_TYPE_TEXT[paymentMethods[2]]}>F12 / F18</Key>
							</>
						)}
					</div>
				)}
			</Spin>
		</div>
	);
}

export default observer(POS, { forwardRef: true });
