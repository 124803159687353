import { t, Trans } from '@lingui/macro';
import {
	Badge,
	Button,
	DatePicker,
	Dropdown,
	Input,
	InputNumber,
	Menu,
	Select,
	Space,
	Tooltip,
	Grid,
	Alert,
} from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { Fragment } from 'react';
import nl2br from 'react-nl2br';

import AcceptRejectModal from './Components/AcceptRejectModal';
import CassaScontoInput from './Components/CassaScontoInput';
import CassaScontoView from './Components/CassaScontoView';
import PayButton from './Components/PayButton';
import ReferenceInput from './Components/ReferenceInput';
import ReferenceView from './Components/ReferenceView';
import CurrencySelect from '../../../../components/CurrencySelect';
import InvoiceItemsView from '../../../../components/InvoiceCommon/InvoiceItemsView';
import PartnerView from '../../../../components/InvoiceCommon/PartnerView';
import InvoiceSummaryView from '../../../../components/InvoiceSummaryView';
import { CreatePage } from '../../../../components/Page';
import pageStyles from '../../../../components/Page/Page.module.less';
import PartnerSelect from '../../../../components/Selects/PartnerSelect';
import config from '../../../../config.json';
import {
	getDocumentTypeMap,
	getInvoiceStatusMap,
	INVOICE_STATUS,
} from '../../../../constants/documentInvoice';
import numberFormat from '../../../../lib/numberFormat';
import { v2Client } from '../../../../store/client';
import stores from '../../../../stores/index.mobx';
import { Invoice } from '../../../../stores/Invoice.mobx';
import { Partner } from '../../../../stores/Partner.mobx';

const { company } = stores;

const hasFeature = company?.hasFeature;
interface Props {
	record: Invoice;
	close: () => void;
}

const InvoiceButtons = observer(({ record, close }: Props) => {
	const [acceptModalVisible, setAcceptModalVisible] = useState(false);
	const [rejectModalVisible, setRejectModalVisible] = useState(false);

	const screens = Grid.useBreakpoint();

	const menu = useMemo(() => {
		if (!record) {
			return [];
		}

		const menuItems = [
			{
				type: 'item',
				label: t`Преузми XML`,
				key: 'xml',
				icon: <i className="fi fi-rr-file-code"></i>,
				disabled: !record.xmlFile,
				onClick: () => {
					window.open(
						`${config.apiV2}/files/${record.xmlFile.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
					);
				},
			},
		];

		if ((record.attachments?.length || 0) > 0) {
			menuItems.push({
				label: t`Прилози`,
				key: 'attachments',
				type: 'divider',
				onClick: null,
				disabled: null,
				icon: null,
			});

			record.attachments.forEach((attachment) => {
				let attachmentIcon = <i className="fi fi-rr-document"></i>;
				if (attachment.mimetype === 'application/xml') {
					attachmentIcon = <i className="fi fi-rr-file-code"></i>;
				} else if (attachment.mimetype === 'application/pdf') {
					attachmentIcon = <i className="fi fi-rr-file-pdf"></i>;
				} else if (attachment.mimetype.startsWith('image/')) {
					attachmentIcon = <i className="fi fi-rr-file-jpg"></i>;
				}

				menuItems.push({
					type: 'item',
					label: t`Преузми прилог ${
						attachment.originalFilename || attachment.filename
					}`,
					key: attachment.id,
					icon: attachmentIcon,
					disabled: false,
					onClick: () => {
						window.open(
							`${config.apiV2}/files/${attachment.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
						);
					},
				});
			});
		}

		if (
			screens.xs &&
			!screens.sm &&
			stores.marketplaceApplications.isInstalled('rs.storebuddy.efaktura')
		) {
			if (
				record.status === INVOICE_STATUS.NEW ||
				record.status === INVOICE_STATUS.SEEN ||
				record.status === INVOICE_STATUS.REJECTED
			) {
				menuItems.push({
					type: 'divider',
					label: '',
					key: 'divider',
					icon: null,
					disabled: false,
					onClick: null,
				});
			}

			if (
				record.status === INVOICE_STATUS.NEW ||
				record.status === INVOICE_STATUS.SEEN
			) {
				menuItems.push({
					type: 'item',
					label: t`Одбиј`,
					key: 'reject',
					icon: <i className="fi fi-rr-cross"></i>,
					disabled: record.isUpdating,
					danger: true,
					onClick: () => {
						setRejectModalVisible(true);
					},
				});
			}

			if (
				record.status === INVOICE_STATUS.NEW ||
				record.status === INVOICE_STATUS.SEEN ||
				record.status === INVOICE_STATUS.REJECTED
			) {
				menuItems.push({
					type: 'item',
					label: t`Прихвати`,
					key: 'approve',
					icon: <i className="fi fi-rr-check"></i>,
					disabled: record.isUpdating,
					onClick: () => {
						setAcceptModalVisible(true);
					},
				});
			}
		}

		return (
			<Menu
				onClick={({ key }) => {
					menuItems.find((item) => item.key === key)?.onClick();
				}}
				items={menuItems}
			/>
		);
	}, [record, screens]);

	if (!record) {
		return null;
	}

	return (
		<>
			<Space className={pageStyles.leftButton}>
				{record.file && (
					<Dropdown.Button
						overlay={menu}
						target="_blank"
						href={`${config.apiV2}/files/${record.file.id}?token=${v2Client.defaults.headers.common['x-access-token']}`}
					>
						<i className="fi fi-rr-download"></i> <Trans>Преузми ПДФ</Trans>
					</Dropdown.Button>
				)}
				{screens.sm &&
					stores.marketplaceApplications.isInstalled(
						'rs.storebuddy.efaktura'
					) &&
					(record.status === INVOICE_STATUS.NEW ||
						record.status === INVOICE_STATUS.SEEN) && (
						<Button
							loading={record.isUpdating}
							disabled={record.isUpdating}
							danger
							icon={<i className="fi fi-rr-cross"></i>}
							onClick={() => {
								setRejectModalVisible(true);
							}}
						>
							<Trans>Одбиј</Trans>
						</Button>
					)}
				{screens.sm &&
					stores.marketplaceApplications.isInstalled(
						'rs.storebuddy.efaktura'
					) &&
					(record.status === INVOICE_STATUS.NEW ||
						record.status === INVOICE_STATUS.SEEN ||
						record.status === INVOICE_STATUS.REJECTED) && (
						<Button
							loading={record.isUpdating}
							disabled={record.isUpdating}
							icon={<i className="fi fi-rr-check"></i>}
							onClick={() => setAcceptModalVisible(true)}
						>
							<Trans>Прихвати</Trans>
						</Button>
					)}
			</Space>
			<Button key="close" type="primary" onClick={close}>
				<Trans>Затвори</Trans>
			</Button>

			<AcceptRejectModal
				visible={acceptModalVisible}
				title={t`Прихвати документ`}
				okText={t`Прихвати`}
				cancelText={t`Одустани`}
				onOk={({ comment }) => {
					record.accept({ comment });
				}}
				close={() => setAcceptModalVisible(false)}
				record={record}
			/>
			<AcceptRejectModal
				visible={rejectModalVisible}
				title={t`Одбиј документ`}
				okText={t`Одбиј`}
				cancelText={t`Одустани`}
				onOk={({ comment }) => {
					record.reject({ comment });
				}}
				isCommentRequired
				close={() => setRejectModalVisible(false)}
				record={record}
			/>
		</>
	);
});

export default () => {
	const DOCUMENT_TYPE_MAP = getDocumentTypeMap();
	const INVOICE_STATUS_MAP = getInvoiceStatusMap();

	return CreatePage({
		module: 'documents',
		submodule: 'incoming-invoices',
		model: 'invoice',
		view: {
			width: 1200,
			shouldFetch: true,
			footer: (single, edit, close) => (
				<InvoiceButtons record={single} close={close} />
			),
			title: (entity) => t`Преглед документа ${entity?.number || ''}`,
			// fields: <ViewInvoice />,
			fields: (record) => [
				{
					key: 'info',
					label: t`Информације`,
					column: 12,
					fields: [
						{
							key: 'documentType',
							label: t`Тип документа`,
							md: 3,
							xs: 12,
							render: (text) => (text ? DOCUMENT_TYPE_MAP[text] : 'Nepoznat'),
						},
						{
							key: 'partner',
							label: t`Продавац`,
							md: 9,
							xs: 12,
							component: <PartnerView />,
						},
						{
							key: 'number',
							label: t`Број документа`,
							md: 3,
							sm: 6,
							xs: 12,
						},
						{
							key: 'reference',
							label: t`Позив на број`,
							md: 3,
							sm: 6,
							xs: 12,
							render: (text, record) =>
								record.reference?.number && record.reference?.number !== '' ? (
									<ReferenceView value={record.reference} />
								) : null,
						},
						{
							key: 'date',
							label: t`Датум промета`,
							md: 3,
							sm: 6,
							xs: 12,
							render: (text) => dayjs(text).format('LL'),
						},
						{
							key: 'dueDate',
							label: t`Датум валуте`,
							md: 3,
							sm: 6,
							xs: 12,
							render: (text) => (text ? dayjs(text).format('LL') : null),
						},
					],
				},
				{
					key: 'payment',
					label: t`Плаћање`,
					column: 12,
					fields: [
						{
							key: 'payableAmount',
							label: t`Износ`,
							md: 3,
							sm: 6,
							xs: 12,
							render: (text) => numberFormat(text, true),
						},
						{
							key: 'amountWithCassaSconto',
							label: t`Износ за плаћање у року`,
							md: 3,
							sm: 6,
							xs: 12,
							render: (text, record) =>
								numberFormat(text, true, 2, true, record.currencyId),
						},
						{
							key: 'paidAmount',
							label: t`Плаћено`,
							md: 3,
							sm: 6,
							xs: 12,
							render: (text, record) =>
								numberFormat(text, true, 2, true, record.currencyId),
						},
						{
							label: t`Преостало`,
							md: 3,
							sm: 6,
							xs: 12,
							render: (text, record) =>
								numberFormat(
									record.amountWithCassaSconto - record.paidAmount,
									true,
									2,
									true,
									record.currencyId
								),
						},
						...(record?.paymentMeans?.length > 0 ||
						record?.partner?.bankAccounts?.length > 0
							? [
									{
										key: 'paymentMethod',
										label: t`Рачуни за уплату`,
										span: 12,
										render: (text, record) => (
											<>
												{record.paymentMeans?.length > 0
													? record.paymentMeans
															.filter((pm) => pm.startsWith('30'))
															.map((pm, i) => (
																<div>
																	{pm
																		.split(':')[1]
																		.replace(
																			/^(\d{3})(\d.*)(\d{2})$/,
																			'$1-$2-$3'
																		)}
																</div>
															))
													: record.partner?.bankAccounts?.map((ba, i) => (
															<div>{ba.formattedNumber}</div>
													  ))}
											</>
										),
									},
							  ]
							: []),
						{
							key: 'cassaSconto',
							label: (
								<>
									<Trans>Износи за плаћање у року</Trans>{' '}
									<Tooltip
										title={t`Уколико је продавац навео посебне услове за плаћање у одређеном року, можете их навести овде.`}
									>
										<i className="fi fi-rr-info"></i>
									</Tooltip>
								</>
							),
							span: 12,
							component: <CassaScontoView />,
						},
					],
				},
				...(record.invoiceItems?.length > 0
					? [
							{
								key: 'items',
								label: t`Ставке фактуре`,
								column: 12,

								fields: [
									{
										key: 'invoiceItems',
										span: 12,
										component: <InvoiceItemsView />,
									},
									{
										key: 'taxBreakdown',
										span: 12,
										component: <InvoiceSummaryView record={record} />,
									},
								],
							},
					  ]
					: []),
				...(record.note
					? [
							{
								key: 'notes',
								label: t`Напомена`,
								column: 12,
								fields: [
									{
										key: 'note',
										span: 24,
										render: (text) => nl2br(text),
									},
								],
							},
					  ]
					: []),
			],
		},
		edit: {
			width: 800,
			title: {
				new: t`Додавање улазног документа`,
				existing: (entity) =>
					t`Измена улазног документа ${entity?.number || ''}`,
			},
			shouldFetch: true,
			beforeSave: (entity) => {
				entity.storeId = stores.stores.currentStoreId;
				return entity;
			},
			disabled: (entity) => {
				return entity?.externalId;
			},
			disabledReason: (entity) => {
				return t`Документ је увезен из другог система и није га могуће мењати`;
			},
			fields: (record, form, setFields) => [
				...(stores.marketplaceApplications.isInstalled('rs.storebuddy.efaktura')
					? [
							{
								key: 'notice',
								column: 12,
								fields: [
									{
										key: 'notice',
										span: 24,
										component: (
											<Alert
												description={t`Уколико сте корисник система е-Фактура, користите аутоматски или ручни увоз докумената. Ручно додавање документа користите уколико сте добили фактуру од продавца који није корисник система е-Фактура.`}
												type="info"
											/>
										),
									},
								],
							},
					  ]
					: []),
				{
					key: 'row1',
					label: t`Основне информације`,
					fields: [
						{
							key: 'documentType',
							label: t`Тип документа`,
							rules: [
								{
									required: true,
									message: t`Тип документа је обавезан`,
								},
							],
							component: (
								<Select>
									{Object.entries(DOCUMENT_TYPE_MAP).map(([key, value]) => (
										<Select.Option value={key}>{value}</Select.Option>
									))}
								</Select>
							),
							xs: 24,
							sm: 6,
						},
						{
							key: 'partnerId',
							label: t`Продавац`,
							rules: [
								{
									required: true,
									message: t`Продавац је обавезан`,
								},
							],
							component: <PartnerSelect />,
							xs: 24,
							sm: 18,
						},
						{
							key: 'date',
							label: t`Датум промета`,
							xs: 24,
							sm: 8,
							rules: [
								{
									required: true,
									message: t`Датум промета фактуре је обавезан`,
								},
							],
							component: <DatePicker style={{ width: '100%' }} format="L" />,
						},
						{
							key: 'issueDate',
							label: t`Датум издавања`,
							xs: 24,
							sm: 8,
							rules: [
								{
									required: true,
									message: t`Датум издавања фактуре је обавезан`,
								},
							],
							component: <DatePicker style={{ width: '100%' }} format="L" />,
						},
						{
							key: 'dueDate',
							label: t`Датум валуте`,
							xs: 24,
							sm: 8,
							rules: [
								{
									required: true,
									message: t`Датум валуте је обавезан`,
								},
							],
							component: <DatePicker style={{ width: '100%' }} format="L" />,
						},
					],
				},
				{
					key: 'documentNumber',
					fields: [
						{
							key: 'number',
							label: t`Број документа`,
							xs: 24,
							sm: 12,
							rules: [
								{
									required: true,
									message: t`Број документа је обавезан`,
								},
							],
							component: <Input />,
						},
						{
							key: 'reference',
							label: t`Позив на број`,
							xs: 24,
							sm: 12,
							rules: [],
							component: <ReferenceInput />,
						},
					],
				},
				{
					key: 'row4',
					fields: [
						{
							key: 'currencyId',
							label: t`Валута`,
							hidden: true,
							component: <CurrencySelect />,
							rerenderOnChange: true,
						},
						{
							key: 'amount',
							label: t`Износ`,
							span: 12,
							rules: [
								{
									required: true,
									message: t`Износ је обавезан`,
								},
							],
							component: (
								<InputNumber
									type="number"
									style={{ width: '100%' }}
									addonAfter={
										<CurrencySelect
											style={{ width: 75 }}
											value={form.getFieldValue('currencyId') || 'RSD'}
											optionLabelProp="value"
											onChange={(value) => {
												form.setFieldsValue({ currencyId: value });
												setFields?.([{ name: ['currencyId'] }]);
											}}
										/>
									}
									lang="sr"
									step={0.01}
								/>
							),
						},
						{
							key: 'taxAmount',
							label: t`Износ пореза`,
							span: 12,
							rules: [
								{
									required: true,
									message: t`Износ пореза је обавезан`,
								},
							],
							component: (
								<InputNumber
									type="number"
									style={{ width: '100%' }}
									addonAfter={
										<CurrencySelect
											style={{ width: 75 }}
											value={form.getFieldValue('currencyId') || 'RSD'}
											optionLabelProp="value"
											onChange={(value) => {
												form.setFieldsValue({ currencyId: value });
												setFields?.([{ name: ['currencyId'] }]);
											}}
										/>
									}
									lang="sr"
									step={0.01}
								/>
							),
						},
						// {
						// 	key: 'type',
						// 	label: 'Tip',
						// 	span: 12,
						// 	component: (
						// 		<Select
						// 			mode="multiple"
						// 			style={{ width: '100%' }}
						// 			placeholder="Izaberite tipove"
						// 		>
						// 			<Select.Option value="goods">Ulaz robe</Select.Option>
						// 			<Select.Option value="assets">Osnovna sredstva</Select.Option>
						// 			<Select.Option value="supplies">Potrošna roba</Select.Option>
						// 			<Select.Option value="services">Usluge</Select.Option>
						// 		</Select>
						// 	),
						// },
					],
				},

				{
					key: 'row5',
					fields: [
						{
							key: 'cassaSconto',
							label: t`Износи за плаћање у року`,
							rules: [],
							component: <CassaScontoInput />,
							span: 24,
							initialValue: [],
						},
					],
				},
			],
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/dokumenti/ulazni-dokumenti/',
			empty: {
				image: 'download',
				text: t`Нема улазних докумената`,
			},
			additionalQueryParams: {
				direction: 'incoming',
			},
			shouldFetch: true,
			table: {
				props: {
					scroll: { x: 1900 },
				},
				actions: [(record) => <PayButton record={record} />],
				showDisabledActions: true,
				columns: () => [
					{
						title: t`Број документа`,
						dataIndex: 'number',
						key: 'number',
						width: 220,
						shouldLink: true,
						ellipsis: true,
						filterKey: 'number',
						render: (text, record) => (
							<>
								{text}
								{record.externalId && (
									<img
										src="/images/icons/efaktura-logo.png"
										style={{ float: 'right', marginTop: '4px' }}
										title="e-Faktura"
									/>
								)}
							</>
						),
						filterIcon: (filtered) => (
							<i
								className="fi fi-rr-search"
								style={{ color: filtered ? '#7E89C8' : undefined }}
							></i>
						),
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<div>
								<div style={{ padding: '8px' }}>
									<Input
										placeholder={t`Број документа`}
										value={selectedKeys[0]}
										onPressEnter={() => confirm({ closeDropdown: true })}
										onChange={(event) => setSelectedKeys([event.target.value])}
									/>
								</div>
								<div className="ant-table-filter-dropdown-btns">
									<Button
										onClick={() => clearFilters()}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Поништи</Trans>
									</Button>
									<Button
										type="primary"
										onClick={() => confirm({ closeDropdown: true })}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Потврди</Trans>
									</Button>
								</div>
							</div>
						),
					},
					...(hasFeature('stock')
						? [
								{
									title: t`Калкулација`,
									key: 'calculation',
									ellipsis: true,
									width: 130,
									render: (record) => record.calculations?.[0]?.number,
								},
						  ]
						: []),
					{
						title: t`Тип документа`,
						dataIndex: 'documentType',
						key: 'documentType',
						ellipsis: true,
						width: 150,
						render: (text) => {
							return DOCUMENT_TYPE_MAP[text] || t`Непознат`;
						},
						filters: Object.entries(DOCUMENT_TYPE_MAP).map(([key, value]) => ({
							text: value,
							value: key,
						})),
						filterMultiple: true,
						filterKey: 'documentType',
					},
					// {
					// 	title: 'Poziv na broj',
					// 	key: 'reference',
					// 	ellipsis: true,
					// 	render: (record) =>
					// 		record.reference &&
					// 		`${record.reference.model ? `(${record.reference.model})` : ''} ${
					// 			record.reference.number
					// 		}`,
					// },
					{
						title: t`Статус`,
						dataIndex: 'status',
						key: 'status',
						filterKey: 'status',
						width: 120,
						filters: Object.entries(INVOICE_STATUS_MAP).map(([key, value]) => ({
							text: value,
							value: key,
						})),
						filterMultiple: true,
						render: (text) => {
							return INVOICE_STATUS_MAP[text] || t`Непознат`;
						},
					},
					{
						title: t`Продавац`,
						dataIndex: ['partner', 'name'],
						key: 'partnerId',
						filterKey: 'partnerId',
						filters: () =>
							stores.partners.list?.map((partner: Partner) => ({
								text: partner.name,
								value: partner.id,
							})),
						filterMultiple: true,
						filterSearch: true,
						ellipsis: true,
					},
					{
						title: t`Износ`,
						width: 160,
						key: 'amountWithCassaSconto',
						dataIndex: 'amountWithCassaSconto',
						render: (text, record) =>
							numberFormat(text, true, 2, true, record.currencyId),
					},
					{
						title: t`Преостало`,
						width: 160,
						key: 'remainingAmount',
						dataIndex: 'remainingAmount',
						render: (text, record) =>
							numberFormat(
								record.remainingAmount,
								true,
								2,
								true,
								record.currencyId
							),
					},
					{
						title: t`Датум промета`,
						width: 170,
						key: 'date',
						dataIndex: 'date',
						render: (text) => dayjs(text).format('LL'),
						filterKey: 'date',
						sorter: true,
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<div>
								<div style={{ padding: '8px' }}>
									<DatePicker.RangePicker
										value={[
											selectedKeys[0]
												? dayjs(selectedKeys[0]).startOf('day')
												: undefined,
											selectedKeys[1]
												? dayjs(selectedKeys[1]).endOf('day')
												: undefined,
										]}
										onChange={(values) =>
											setSelectedKeys([
												dayjs(values[0]).startOf('day').toISOString(),
												dayjs(values[1]).endOf('day').toISOString(),
											])
										}
										format="L"
									/>
								</div>
								<div className="ant-table-filter-dropdown-btns">
									<Button
										onClick={() => clearFilters()}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Поништи</Trans>
									</Button>
									<Button
										type="primary"
										onClick={() => confirm({ closeDropdown: true })}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Потврди</Trans>
									</Button>
								</div>
							</div>
						),
					},
					{
						title: t`Датум валуте`,
						width: 170,
						key: 'dueDate',
						dataIndex: 'dueDateWithCassaSconto',
						render: (text, record) => {
							if (!text) {
								return '';
							}
							const badgeColor =
								record.cassaSconto && record.cassaSconto.length
									? 'yellow'
									: 'green';
							return (
								<Tooltip
									title={
										record.cassaSconto && record.cassaSconto.length
											? [
													...record.cassaSconto,
													{
														dueDate: record.dueDate,
														amount: record.payableAmount,
													},
											  ].map((cassa) => (
													<Fragment key={cassa.dueDate}>
														{dayjs(cassa.dueDate).format('LL')}:{' '}
														{numberFormat(cassa.amount, true)}
														<br />
													</Fragment>
											  ))
											: undefined
									}
								>
									<Badge
										color={
											dayjs().endOf('day').isAfter(dayjs(text).endOf('day'))
												? 'red'
												: badgeColor
										}
										text={dayjs(text).format('LL')}
									/>
								</Tooltip>
							);
						},
						sorter: true,
						filterKey: 'dueDate',
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<div>
								<div style={{ padding: '8px' }}>
									<DatePicker.RangePicker
										value={[
											selectedKeys[0]
												? dayjs(selectedKeys[0]).startOf('day')
												: undefined,
											selectedKeys[1]
												? dayjs(selectedKeys[1]).endOf('day')
												: undefined,
										]}
										onChange={(values) =>
											setSelectedKeys([
												dayjs(values[0]).startOf('day').toISOString(),
												dayjs(values[1]).endOf('day').toISOString(),
											])
										}
										format="L"
									/>
								</div>
								<div className="ant-table-filter-dropdown-btns">
									<Button
										onClick={() => clearFilters()}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Поништи</Trans>
									</Button>
									<Button
										type="primary"
										onClick={() => confirm({ closeDropdown: true })}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Потврди</Trans>
									</Button>
								</div>
							</div>
						),
					},
					{
						title: 'Plaćeno',
						width: 125,
						key: 'paid',
						filters: [
							{
								text: <Badge color="green" text="Plaćeno" />,
								value: 'yes',
							},
							{
								text: <Badge color="orange" text="Delimično" />,
								value: 'partial',
							},
							{
								text: <Badge color="red" text="Nije plaćeno" />,
								value: 'no',
							},
						],
						defaultFilteredValue: ['no', 'partial'],
						filterKey: 'paid',
						filterMultiple: true,
						render: (record) => {
							if (!record.paid && record.partiallyPaid) {
								return <Badge color="orange" text={t`Делимично`} />;
							}

							return record.paid ? (
								<Badge color="green" text={t`Плаћено`} />
							) : (
								<Badge color="red" text={t`Није плаћено`} />
							);
						},
					},
				],
			},
			createButtonText: t`Додај улазни документ`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете овај документ?`,
		},
	});
};
