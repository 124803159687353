import { Trans } from '@lingui/macro';
import { Button } from 'antd';

import { InputRange } from '../../InputRange';

type Props = {
	setSelectedKeys: (keys: number[]) => void;
	selectedKeys: number[];
	confirm: (options?: { closeDropdown?: boolean }) => void;
	clearFilters: () => void;
};

export function NumberRangeFilter({
	setSelectedKeys,
	selectedKeys,
	confirm,
	clearFilters,
}: Props) {
	return (
		<div>
			<div style={{ padding: '8px' }}>
				<InputRange
					value={selectedKeys}
					onChange={(values) => {
						setSelectedKeys(values);
					}}
					focus
					onKeyUp={(e) => {
						if (e.key === 'Enter') {
							confirm({ closeDropdown: true });
						}
					}}
				/>
			</div>
			<div className="ant-table-filter-dropdown-btns">
				<Button
					onClick={() => clearFilters()}
					size="small"
					type="link"
					disabled={!selectedKeys[0] && !selectedKeys[1]}
				>
					<Trans>Поништи</Trans>
				</Button>
				<Button
					type="primary"
					onClick={() => confirm({ closeDropdown: true })}
					size="small"
				>
					<Trans>У реду</Trans>
				</Button>
			</div>
		</div>
	);
}
