import { t } from '@lingui/macro';
import { Empty } from 'antd';
import { observer } from 'mobx-react-lite';
import { useImperativeHandle } from 'react';

function UnderConstruction(undefined, ref) {
	useImperativeHandle(ref, () => ({
		renderHader: () => null,
	}));
	return (
		<Empty
			image={<img src="/images/icons/new/blockade.svg" />}
			imageStyle={{
				marginTop: 64,
				height: 64,
			}}
			description={t`Ова функционалност је у развоју.`}
		></Empty>
	);
}

export default () => ({
	Page: observer(UnderConstruction, { forwardRef: true }),
});
