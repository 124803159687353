import { t } from '@lingui/macro';
import { Grid, Input, Select } from 'antd';
import cyr2lat from 'cyrillic-to-latin';
import { useCallback, useEffect, useMemo, useState } from 'react';

import styles from './TaxExemptionInput.module.less';
import { TableInput } from '../../../../../../components/TableInput';
import {
	TAX_EXEMPTION_CODE_CATEGORY,
	getTaxExemptionCode,
	getTaxFeeCategory,
} from '../../../../../../constants/documentInvoice';
import { defaultLocale } from '../../../../../../lib/i18n';
import stores from '../../../../../../stores/index.mobx';

type TaxExemption = {
	category: string;
	code?: string;
	decisionNumber?: string;
};

interface Props {
	value?: TaxExemption[];
	onChange?: (any) => void;
	categories?: string[];
	defaultPicker?: boolean;
}
export function TaxExemptionInput({
	value,
	onChange,
	categories = [],
	defaultPicker = false,
	...props
}: Props) {
	const [TAX_FEE_CATEGORY, TAX_EXEMPTION_CODE] = useMemo(
		() => [getTaxFeeCategory(), getTaxExemptionCode()],
		[]
	);

	const {
		company: { defaultTaxExemption },
		users: { authenticatedUser },
	} = stores;

	const screens = Grid.useBreakpoint();

	const [currentValue, setCurrentValue] = useState(value || []);

	useEffect(() => {
		setCurrentValue(value || []);
	}, [value]);

	const handleChange = useCallback(
		(value) => {
			setCurrentValue(value);
			onChange(value);
		},
		[onChange]
	);

	useEffect(() => {
		if (categories.length > 0 && currentValue.length === 0) {
			// remove all categories that are not in the list and add new ones
			const newValue = (currentValue || []).filter((item) =>
				categories.includes(item.category)
			);
			categories
				.filter((item) => item !== 'S')
				.forEach((category) => {
					if (!newValue.find((item) => item.category === category)) {
						const defaultValue = defaultTaxExemption?.find(
							(item) => item.category === category
						);
						newValue.push({
							category,
							code: defaultValue?.code,
							decisionNumber: defaultValue?.decisionNumber,
						});
					}
				});

			handleChange(newValue);
		}
	}, [categories, defaultTaxExemption, handleChange, currentValue]);

	const columns = useMemo(
		() => [
			{
				title: screens.sm ? t`ПДВ категорија` : t`ПДВ кат.`,
				key: 'category',
				dataIndex: 'category',
				render: (text: string) => {
					return (
						<div className={styles.taxCategory}>
							<strong>{text}:</strong> {TAX_FEE_CATEGORY[text]}
						</div>
					);
				},
			},
			{
				title: t`Шифра основа`,
				key: 'code',
				dataIndex: 'code',
				editable: true,
				rules: !defaultPicker
					? [
							{
								required: true,
								message: t`Шифра основа је обавезна`,
							},
					  ]
					: undefined,
				component: (record: TaxExemption) => {
					return (
						<Select
							placeholder={t`Изаберите основ`}
							style={{ width: '100%' }}
							value={record.code}
							popupMatchSelectWidth={screens.lg}
							optionLabelProp="label"
						>
							<Select.Option value={''} label="">
								{' '}
							</Select.Option>
							{TAX_EXEMPTION_CODE_CATEGORY[record.category]?.map?.((code) => (
								<Select.Option key={code} value={code} label={code}>
									<strong>{code}:</strong>
									<p className={styles.description}>
										{['sr-latn', 'en'].includes(
											authenticatedUser?.locale || defaultLocale
										)
											? cyr2lat(TAX_EXEMPTION_CODE[code])
											: TAX_EXEMPTION_CODE[code]}
									</p>
								</Select.Option>
							))}
						</Select>
					);
				},
			},
			{
				title: t`Број одлуке`,
				dataIndex: 'decisionNumber',
				key: 'decisionNumber',
				editable: true,
				component: <Input />,
				width: 120,
			},
		],
		[screens, defaultPicker, TAX_FEE_CATEGORY, TAX_EXEMPTION_CODE]
	);

	return (
		<TableInput
			{...props}
			isResponsive={false}
			presetItems
			addButtonText=""
			iconPath=""
			emptyText={t`Нема ставки`}
			onChange={handleChange}
			columns={columns}
			value={currentValue}
		/>
	);
}
