import { t, Trans } from '@lingui/macro';
import { Alert, Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useImperativeHandle, useState } from 'react';

import styles from './LocalApi.module.less';
import { StaticComponents } from '../../../../../components/StaticComponents';
import { usePermissions } from '../../../../../hooks/permissions';
import stores from '../../../../../stores/index.mobx';
import { flowResult } from 'mobx';
import { useWatch } from 'antd/es/form/Form';

function LocalApi(undefined, ref) {
	const [form] = Form.useForm();

	const enabledWatch = useWatch('enabled', form);

	const [isFormTouched, setIsFormTouched] = useState(false);
	const { localApi } = stores;

	const { saveConfiguration } = localApi;

	useEffect(() => {
		form.setFieldsValue({
			localApi,
		});
	}, [form, localApi]);

	useImperativeHandle(ref, () => ({
		// TODO: Add docs link
		// getDocsLink: () =>
		// 	'koriscenje/moduli-aplikacije/podesavanja/procesor-fiskalnih-racuna/',
	}));

	async function onSubmit() {
		try {
			const values = await form.validateFields();

			await flowResult(saveConfiguration(values));
			StaticComponents.notification.success({
				message: t`Подешавања су успешно сачувана`,
			});
			setIsFormTouched(false);
		} catch (error) {
			StaticComponents.notification.error({
				message: t`Дошло је до грешке приликом чувања подешавања`,
			});
		}
	}

	function onValuesChange() {
		setIsFormTouched(true);
	}

	const hasApiKeysPermission = usePermissions('settings', 'apiKeys', 'edit');

	return (
		<div className={styles.wrapper}>
			<Form
				layout="vertical"
				className={styles.form}
				initialValues={localApi}
				onFinish={onSubmit}
				form={form}
				onValuesChange={onValuesChange}
				disabled={!hasApiKeysPermission}
			>
				<Form.Item name="enabled" valuePropName="checked">
					<Trans>
						Локални АПИ вам омогућава да из ваше апликације комуницирате са
						локално покренутом инстанцом Бади апликације у циљу издавања
						фискалних рачуна чак и када није доступна интернет веза.
					</Trans>
				</Form.Item>
				<Form.Item name="enabled" valuePropName="checked">
					<Alert
						type="error"
						message={t`Будите уверени да нисте омогућили приступ локалном АПИ серверу ван ваше мреже, пошто не постоје ограничења и провера дозвола на локалном АПИ серверу. Уколико нисте сигурни, молимо вас да се консултујете са вашим администратором мреже.`}
					/>
				</Form.Item>
				<Form.Item name="enabled" valuePropName="checked">
					<Checkbox>
						<Trans>Локални АПИ сервер је омогућен</Trans>
					</Checkbox>
				</Form.Item>
				<Row gutter={16}>
					<>
						<Col span={8}>
							<Form.Item
								label={t`ИП адреса за слушање`}
								name="listenIp"
								rules={[
									{
										required: true,
										message: t`ИП адреса за слушање је обавезна`,
									},
									{
										pattern: new RegExp(
											/^((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.|$)){4}$/
										),
										message: t`Неисправна ИП адреса`,
									},
								]}
							>
								<Input disabled={!enabledWatch} />
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item
								label={t`Порт`}
								name="listenPort"
								rules={[
									{
										required: true,
										message: t`Порт је обавезан`,
									},
									{
										type: 'number',
										min: 1025,
										max: 65535,
										message: t`Неисправан порт`,
										transform: (value) => Number(value),
									},
								]}
							>
								<Input type="number" disabled={!enabledWatch} />
							</Form.Item>
						</Col>
					</>
				</Row>

				<Form.Item>
					<Button
						size="large"
						type="primary"
						htmlType="submit"
						disabled={!isFormTouched}
						// loading={isSaving}
					>
						<Trans>Сачувај</Trans>
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}

export default observer(LocalApi, { forwardRef: true });
