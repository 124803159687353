import { Trans, t } from '@lingui/macro';
import { Button, Empty } from 'antd';
import dayjs from 'dayjs';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useDrawer } from '../../../../../../components/Page';
import stores from '../../../../../../stores/index.mobx';

function CustomEmptyBase() {
	const { pricechanges } = stores;

	const [, openEditPricechange] = useDrawer('edit/stocks/pricechange');

	return (
		<Empty
			image={<img src={`/images/icons/new/price-change.svg`} alt="" />}
			description={t`Нема нивелација`}
		>
			<Button
				type="link"
				onClick={async () => {
					const response = await flowResult(
						pricechanges.create({
							date: dayjs(),
							items: [],
						})
					);
					openEditPricechange(response.id);
				}}
			>
				<Trans>Додај нивелацију</Trans>
			</Button>
		</Empty>
	);
}

export const CustomEmpty = observer(CustomEmptyBase);
