import { Button } from 'antd';
import { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { usePermissions } from '../../../../../../hooks/permissions';
import {
	StockTurnover,
	StockTurnoverType,
} from '../../../../../../stores/StockTurnover.mobx';

type Props = {
	record: StockTurnover;
};

export function EditButton({ record }: Props) {
	const moduleAndSubmodule = useMemo(() => {
		if (record.type === StockTurnoverType.INITIAL) {
			return 'edit/stocks/initial';
		}
		if (record.type === StockTurnoverType.CALCULATION) {
			return 'edit/stocks/calculation';
		}

		if (record.type === StockTurnoverType.RECEIPT) {
			return 'view/receipts/list';
		}
	}, [record]);

	const [, setEditQueryParam] = useQueryParam(moduleAndSubmodule, StringParam);

	const id = useMemo(() => {
		if (record.type === StockTurnoverType.INITIAL) {
			return record?.initialStock?.id;
		}
		if (record.type === StockTurnoverType.CALCULATION) {
			return record?.calculation?.id;
		}

		if (record.type === StockTurnoverType.RECEIPT) {
			return record?.receiptId;
		}
	}, [record]);

	const editButtonIcon = useMemo(() => {
		if (record.type === StockTurnoverType.RECEIPT) {
			return <i className="fi fi-rr-eye"></i>;
		}

		return <i className="fi fi-rr-pencil"></i>;
	}, [record]);

	const canEditPermission = usePermissions('stocks', 'turnover', 'edit');

	return (
		<Button
			icon={editButtonIcon}
			onClick={() => {
				setEditQueryParam(id);
			}}
			disabled={!canEditPermission}
		/>
	);
}
