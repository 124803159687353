import { t, Trans } from '@lingui/macro';
import { ConfigProvider, Empty, Select } from 'antd';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import stores from '../../../../../../stores/index.mobx';
import {
	Partner,
	PartnerLocation,
} from '../../../../../../stores/Partner.mobx';

interface Props extends Omit<React.ComponentProps<typeof Select>, 'value'> {
	onChange?: (value: any, option: any) => void;
	value?: Partner[] | void;
	partnerId: string;
}
const PartnerLocationSelect = function PartnerLocationSelect({
	onChange,
	partnerId,
	...props
}: Props) {
	const [open, setOpen] = useState(false);
	const {
		partners: { fetchSingle, single: partner },
	} = stores;

	const [locations, setLocations] = useState<PartnerLocation[]>([]);

	useEffect(() => {
		if (partnerId) {
			flowResult(fetchSingle(partnerId)).then((partner) => {
				setLocations(partner.locations);
				onChange(null, null);
			});
		}
	}, [fetchSingle, partnerId]);

	const onDropdownVisibleChange = useCallback((visible) => {
		setOpen(visible);
	}, []);

	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/new/place.svg" />}
						imageStyle={{ height: 64 }}
						description={t`Нема издвојених локација`}
					></Empty>
				)}
			>
				<Select
					open={open}
					onDropdownVisibleChange={onDropdownVisibleChange}
					showSearch
					filterOption={(input, option) =>
						option.props.children.toLowerCase().includes(input.toLowerCase())
					}
					loading={stores.partners.isFetching}
					onChange={onChange}
					{...props}
				>
					<Select.Option value={null}>
						<Trans>Седиште</Trans>
					</Select.Option>
					{locations.map((location) => (
						<Select.Option key={location.id} value={location.id}>
							{location.name}
						</Select.Option>
					))}
				</Select>
			</ConfigProvider>
		</>
	);
};

export default observer(PartnerLocationSelect);
