import { Trans, t } from '@lingui/macro';
import { App } from 'antd';
import type { MessageInstance } from 'antd/es/message/interface';
import type { ModalStaticFunctions } from 'antd/es/modal/confirm';
import type { NotificationInstance } from 'antd/es/notification/interface';
import Deferred from 'promise-deferred';
import { useContext, useEffect } from 'react';

import { DrawerContext } from '../../context/DrawerContext';
export const registerDeferred = new Deferred();
const StaticComponents = {} as {
	message: MessageInstance;
	notification: NotificationInstance;
	modal: Omit<ModalStaticFunctions, 'warn'>;
	registerDrawer: (name: string, drawer: any) => void;
};

function Consumer() {
	const staticFunction = App.useApp();
	const { registerDrawer } = useContext(DrawerContext);
	StaticComponents.message = staticFunction.message;
	StaticComponents.modal = staticFunction.modal;
	StaticComponents.notification = staticFunction.notification;
	StaticComponents.registerDrawer = registerDrawer;
	registerDeferred.resolve();
	return null;
}

export default () => {
	useEffect(() => {
		if (window.location.hostname === 'storebuddy.rs') {
			setTimeout(() => {
				StaticComponents.notification.info({
					message: t`StoreBuddy је променио име`,
					style: { width: 700, maxWidth: 'calc(100vw - 48px)' },
					description: (
						<>
							<Trans>
								<video
									autoPlay
									playsInline
									muted
									loop
									height={100}
									ref={async (video) => {
										try {
											await video?.play();
										} catch (error) {}
									}}
								>
									<source src="/videos/rename.mp4" type="video/mp4" />
								</video>
								<p>
									Одлучили смо да променимо брендинг апликације и да јој
									променимо назив у <strong>Бади</strong>. Назив StoreBuddy смо
									сматрали нешто ограничавајућим фактором с обзиром да је уско
									везан за продавнице, а желимо да апликација иде у смеру да
									буде коришћена и у другим областима. Такође, нови назив је
									краћи и лакши за памћење.
								</p>
							</Trans>
							<p>
								{window.electron && (
									<Trans>
										Препоручујемо да преузмете и инсталирате{' '}
										<strong>Бади</strong> са{' '}
										<a target="_blank" href="https://badi.rs/download/">
											овог линка
										</a>
										.
									</Trans>
								)}
								{!window.electron && (
									<Trans>
										Препоручујемо да <strong>Бади</strong> користите на{' '}
										<a href="https://app.badi.rs/">новој адреси</a>.
									</Trans>
								)}{' '}
								Сви ваши подаци ће бити сачувани.
							</p>
						</>
					),
					key: 'rename',
					duration: 0,
				});
			}, 5000);
		} else {
			if (!window.electron) {
				// StaticComponents.modal.warning({
				// 	title: t`Упозорење`,
				// 	content: (
				// 		<Trans>
				// 			Тренутно постоје потешкоће у комуникацији са виртуалним процесором
				// 			фискалних рачуна када се апликација покреће из веб прегледача.
				// 			Молимо вас да преузмете и инсталирате апликацију са{' '}
				// 			<a href="https://badi.rs/download/">https://badi.rs/download/</a>
				// 		</Trans>
				// 	),
				// });
			}
		}
	}, []);

	return (
		<App
			notification={{
				placement: 'bottomRight',
			}}
		>
			<Consumer />
		</App>
	);
};

export { StaticComponents };
