import { Trans } from '@lingui/macro';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';

type Props = {
	setSelectedKeys: (keys: string[]) => void;
	selectedKeys: string[];
	confirm: (options?: { closeDropdown?: boolean }) => void;
	clearFilters: () => void;
};

export function DateRangeFilter({
	setSelectedKeys,
	selectedKeys,
	confirm,
	clearFilters,
}: Props) {
	return (
		<div>
			<div style={{ padding: '8px' }}>
				<DatePicker.RangePicker
					ref={(node) => {
						setTimeout(() => node?.focus());
					}}
					value={[
						selectedKeys[0] ? dayjs(selectedKeys[0]).startOf('day') : undefined,
						selectedKeys[1] ? dayjs(selectedKeys[1]).endOf('day') : undefined,
					]}
					onChange={(values) =>
						setSelectedKeys([
							dayjs(values[0]).startOf('day').toISOString(),
							dayjs(values[1]).endOf('day').toISOString(),
						])
					}
					format="L"
				/>
			</div>
			<div className="ant-table-filter-dropdown-btns">
				<Button
					onClick={() => clearFilters()}
					size="small"
					type="link"
					disabled={!selectedKeys[0] && !selectedKeys[1]}
				>
					<Trans>Поништи</Trans>
				</Button>
				<Button
					type="primary"
					onClick={() => confirm({ closeDropdown: true })}
					size="small"
				>
					<Trans>У реду</Trans>
				</Button>
			</div>
		</div>
	);
}
