import { flow, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { StaticComponents } from '../components/StaticComponents';
import { t } from '@lingui/macro';
import {
	addAdvancePayment,
	closeAdvance,
	copy,
	createReceipt,
	refund,
} from '../api';
import BaseError from '../api/exceptions';

const { Entity } = CreateStore({
	name: 'localApi',
	type: 'entity',
	paginated: false,
	persistFields: ['*'],
});

class LocalApi extends Entity {
	@observable listenIp = '';
	@observable listenPort = '';
	@observable enabled = false;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@flow.bound
	*saveConfiguration(data) {
		this.listenIp = data.listenIp;
		this.listenPort = data.listenPort;
		this.enabled = data.enabled;

		if (this.enabled) {
			yield this.startServer();
		}
	}

	@flow.bound
	*startServer() {
		if (!window['electron']) {
			return;
		}

		try {
			yield window['electron'].stopLocalApi();
			yield window['electron'].startLocalApi({
				ip: this.listenIp,
				port: this.listenPort,
			});

			StaticComponents.notification.success({
				message: t`Локални АПИ`,
				description: t`Локални АПИ сервер је успешно покренут на адреси ${this.listenIp}:${this.listenPort}.`,
			});
		} catch (error) {
			if (error.code === 'EADDRINUSE') {
				StaticComponents.notification.error({
					message: t`Локални АПИ`,
					description: t`Дошло је до грешке приликом покретања локалног АПИ сервера. Порт ${this.listenPort} је већ у употреби на адреси ${this.listenIp}.`,
				});
			} else {
				StaticComponents.notification.error({
					message: t`Локални АПИ`,
					description: t`Дошло је до грешке приликом покретања локалног АПИ сервера`,
				});
			}
		}
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			if (this.enabled) {
				this.startServer();
			}

			if (window['electron']) {
				window['electron'].receive('local-api', async (data) => {
					try {
						let response;
						switch (`${data.method} ${data.path}`) {
							case 'POST /fiscalization/receipts':
								response = await createReceipt(data);
								break;
							case 'POST /fiscalization/receipts/:id/add-advance-payment':
								response = await addAdvancePayment(data);
								break;
							case 'POST /fiscalization/receipts/:id/close-advance':
								response = await closeAdvance(data);
								break;
							case 'POST /fiscalization/receipts/:id/copy':
								response = await copy(data);
								break;
							case 'POST /fiscalization/receipts/:id/refund':
								response = await refund(data);
								break;
							default:
							// error
						}

						window['electron'].localApiResponse(data.requestId, { response });
					} catch (error) {
						let { code } = error;

						code = Number.isInteger(code) && code > 0 ? code : 500;

						if (error instanceof BaseError) {
							const err = {
								...BaseError.prototype.toJSON.call(error),
								code,
							};
							window['electron'].localApiResponse(data.requestId, {
								error: err,
							});
						} else {
							window['electron'].localApiResponse(data.requestId, {
								error: {
									code,
									message: error.message,
								},
							});
						}
					}
				});
			}
		}
	}
}

export { LocalApi };
