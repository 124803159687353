import { t } from '@lingui/macro';

export const getRoleModuleName = () => ({
	Kasa: t`Каса`,
	Katalog: t`Каталог`,
	Kategorije: t`Категорије`,
	Artikli: t`Артикли`,
	Računi: t`Рачуни`,
	Podešavanja: t`Подешавања`,
	Preduzeće: t`Предузеће`,
	'Uloge korisnika': t`Улоге корисника`,
	Korisnici: t`Корисници`,
	'Prodajna mesta': t`Продајна места`,
	Partneri: t`Партнери`,
	PFR: t`ПФР`,
	Uređaji: t`Уређаји`,
	Integracije: t`Интеграције`,
	'Jedinice mere': t`Јединице мере`,
	Popusti: t`Попусти`,
	Dokumenti: t`Документи`,
	'Ulazni dokumenti': t`Улазни документи`,
	'Izlazni dokumenti': t`Излазни документи`,
	'KPO knjiga': t`КПО књига`,
});

export const getRoleActions = () => ({
	Pregled: t`Преглед`,
	Pristup: t`Приступ`,
	Dodavanje: t`Додавање`,
	Izmena: t`Измена`,
	Brisanje: t`Брисање`,
});
