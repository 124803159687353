import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import { Table, Tag } from 'antd';
import reverse from 'lodash/reverse';
import { observer } from 'mobx-react-lite';

import styles from './UpdatedProducts.module.less';
import { defaultLocale, useLocale } from '../../lib/i18n';
import numberFormat from '../../lib/numberFormat';
import stores from '../../stores/index.mobx';

function UpdatedProducts() {
	const {
		products: { updatedProducts },
		users: { authenticatedUser },
	} = stores;

	useLocale(authenticatedUser?.locale || defaultLocale);

	return (
		<>
			<I18nProvider i18n={i18n}>
				<div>
					<Trans>Ажурирани су следећи артикли:</Trans>
				</div>
				<div className={styles.table}>
					<Table
						size="small"
						dataSource={reverse([...updatedProducts])}
						scroll={{ y: 300, x: 800 }}
						pagination={false}
					>
						<Table.Column
							title={t`Шифра`}
							key="sku"
							dataIndex="sku"
							width={70}
						/>
						<Table.Column
							title={t`Артикал`}
							key="name"
							render={(record) => {
								return (
									<>
										{record.name}&nbsp;&nbsp;&nbsp;
										{record.variantName ? (
											<Tag bordered={false} color="magenta">
												{record.variantName}
											</Tag>
										) : (
											''
										)}
									</>
								);
							}}
						/>

						<Table.Column
							title={t`Промена`}
							key="type"
							width={150}
							render={(record) => {
								if (
									record.type === 'updated' &&
									record.oldPrice < record.newPrice
								) {
									return (
										<span style={{ color: 'red ' }}>
											<i className="fi fi-rr-arrow-up"></i>
											<Trans>Повећање цене</Trans>
										</span>
									);
								}
								if (
									record.type === 'updated' &&
									record.oldPrice > record.newPrice
								) {
									return (
										<span style={{ color: 'green ' }}>
											<i className="fi fi-rr-arrow-down"></i>
											<Trans>Смањење цене</Trans>
										</span>
									);
								}
								if (record.type === 'new') {
									return (
										<span style={{ color: 'grey' }}>
											<i className="fi fi-rr-plus"></i>
											<Trans>Нови артикал</Trans>
										</span>
									);
								}
								if (record.type === 'deleted') {
									return (
										<span style={{ color: 'grey ' }}>
											<i class="fi fi-rr-trash"></i>
											<Trans>Обрисан артикал</Trans>
										</span>
									);
								}
							}}
						/>
						<Table.Column
							title={t`Стара цена`}
							key="price"
							width={130}
							render={(record) => {
								return record.oldPrice !== null ? (
									<span
										style={{ color: 'grey', textDecoration: 'line-through' }}
									>
										{numberFormat(
											record.oldPrice,
											true,
											2,
											true,
											record.oldCurrencyId || 'RSD'
										)}
									</span>
								) : (
									'-'
								);
							}}
						/>
						<Table.Column
							title={t`Цена`}
							key="price"
							width={130}
							render={(record) => {
								return record.newPrice !== null
									? numberFormat(
											record.newPrice,
											true,
											2,
											true,
											record.newCurrencyId || 'RSD'
									  )
									: '-';
							}}
						/>
					</Table>
				</div>
			</I18nProvider>
		</>
	);
}

export default observer(UpdatedProducts);
