import { t } from '@lingui/macro';

export const getStatusCodes = () => {
	const STATUS_CODES_TEXT = {
		// INFO
		PIN_CORRECT: t`ПИН код је исправан`,
		INTERNET_AVAILABLE: t`Интернет је доступан`,
		INTERNET_UNAVAILABLE: t`Интернет није доступан`,
		// WARNING
		STORAGE_ALMOST_FULL: t`Простор за складиштење је скоро пун`,
		CARD_NOT_PRESENT: t`Безбедносни елемент није присутан`,
		AUDIT_REQUIRED: t`Потребно је ишчитавање`,
		PIN_REQUIRED: t`Потребан је унос ПИН кода`,
		UNDEFINED_WARNING: t`Недефинисано упозорење`,
		// ERROR
		PIN_INCORRECT: t`ПИН код је неисправан`,
		CARD_LOCKED: t`Паметна картица је закључана`,
		SECURE_ELEMENT_LOCKED: t`Безбедносни елемент је закључан`,
		SECURE_ELEMENT_COMMUNICATION_FAILED: t`Комуникација са безбедносним елементом није успела`,
		SECURE_ELEMENT_PROTOCOL_MISMATCH: t`Протокол безбедносног елемента се не подудара`,
		INVALID_TAX_LABELS: t`Неисправне пореске ознаке`,
		NOT_CONFIGURED: t`ЛПФР није конфигурисан`,
		FIELD_REQUIRED: t`Обавезно поље није попуњено`,
		FIELD_VALUE_TOO_LONG: t`Вредност поља је предугачка`,
		FIELD_VALUE_TOO_SHORT: t`Вредност поља је прекратка`,
		INVALID_FIELD_LENGTH: t`Неисправна дужина поља`,
		FIELD_OUT_OF_RANGE: t`Вредност поља је ван опсега`,
		INVALID_FIELD_VALUE: t`Неисправна вредност поља`,
		INVALID_DATA_FORMAT: t`Неисправан формат података`,
		LIST_TOO_SHORT: t`Листа је прекратка`,
		LIST_TOO_LONG: t`Листа је предугачка`,
	};

	return {
		// INFO
		'0100': [STATUS_CODES_TEXT.PIN_CORRECT, 'secure'],
		'0210': [STATUS_CODES_TEXT.INTERNET_AVAILABLE, 'global-network'],
		'0220': [STATUS_CODES_TEXT.INTERNET_UNAVAILABLE, 'global-network'],
		// WARNING
		'1100': [STATUS_CODES_TEXT.STORAGE_ALMOST_FULL, 'disk-storage'],
		'1300': [STATUS_CODES_TEXT.CARD_NOT_PRESENT, 'sim-card'],
		'1400': [STATUS_CODES_TEXT.AUDIT_REQUIRED, 'search'],
		'1500': [STATUS_CODES_TEXT.PIN_REQUIRED, 'secure'],
		'1999': [STATUS_CODES_TEXT.UNDEFINED_WARNING, 'warning'],
		// ERROR
		'2100': [STATUS_CODES_TEXT.PIN_INCORRECT, 'secure'],
		'2110': [STATUS_CODES_TEXT.CARD_LOCKED, 'sim-card'],
		'2210': [STATUS_CODES_TEXT.SECURE_ELEMENT_LOCKED, 'sim-card'],
		'2220': [
			STATUS_CODES_TEXT.SECURE_ELEMENT_COMMUNICATION_FAILED,
			'question-mark',
		],
		'2230': [
			STATUS_CODES_TEXT.SECURE_ELEMENT_PROTOCOL_MISMATCH,
			'question-mark',
		],
		'2310': [STATUS_CODES_TEXT.INVALID_TAX_LABELS, 'folder'],
		'2400': [STATUS_CODES_TEXT.NOT_CONFIGURED, 'settings'],
		'2800': [STATUS_CODES_TEXT.FIELD_REQUIRED, 'decline'],
		'2801': [STATUS_CODES_TEXT.FIELD_VALUE_TOO_LONG, 'decline'],
		'2802': [STATUS_CODES_TEXT.FIELD_VALUE_TOO_SHORT, 'decline'],
		'2803': [STATUS_CODES_TEXT.INVALID_FIELD_LENGTH, 'decline'],
		'2804': [STATUS_CODES_TEXT.FIELD_OUT_OF_RANGE, 'decline'],
		'2805': [STATUS_CODES_TEXT.INVALID_FIELD_VALUE, 'decline'],
		'2806': [STATUS_CODES_TEXT.INVALID_DATA_FORMAT, 'decline'],
		'2807': [STATUS_CODES_TEXT.LIST_TOO_SHORT, 'decline'],
		'2808': [STATUS_CODES_TEXT.LIST_TOO_LONG, 'decline'],
	};
};

export const getStatusPerMRC = () => {
	const CUSTOM_STATUS = {
		RECIPT_OLDER_THAN_LAST: t`Датум и време издавања рачуна не може бити ранији од датума и времена издавања претходног рачуна`,
		SDC_TIME_INVALID: t`Неисправан датум и време на ЛПФР-у`,
		SDC_SERIAL_NUMBER_NOT_DEFINED: t`Серијски број ЛПФР-а није дефинисан`,
		DISK_FULL: t`Диск је пун`,
		SE_CERTIFICATE_EXPIRED: t`Сертификат безбедносног елемента је истекао. Потребно је заменити безбедносни елемент`,
		SE_CERTIFICATE_REVOKED: t`Сертификат безбедносног елемента је опозван. Потребно је заменити безбедносни елемент`,
		LICENCE_INVALID: t`Лиценца за коришћење ЛПФР-а није исправна`,
		LICENCE_ACTIVE: t`Лиценца за коришћење је активна`,
		LICENCE_EXPIRED: t`Лиценца за коришћење ЛПФР-а је истекла`,
		DUPLICATE_INVOICE_REQUEST: t`Захтев за рачуном је дуплициран`,
		DONGLE_DOES_NOT_EXIST: t`Dongle не постоји`,
		CARD_READER_NOT_PRESENT: t`Читач картица није присутан`,
		DRIVE_NOT_FOUND: t`Екстерна јединица меморије није пронађена`,
		COMMANDS_FILE_NOT_FOUND: t`Фајл са командама није пронађен`,
		CA_CERTIFICATES_NOT_INSTALLED: t`ЦА Сертификати нису инсталирани`,
		LAST_INVOICE_NOT_FOUND: t`Последњи рачун није пронађен`,
	};
	return {
		'05-0002': {
			// TiMProcesor
			'3100': [CUSTOM_STATUS.LICENCE_INVALID, 'license'],
			'3101': [CUSTOM_STATUS.SDC_TIME_INVALID, 'clock'],
		},
		'48-0001': {
			// HCP LPFR-W
			'5000': [CUSTOM_STATUS.DONGLE_DOES_NOT_EXIST, 'license'],
			'5100': [CUSTOM_STATUS.CARD_READER_NOT_PRESENT, 'sim-card'],
			'5101': [CUSTOM_STATUS.DRIVE_NOT_FOUND, 'disk-storage'],
			'5103': [CUSTOM_STATUS.SDC_TIME_INVALID, 'clock'],
			'5104': [CUSTOM_STATUS.COMMANDS_FILE_NOT_FOUND, 'search'],
			'5107': [CUSTOM_STATUS.DISK_FULL, 'disk-storage'],
			'5108': [CUSTOM_STATUS.CA_CERTIFICATES_NOT_INSTALLED, 'license'],
			'5109': [CUSTOM_STATUS.LAST_INVOICE_NOT_FOUND, 'question-mark'],
			'5110': [CUSTOM_STATUS.LICENCE_ACTIVE, 'license'],
			'5111': [CUSTOM_STATUS.LICENCE_EXPIRED, 'license'],
			'5112': [CUSTOM_STATUS.SE_CERTIFICATE_EXPIRED, 'sim-card'],
			'5113': [CUSTOM_STATUS.SE_CERTIFICATE_REVOKED, 'sim-card'],
		},
		'75-0001': {
			// Master LPFR
			'3200': [CUSTOM_STATUS.SDC_SERIAL_NUMBER_NOT_DEFINED, 'bars-code'],
			'3201': [CUSTOM_STATUS.SDC_TIME_INVALID, 'clock'],
			'3202': [CUSTOM_STATUS.LICENCE_INVALID, 'license'],
		},
		'85-0001': {
			// Badi L-PFR
			'2900': [CUSTOM_STATUS.RECIPT_OLDER_THAN_LAST, 'clock'],
			'2910': [CUSTOM_STATUS.DISK_FULL, 'disk-storage'],
			'2950': [CUSTOM_STATUS.SE_CERTIFICATE_EXPIRED, 'sim-card'],
			'2990': [CUSTOM_STATUS.LICENCE_INVALID, 'license'],
			'2991': [CUSTOM_STATUS.LICENCE_EXPIRED, 'license'],
		},
		'1B-0001': {
			// Symphony
			'4230': [CUSTOM_STATUS.LICENCE_INVALID, 'license'],
			'4242': [CUSTOM_STATUS.DUPLICATE_INVOICE_REQUEST, 'copy'],
		},
	};
};

export const vsdcUrl =
	window.location.hostname === 'localhost' ||
	window.location.hostname.includes('sandbox')
		? 'https://vsdc.sandbox.suf.purs.gov.rs'
		: 'https://vsdc.suf.purs.gov.rs';
