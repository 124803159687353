import { observable, flow, runInAction, flowResult } from 'mobx';

import { CreateStore } from './Crud.mobx';
import type { File } from './shared/File.mobx';

export type LoyaltyType = 'none' | 'points' | 'stickers' | 'tiers';

export type LoyaltyConfiguration = {
	purchaseAmount?: number;
	rewardPoints?: number;
	pointWorth?: number;
	maxPointsPerPurchase?: number;
	expiry?: 'never' | 'month' | 'quarter' | 'halfyear' | 'year' | 'custom';
	expiryCustomUnit?: 'days' | 'months' | 'years';
	expiryCustom?: number;
	stickerAwardMode?: 'once' | 'every';
	sticker?: string;
	stickerRules?: {
		stickerCount?: number;
		discount?: number;
	}[];
	tiersRules?: {
		tierName?: string;
		totalSpentAmount?: number;
		discount?: number;
	}[];
};

const { Entity } = CreateStore({
	type: 'entity',
	name: 'company',
	paginated: false,
	persistFields: ['*'],
	persistDelay: 0,
});

export class MojBadiSettings {
	@observable enabled?: boolean;
	@observable name?: string;
	@observable description?: string;
	@observable coverImageFileId?: string;
	@observable coverImageFile?: File;
	@observable profileImageFileId?: string;
	@observable profileImageFile?: File;
	@observable loyaltyType?: LoyaltyType = 'none';
	@observable loyaltyConfiguration?: LoyaltyConfiguration;
	@observable status?: 'active' | 'pending' | 'rejected';
	@observable rejectedReason?: string;
	@observable storeIds?: string[];
}

class MojBadi extends Entity {
	@observable settings: MojBadiSettings;
	@observable isFetchingSettings = false;
	@observable isUpdatingSettings = false;

	@observable isCreating = false;

	constructor() {
		super();
		this.init();
	}

	@flow.bound
	*fetchSettings() {
		this.isFetchingSettings = true;
		try {
			const response = yield this.getClient().get('/moj-badi/settings');
			this.settings = response.data;
		} finally {
			this.isFetchingSettings = false;
		}
	}

	@flow.bound
	*updateSettings(settings: MojBadiSettings) {
		this.isUpdatingSettings = true;
		try {
			const response = yield this.getClient().patch(
				'/moj-badi/settings',
				settings
			);
			this.settings = response.data;
		} finally {
			this.isUpdatingSettings = false;
		}
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await flowResult(this.fetchSettings());
		} else {
			runInAction(() => {
				this.isCreating = false;
				this.isUpdating = false;
				this.isFetching = false;
				this.isFetchingSettings = false;
			});
		}
	}
}

export { MojBadi };
