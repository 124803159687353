/* eslint-disable no-template-curly-in-string */

const typeTemplate = '${label} није важећи ${type}';

const CalendarLocale = {
	locale: 'sr-cyrl_RS',
	today: 'Данас',
	now: 'Сада',
	backToToday: 'Врати се на данас',
	ok: 'У реду',
	clear: 'Обриши',
	month: 'Месец',
	year: 'Година',
	timeSelect: 'Изабери време',
	dateSelect: 'Изабери датум',
	monthSelect: 'Изабери месец',
	yearSelect: 'Изабери годину',
	decadeSelect: 'Изабери деценију',
	yearFormat: 'YYYY',
	dateFormat: 'DD.MM.YYYY',
	dayFormat: 'D',
	dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
	monthBeforeYear: true,
	previousMonth: 'Претходни месец (PageUp)',
	nextMonth: 'Следећи месец (PageDown)',
	previousYear: 'Претходна година (Control + left)',
	nextYear: 'Следећа година (Control + right)',
	previousDecade: 'Претходна деценија',
	nextDecade: 'Следећа деценија',
	previousCentury: 'Претходни век',
	nextCentury: 'Следећи век',
};

const Pagination = {
	// Options.jsx
	items_per_page: '/ страни',
	jump_to: 'Иди на',
	page: '',
	// Pagination.jsx
	prev_page: 'Претходна страна',
	next_page: 'Следећа страна',
	prev_5: 'Претходних 5 Страна',
	next_5: 'Следећих 5 Страна',
	prev_3: 'Претходних 3 Стране',
	next_3: 'Следећих 3 Стране',
	page_size: 'Page Size',
};

const Calendar = {
	lang: Object.assign(
		{
			placeholder: 'Изабери датум',
			yearPlaceholder: 'Изабери годину',
			quarterPlaceholder: 'Изабери тромесечје',
			monthPlaceholder: 'Изабери месец',
			weekPlaceholder: 'Изабери седмицу',
			rangePlaceholder: ['Датум почетка', 'Датум завршетка'],
			rangeYearPlaceholder: ['Година почетка', 'Година завршетка'],
			rangeMonthPlaceholder: ['Месец почетка', 'Месец завршетка'],
			rangeWeekPlaceholder: ['Седмица почетка', 'Седмица завршетка'],
		},
		CalendarLocale
	),
	timePickerLocale: {
		placeholder: 'Изабери време',
		rangePlaceholder: ['Време почетка', 'Време завршетка'],
	},
};

const localeValues = {
	locale: 'sr-cyrl',
	Pagination,
	DatePicker: Calendar,
	TimePicker: Calendar.timePickerLocale,
	Calendar,
	global: {
		placeholder: 'Изабери',
	},
	Table: {
		filterTitle: 'Мени филтера',
		filterConfirm: 'У реду',
		filterReset: 'Поништи',
		filterEmptyText: 'Нема филтера',
		emptyText: 'Нема података',
		selectAll: 'Изабери тренутну страницу',
		selectInvert: 'Обрни избор тренутне странице',
		selectNone: 'Обриши све податке',
		selectionAll: 'Изабери све податке',
		sortTitle: 'Сортирај',
		expand: 'Прошири ред',
		collapse: 'Скупи ред',
		triggerDesc: 'Кликни да сортираш по падајућем редоследу',
		triggerAsc: 'Кликни да сортираш по растућем редоследу',
		cancelSort: 'Кликни да откажеш сортирање',
	},
	Modal: {
		okText: 'У реду',
		cancelText: 'Откажи',
		justOkText: 'У реду',
	},
	Popconfirm: {
		okText: 'У реду',
		cancelText: 'Откажи',
	},
	Transfer: {
		titles: ['', ''],
		searchPlaceholder: 'Претражи овде',
		itemUnit: 'ставка',
		itemsUnit: 'ставки',
		remove: 'Уклони',
		selectCurrent: 'Изабери тренутну страницу',
		removeCurrent: 'Уклони тренутну страницу',
		selectAll: 'Изабери све податке',
		removeAll: 'Уклони све податке',
		selectInvert: 'Обрни избор тренутне странице',
	},
	Upload: {
		uploading: 'Отпремање...',
		removeFile: 'Уклони датотеку',
		uploadError: 'Грешка при отпремању',
		previewFile: 'Прегледај датотеку',
		downloadFile: 'Преузми датотеку',
	},
	Empty: {
		description: 'Нема података',
	},
	Icon: {
		icon: 'икона',
	},
	Text: {
		edit: 'Уреди',
		copy: 'Копирај',
		copied: 'Копирано',
		expand: 'Прошири',
	},
	PageHeader: {
		back: 'Назад',
	},
	Form: {
		optional: '(опционално)',
		defaultValidateMessages: {
			default: 'Грешка при провери ваљаности за ${label}',
			required: 'Унеси ${label}',
			enum: '${label} мора да буде нешто од [${enum}]',
			whitespace: '${label} не може бити празан знак',
			date: {
				format: '${label} формат датума је неважећи',
				parse: '${label} се не може конвертовати у датум',
				invalid: '${label} је неважећи датум',
			},
			types: {
				string: typeTemplate,
				method: typeTemplate,
				array: typeTemplate,
				object: typeTemplate,
				number: typeTemplate,
				date: typeTemplate,
				boolean: typeTemplate,
				integer: typeTemplate,
				float: typeTemplate,
				regexp: typeTemplate,
				email: typeTemplate,
				url: typeTemplate,
				hex: typeTemplate,
			},
			string: {
				len: '${label} мора да садржи ${len} знакова',
				min: '${label} мора да садржи бар ${min} знакова',
				max: '${label} мора да садржи до ${max} знакова',
				range: '${label} мора да садржи између ${min} и ${max} знакова',
			},
			number: {
				len: '${label} мора бити једнак ${len}',
				min: '${label} мора бити најмање ${min}',
				max: '${label} мора бити највише ${max}',
				range: '${label} мора бити између ${min} и ${max}',
			},
			array: {
				len: 'Мора бити ${len} ${label}',
				min: 'Најмање ${min} ${label}',
				max: 'највише ${max} ${label}',
				range: 'Износ ${label} мора бити између ${min} и ${max}',
			},
			pattern: {
				mismatch: '${label} не одговара обрасцу ${pattern}',
			},
		},
	},
	Image: {
		preview: 'Преглед',
	},
};
export default localeValues;
