import { t } from '@lingui/macro';
import { Modal, InputNumber, Form, DatePicker, Alert } from 'antd';
import dayjs from 'dayjs';
import IPSQRCode from 'ips-qr-code';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import QRCode from 'qrcode';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { INVOICE_STATUS } from '../../../../../../constants/documentInvoice';
import stores from '../../../../../../stores/index.mobx';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

interface Props {
	visible: boolean;
	close: () => void;
	id?: string;
}

function PayModal({ visible, close, id }: Props) {
	const {
		invoices,
		partners,
		company: { name: companyName, city: companyCity },
	} = stores;

	const invoice = useMemo(() => (id ? invoices.getById(id) : null), [id]);

	// const [value, setValue] = useState(
	// 	round((invoice?.amount || 0) - (invoice?.paidAmount || 0), 2)
	// );
	const [ipsQr, setIpsQr] = useState(null);
	const [form] = Form.useForm();
	const isFocused = useRef(false);

	useEffect(() => {
		if (!visible) {
			isFocused.current = false;
		} else {
			generateIpsQr({
				amount: round(
					(invoice?.payableAmount || 0) - (invoice?.paidAmount || 0),
					2
				),
			});
		}
	}, [visible, invoice]);

	const submit = useCallback(() => {
		form.validateFields().then(async (values) => {
			const { amount, date } = values;
			if (amount > 0 || (invoice.payableAmount === 0 && amount === 0)) {
				await invoice.pay(round(amount, 2), date);
			}
			close();
		});
	}, [form, invoice, close]);

	const generateIpsQr = useCallback(async (allValues) => {
		if (!invoice) {
			return;
		}
		const partner = partners?.getById(invoice.partnerId);
		const bankAccountNumber = partner?.bankAccounts?.find(
			(bankAccount) => bankAccount.primary
		)?.number;
		if (bankAccountNumber) {
			IPSQRCode({
				p: `${companyName}${companyCity ? `, ${companyCity}` : ''}`,
				s: `Uplata po računu ${invoice.number}`,
				n: `${partner.name}${partner.city ? `, ${partner.city}` : ''}`
					.trim()
					.substring(0, 70),
				sf: 221,
				i: `RSD${numeral(allValues.amount).format('0.00')}`,
				r: bankAccountNumber,
				k: 'PR',
				v: '01',
				c: 1,
				ro: `${
					invoice.reference?.model || invoice.reference?.number ? '00' : ''
				}${
					invoice.reference?.model === '97'
						? invoice.reference?.number?.replace(/-/g, '').replace(/ /g, '') ||
						  ''
						: invoice.reference?.number || ''
				}`,
			})
				.then((qr) => {
					return QRCode.toDataURL(qr, { width: 252, margin: 0 });
				})
				.then((qr) => setIpsQr(qr))
				.catch((err) => {
					console.log(err);
					setIpsQr(null);
				});
		} else {
			setIpsQr(null);
		}
	}, []);

	return (
		<Modal
			centered
			width={300}
			title={t`Плаћање фактуре`}
			open={visible}
			destroyOnClose
			onOk={() => {
				submit();
			}}
			onCancel={() => {
				close();
			}}
		>
			{visible && (
				<>
					<Form
						{...formItemLayout}
						form={form}
						layout="vertical"
						preserve={false}
						initialValues={{
							date: dayjs(),
							amount: round(
								(invoice?.payableAmount || 0) - (invoice?.paidAmount || 0),
								2
							),
						}}
						onValuesChange={(changedValues, allValues) => {
							generateIpsQr(allValues);
						}}
					>
						{stores.marketplaceApplications.isInstalled(
							'rs.storebuddy.efaktura'
						) &&
							[
								INVOICE_STATUS.NEW,
								INVOICE_STATUS.SEEN,
								INVOICE_STATUS.REJECTED,
							].includes(invoice.status) && (
								<Form.Item>
									<Alert
										type="warning"
										message={t`Плаћањем фактуре ћете је прихватити на систему е-Фактура.`}
									/>
								</Form.Item>
							)}
						<Form.Item
							label={t`Уплаћен износ`}
							required
							name="amount"
							rules={[
								{
									type: 'number',
									max: round(invoice.payableAmount - invoice.paidAmount, 2),
									message: t`Уплаћени износ може бити максимално преостали износ за плаћање (${round(
										invoice.payableAmount - invoice.paidAmount,
										2
									)})`,
								},
								{
									required: true,
									message: t`Обавезан је унос износа`,
								},
							]}
						>
							<InputNumber
								type="number"
								style={{ width: '100%' }}
								ref={(input) => {
									if (!isFocused.current && input && visible) {
										isFocused.current = true;
										setTimeout(() => {
											(input as any)?.select?.();
										});
									}
								}}
								onPressEnter={() => {
									submit();
								}}
							/>
						</Form.Item>
						<Form.Item label={t`Датум плаћања`} name={'date'}>
							<DatePicker style={{ width: '100%' }} format="L" />
						</Form.Item>
						{ipsQr && (
							<Form.Item label={t`НБС ИПС QR`}>
								<img src={ipsQr} alt={t`НБС ИПС QR Код`} />
							</Form.Item>
						)}
					</Form>
				</>
			)}
		</Modal>
	);
}

export default observer(PayModal);
