import { EditableProTable } from '@ant-design/pro-table/es';
import type { EditableProTableProps } from '@ant-design/pro-table/es';
import type { ActionType, ProColumns } from '@ant-design/pro-table/es';
import type { EditableFormInstance } from '@ant-design/pro-table/es';
import { t } from '@lingui/macro';
import { Button, ConfigProvider, Empty, Form, Space } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import styles from './TableInput.module.less';

type Props<RowType> = {
	addButtonText: string;
	columns: ProColumns[];
	shouldShowDuplicateButton?: boolean;
	shouldShowIndexColumn?: boolean;
	value?: RowType[];
	onChange?: (value: RowType[]) => void;
	editableFormRef?: React.MutableRefObject<EditableFormInstance>;
	initialValue?: Partial<RowType>;
	emptyText: string;
	iconPath: string;
	tableProps: EditableProTableProps<RowType, unknown, unknown>;
	footer?: () => React.ReactNode;
	onSave?: (key, record) => Promise<void>;
};

export function TableInputProTable<RowType>({
	addButtonText,
	columns,
	shouldShowDuplicateButton,
	shouldShowIndexColumn,
	value,
	editableFormRef,
	onChange,
	initialValue = {},
	emptyText,
	iconPath,
	tableProps,
	footer: footerProp,
	onSave,
}: Props<RowType>) {
	const actionRef = useRef<ActionType>();
	const containerRef = useRef<HTMLDivElement>(null);

	const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
	const [currentPage, setCurrentPage] = useState(1);

	const [form] = Form.useForm();

	const handleAdd = useCallback(() => {
		actionRef.current?.addEditRecord?.(
			{
				id: uuid(),
				...initialValue,
			},
			{
				newRecordType: 'cache',
			}
		);
		setCurrentPage((((value?.length + 1 || 0) / 10) | 0) + 1);

		setTimeout(() => {
			(
				containerRef?.current?.querySelector(
					'.ant-table-tbody tr:last-child input'
				) as HTMLInputElement
			)?.focus();
		}, 100);
	}, [initialValue, value?.length]);

	const footer = useCallback(() => {
		return (
			<>
				<Button onClick={handleAdd}>{addButtonText}</Button>
				{footerProp?.()}
			</>
		);
	}, [addButtonText, handleAdd, footerProp]);

	const extendedColumns = useMemo(() => {
		return [
			...(shouldShowIndexColumn
				? [
						{
							title: t`РБ`,
							width: 80,
							render: (value, record, index) => (
								<span>{index + 1 + (currentPage - 1) * 10}</span>
							),
							colProps: {
								xs: 24,
								sm: 2,
							},
							dataIndex: 'index',
							editable: false,
							fixed: 'left',
						},
				  ]
				: []),
			...columns.map((column) => {
				return {
					...column,
					handlePressEnter: async (e, recordKey) => {
						e.preventDefault();
						const index = value.findIndex(
							(item) => (item as { id: string }).id === recordKey
						);
						const tr = e.currentTarget.closest('tr');

						actionRef.current?.saveEditable?.(recordKey);

						setTimeout(() => {
							const valid =
								tr?.querySelectorAll('.ant-form-item-has-error').length === 0;
							if ((index === value.length - 1 || index === -1) && valid) {
								handleAdd();
							}
						}, 100);
					},
					handleKeyDown: (e, recordKey) => {
						if (e.key === 'Escape') {
							e.stopPropagation();

							const index = value.findIndex(
								(item) => (item as { id: string }).id === recordKey
							);

							actionRef.current?.cancelEditable?.(recordKey);
						}
					},
				};
			}),
			{
				valueType: 'option',
				width: shouldShowDuplicateButton ? 110 : 80,
				fixed: 'right',
				render: (text, record, _, action) => (
					<Space.Compact>
						<Button
							icon={<i className="fi fi-rr-pencil"></i>}
							onClick={() => action?.startEditable?.(record.id)}
						/>
						{shouldShowDuplicateButton && (
							<EditableProTable.RecordCreator
								record={{
									...record,
									id: uuid(),
								}}
							>
								<Button icon={<i className="fi fi-rr-duplicate"></i>} />
							</EditableProTable.RecordCreator>
						)}
						<Button
							onClick={() => {
								onChange(
									value.filter(
										(item) => (item as { id: string }).id !== record.id
									)
								);
							}}
							icon={<i className="fi fi-rr-trash"></i>}
						></Button>
					</Space.Compact>
				),
			},
		];
	}, [
		columns,
		currentPage,
		handleAdd,
		onChange,
		shouldShowDuplicateButton,
		shouldShowIndexColumn,
		value,
	]);

	const actionRender = useCallback((row, config, dom) => {
		return [
			<Space.Compact>
				{dom.save}
				{dom.cancel}
			</Space.Compact>,
		];
	}, []);

	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src={iconPath} alt="" />}
						imageStyle={{
							height: 64,
						}}
						description={emptyText}
					>
						<Button type="link" onClick={handleAdd}>
							{addButtonText}
						</Button>
					</Empty>
				)}
			>
				<div className={styles.container} ref={containerRef}>
					<EditableProTable<RowType>
						editableFormRef={editableFormRef}
						rowKey="id"
						bordered={false}
						size="small"
						// controlled
						className={styles.table}
						pagination={{
							pageSize: 10,
							onChange(page, pageSize) {
								setCurrentPage(page);
							},
							current: currentPage,
						}}
						actionRef={actionRef}
						recordCreatorProps={false}
						columns={extendedColumns}
						value={value}
						footer={footer}
						onChange={onChange}
						editable={{
							form,
							editableKeys,
							onSave,
							onChange: setEditableRowKeys,
							onlyOneLineEditorAlertMessage: t`Само један ред може бити уређиван у једном тренутку`,
							actionRender,
							cancelText: (
								<Button icon={<i className="fi fi-rr-cross-small"></i>} />
							),
							saveText: <Button icon={<i className="fi fi-rr-check"></i>} />,
						}}
						{...tableProps}
					/>
				</div>
			</ConfigProvider>
		</>
	);
}
