import { t, Trans } from '@lingui/macro';
import { DatePicker, Input, Table } from 'antd';
import dayjs from 'dayjs';
import { Observer } from 'mobx-react-lite';

import CustomCreateButton from './Components/CustomCreateButton';
import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';
import stores from '../../../../stores/index.mobx';
import { StringParam } from 'serialize-query-params';
export default () =>
	CreatePage({
		module: 'documents',
		submodule: 'kpo',
		model: 'kpo',
		view: {
			useEdit: true,
		},
		edit: {
			width: 500,
			title: {
				new: t`Додавање ставке у КПО књигу`,
				existing: (entity) => t`Измена ставке КПО књиге`,
			},
			shouldFetch: false,
			fields: (record, form, setFields) => {
				return [
					{
						key: 'info',
						fields: [
							{
								hidden: true,
								key: 'type',
								initialValue: 'custom',
								component: <Input />,
							},
							{
								hidden: true,
								key: 'currencyId',
								initialValue: 'RSD',
								component: <Input />,
							},
							{
								label: t`Датум`,
								key: 'documentDate',
								initialValue: dayjs(),
								component: (
									<DatePicker format={'L'} style={{ width: '100%' }} />
								),
								rules: [
									{
										required: true,
										message: t`Датум документа је обавезан`,
									},
								],
								span: 24,
							},
							{
								label: t`Опис`,
								key: 'description',
								component: <Input.TextArea rows={4} />,
								rules: [
									{
										required: true,
										message: t`Опис је обавезан`,
									},
								],
								span: 24,
							},
							{
								label: t`Приход од продаје производа`,
								key: 'productAmount',
								component: (
									<Input
										type="number"
										min={0}
										style={{ width: '100%' }}
										suffix="RSD"
									/>
								),
								span: 12,
							},
							{
								label: t`Приход од извршених услуга`,
								key: 'serviceAmount',
								component: (
									<Input
										type="number"
										min={0}
										style={{ width: '100%' }}
										suffix="RSD"
									/>
								),
								span: 12,
							},
						],
					},
				];
			},
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/dokumenti/kpo-knjiga/',
			empty: {
				text: t`Нема ставки у КПО књизи`,
				image: 'book',
			},
			shouldFetch: true,
			table: {
				additionalFilters: {
					date: StringParam,
				},
				showDisabledActions: true,
				props: {
					scroll: { x: 1200 },
					summary: () => (
						<Observer>
							{() => (
								<Table.Summary fixed>
									<Table.Summary.Row>
										<Table.Summary.Cell index={0} colSpan={3} align="right">
											<strong>
												<Trans>Укупно</Trans>
											</strong>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={1}>
											{numberFormat(stores.kpos.productAmount, true)}
										</Table.Summary.Cell>
										<Table.Summary.Cell index={2}>
											{numberFormat(stores.kpos.serviceAmount, true)}
										</Table.Summary.Cell>
										<Table.Summary.Cell index={3}>
											{numberFormat(stores.kpos.totalAmount, true)}
										</Table.Summary.Cell>
										<Table.Summary.Cell index={4}></Table.Summary.Cell>
									</Table.Summary.Row>
								</Table.Summary>
							)}
						</Observer>
					),
				},
				columns: () => {
					return [
						{
							title: t`Редни број`,
							key: 'ordinalNumber',
							width: 110,
							render: (text, record, index) => (
								<Observer>
									{() => index + 1 + (stores.kpos.pagination.offset || 0)}
								</Observer>
							),
						},
						{
							title: t`Датум`,
							dataIndex: 'documentDate',
							key: 'documentDate',
							width: 120,
							shouldLink: false,
							render: (value) => value.format('L'),
						},
						{
							title: t`Опис`,
							dataIndex: 'description',
							key: 'description',
							ellipsis: true,
							render(text?, record?, index?) {
								if (record.type === 'receipts') {
									return t`Фискални рачуни за дан ${record.documentDate.format(
										'L'
									)}`;
								}
								if (record.type === 'invoice') {
									return t`Фактура ${record.documentNumber} за ${record.partnerName}`;
								}
								if (record.type === 'creditNote') {
									return t`Књижно одобрење ${record.documentNumber} за ${record.partnerName}`;
								}
								if (record.type === 'debitNote') {
									return t`Књижно задужење ${record.documentNumber} за ${record.partnerName}`;
								}
								if (record.type === 'advanceInvoice') {
									return t`Авансни рачун ${record.documentNumber} за ${record.partnerName}`;
								}

								return text;
							},
						},
						{
							title: t`Приход од продаје производа`,
							dataIndex: 'productAmount',
							key: 'productAmount',
							ellipsis: true,
							render: (value) => numberFormat(value, true),
						},
						{
							title: t`Приход од извршених услуга`,
							dataIndex: 'serviceAmount',
							key: 'serviceAmount',
							ellipsis: true,
							render: (value) => numberFormat(value, true),
						},
						{
							title: t`Свега приходи од делатности`,
							dataIndex: 'amount',
							key: 'amount',
							ellipsis: true,
							render: (value) => numberFormat(value, true),
						},
					];
				},
			},

			createButtonText: t`Додај ставку`,
			additionalQueryParams: {
				date: dayjs().format('YYYY-MM-DD'),
			},
			deletePrompt: t`Да ли сте сигурни да желите да обришете ову ставку?`,
			customCreateButton: (createHandler) => (
				<CustomCreateButton createButtonHandler={createHandler} />
			),
		},
	});
