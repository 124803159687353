import { observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { Product } from './Product.mobx';
import { ReferenceTransformer } from './transformers/Reference';

const { Store, Entity } = CreateStore({
	name: 'stock',
	paginated: false,
	searchFields: [
		'product.name',
		'product.sku',
		'product.ean',
		'product.variants.sku',
		'product.variants.ean',
		'product.variants.variantName',
		'product.manufacturerSku',
		'product.variant.manufacturerSku',
	],
});

class Stock extends Entity {
	@observable quantity?: number;
	@observable productId?: string;

	@ReferenceTransformer('product', 'productId') product?: Product;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isDeletable() {
		return false;
	}

	get isEditable() {
		return false;
	}
}

class Stocks extends Store<Stock> {
	constructor() {
		super(Stock);
	}

	get isCreatable() {
		return true;
	}
}

export { Stocks, Stock };
