import { t } from '@lingui/macro';
import { Input } from 'antd';
import gs1 from 'gs1-key';

import { CreatePage } from '../../../../../components/Page';

export default () =>
	CreatePage({
		module: 'settings',
		submodule: 'warehouses',
		model: 'warehouse',
		page: {
			shouldFetch: false,
			table: {
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
					},
					{
						title: t`Адреса`,
						dataIndex: 'fullAddress',
						ellipsis: true,
						key: 'fullAddress',
						responsive: ['sm'],
					},
					{
						title: t`Телефон`,
						dataIndex: 'phone',
						key: 'phone',
						ellipsis: true,
						responsive: ['sm'],
					},
				],
			},
			createButtonText: t`Додај складиште`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете ово складиште?`,
		},
		edit: {
			width: 500,
			title: {
				new: t`Додавање складишта`,
				existing: (entity) => `Измена складишта ${entity?.name || ''}`,
			},
			shouldFetch: false,
			fields: [
				{
					key: 'basic',
					label: t`Основни подаци`,
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							rules: [
								{
									required: true,
									message: t`Назив складишта је обавезан`,
								},
							],
							component: <Input />,
							span: 24,
						},
						{
							key: 'address',
							label: t`Адреса`,
							component: <Input />,
							span: 24,
						},
						{
							key: 'zip',
							label: t`Поштански број`,
							component: <Input />,
							xs: 24,
							sm: 12,
						},
						{
							key: 'city',
							label: t`Град`,
							component: <Input />,
							xs: 24,
							sm: 12,
						},
						{
							key: 'gln',
							label: t`ГЛН`,
							component: <Input />,
							span: 24,
							rules: [
								{
									validator: (_, value) => {
										if (!value || value.length === 0) {
											return Promise.resolve();
										}

										if (!gs1.validate(value)) {
											return Promise.reject();
										}
										return Promise.resolve();
									},
									message: t`ГЛН није исправан`,
								},
							],
						},
						{
							key: 'phone',
							label: t`Телефон`,
							component: <Input />,
							span: 24,
						},
					],
				},
			],
		},
	});
