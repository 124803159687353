import { t, Trans } from '@lingui/macro';
import { Button, Drawer, Input, Space, Table } from 'antd';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import styles from './AdvanceFinalizeSpecification.module.less';
import { StaticComponents } from '../../../StaticComponents';
interface Props {
	visible: boolean;
	labels: Record<string, number>;
	items: any[];
	onCancel: () => void;
	onSave: (data: any) => void;
	sum: number;
}

const AdvanceFinalizeSpecification = ({
	visible,
	onCancel,
	onSave,
	labels,
	items,
	sum,
}: Props) => {
	const [data, setData] = useState([]);
	useEffect(() => {
		setData(
			items.map((item) => {
				return {
					...item,
					totalAmount: 0,
				};
			})
		);
	}, [items, labels, labels.length]);
	return (
		<Drawer
			destroyOnClose
			open={visible}
			title={t`Спецификација продатих артикала`}
			width={800}
			bodyStyle={{ padding: 0, marginTop: -1, marginBottom: -1 }}
			onClose={onCancel}
			footer={
				<>
					<Space className={styles.leftButtons}>
						<Button onClick={onCancel}>
							<Trans>Одустани</Trans>
						</Button>
					</Space>
					<Space className={styles.rightButtons}>
						<Button
							type="primary"
							onClick={() => {
								const totalPerLabel = data.reduce((acc, curr) => {
									const label = curr.taxRateLabel;
									if (!acc[label]) {
										acc[label] = 0;
									}
									acc[label] = round(acc[label] + curr.totalAmount, 2);
									return acc;
								}, {});

								const total = round(
									data.reduce((acc, curr) => acc + curr.totalAmount, 0),
									2
								);
								for (const label in totalPerLabel) {
									if (totalPerLabel[label] < labels[label]) {
										return StaticComponents.notification.error({
											message: t`Грешка`,
											description: t`За пореску ознаку ${label} износ није једнак или већи уплаћеном авансу!`,
										});
									}
								}

								if (sum !== total) {
									return StaticComponents.notification.error({
										message: t`Грешка`,
										description: t`Укупан износ није једнак уплаћеном авансу!`,
									});
								}

								onSave(data);
							}}
						>
							<Trans>Потврди</Trans>
						</Button>
					</Space>
				</>
			}
			className="noPadding"
		>
			<Table
				size="small"
				pagination={false}
				bordered
				dataSource={data}
				sticky
				// rowKey={(record) => record.label}
				columns={[
					{
						title: t`Назив артикла`,
						dataIndex: ['product', 'name'],
						key: 'name',
					},
					{
						title: t`Количина`,
						dataIndex: 'quantity',
						key: 'quantity',
						width: 100,
					},
					{
						title: t`Износ`,
						key: 'totalAmount',
						width: 150,
						render: (_, record, index) => (
							<Input
								onClick={(e) => e.currentTarget.select()}
								type="number"
								style={{ textAlign: 'right' }}
								value={record.totalAmount}
								onChange={(e) => {
									const value = e.target.value;
									setData((data) => {
										const newData = [...data];
										newData[index].unitPrice = round(
											Number(value) / record.quantity,
											2
										);

										newData[index].finalPrice = newData[index].unitPrice;
										newData[index].totalAmount = Number(value);
										return newData;
									});
								}}
							/>
						),
						align: 'right',
					},
				]}
			></Table>
		</Drawer>
	);
};

const ObservedAdvanceFinalizeSpecification = observer(
	AdvanceFinalizeSpecification
);

export default ObservedAdvanceFinalizeSpecification;
