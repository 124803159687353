import { t } from '@lingui/macro';
import { Button, Popconfirm } from 'antd';
import { useCallback } from 'react';

import { usePermissions } from '../../../../../../hooks/permissions';
import {
	StockTurnover,
	StockTurnoverStatus,
	StockTurnoverType,
} from '../../../../../../stores/StockTurnover.mobx';

type Props = {
	record: StockTurnover;
};

export function DeleteButton({ record }: Props) {
	const canDeletePermission = usePermissions('stocks', 'turnover', 'delete');

	const handleDestroy = useCallback(() => {
		if (record.type === StockTurnoverType.INITIAL) {
			record?.initialStock?.destroy();
		}

		if (record.type === StockTurnoverType.CALCULATION) {
			record?.calculation?.destroy();
		}
	}, [record]);

	return (
		<Popconfirm
			placement="topRight"
			title={t`Да ли сте сигурни да желите да обришете овај запис?`}
			onConfirm={handleDestroy}
			okText={t`Да`}
			cancelText={t`Не`}
		>
			<Button
				icon={<i className="fi fi-rr-trash"></i>}
				disabled={
					!canDeletePermission ||
					record.status === StockTurnoverStatus.CONFIRMED
				}
			/>
		</Popconfirm>
	);
}
