import { t, Trans } from '@lingui/macro';
import { Button, Drawer, Input, message, Space, Table, Tooltip } from 'antd';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import {
	addToDrawersRegistry,
	useDrawer,
} from '../../../../../../components/Page';
import PaymentModal from '../../../../../../components/PaymentModal/PaymentModal';
import { StaticComponents } from '../../../../../../components/StaticComponents';
import {
	InvoiceType,
	TransactionType,
} from '../../../../../../constants/invoice';
import {
	INVOICE_TYPE_FROM_STRING,
	TRANSACTION_TYPE_FROM_STRING,
} from '../../../../../../constants/journal';
import numberFormat from '../../../../../../lib/numberFormat';
import { PaymentModalMode } from '../../../../../../lib/receipt';
import stores from '../../../../../../stores/index.mobx';
import { Receipt } from '../../../../../../stores/Receipt.mobx';
import styles from '../../List.module.less';
const Refund = () => {
	const [receiptId, , close, visible] = useDrawer('receipt-refund');
	const [, setViewQueryParam] = useQueryParam('view', StringParam);

	const {
		receipts: { single, fetchSingle, isFetchingSingle },
	} = stores;

	const [data, setData] = useState([]);
	const [paymentModalVisible, setPaymentModalVisible] = useState(false);

	const total = useMemo(() => {
		return round(
			data.reduce((acc, cur) => acc + cur.refundQuantity * cur.unitPrice, 0),
			2
		);
	}, [data]);
	const totalQuantity = useMemo(() => {
		return data.reduce((acc, cur) => acc + cur.refundQuantity, 0);
	}, [data]);

	const receipt = useMemo(() => (visible ? single : null), [single, visible]);

	useEffect(() => {
		if (visible) {
			fetchSingle(receiptId);
		}
	}, [fetchSingle, receiptId, visible]);

	useEffect(() => {
		if (receipt) {
			setData(
				receipt.receiptItems.map((item) => {
					const refundedQuantity = receipt.connectedReceipts
						.filter(
							(cr) =>
								TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
									TransactionType.REFUND &&
								[
									InvoiceType.NORMAL,
									InvoiceType.PROFORMA,
									InvoiceType.TRAINING,
								].includes(INVOICE_TYPE_FROM_STRING[cr.invoiceType]) &&
								!cr.void &&
								!cr.voids
						)
						.reduce((acc, cur) => {
							return (
								acc +
								cur.receiptItems
									.filter((ri) => ri.productId === item.productId)
									.reduce((acc, cur) => acc + cur.quantity, 0)
							);
						}, 0);
					return {
						...item,
						key: item.productId,
						refundQuantity: null,
						leftQuantity: round(item.quantity - refundedQuantity, 3),
					};
				})
			);
		}
	}, [receipt, visible]);

	const updateQuantity = useCallback(
		(id, quantity) => {
			const newData = [...data];
			const index = newData.findIndex((item) => item.key === id);
			if (index !== -1) {
				newData[index].refundQuantity = quantity;
				setData(newData);
			}
		},
		[data]
	);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys: data
				.filter((item) => Number(item.refundQuantity) > 0)
				.map((item) => item.productId),
			onChange: (selectedRowKeys) => {
				const currentlySelected = data
					.filter((item) => Number(item.refundQuantity) > 0)
					.map((item) => item.productId);

				const diff = currentlySelected.filter(
					(item) => !selectedRowKeys.includes(item)
				);
				selectedRowKeys.forEach((key) => {
					updateQuantity(
						key,
						data.find((item) => item.productId === key).leftQuantity
					);
				});

				diff.forEach((key) => {
					updateQuantity(key, 0);
				});
			},
		}),
		[data, updateQuantity]
	);

	const onConfirmClick = useCallback(() => {
		for (let i = 0; i < data.length; i++) {
			const item = data[i];
			if (item.refundQuantity > item.leftQuantity) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Рефундирана количина не може бити већа од купљене количине. Проверите артикал ${item.name}`,
				});
			}
			if (totalQuantity === 0) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Није одабран ни један артикал за рефундацију`,
				});
			}
		}
		setPaymentModalVisible(true);
	}, [data, totalQuantity, setPaymentModalVisible]);

	const refundReceipt = useMemo(() => {
		if (visible && receipt) {
			const clonedReceipt = receipt.toPlain();
			clonedReceipt.receiptItems = data
				.filter((item) => item.refundQuantity > 0)
				.map((item) => {
					return {
						...item,
						quantity: item.refundQuantity,
					};
				});

			clonedReceipt.totalAmount = total;
			return new Receipt(clonedReceipt, null);
		}
	}, [data, receipt, total, visible]);

	return (
		<Drawer
			destroyOnClose
			open={visible}
			title={t`Рефундација рачуна ${receipt?.invoiceNumber}`}
			width={800}
			bodyStyle={{ padding: 0, marginTop: -1, marginBottom: -1 }}
			onClose={close}
			// zIndex={zIndex}
			footer={
				<>
					<Space className={styles.leftButtons}>
						<Button onClick={close}>
							<Trans>Одустани</Trans>
						</Button>
					</Space>
					<Space className={styles.rightButtons}>
						<Button type="primary" onClick={onConfirmClick}>
							<Trans>Потврди</Trans>
						</Button>
					</Space>
				</>
			}
			className={`${styles.refundDrawer} noPadding`}
		>
			<Table
				loading={isFetchingSingle}
				size="small"
				pagination={false}
				dataSource={data}
				rowSelection={rowSelection}
				scroll={{ x: 450 }}
				sticky
				rowKey={(record) => record.productId}
				className={styles.refundTable}
				columns={[
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
					},
					{
						title: t`Преостало`,
						key: 'leftQuantity',
						dataIndex: 'leftQuantity',
						render: (leftQuantity) =>
							numberFormat(leftQuantity, false, 3, false),
						width: 100,
						align: 'right',
					},
					{
						title: t`Количина`,
						key: 'refundQuantity',
						render: (record) => (
							<Tooltip
								title={
									record.refundQuantity > record.leftQuantity
										? t`Рефундирана количина не може бити већа од купљене количине`
										: null
								}
							>
								<Input
									status={
										record.refundQuantity > record.leftQuantity ? 'error' : null
									}
									type="number"
									style={{ textAlign: 'right' }}
									value={record.refundQuantity}
									onClick={(e) => e.stopPropagation()}
									onChange={(e) => updateQuantity(record.key, e.target.value)}
								/>
							</Tooltip>
						),
						width: 90,
						align: 'right',
					},
					{
						title: t`Вредност`,
						key: 'total',
						render: (record) =>
							numberFormat(
								record.refundQuantity * record.unitPrice,
								false,
								2,
								true
							),
						width: 100,
						align: 'right',
					},
				]}
			></Table>
			<PaymentModal
				initialMode={PaymentModalMode.refund}
				open={paymentModalVisible}
				onClose={() => {
					setPaymentModalVisible(false);
					close();
					setViewQueryParam(null);
				}}
				initialReceipt={refundReceipt}

				// type="refund"
				// visible={paymentModalVisible}
				// items={data
				// 	.filter((item) => item.refundQuantity > 0)
				// 	.map((item) => ({
				// 		...item,
				// 		product: {
				// 			id: item.key,
				// 			gtin: item.gtin,
				// 			name: item.name,
				// 			price: item.unitPrice,
				// 			taxRateLabel: item.taxLabels[0],
				// 			unit: item.unit,
				// 		},
				// 		finalPrice: item.unitPrice,
				// 		quantity: item.refundQuantity,
				// 	}))}
				// amount={total}
				// receipt={receipt}
				// prefillPayment={[0, 0, 0, 0, 0, 0, 0]}
				// closePayment={() => {
				// 	setPaymentModalVisible(false);
				// 	close();
				// 	setViewQueryParam(null);
				// }}
			/>
		</Drawer>
	);
};

const ObservedRefund = observer(Refund);

addToDrawersRegistry('receipt-refund', ObservedRefund);

export default ObservedRefund;
