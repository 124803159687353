import { t, Trans } from '@lingui/macro';
import { Dropdown, Grid, Menu, message, Upload } from 'antd';
import { useMemo, useState } from 'react';
import React from 'react';

import { StaticComponents } from '../../../../../../components/StaticComponents';
import { usePermissions } from '../../../../../../hooks/permissions';
import { v2Client } from '../../../../../../store/client';
import stores from '../../../../../../stores/index.mobx';

interface Props {
	createButtonHandler: () => void;
}

export default function CustomCreateButton({ createButtonHandler }: Props) {
	const [isUploading, setIsUploading] = useState(false);
	const canCreatePermission = usePermissions('catalog', 'products', 'create');

	const {
		products: { fetchAll },
	} = stores;

	const menu = useMemo(() => {
		return (
			<Menu
				onClick={(item) => {
					if (item.key === '2') {
						window.open(
							v2Client.defaults.baseURL +
								'/products/export?token=' +
								v2Client.defaults.headers.common['x-access-token']
						);
					}
				}}
				items={[
					{
						label: (
							<Upload
								disabled={isUploading}
								name="file"
								action={v2Client.defaults.baseURL + '/products/import'}
								headers={{
									'x-access-token':
										v2Client.defaults.headers.common['x-access-token'],
								}}
								showUploadList={false}
								onChange={(info) => {
									if (info.file.status !== 'uploading') {
										setIsUploading(true);
									}
									if (info.file.status === 'done') {
										StaticComponents.notification.success({
											message: t`Увоз артикала је завршен.`,
										});
										fetchAll();
										setIsUploading(false);
									} else if (info.file.status === 'error') {
										StaticComponents.notification.error({
											message: t`Грешка`,
											description: t`Увоз артикала није успешан, проверите да ли је CSV фајл у исправном формату`,
										});
										setIsUploading(false);
									}
								}}
							>
								<Trans>Увоз артикала</Trans>
							</Upload>
						),
						key: '1',
					},
					{
						label: t`Извоз артикала`,
						key: '2',
					},
				]}
			/>
		);
	}, []);

	const screens = Grid.useBreakpoint();

	return canCreatePermission ? (
		<Dropdown.Button
			loading={isUploading}
			disabled={isUploading}
			icon={<i className="fi fi-rr-angle-small-down"></i>}
			overlay={menu}
			type="primary"
			onClick={createButtonHandler}
		>
			{screens.xs ? t`Додај` : t`Додај артикал`}
		</Dropdown.Button>
	) : null;
}
